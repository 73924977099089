import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {LoadingCircle} from 'utils/loadingCircle';

import {
  CurrencyEnum,
  ExploitationDigitalEnum,
  ExploitationPhysicalEnum,
  ExploitationYoutubeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
  ProtectiveUmbrellaEnum,
  RightsEndEnum,
  TaxesEnum,
  TimeToFinishEnum,
} from 'constants/conditionEnums';
import {TransactionFee} from 'pages/MyStudio/Conditions/EditCondition';
import {WinWinSvg} from 'components/SvgComponents';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {useImage} from 'contexts/ImageContext';
import api from 'services/axiosConfig';
import coverImage from 'images/product-detail-cover.png';
import trackSvg from 'images/svg/track.svg';
import producerPhoto from 'images/producer-photo.png';
import playIcon from 'images/svg/play-icon.svg';
import winWinIcon from 'images/svg/win-win-icon.svg';
import locationIcon from 'images/svg/grey-location-icon.svg';
import approveIcon from 'images/svg/approve-icon-product.svg';
import cancelIcon from 'images/svg/cancel-icon-product.svg';
import exclamationIcon from 'images/svg/exclamation-mark-product.svg';
import pauseIcon from 'images/svg/pause-icon.svg';
import demoMp3 from 'utils/demo.mp3';
import {Offer} from '../../Products/index';
import {hexToRgb} from 'pages/MyStudio/BusinessCard';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import OrderDetails from 'pages/OrderDetails';
import MusicPlayer from 'components/MusicPlayer';
import LoginPage from 'pages/Identity/Login';
import newMobileTrackPlayerSvg from 'images/svg/new-mobile-track-player.svg';
import newDesktopTrackPlayerSvg from 'images/svg/new-desktop-track-player.svg';
import {
  NewDesktopTrackPlayerSvg,
  NewMobileTrackPlayerSvg,
} from 'components/SvgComponents';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {useIsOrderPageOpenContext} from 'contexts/IsOrderPageOpenContext';
import SendMessageBottomSheet from 'components/SendMessageBottomSheet';
import {ExpandableHeaderWithDescription} from 'components/ExpandableHeaderWithDescription';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {
  ConditionSectionDescription,
  ConditionSectionDescriptionExample,
  SectionHeaderNoEditing,
  SellerObligationWrapper,
  SelectViewContainer,
  ConditionTitleWithInputWrapper,
  DisplayGrossValueNoEditing,
  DisplayGrossValueWithTaxNoEditing,
  BlueHeaderComponent,
} from 'pages/MyStudio/Conditions/EditCondition';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const ExpandableHeaderWithDescriptionWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  & > div {
    & > div {
      width: 100%;
      padding: 0 25px;
      justify-content: space-between;
      margin-bottom: unset;

      & > h1 {
        letter-spacing: 0.0625px;
        font-weight: 500;
      }
    }
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: 742px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.columnBorder};
  background: ${({theme}) => theme.colors.headerContentBackground};
  position: absolute;
  top: 88px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 21px 21px;
  /* margin-bottom: 20px; */
  /* margin-top: 10px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 380px;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  background: #0091ff;
  height: 55px;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 18px;
    font-weight: 400;
  }
`;

export const BackButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  background: transparent;
  border-radius: 24px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const SendMessageButton = styled.button`
  width: 149px;
  min-height: 32px;
  cursor: pointer;
  background: ${({theme}) => theme.colors.blueTheme};
  border-radius: 9px;
  font-weight: 500;
  justify-self: flex-end;
  font-size: 13px;
  letter-spacing: 0.1px;
  border: none;
  color: #ffffff;
  transition: all 0.15s;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* position: unset; */
    margin: 0 auto;
    top: 10px;
    right: 11px;
  }
`;

export const DownloadButtonWrapper = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    letter-spacing: 0.625px;
    color: rgba(53, 47, 64, 0.9);
    font-weight: 400;
  }
`;

export const DownloadButton = styled.button`
  width: calc(100% - 20px);
  min-height: 44.33px;
  border: 2px solid #0091ff;
  background: transparent;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 400;
  justify-self: flex-end;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.downloadButtonFontColor};
  transition: all 0.15s;
  margin-bottom: 11px;

  &:hover {
    filter: brightness(0.97);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 46px);
  }
`;

export const Grid = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  border-radius: 9px;
  display: flex;
  background: ${({theme}) => theme.colors.trackDetailBackground};
`;

export const LeftSection = styled.div`
  width: 683px;
  padding: 9px 14px 14px;
  display: flex;
  grid-column: span 2;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 21px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: ${({theme}) => theme.colors.mobileTrackDetailTopBackground};
  }
`;

export const CenterSection = styled.div`
  width: 100%;
  height: calc(100% - 10px - 14px);
  margin-top: 10px;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  border-right: 1px solid rgba(255, 255, 255, 0.21);

  @media (max-width: 1100px) {
    border-left: none;
    border-right: none;
    border-top: 1px solid rgba(255, 255, 255, 0.21);
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    padding: 8px 14px;
  }
`;

export const CategoriesWrapper = styled.div`
  width: 100%;
  min-height: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;

  & > div {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid rgba(112, 112, 112, 0.3);

    & > p {
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.15px;
    }
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* gap: 3px; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;

  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(53, 47, 64, 0.5);
    letter-spacing: 0.625px;
  }
`;

export const CoverWrapperWithTrackPlayer = styled.div`
  height: 100%;
  width: 314px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.15s;
`;

export const CoverWrapper = styled.div<{hasLongDescription?: boolean}>`
  height: ${({hasLongDescription}) => (hasLongDescription ? '104px' : '314px')};
  aspect-ratio: 1;
  border-radius: 11px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 11px;
    cursor: pointer;
  }

  & > h5 {
    line-height: 21px;
    letter-spacing: -0.25px;
    font-size: 18px;
    font-weight: bold;
    color: #07020f;
    position: absolute;
    bottom: 12px;
    left: 0px;
    right: 0px;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 358px;
    height: 358px;
    position: relative;
    border-radius: 16px;

    ::before {
      content: '';
      position: absolute;
      top: 149px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px;
      background: linear-gradient(
        transparent,
        transparent 0%,
        rgba(255, 255, 255, 0.9)
      );
      pointer-events: none;
    }

    & > img {
      border-radius: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    height: unset;

    & > img {
      height: unset;
      aspect-ratio: 1;
    }
  }
`;

export const MobileNameWithImageAndDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h5 {
    width: 380px;
    text-align: center;
    line-height: 21px;
    letter-spacing: -0.15px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-top: 27px;
    margin-bottom: 21.5px;
  }

  & p {
    line-height: 18px;
    letter-spacing: -0.25px;
    font-size: 13px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
    /* min-height: 101px; */
    margin-top: 14.5px;
    width: 367px;
  }

  & > img {
    width: 367px;
    height: 367px;
    border-radius: 21px;
  }
`;

export const ProductNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 9px;
  margin-bottom: 6px;

  & > h1 {
    font-size: 21px;
    line-height: 24px;
    color: #352f40;
    font-weight: 700;
    letter-spacing: 0.625px;
    text-align: center;
  }
`;

export const ProductSymbolWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 11px;
    line-height: 24px;
    color: rgba(53, 47, 64, 1);
    font-weight: 300;
    letter-spacing: 0.625px;
  }

  & > h5 {
    font-size: 11px;
    line-height: 24px;
    color: rgba(53, 47, 64, 0.7);
    font-weight: 300;
    letter-spacing: 0.625px;
  }
`;

export const ConditionDetailsWithButtonWrapper = styled.div`
  /* min-height: 257px; */
  width: calc(100% - 20px);
  max-height: 513px;
  overflow-y: auto;
  /* width: 101%; */
  background: ${({theme}) => theme.colors.trackDetailBackground};
  padding: 0px 7px 14px;
  border-radius: 16px;
  padding-right: 0;
  border: 1px solid rgba(112, 112, 112, 0.3);
  /* border: 1px solid rgba(112, 112, 112, 0.3); */
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin-top: 9px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > p {
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 367px;
    height: auto;
    min-height: unset;
    max-height: unset;
    padding: 30px 19px 30px;
    background: ${({theme}) => theme.colors.mobileTrackDetailTopBackground};
    margin-bottom: 24px;
  }
`;

export const ConditionDetailsWrapper = styled.div`
  height: calc(100% - 0px);
  overflow-y: auto;
  width: 101%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > p {
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    height: auto;
    overflow-y: unset;
  }
`;

export const ProductDescriptionWrapper = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  min-height: 339px;
  max-height: 363px;
  overflow-y: auto;
  margin: 0 0 10px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h4 {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 0.625px;
  }

  & > p {
    margin-left: 8px;
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    inline-size: 304px;
    overflow-wrap: break-word;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.625px;
    text-align: justify;
  }
`;

export const TrackCoverWrapper = styled.div`
  min-width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
    border-radius: 9px;
  }
`;

export const TrackDetailWrapper = styled.div`
  width: 258px;
  height: 116px;
  padding: 0px 21px 0px 25px;
  gap: 19px;
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: rgba(213, 213, 213, 0.05); */
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  margin-top: 10px;
`;

export const PlayButtonWrapper = styled.div`
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  cursor: pointer;
  @media (max-width: 1024px) {
    position: absolute;
    right: 5px;
    top: 55px;
    z-index: 4px;
  }
`;

export const TrackNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: Center;
  height: 100%;

  & > img {
    user-select: none;
    pointer-events: none;
  }
`;

export const SingleCardWrapper = styled.div`
  width: 100%;
  height: 58px;
  /* border-radius: 16px; */
  display: flex;
  align-items: center;
  position: relative;
  /* cursor: pointer; */
  gap: 9px;
  transition: all 0.15s;
`;

export const SingleCardImageWrapper = styled.div`
  height: 58px;
  width: 58px;
  border-radius: 13px;
  background: white;

  & > img {
    height: 58px;
    width: 58px;
    aspect-ratio: 1;
    border-radius: 13px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background: transparent;

    & > img {
      width: 48px;
      height: 48px;
      border-radius: 16px;
    }
  }
`;

export const SingleCardContentWrapper = styled.div`
  height: 100%;
  width: calc(100% - 58px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.15s;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
  }
`;

export const DefaultBlockName = styled.h5`
  width: 406px;
  font-size: 13px;
  color: ${({theme}) => theme.colors.grayFontOp100};
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.15s;
  position: relative;

  & > svg {
    position: absolute;
    top: 0px;
    right: 8px;
    transition: all 0.15s;

    & > path {
      fill: #352f40;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: unset;
    color: ${({theme}) => theme.colors.blackFontOp100};
    width: 146px;
  }
`;

export const DefaultBlockRole = styled.p`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.25px;
  color: ${({theme}) => theme.colors.grayFontOp100};
  transition: all 0.15s;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 11px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const DefaultBlockLocation = styled.span`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.25px;
  color: ${({theme}) => theme.colors.grayFontOp100};
  transition: all 0.15s;

  & > img {
    width: 10px;
    height: 14px;
    margin-right: 11px;
    transition: all 0.15s;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 11px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const AboutMeWrapper = styled.div`
  width: 101%;
  height: 126px;
  overflow-y: auto;
  padding: 11px 4px 0px 9px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h4 {
    font-size: 11px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 21px;
    font-weight: 500;
  }

  & > p {
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  & > hr {
    border: none;
    border-bottom: 1px solid rgba(53, 47, 64, 0.3);
    margin-bottom: 18px;
    margin-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 12.5px 51px 19px 19px;
    overflow-y: unset;
    height: auto;

    & > p {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const AboutMeHeaderSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h4 {
    font-size: 11px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 21px;
    font-weight: 500;
  }

  & > p {
    font-size: 11px;
    line-height: 21px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const MobileProducerCardWithAboutMe = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.mobileAboutMeWrapperBackground};
  /* background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 80%,
    rgba(112, 112, 112, 0.13) 100%
  ); */
`;

export const ProducerCardWithAboutMe = styled.div`
  width: 100%;
  height: 151px;
  display: flex;
  flex-direction: column;
  padding: 12px 17px 5px 14px;
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: auto;
    width: 367px;
    padding: 1.5px 2px 0px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    background: ${({theme}) => theme.colors.trackDetailContentBackground};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
    /* margin-top: 26px; */
  }
`;

export const ConditionDetailsBox = styled.div`
  background: ${({theme}) => theme.colors.headerContentBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 28px);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    background: ${({theme}) => theme.colors.trackDetailContentBackground};
  }
`;

export const RightSection = styled.div`
  /* padding: 0px 0px 10px 0px; */
  /* width: 334px; */
  width: 337px;
  /* margin: 8px 8px 0px 0px; */
  display: flex;
  height: calc(100% - 5px);
  margin-top: 5px;
  border-radius: 0 0 21px 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 11px 9px;
    padding-top: 0px;
    margin-top: 0px;
    background: transparent;
    width: 100%;
  }
`;

export const ConditionSelectWrapper = styled.div<{howManyConditions?: number}>`
  width: 100%;
  min-height: 28px;
  border-radius: 9px 9px 0 0;
  background: transparent;
  gap: 2px;
  display: grid;
  align-items: center;
  grid-template-columns: ${({howManyConditions}) =>
    howManyConditions ? `repeat(${howManyConditions},1fr)` : '1fr'};
`;

export const ConditionDescriptionWrapper = styled.div`
  width: 100%;
  /* min-height: 18px; */
  max-height: 54px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: 8px 0;
  padding: 0 17px;

  & > p {
    text-align: left;
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.625px;
    line-height: 24px;
    font-weight: 400;
  }
`;

export const WinWinWithPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

export const WinWinInfo = styled.div<{isWinWin?: boolean}>`
  visibility: ${({isWinWin}) => (isWinWin ? 'visible' : 'hidden')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: unset;
    align-self: center;
    display: ${({isWinWin}) => (isWinWin ? 'block' : 'none')};

    & > svg {
      width: 95.19px;
      height: 24px;
    }
  }
`;

export const PriceWithWinWinWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2px 18px 8px 20px; */
  padding: 0 25px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
  }
`;

export const PriceWithTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > h4 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    padding: 0 30px;
    color: ${({theme}) => theme.colors.fontBlackOp100};

    & > span {
      font-size: 21px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;
  }
`;

export const CoverWrapperWithName = styled.div<{hasLongDescription?: boolean}>`
  width: 100%;
  height: ${({hasLongDescription}) => (hasLongDescription ? '114px' : '344px')};
  display: flex;
  flex-direction: row;
  gap: ${({hasLongDescription}) => (hasLongDescription ? '10px' : '19px')};
  padding: 13px 14px;
  border-radius: 16px;
  background: ${({theme}) => theme.colors.trackDetailContentBackground};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: auto;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 8px 8px 13px;
    gap: 8px;
    flex-direction: column;
    align-items: center;
  }
`;

export const LongDescriptionExpandButton = styled.button<{
  isDescriptionExpanded?: boolean;
}>`
  width: ${({isDescriptionExpanded}) =>
    isDescriptionExpanded ? '29px' : '78px'};
  border: none;
  background: transparent;
  font-size: 13px;
  letter-spacing: -0.15px;
  position: absolute;
  color: #3a87f0;
  line-height: 18px;
  font-weight: bold;
  cursor: pointer;
  right: 8px;
  bottom: 4px;
`;

export const CategoriesWithSymbolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  & > h4 {
    line-height: 11px;
    letter-spacing: -0.05px;
    font-size: 11px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    margin-top: 7px;
  }
`;

export const ProductNameWithDescriptionWrapper = styled.div<{
  hasLongDescription?: boolean;
}>`
  height: 100%;
  width: ${({hasLongDescription}) =>
    hasLongDescription ? 'calc(100% - 104px)' : '100%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.15s;
  /* overflow-y: auto; */
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & h5 {
    line-height: 21px;
    letter-spacing: -0.15px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-top: 10px;
  }

  & p {
    line-height: 18px;
    letter-spacing: -0.25px;
    font-size: 13px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
    min-height: 101px;
    margin-top: 20px;
  }

  & h4 {
    line-height: 11px;
    letter-spacing: -0.05px;
    font-size: 11px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    margin-top: 7px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: auto;
    width: 100%;
    padding: 0px;
    overflow-y: unset;

    & > p {
      padding: 0 10px;
      margin-bottom: 12px;
      min-height: unset;
      margin-top: unset;
    }
  }
`;

export const LongDescriptionWrapper = styled.div<{
  hasLongDescription?: boolean;
  isDescriptionExpanded?: boolean;
}>`
  height: 268px;
  width: 100%;
  display: ${({hasLongDescription}) => (hasLongDescription ? 'flex' : 'none')};
  flex-direction: column;
  border: 1px solid rgba(112, 112, 112, 0.3);
  padding: 11px 24px 13px;
  border-radius: 16px;

  transition: all 0.15s;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h5 {
    line-height: 24px;
    letter-spacing: 0.1px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > p {
    line-height: 18px;
    letter-spacing: 0.25px;
    font-size: 13px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    overflow-y: unset;
    position: relative;
    height: auto;
    padding: 17px 19px 32px;
    margin-top: 7px;

    & > h5 {
      text-align: unset;
    }

    & > p {
      ${({isDescriptionExpanded}) =>
        !isDescriptionExpanded &&
        css`
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        `}
    }
  }
`;

export const SingleCondition = styled.div<{
  value: number;
  currentCondition: number;
  howManyConditions?: number;
}>`
  width: 100%;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px 9px 0 0;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(0, 145, 255, 0.05085784313725494) 0%,
    rgba(0, 145, 255, 0.14609593837535018) 100%
  );

  ${({value, currentCondition, theme}) =>
    value === currentCondition &&
    css`
      background: ${theme.colors.headerContentBackground};
      cursor: default;

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        background: ${({theme}) => theme.colors.trackDetailContentBackground};
      }
    `}

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: transparent;

    & > p {
      font-size: 13px;
      width: 100%;
      letter-spacing: -0.15px;
      color: ${({value, currentCondition, theme}) =>
        value === currentCondition
          ? theme.colors.fontBlackOp100
          : 'rgba(0, 145, 255, 0.5)'};
      font-weight: ${({value, currentCondition, theme}) =>
        value === currentCondition ? 'bold' : '500'};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TermsWrapper = styled.div`
  width: 100%;
  /* padding: 0px 2px; */
  display: flex;
  gap: 9px;
  flex-direction: column;
  margin: 6px 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

  &:first-child {
    margin-top: 14px;
  }

  &:nth-child(6) {
    border-bottom: unset;
  }

  &:nth-child(7) {
    & > div {
      justify-content: space-between;

      & > p {
        width: 244px;
      }
    }
  }

  & > div {
    width: 100%;
    margin: 2px 0;
    display: flex;
    gap: 8px;
    /* align-items: center; */

    &:last-child {
      margin-bottom: 20px;
    }

    & > img {
      width: 14px;
      height: 14px;
      margin-top: 2px;
    }

    & > p {
      font-size: 13px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      line-height: 18px;
      font-weight: 400;
      letter-spacing: 0.05px;
    }

    & > span {
      width: 36px;
      font-size: 13px;
      letter-spacing: 0.625px;
      font-weight: bold;
      text-align: right;
      line-height: 18px;
      color: #0066ff;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      gap: 15px;
    }
  }
`;

export const DisplayPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 49px;

  & > p {
    font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-weight: 400;
    letter-spacing: 0;
  }
`;

export const PriceWithCurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & > p {
    font-size: 28px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 700;
    letter-spacing: -0.25px;
  }

  & > span {
    font-size: 28px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.25px;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    background: ${({theme}) => theme.colors.mobileTrackDetailBackground};
  }
`;

export const MobileMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  flex-direction: column;
  align-items: center;
  background: transparent;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: unset;
  }
`;

export const SendMessageLink = styled(Link)`
  width: 100%;
  text-decoration: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileLoginPageWrapper = styled.div<{isOpen: boolean}>`
  width: 100%;
  background: white;
  border-top: ${({isOpen}) => (isOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isOpen}) => (isOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
`;

export const FixedMobileProductName = styled.div<FixedMobileProductNameProps>`
  width: 100%;
  height: 53px;
  position: fixed;
  top: 0;
  border-bottom: 1px solid #dce0e9;
  z-index: 2;
  background: #ffffff;
  display: flex;
  transition: visibility 1s;
  visibility: ${({isMobileProductTitleVisible}) =>
    isMobileProductTitleVisible ? 'visible' : 'hidden'};
  align-items: center;
  justify-content: center;
  animation: ${({isMobileProductTitleVisible}) =>
    isMobileProductTitleVisible
      ? css`
          ${fadeIn} 1s
        `
      : css`
          ${fadeOut} 1s
        `};
  opacity: ${({isMobileProductTitleVisible}) =>
    isMobileProductTitleVisible ? '1' : '0'};

  & > h5 {
    line-height: 21px;
    letter-spacing: -0.25px;
    font-size: 18px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);

  & > h5 {
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-bottom: 10px;
  }

  & > p {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.05px;
    margin-bottom: 20px;
  }
`;

export const ButtonsSpan = styled.div`
  display: flex;
  gap: 11px;
`;

export const MobileTrackPlayer = styled.div`
  width: 100%;
  height: 112px;
  position: relative;
  background: #f8f8f8;
  border-radius: 11px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    position: absolute;
    width: 48px;
    height: 48px;
  }
`;

export const DesktopTrackPlayerSvgWrapper = styled.div`
  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const DesktopTrackPlayer = styled.div`
  width: calc(100%);
  height: 138px;
  position: relative;
  background: ${({theme}) => theme.colors.trackDetailContentBackground};
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  & > svg {
    position: absolute;
    width: 48px;
    height: 48px;
  }

  & > img {
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 367px;
  }
`;

export const DownloadDemoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13.5px;
  background: #0091ff;
  border-radius: 9px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: white;
  width: 149px;
  height: 32px;
  position: absolute;
  bottom: 13px;
  right: 17px;

  & > a {
    text-decoration: none;
    color: white;
  }
`;

export const BuyButton = styled.button`
  background: ${({theme}) => theme.colors.blueTheme};
  cursor: pointer;
  width: calc(100% - 20px);
  min-height: 44.33px;
  border: none;
  border-radius: 16px;
  transition: all 0.15s;
  font-size: 15px;
  color: white;

  & > a {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 15px;
    text-decoration: none;
    letter-spacing: 0.1px;
    font-weight: 400;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 46px);
  }
`;

export const MobileHeader = styled.div`
  height: 54px;
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid
    ${({theme}) => theme.colors.mobileOfferDetailHeaderBorderColor};

  & > h1 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.07px;
    font-weight: 400;
  }

  & > svg {
    left: 21px;
    position: absolute;
  }
`;

export const TransparentDiv = styled.div<{isAudioPlayed: boolean}>`
  width: 100%;
  height: 100vh;
  /* background: rgba(255, 0, 0, 0.2); */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;

  display: ${({isAudioPlayed}) => (isAudioPlayed ? 'block' : 'none')};
`;

interface FixedMobileProductNameProps {
  isMobileProductTitleVisible: boolean;
}

interface Category {
  Description: string;
  Id: string;
  Name: string;
  ParentCategoryId: string | null;
  Position: number;
  ProductType: number;
}

interface Tag {
  Id: string;
  Name: string;
  ProductId: string;
}

interface File {
  FilePath: string;
  Id: string;
  ProductFileType: number;
  Name: string;
  Position: number;
  ProductId: string;
}

interface Condition {
  IndicationOfContentAuthor: null | string;
  AnotherLimits: string;
  AssignedProducts: null;
  AssignedProductsCount: number;
  CanPriceBeNegotiated: boolean;
  Currency: number;
  Description: string;
  ExploitationDigital: number;
  ExploitationPhysical: number;
  ExploitationYoutube: number;
  GrossValue: number;
  Id: string;
  Invoice: boolean;
  IsExclusive: boolean;
  Name: string;
  NetValue: number;
  ProducerId: string;
  ProtectiveUmbrella: number;
  RightsEnd: number;
  TransactionFee: TransactionFee;
  SellerObligations: string[];
  SellerRestrictions: string[];

  Tax: number;
  TimeToFinish: number;
  WinWinDigital: number;
  WinWinPhysical: number;
  WinWinYoutube: number;
  DateToUploadForCustomer: string | null;
}

interface AssignedCondition {
  Condition: Condition;
  ConditionId: string;
  OrdinalNumber: number;
  ProductId: string;
}

interface AttributeValues {
  AttributeId: string;
  Id: string;
  Name: string;
  Position: number;
}

interface Product {
  Id: string;
  Name: string;
  ProductStatus: number;
  ProductType: number;
  Categories: Category[];
  Tags: Tag[];
  IsLongDescription: boolean;
  LongDescription: null | string;
  Title: null | string;
  IsAvailable: boolean;
  Description: string;
  CoverFiles: File[];
  DemoFiles: File[];
  Offer: Offer;
  Symbol: string;
  AssignedConditions: AssignedCondition[];
  AttributeValues: AttributeValues[];
}

interface BaseBlock {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  Height: number;
  Id: string;
  IsRequiredOnCard: boolean;
  StartX: number;
  StartY: number;
  Type: number;
  Width: number;
}

interface Producer {
  AboutMe: string;
  BackgroundColorHex: string;
  BaseBlock: BaseBlock;
  CardId: string;
  Country: string;
  PostalCode: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerName: string;
  ProducerPhoto: string;
  Role: number;
  SecondaryRole: number;
  Street: string;
}

export interface City {
  City: string;
  Region: string;
}

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export interface ConditionOption {
  FieldName: string;
  FlagValue: number;
}

interface ProductOption {
  FieldName: string;
  FlagValue: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
`;

export const getSpecificRole = (roles: Role[], selectedRole: number) => {
  if (!roles || (!selectedRole && selectedRole !== 0)) return 0;
  return roles.filter((role) => role.FlagValue === selectedRole).length > 0
    ? roles.filter((role) => role.FlagValue === selectedRole)[0].FieldName
    : 'Nie znaleziono';
};

const TrackDetail: React.FC = () => {
  const [isAudioPlayed, setIsAudioPlayed] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isProducerCardLoading, setIsProducerCardLoading] =
    useState<boolean>(true);
  const [isProductLoading, setIsProductLoading] = useState<boolean>(true);
  const [offer, setOffer] = useState<Offer>({
    AvailabilityEndDate: '',
    AvailabilityStartDate: '',
    Category: null,
    SecondCategoryId: null,
    ThirdCategoryId: null,
    CoverFile: '',
    Description: '',
    Featured: false,
    ProducerFirstName: '',
    ProducerSecondName: '',
    GrossValue: '',
    Id: '',
    IsWinWin: false,
    Name: '',
    NegotiatedOffer: false,
    ProducerId: '',
    ProducerName: '',
    ProductDemoFile: '',
    ProductId: '',
    ProductType: 0,
    RightsEnd: 0,
    Tags: '',
    Currency: null,
    ProducerBuildingNumber: '',
    ProducerCountry: '',
    ProducerFlatNumber: '',
    ProducerPostalCode: '',
    ProducerRegion: '',
    ProducerStreet: '',
    ViewsInfo: {
      LastViewDate: '',
      ViewsCount: 0,
    },
  });
  const [cities, setCities] = useState<City[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [product, setProduct] = useState<Product | undefined>();
  const [producer, setProducer] = useState<Producer>({
    BackgroundColorHex: '',
    AboutMe: '',
    BaseBlock: {
      BackgroundColorHex: '',
      BlockOpacityIn0To100Ratio: 0,
      FontColorHex: '',
      Height: 0,
      Id: '',
      IsRequiredOnCard: false,
      StartX: 0,
      StartY: 0,
      Type: 0,
      Width: 0,
    },
    CardId: '',
    Country: '',
    PostalCode: '',
    ProducerFirstName: '',
    ProducerId: '',
    ProducerLastName: '',
    ProducerName: '',
    ProducerPhoto: '',
    Role: 0,
    SecondaryRole: 0,
    Street: '',
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [currentCondition, setCurrentCondition] = useState<number>(1);
  const [firstCondition, setFirstCondition] = useState<Condition | undefined>();
  const [secondCondition, setSecondCondition] = useState<
    Condition | undefined
  >();
  const [thirdCondition, setThirdCondition] = useState<Condition | undefined>();
  const {isOrderPageOpen, setIsOrderPageOpen} = useIsOrderPageOpenContext();
  const [isMobileProductTitleVisible, setIsMobileProductTitleVisible] =
    useState<boolean>(false);
  const [isLoginPageOpen, setIsLoginPageOpen] = useState<boolean>(false);
  const [isMobileNewMessageViewOpen, setIsMobileNewMessageViewOpen] =
    useState<boolean>(false);
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const {selectImage} = useImage();

  const targetRef = useRef<HTMLHeadingElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);

  const {
    AvailabilityEndDate,
    AvailabilityStartDate,
    Category,
    SecondCategoryId,
    ThirdCategoryId,
    CoverFile,
    Description,
    Featured,
    GrossValue,
    Id,
    IsWinWin,
    Name,
    NegotiatedOffer,
    ProducerId,
    ProducerName,
    ProductDemoFile,
    ProductId,
    ProductType,
    RightsEnd,
    Tags,
  } = offer;

  const {decodedToken} = useContext(ProducerDataContext);

  const BackgroundColorRgb = hexToRgb(producer.BaseBlock?.BackgroundColorHex);

  const params = useParams();

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const playAudio = (audio: HTMLAudioElement) => {
    audio.play();
    setIsAudioPlayed(true);
  };

  const pauseAudio = (audio: HTMLAudioElement) => {
    audio.pause();
    setIsAudioPlayed(false);
  };

  const increaseTheNumberOfViews = async (offerId: string) => {
    if (!offerId) return;
    try {
      const response = await api.put(`Offer/${offerId}/views`);
    } catch (error) {
      console.error(error);
    }
  };

  // const getOffer = async (offerId: string) => {
  //   try {
  //     const response = await api.get(`Offer/${offerId}`);
  //     setOffer(response.data.Response);
  //   } catch (error) {
  //     console.log(error);
  //     navigate('/tracks/main');
  //   }
  // };

  const getPdf = async () => {
    try {
      const body = {
        ProductId,
        ConditionId:
          currentCondition === 1
            ? firstCondition.Id
            : currentCondition === 2
            ? secondCondition.Id
            : thirdCondition.Id,
        TransactionId: null,
        Language: 'pl-pl',
      };

      const response = await api.post('DocumentGenerator', body, {
        headers: {Authorization: `bearer ${cookies.token}`},
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Podgląd_umowy_${Name}.pdf`);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const getProductDetails = async (productId: string) => {
    if (!productId) return;
    try {
      const response = await api.get(`products/${productId}`);
      setProduct(response.data.Response);
      setOffer(response.data.Response.Offer);
      if (
        response.data.Response?.DemoFiles?.filter(
          (file) => file.ProductFileType === 3,
        ).length > 0
      ) {
        setAudio(
          new Audio(
            response.data.Response?.DemoFiles.filter(
              (file) => file.ProductFileType === 3,
            )[0].FilePath,
          ),
        );
      }
      response.data.Response.AssignedConditions.map((condition, index) => {
        if (index === 0) setFirstCondition(condition.Condition);
        if (index === 1) setSecondCondition(condition.Condition);
        if (index === 2) setThirdCondition(condition.Condition);
        return;
      });
    } catch (error) {
      console.log(error);
      navigate('/tracks/main');
    } finally {
      setIsProductLoading(false);
    }
  };

  const getCitiesFromPostalCode = async (PostalCode: string) => {
    if (!PostalCode) return;
    try {
      const params = {
        PostalCode,
      };

      const response = await api.get('Localizations', {params});
      setCities(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducerCard = async (producerId: string) => {
    if (!producerId) return;
    try {
      const response = await api.get(`producer/card/${producerId}`);
      setProducer(response.data.Response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProducerCardLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsMobileNewMessageViewOpen = (value: boolean) =>
    setIsMobileNewMessageViewOpen(value);

  const handleIsLoginPageOpenChange = (value: boolean) =>
    setIsLoginPageOpen(value);

  const handleCurrentConditionChange = (value: number) =>
    setCurrentCondition(value);

  const checkVisibility = () => {
    if (targetRef.current) {
      const {top, bottom} = targetRef.current.getBoundingClientRect();
      const isVisible = top >= 0 && bottom <= window.innerHeight - 0;
      setIsMobileProductTitleVisible(!isVisible);
    }
  };

  const downloadDemoFile = (id) => {
    if (product?.DemoFiles?.filter((file) => file.Id === id).length > 0) {
      product?.DemoFiles?.filter((file) => file.Id === id).map((file, i) => {
        const {Id, FilePath, Name} = file;

        if (i > 0) return;
        const link = document.createElement('a');
        link.href = FilePath;
        link.target = '_blank';
        link.download = Name;
        link.click();
      });
    }
  };

  useEffect(() => {
    checkVisibility();

    window.addEventListener('scroll', checkVisibility);

    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  useEffect(() => {
    // getOffer(params.id);
    getProductDetails(params.id);
    getRoles();
  }, []);

  useEffect(() => {
    getProducerCard(ProducerId);
    increaseTheNumberOfViews(offer.Id);
  }, [offer]);

  useEffect(() => {
    getCitiesFromPostalCode(producer.PostalCode);
  }, [producer]);

  useEffect(() => {
    const handlePopstate = () => {
      setIsOrderPageOpen(false);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleClick = () => {
    window.history.pushState({}, '', '');
  };

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileFilterMenuWrapperRef.current) {
        if (isLoginPageOpen) {
          mobileFilterMenuWrapperRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileFilterMenuWrapperRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isLoginPageOpen]);

  if (isOrderPageOpen)
    return (
      <OrderDetails
        currentCondition={currentCondition}
        offer={offer}
        product={product}
        setIsOrderOpen={setIsOrderPageOpen}
        isOrderOpen={isOrderPageOpen}
      />
    );

  return (
    <>
      <TransparentDiv
        isAudioPlayed={isAudioPlayed}
        onClick={() => pauseAudio(audio)}
      />
      <MainWrapper>
        <Main>
          {(isProductLoading || isProducerCardLoading) && <LoadingCircle />}
          <HeaderWrapper>
            <p>
              {ProductTypeEnum.length > 0 &&
                offer.ProductType &&
                getProductTypePolishName(
                  ProductTypeEnum.filter(
                    (productType) =>
                      productType.FlagValue === offer.ProductType,
                  )[0].FlagValue,
                )}
            </p>
            <BackButton onClick={() => navigate('/tracks/main')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.5"
                height="20.074"
                viewBox="0 0 11.5 20.074"
              >
                <path
                  id="Path_4070"
                  data-name="Path 4070"
                  d="M11.093,18.031,2.851,10.137l8.141-7.86a1.362,1.362,0,0,0,.1-1.846,1.361,1.361,0,0,0-1.845.1L0,10.139,9.344,19.78a1.346,1.346,0,0,0,1.834.085,1.347,1.347,0,0,0-.085-1.834"
                  transform="translate(0 -0.114)"
                  fill="#f8f8f8"
                />
              </svg>
            </BackButton>
          </HeaderWrapper>
          <Grid>
            <LeftSection>
              <CoverWrapperWithName hasLongDescription={false}>
                <CoverWrapperWithTrackPlayer>
                  <CoverWrapper
                    onClick={() => selectImage(CoverFile)}
                    hasLongDescription={false}
                  >
                    <img src={CoverFile} />
                  </CoverWrapper>
                </CoverWrapperWithTrackPlayer>
                <ProductNameWithDescriptionWrapper hasLongDescription={false}>
                  <div style={{width: '296px'}}>
                    <h5>{Name}</h5>
                    <p>{Description}</p>
                  </div>
                  <CategoriesWithSymbolWrapper>
                    {/* <h4>{`${
                      product?.Categories?.filter(
                        (category) => category.Position === 1,
                      ).length > 0
                        ? product?.Categories?.filter(
                            (category) => category.Position === 1,
                          )[0].Name
                        : ''
                    }${
                      product?.Categories?.filter(
                        (category) => category.Position === 2,
                      ).length > 0
                        ? `, ${
                            product?.Categories?.filter(
                              (category) => category.Position === 2,
                            )[0].Name
                          }`
                        : ''
                    }${
                      product?.Categories?.filter(
                        (category) => category.Position === 3,
                      ).length > 0
                        ? `, ${
                            product?.Categories?.filter(
                              (category) => category.Position === 3,
                            )[0].Name
                          }`
                        : ''
                    }`}</h4> */}
                    <h4>
                      {product?.Categories.map((category, i) => {
                        if (i === 0) {
                          return <>{category.Name}</>;
                        } else return <>{`, ${category.Name}`}</>;
                      })}
                      {product?.AttributeValues.map((attribute, i) => {
                        return <>{`, ${attribute.Name}`}</>;
                      })}
                    </h4>
                    {/* <h4>{product?.Symbol}</h4> */}
                  </CategoriesWithSymbolWrapper>
                </ProductNameWithDescriptionWrapper>
              </CoverWrapperWithName>
              {false && (
                <LongDescriptionWrapper hasLongDescription={false}>
                  <h5>{product?.Title}</h5>
                  <p>{product?.LongDescription}</p>
                </LongDescriptionWrapper>
              )}

              <DesktopTrackPlayer>
                <DesktopTrackPlayerSvgWrapper>
                  <NewDesktopTrackPlayerSvg />
                </DesktopTrackPlayerSvgWrapper>
                {/* <img src={newDesktopTrackPlayerSvg} /> */}
                {product &&
                  product?.DemoFiles?.filter(
                    (file) => file.ProductFileType === 3,
                  ).length > 0 && (
                    <>
                      {isAudioPlayed ? (
                        <img
                          src={pauseIcon}
                          onClick={(e) => pauseAudio(audio)}
                        />
                      ) : (
                        <img src={playIcon} onClick={(e) => playAudio(audio)} />
                      )}
                    </>
                  )}
                {product &&
                  product?.DemoFiles?.filter(
                    (file) => file.ProductFileType === 1,
                  ).length > 0 &&
                  product?.DemoFiles?.filter(
                    (file) => file.ProductFileType === 1,
                  ).map((file, i) => {
                    const {FilePath, Id} = file;

                    if (i > 0) return;

                    return (
                      <DownloadDemoButton key={Id}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                        >
                          <path
                            id="Icon_open-data-transfer-download"
                            data-name="Icon open-data-transfer-download"
                            d="M5.625,0V5.625H1.875L7.5,11.25l5.625-5.625H9.375V0ZM0,13.125V15H15V13.125Z"
                            fill="#fff"
                          />
                        </svg>
                        <a href={FilePath} target="_blank" rel="noreferrer">
                          Pobierz demo
                        </a>
                      </DownloadDemoButton>
                    );
                  })}
              </DesktopTrackPlayer>

              <ProducerCardWithAboutMe>
                <SingleCardWrapper>
                  <SingleCardImageWrapper>
                    {producer.ProducerPhoto && (
                      <img src={producer.ProducerPhoto} />
                    )}
                  </SingleCardImageWrapper>
                  <SingleCardContentWrapper>
                    <DefaultBlockName
                      title={
                        producer.ProducerFirstName
                          ? `${producer.ProducerFirstName} ${producer.ProducerLastName}`
                          : `${producer.ProducerName}`
                      }
                    >
                      {producer.ProducerFirstName
                        ? `${producer.ProducerFirstName} ${producer.ProducerLastName}`
                        : `${producer.ProducerName}`}
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                    >
                      <path
                        id="Icon_material-stars"
                        data-name="Icon material-stars"
                        d="M8.495,3A5.5,5.5,0,1,0,14,8.5,5.5,5.5,0,0,0,8.495,3Zm2.332,8.8L8.5,10.4,6.174,11.8,6.79,9.155,4.738,7.378l2.706-.231L8.5,4.65,9.556,7.142l2.706.231L10.211,9.149Z"
                        transform="translate(-3 -3)"
                        fill="#fff"
                      />
                    </svg> */}
                    </DefaultBlockName>
                    <DefaultBlockRole>
                      {`${getSpecificRole(roles, producer.Role)} ${
                        producer.SecondaryRole
                          ? `, ${getSpecificRole(
                              roles,
                              producer.SecondaryRole,
                            )}`
                          : ''
                      }`}
                    </DefaultBlockRole>
                    <DefaultBlockLocation>
                      {/* <img src={locationIcon} /> */}
                      {cities.length > 0 ? cities[0].City : 'Nie znaleziono'}
                    </DefaultBlockLocation>
                    {decodedToken?.ProducerId &&
                      offer.ProducerId !== decodedToken?.ProducerId && (
                        <Link
                          style={{width: '100%'}}
                          to={'/messages'}
                          state={{
                            productIdFromOffe: offer.ProductId,
                            producerIdFromOffer: offer.ProducerId,
                            productNameFromOffer: offer.Name,
                            producerNameFromOffer: offer.ProducerFirstName
                              ? `${offer.ProducerFirstName} ${offer.ProducerSecondName}`
                              : offer.ProducerName,
                          }}
                        >
                          <SendMessageButton>
                            Wyślij wiadomość
                          </SendMessageButton>
                        </Link>
                      )}
                  </SingleCardContentWrapper>
                </SingleCardWrapper>
                <AboutMeWrapper>
                  <p>{producer.AboutMe}</p>
                </AboutMeWrapper>
              </ProducerCardWithAboutMe>
            </LeftSection>
            <RightSection>
              <ConditionSelectWrapper
                howManyConditions={[
                  firstCondition?.Id,
                  secondCondition?.Id,
                  thirdCondition?.Id,
                ].reduce((acc, next) => {
                  if (!next) {
                    return acc;
                  } else {
                    return (acc = acc + 1);
                  }
                }, 0)}
              >
                {/* {!firstCondition && !secondCondition && !thirdCondition && (
                <SingleCondition
                  value={0}
                  currentCondition={currentCondition}
                  style={{cursor: 'default'}}
                >
                  <div>
                    <p>Brak warunków</p>
                  </div>
                </SingleCondition>
              )} */}
                {firstCondition?.Id && (
                  <SingleCondition
                    value={1}
                    currentCondition={currentCondition}
                    onClick={() => handleCurrentConditionChange(1)}
                    title={firstCondition?.Name}
                  >
                    <div>
                      {/* <p>{firstCondition?.Name}</p> */}
                      <p>Wariant A</p>
                    </div>
                  </SingleCondition>
                )}
                {secondCondition?.Id && (
                  <SingleCondition
                    value={2}
                    currentCondition={currentCondition}
                    onClick={() => handleCurrentConditionChange(2)}
                    title={secondCondition?.Name}
                  >
                    <div>
                      {/* <p>{secondCondition?.Name}</p> */}
                      <p>Wariant B</p>
                    </div>
                  </SingleCondition>
                )}
                {thirdCondition?.Id && (
                  <SingleCondition
                    value={3}
                    currentCondition={currentCondition}
                    onClick={() => handleCurrentConditionChange(3)}
                    title={thirdCondition?.Name}
                  >
                    <div>
                      {/* <p>{thirdCondition?.Name}</p> */}
                      <p>Wariant C</p>
                    </div>
                  </SingleCondition>
                )}
              </ConditionSelectWrapper>
              <ConditionDetailsBox>
                {currentCondition === 1 && firstCondition && (
                  <>
                    <ExpandableHeaderWithDescriptionWrapper>
                      <ExpandableHeaderWithDescription
                        header={firstCondition?.Name}
                        text=""
                        isEditing={true}
                      >
                        <ConditionSectionDescription
                          style={{flexDirection: 'column'}}
                          isEditing={true}
                        >
                          <ConditionSectionDescriptionExample isEditing={true}>
                            <p>
                              {firstCondition?.Description
                                ? firstCondition?.Description
                                : 'Brak opisu'}
                            </p>
                          </ConditionSectionDescriptionExample>
                        </ConditionSectionDescription>
                      </ExpandableHeaderWithDescription>
                    </ExpandableHeaderWithDescriptionWrapper>
                    <PriceWithWinWinWrapper>
                      <PriceWithTitleWrapper>
                        <h4>
                          {`${new Intl.NumberFormat('de-DE').format(
                            +firstCondition?.GrossValue / 100,
                          )} PLN`}
                        </h4>
                      </PriceWithTitleWrapper>
                      <WinWinInfo
                        isWinWin={
                          firstCondition?.WinWinDigital ||
                          firstCondition?.WinWinPhysical ||
                          firstCondition?.WinWinYoutube
                            ? true
                            : false
                        }
                      >
                        <WinWinSvg />
                      </WinWinInfo>
                    </PriceWithWinWinWrapper>
                    <ConditionDetailsWithButtonWrapper>
                      {/* <ConditionDetailsWrapper
                  // style={{paddingLeft: '10px', paddingBottom: '60px'}}
                > */}
                      <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                      <SectionHeaderNoEditing>
                        <p>Zobowiązania</p>
                      </SectionHeaderNoEditing>
                      {firstCondition?.SellerObligations.map(
                        (sellerObligation) => (
                          <SellerObligationWrapper
                            key={sellerObligation}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{left: '13.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                              >
                                <path
                                  id="Icon_awesome-check-circle"
                                  data-name="Icon awesome-check-circle"
                                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                  transform="translate(-0.563 -0.563)"
                                  fill="#00d10f"
                                />
                              </svg>
                              <p>{sellerObligation}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      {firstCondition?.SellerRestrictions.length > 0 && (
                        <SectionHeaderNoEditing>
                          <p>Wykluczenia i ograniczenia</p>
                        </SectionHeaderNoEditing>
                      )}
                      {firstCondition?.SellerRestrictions.map(
                        (sellerRestriction) => (
                          <SellerObligationWrapper
                            key={sellerRestriction}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{top: '3px', left: '11.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.729"
                                height="12.728"
                                viewBox="0 0 12.729 12.728"
                              >
                                <path
                                  id="Icon_awesome-plus-circle"
                                  data-name="Icon awesome-plus-circle"
                                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                  transform="translate(6.364 -0.796) rotate(45)"
                                  fill="#ff0300"
                                />
                              </svg>

                              <p>{sellerRestriction}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      <BlueHeaderComponent>
                        Kanały monetyzacji
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Serwisy cyfrowe</p>
                          <select
                            value={firstCondition?.ExploitationDigital}
                            disabled={true}
                          >
                            {ExploitationDigitalEnum.map(
                              (exploitationDigital) => {
                                const {FieldName, FlagValue} =
                                  exploitationDigital;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>YouTube</p>
                          <select
                            value={firstCondition?.ExploitationYoutube}
                            disabled={true}
                          >
                            {ExploitationYoutubeEnum.map(
                              (exploitationYoutube) => {
                                const {FieldName, FlagValue} =
                                  exploitationYoutube;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Dystrybucja Fizyczna</p>
                          <select
                            value={firstCondition?.ExploitationPhysical}
                            disabled={true}
                          >
                            {ExploitationPhysicalEnum.map(
                              (exploitationPhysical) => {
                                const {FieldName, FlagValue} =
                                  exploitationPhysical;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <BlueHeaderComponent>
                        Warunki współpracy
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Rodzaj umowy</p>
                          <select
                            disabled={true}
                            value={firstCondition?.IsExclusive ? 1 : 2}
                          >
                            <option value={1}>Na wyłączność</option>
                            <option value={2}>Otwarta</option>
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Licencja</p>
                          <select
                            disabled={true}
                            value={firstCondition?.RightsEnd}
                          >
                            {RightsEndEnum.map((rightEnd) => {
                              const {FieldName, FlagValue} = rightEnd;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Czas na wykonanie</p>
                          <select
                            disabled={true}
                            value={firstCondition?.TimeToFinish}
                          >
                            {TimeToFinishEnum.map((timeToFinish) => {
                              const {FieldName, FlagValue} = timeToFinish;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <>
                        <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                        {firstCondition?.IndicationOfContentAuthor ? (
                          <ConditionSectionDescription
                            isEditing={true}
                            style={{marginBottom: '5px'}}
                          >
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wymaga oznaczenia treści
                              <br />
                              imieniem, nazwiskiem bądź pseudonimem
                            </p>
                          </ConditionSectionDescription>
                        ) : (
                          <ConditionSectionDescription isEditing={true}>
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                              udostępnianie
                            </p>
                          </ConditionSectionDescription>
                        )}
                        {firstCondition?.IndicationOfContentAuthor && (
                          <DisplayGrossValueNoEditing>
                            <p>Oznaczenie</p>
                            <div>
                              <p>{firstCondition?.IndicationOfContentAuthor}</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        )}
                      </>
                      <BlueHeaderComponent>
                        Rozliczenia-Warunki finansowe
                      </BlueHeaderComponent>
                      {firstCondition?.WinWinDigital ||
                      firstCondition?.WinWinPhysical ||
                      firstCondition?.WinWinYoutube ? (
                        <>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z serwisów cyfrowch</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={firstCondition?.WinWinDigital + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z YouTube</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              maxLength={3}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={firstCondition?.WinWinYoutube + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z dystrybucji fizycznej</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={firstCondition?.WinWinPhysical + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p style={{width: 'calc(100% - 80px)'}}>
                              Data wydania w MUGO
                            </p>
                            <input
                              style={{width: '116px'}}
                              disabled={true}
                              type="date"
                              value={
                                firstCondition?.DateToUploadForCustomer.split(
                                  'T',
                                )[0]
                              }
                            />
                          </ConditionTitleWithInputWrapper>
                        </>
                      ) : null}

                      <>
                        {firstCondition?.GrossValue ? (
                          <DisplayGrossValueNoEditing>
                            <p>
                              {firstCondition?.WinWinDigital ||
                              firstCondition?.WinWinPhysical ||
                              firstCondition?.WinWinYoutube
                                ? 'Kwota gwarantowana'
                                : 'Cena (brutto)'}
                            </p>
                            <div>
                              <h5>{firstCondition?.GrossValue / 100}</h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        ) : (
                          !firstCondition?.GrossValue && null
                        )}

                        {/* {userMetaData.IsLegal && (
                        <ConditionTitleWithSliderAndSelectWrapper>
                          {isConditionEditing ? (
                            <>
                              <p>Faktura VAT</p>
                              <ConditionSlider
                                value={invoice}
                                onClick={() => handleInvoiceChange(!invoice)}
                              >
                                <div />
                              </ConditionSlider>
                            </>
                          ) : (
                            <CheckboxInfoWrapper
                              style={{
                                marginBottom: 'unset',
                                width: '196px',
                                height: '24px',
                              }}
                            >
                              <p>Faktura VAT</p>
                              <span>{invoice ? 'Tak' : 'Nie'}</span>
                            </CheckboxInfoWrapper>
                          )}

                          <SelectView
                            isEditing={isConditionEditing}
                            style={{width: '100px', marginTop: 'unset'}}
                          >
                            <select
                              value={tax}
                              onChange={handleTaxChange}
                              disabled={!isConditionEditing}
                            >
                              {TaxesEnum.map((tax) => {
                                const {FieldName, FlagValue} = tax;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              })}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </ConditionTitleWithSliderAndSelectWrapper>
                      )} */}
                        {firstCondition?.GrossValue > 0 && (
                          <>
                            <DisplayGrossValueNoEditing
                              style={{marginTop: '10px'}}
                            >
                              <p>Opłata transakcyjna</p>
                              <div>
                                <h5>
                                  {firstCondition?.TransactionFee?.Brutto / 100}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueNoEditing>
                            <DisplayGrossValueWithTaxNoEditing>
                              <p>Razem do zapłaty</p>
                              <div>
                                <h5>
                                  {firstCondition?.GrossValue
                                    ? `${
                                        firstCondition?.GrossValue / 100 +
                                        firstCondition?.TransactionFee?.Brutto /
                                          100
                                      }`
                                    : '0'}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueWithTaxNoEditing>
                          </>
                        )}
                      </>
                      {/* </ConditionDetailsWrapper> */}
                    </ConditionDetailsWithButtonWrapper>
                  </>
                )}
                {currentCondition === 2 && secondCondition && (
                  <>
                    <ExpandableHeaderWithDescriptionWrapper>
                      <ExpandableHeaderWithDescription
                        header={secondCondition?.Name}
                        text=""
                        isEditing={true}
                      >
                        <ConditionSectionDescription
                          style={{flexDirection: 'column'}}
                          isEditing={true}
                        >
                          <ConditionSectionDescriptionExample isEditing={true}>
                            <p>
                              {secondCondition?.Description
                                ? secondCondition?.Description
                                : 'Brak opisu'}
                            </p>
                          </ConditionSectionDescriptionExample>
                        </ConditionSectionDescription>
                      </ExpandableHeaderWithDescription>
                    </ExpandableHeaderWithDescriptionWrapper>
                    <PriceWithWinWinWrapper>
                      <PriceWithTitleWrapper>
                        <h4>
                          {`${new Intl.NumberFormat('de-DE').format(
                            +secondCondition?.GrossValue / 100,
                          )} PLN`}
                        </h4>
                      </PriceWithTitleWrapper>
                      <WinWinInfo
                        isWinWin={
                          secondCondition?.WinWinDigital ||
                          secondCondition?.WinWinPhysical ||
                          secondCondition?.WinWinYoutube
                            ? true
                            : false
                        }
                      >
                        <WinWinSvg />
                      </WinWinInfo>
                    </PriceWithWinWinWrapper>
                    <ConditionDetailsWithButtonWrapper>
                      {/* <ConditionDetailsWrapper
                // style={{paddingLeft: '10px', paddingBottom: '60px'}}
              > */}
                      <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                      <SectionHeaderNoEditing>
                        <p>Zobowiązania</p>
                      </SectionHeaderNoEditing>
                      {secondCondition?.SellerObligations.map(
                        (sellerObligation) => (
                          <SellerObligationWrapper
                            key={sellerObligation}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{left: '13.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                              >
                                <path
                                  id="Icon_awesome-check-circle"
                                  data-name="Icon awesome-check-circle"
                                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                  transform="translate(-0.563 -0.563)"
                                  fill="#00d10f"
                                />
                              </svg>
                              <p>{sellerObligation}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      {secondCondition?.SellerRestrictions.length > 0 && (
                        <SectionHeaderNoEditing>
                          <p>Wykluczenia i ograniczenia</p>
                        </SectionHeaderNoEditing>
                      )}
                      {secondCondition?.SellerRestrictions.map(
                        (sellerRestriction) => (
                          <SellerObligationWrapper
                            key={sellerRestriction}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{top: '3px', left: '11.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.729"
                                height="12.728"
                                viewBox="0 0 12.729 12.728"
                              >
                                <path
                                  id="Icon_awesome-plus-circle"
                                  data-name="Icon awesome-plus-circle"
                                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                  transform="translate(6.364 -0.796) rotate(45)"
                                  fill="#ff0300"
                                />
                              </svg>

                              <p>{sellerRestriction}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      <BlueHeaderComponent>
                        Kanały monetyzacji
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Serwisy cyfrowe</p>
                          <select
                            value={secondCondition?.ExploitationDigital}
                            disabled={true}
                          >
                            {ExploitationDigitalEnum.map(
                              (exploitationDigital) => {
                                const {FieldName, FlagValue} =
                                  exploitationDigital;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>YouTube</p>
                          <select
                            value={secondCondition?.ExploitationYoutube}
                            disabled={true}
                          >
                            {ExploitationYoutubeEnum.map(
                              (exploitationYoutube) => {
                                const {FieldName, FlagValue} =
                                  exploitationYoutube;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Dystrybucja Fizyczna</p>
                          <select
                            value={secondCondition?.ExploitationPhysical}
                            disabled={true}
                          >
                            {ExploitationPhysicalEnum.map(
                              (exploitationPhysical) => {
                                const {FieldName, FlagValue} =
                                  exploitationPhysical;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <BlueHeaderComponent>
                        Warunki współpracy
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Rodzaj umowy</p>
                          <select
                            disabled={true}
                            value={secondCondition?.IsExclusive ? 1 : 2}
                          >
                            <option value={1}>Na wyłączność</option>
                            <option value={2}>Otwarta</option>
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Licencja</p>
                          <select
                            disabled={true}
                            value={secondCondition?.RightsEnd}
                          >
                            {RightsEndEnum.map((rightEnd) => {
                              const {FieldName, FlagValue} = rightEnd;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Czas na wykonanie</p>
                          <select
                            disabled={true}
                            value={secondCondition?.TimeToFinish}
                          >
                            {TimeToFinishEnum.map((timeToFinish) => {
                              const {FieldName, FlagValue} = timeToFinish;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <>
                        <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                        {secondCondition?.IndicationOfContentAuthor ? (
                          <ConditionSectionDescription
                            isEditing={true}
                            style={{marginBottom: '5px'}}
                          >
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wymaga oznaczenia treści
                              <br />
                              imieniem, nazwiskiem bądź pseudonimem
                            </p>
                          </ConditionSectionDescription>
                        ) : (
                          <ConditionSectionDescription isEditing={true}>
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                              udostępnianie
                            </p>
                          </ConditionSectionDescription>
                        )}
                        {secondCondition?.IndicationOfContentAuthor && (
                          <DisplayGrossValueNoEditing>
                            <p>Oznaczenie</p>
                            <div>
                              <p>
                                {secondCondition?.IndicationOfContentAuthor}
                              </p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        )}
                      </>
                      <BlueHeaderComponent>
                        Rozliczenia-Warunki finansowe
                      </BlueHeaderComponent>
                      {secondCondition?.WinWinDigital ||
                      secondCondition?.WinWinPhysical ||
                      secondCondition?.WinWinYoutube ? (
                        <>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z serwisów cyfrowch</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={secondCondition?.WinWinDigital + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z YouTube</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              maxLength={3}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={secondCondition?.WinWinYoutube + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z dystrybucji fizycznej</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={secondCondition?.WinWinPhysical + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p style={{width: 'calc(100% - 80px)'}}>
                              Data wydania w MUGO
                            </p>
                            <input
                              style={{width: '116px'}}
                              disabled={true}
                              type="date"
                              value={
                                secondCondition?.DateToUploadForCustomer.split(
                                  'T',
                                )[0]
                              }
                            />
                          </ConditionTitleWithInputWrapper>
                        </>
                      ) : null}
                      <>
                        {secondCondition?.GrossValue ? (
                          <DisplayGrossValueNoEditing>
                            <p>
                              {secondCondition?.WinWinDigital ||
                              secondCondition?.WinWinPhysical ||
                              secondCondition?.WinWinYoutube
                                ? 'Kwota gwarantowana'
                                : 'Cena (brutto)'}
                            </p>
                            <div>
                              <h5>{secondCondition?.GrossValue / 100}</h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        ) : (
                          !secondCondition?.GrossValue && null
                        )}

                        {/* {userMetaData.IsLegal && (
                      <ConditionTitleWithSliderAndSelectWrapper>
                        {isConditionEditing ? (
                          <>
                            <p>Faktura VAT</p>
                            <ConditionSlider
                              value={invoice}
                              onClick={() => handleInvoiceChange(!invoice)}
                            >
                              <div />
                            </ConditionSlider>
                          </>
                        ) : (
                          <CheckboxInfoWrapper
                            style={{
                              marginBottom: 'unset',
                              width: '196px',
                              height: '24px',
                            }}
                          >
                            <p>Faktura VAT</p>
                            <span>{invoice ? 'Tak' : 'Nie'}</span>
                          </CheckboxInfoWrapper>
                        )}

                        <SelectView
                          isEditing={isConditionEditing}
                          style={{width: '100px', marginTop: 'unset'}}
                        >
                          <select
                            value={tax}
                            onChange={handleTaxChange}
                            disabled={!isConditionEditing}
                          >
                            {TaxesEnum.map((tax) => {
                              const {FieldName, FlagValue} = tax;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </ConditionTitleWithSliderAndSelectWrapper>
                    )} */}
                        {secondCondition?.GrossValue > 0 && (
                          <>
                            <DisplayGrossValueNoEditing
                              style={{marginTop: '10px'}}
                            >
                              <p>Opłata transakcyjna</p>
                              <div>
                                <h5>
                                  {secondCondition?.TransactionFee?.Brutto /
                                    100}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueNoEditing>
                            <DisplayGrossValueWithTaxNoEditing>
                              <p>Razem do zapłaty</p>
                              <div>
                                <h5>
                                  {secondCondition?.GrossValue
                                    ? `${
                                        secondCondition?.GrossValue / 100 +
                                        secondCondition?.TransactionFee
                                          ?.Brutto /
                                          100
                                      }`
                                    : '0'}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueWithTaxNoEditing>
                          </>
                        )}
                      </>
                      {/* </ConditionDetailsWrapper> */}
                    </ConditionDetailsWithButtonWrapper>
                  </>
                )}
                {currentCondition === 3 && thirdCondition && (
                  <>
                    <ExpandableHeaderWithDescriptionWrapper>
                      <ExpandableHeaderWithDescription
                        header={thirdCondition?.Name}
                        text=""
                        isEditing={true}
                      >
                        <ConditionSectionDescription
                          style={{flexDirection: 'column'}}
                          isEditing={true}
                        >
                          <ConditionSectionDescriptionExample isEditing={true}>
                            <p>
                              {thirdCondition?.Description
                                ? thirdCondition?.Description
                                : 'Brak opisu'}
                            </p>
                          </ConditionSectionDescriptionExample>
                        </ConditionSectionDescription>
                      </ExpandableHeaderWithDescription>
                    </ExpandableHeaderWithDescriptionWrapper>
                    <PriceWithWinWinWrapper>
                      <PriceWithTitleWrapper>
                        <h4>
                          {`${new Intl.NumberFormat('de-DE').format(
                            +thirdCondition?.GrossValue / 100,
                          )} PLN`}
                        </h4>
                      </PriceWithTitleWrapper>
                      <WinWinInfo
                        isWinWin={
                          thirdCondition?.WinWinDigital ||
                          thirdCondition?.WinWinPhysical ||
                          thirdCondition?.WinWinYoutube
                            ? true
                            : false
                        }
                      >
                        <WinWinSvg />
                      </WinWinInfo>
                    </PriceWithWinWinWrapper>
                    <ConditionDetailsWithButtonWrapper>
                      {/* <ConditionDetailsWrapper
                // style={{paddingLeft: '10px', paddingBottom: '60px'}}
              > */}
                      <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                      <SectionHeaderNoEditing>
                        <p>Zobowiązania</p>
                      </SectionHeaderNoEditing>
                      {thirdCondition?.SellerObligations.map(
                        (sellerObligation) => (
                          <SellerObligationWrapper
                            key={sellerObligation}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{left: '13.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                              >
                                <path
                                  id="Icon_awesome-check-circle"
                                  data-name="Icon awesome-check-circle"
                                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                  transform="translate(-0.563 -0.563)"
                                  fill="#00d10f"
                                />
                              </svg>
                              <p>{sellerObligation}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      {thirdCondition?.SellerRestrictions.length > 0 && (
                        <SectionHeaderNoEditing>
                          <p>Wykluczenia i ograniczenia</p>
                        </SectionHeaderNoEditing>
                      )}
                      {thirdCondition?.SellerRestrictions.map(
                        (sellerRestriction) => (
                          <SellerObligationWrapper
                            key={sellerRestriction}
                            isEditing={false}
                          >
                            <>
                              <svg
                                style={{top: '3px', left: '11.5px'}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.729"
                                height="12.728"
                                viewBox="0 0 12.729 12.728"
                              >
                                <path
                                  id="Icon_awesome-plus-circle"
                                  data-name="Icon awesome-plus-circle"
                                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                  transform="translate(6.364 -0.796) rotate(45)"
                                  fill="#ff0300"
                                />
                              </svg>

                              <p>{sellerRestriction}</p>
                            </>
                          </SellerObligationWrapper>
                        ),
                      )}
                      <BlueHeaderComponent>
                        Kanały monetyzacji
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Serwisy cyfrowe</p>
                          <select
                            value={thirdCondition?.ExploitationDigital}
                            disabled={true}
                          >
                            {ExploitationDigitalEnum.map(
                              (exploitationDigital) => {
                                const {FieldName, FlagValue} =
                                  exploitationDigital;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>YouTube</p>
                          <select
                            value={thirdCondition?.ExploitationYoutube}
                            disabled={true}
                          >
                            {ExploitationYoutubeEnum.map(
                              (exploitationYoutube) => {
                                const {FieldName, FlagValue} =
                                  exploitationYoutube;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Dystrybucja Fizyczna</p>
                          <select
                            value={thirdCondition?.ExploitationPhysical}
                            disabled={true}
                          >
                            {ExploitationPhysicalEnum.map(
                              (exploitationPhysical) => {
                                const {FieldName, FlagValue} =
                                  exploitationPhysical;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <BlueHeaderComponent>
                        Warunki współpracy
                      </BlueHeaderComponent>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Rodzaj umowy</p>
                          <select
                            disabled={true}
                            value={thirdCondition?.IsExclusive ? 1 : 2}
                          >
                            <option value={1}>Na wyłączność</option>
                            <option value={2}>Otwarta</option>
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Licencja</p>
                          <select
                            disabled={true}
                            value={thirdCondition?.RightsEnd}
                          >
                            {RightsEndEnum.map((rightEnd) => {
                              const {FieldName, FlagValue} = rightEnd;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <SelectViewContainer isEditing={false}>
                        <SelectView isEditing={false}>
                          <p>Czas na wykonanie</p>
                          <select
                            disabled={true}
                            value={thirdCondition?.TimeToFinish}
                          >
                            {TimeToFinishEnum.map((timeToFinish) => {
                              const {FieldName, FlagValue} = timeToFinish;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <>
                        <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                        {thirdCondition?.IndicationOfContentAuthor ? (
                          <ConditionSectionDescription
                            isEditing={true}
                            style={{marginBottom: '5px'}}
                          >
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wymaga oznaczenia treści
                              <br />
                              imieniem, nazwiskiem bądź pseudonimem
                            </p>
                          </ConditionSectionDescription>
                        ) : (
                          <ConditionSectionDescription isEditing={true}>
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                              udostępnianie
                            </p>
                          </ConditionSectionDescription>
                        )}
                        {thirdCondition?.IndicationOfContentAuthor && (
                          <DisplayGrossValueNoEditing>
                            <p>Oznaczenie</p>
                            <div>
                              <p>{thirdCondition?.IndicationOfContentAuthor}</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        )}
                      </>
                      <BlueHeaderComponent>
                        Rozliczenia-Warunki finansowe
                      </BlueHeaderComponent>
                      {thirdCondition?.WinWinDigital ||
                      thirdCondition?.WinWinPhysical ||
                      thirdCondition?.WinWinYoutube ? (
                        <>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z serwisów cyfrowch</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={thirdCondition?.WinWinDigital + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z YouTube</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              maxLength={3}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={thirdCondition?.WinWinYoutube + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p>Przychody z dystrybucji fizycznej</p>
                            <input
                              disabled={true}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              value={thirdCondition?.WinWinPhysical + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper isEditing={false}>
                            <p style={{width: 'calc(100% - 80px)'}}>
                              Data wydania w MUGO
                            </p>
                            <input
                              style={{width: '116px'}}
                              disabled={true}
                              type="date"
                              value={
                                thirdCondition?.DateToUploadForCustomer.split(
                                  'T',
                                )[0]
                              }
                            />
                          </ConditionTitleWithInputWrapper>
                        </>
                      ) : null}
                      <>
                        {thirdCondition?.GrossValue ? (
                          <DisplayGrossValueNoEditing>
                            <p>
                              {thirdCondition?.WinWinDigital ||
                              thirdCondition?.WinWinPhysical ||
                              thirdCondition?.WinWinYoutube
                                ? 'Kwota gwarantowana'
                                : 'Cena (brutto)'}
                            </p>
                            <div>
                              <h5>{thirdCondition?.GrossValue / 100}</h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        ) : (
                          !thirdCondition?.GrossValue && null
                        )}

                        {/* {userMetaData.IsLegal && (
                      <ConditionTitleWithSliderAndSelectWrapper>
                        {isConditionEditing ? (
                          <>
                            <p>Faktura VAT</p>
                            <ConditionSlider
                              value={invoice}
                              onClick={() => handleInvoiceChange(!invoice)}
                            >
                              <div />
                            </ConditionSlider>
                          </>
                        ) : (
                          <CheckboxInfoWrapper
                            style={{
                              marginBottom: 'unset',
                              width: '196px',
                              height: '24px',
                            }}
                          >
                            <p>Faktura VAT</p>
                            <span>{invoice ? 'Tak' : 'Nie'}</span>
                          </CheckboxInfoWrapper>
                        )}

                        <SelectView
                          isEditing={isConditionEditing}
                          style={{width: '100px', marginTop: 'unset'}}
                        >
                          <select
                            value={tax}
                            onChange={handleTaxChange}
                            disabled={!isConditionEditing}
                          >
                            {TaxesEnum.map((tax) => {
                              const {FieldName, FlagValue} = tax;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </ConditionTitleWithSliderAndSelectWrapper>
                    )} */}
                        {thirdCondition?.GrossValue > 0 && (
                          <>
                            <DisplayGrossValueNoEditing
                              style={{marginTop: '10px'}}
                            >
                              <p>Opłata transakcyjna</p>
                              <div>
                                <h5>
                                  {thirdCondition?.TransactionFee?.Brutto / 100}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueNoEditing>
                            <DisplayGrossValueWithTaxNoEditing>
                              <p>Razem do zapłaty</p>
                              <div>
                                <h5>
                                  {thirdCondition?.GrossValue
                                    ? `${
                                        thirdCondition?.GrossValue / 100 +
                                        thirdCondition?.TransactionFee?.Brutto /
                                          100
                                      }`
                                    : '0'}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueWithTaxNoEditing>
                          </>
                        )}
                      </>
                      {/* </ConditionDetailsWrapper> */}
                    </ConditionDetailsWithButtonWrapper>
                  </>
                )}
                {/* {(firstCondition || secondCondition || thirdCondition) && ( */}
                {!decodedToken ? (
                  <BuyButton style={{marginBottom: '14px'}}>
                    <Link
                      onClick={() => {
                        localStorage.setItem(
                          'productType',
                          `${offer.ProductType}`,
                        );
                        localStorage.setItem('productId', `${offer.Id}`);
                      }}
                      to={`/identity/login`}
                      state={{
                        productType: offer.ProductType,
                        productId: offer.Id,
                      }}
                    >
                      Kup
                    </Link>
                  </BuyButton>
                ) : (
                  decodedToken?.ProducerId &&
                  offer.ProducerId !== decodedToken?.ProducerId && (
                    <BuyButton
                      onClick={() => {
                        if (offer.Id && product?.Id) {
                          setIsOrderPageOpen(true);
                          scrollToTop();
                          handleClick();
                        }
                      }}
                      style={{marginBottom: '14px'}}
                    >
                      {/* <p style={{color: 'white'}}>Kup</p> */}
                      Kup
                    </BuyButton>
                  )
                )}
                <DownloadButton onClick={getPdf}>
                  Pobierz draft warunków umowy PDF
                </DownloadButton>
                {/* )} */}
              </ConditionDetailsBox>
            </RightSection>
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MobileHeader>
          <h1>Detal oferty</h1>
          <svg
            onClick={() => navigate('/tracks/main')}
            xmlns="http://www.w3.org/2000/svg"
            width="10.312"
            height="18"
            viewBox="0 0 10.312 18"
          >
            <path
              id="Path_4070"
              data-name="Path 4070"
              d="M9.947,16.18,2.557,9.1l7.3-7.048A1.221,1.221,0,0,0,9.943.4,1.221,1.221,0,0,0,8.289.485L0,9.1l8.378,8.646a1.207,1.207,0,0,0,1.645.076,1.208,1.208,0,0,0-.076-1.645"
              transform="translate(0 -0.114)"
              fill="#0091ff"
            />
          </svg>
        </MobileHeader>

        <MobileMain>
          {/* {Name && (
            <FixedMobileProductName
              isMobileProductTitleVisible={isMobileProductTitleVisible}
            >
              <h5>{Name}</h5>
            </FixedMobileProductName>
          )} */}

          {(isProductLoading || isProducerCardLoading) && <LoadingCircle />}
          <LeftSection>
            <MobileNameWithImageAndDescriptionWrapper>
              <h5>{Name}</h5>
              <img onClick={() => selectImage(CoverFile)} src={CoverFile} />
              <p>{Description}</p>
              <CategoriesWithSymbolWrapper>
                {/* <h4>{`${
                      product?.Categories?.filter(
                        (category) => category.Position === 1,
                      ).length > 0
                        ? product?.Categories?.filter(
                            (category) => category.Position === 1,
                          )[0].Name
                        : ''
                    }${
                      product?.Categories?.filter(
                        (category) => category.Position === 2,
                      ).length > 0
                        ? `, ${
                            product?.Categories?.filter(
                              (category) => category.Position === 2,
                            )[0].Name
                          }`
                        : ''
                    }${
                      product?.Categories?.filter(
                        (category) => category.Position === 3,
                      ).length > 0
                        ? `, ${
                            product?.Categories?.filter(
                              (category) => category.Position === 3,
                            )[0].Name
                          }`
                        : ''
                    }`}</h4> */}
                <h4>
                  {product?.Categories.map((category, i) => {
                    if (i === 0) {
                      return <>{category.Name}</>;
                    } else return <>{`, ${category.Name}`}</>;
                  })}
                  {product?.AttributeValues.map((attribute, i) => {
                    return <>{`, ${attribute.Name}`}</>;
                  })}
                </h4>
                {/* <h4>{product?.Symbol}</h4> */}
              </CategoriesWithSymbolWrapper>
            </MobileNameWithImageAndDescriptionWrapper>
            {/* <CoverWrapperWithName hasLongDescription={false}>
                <CoverWrapperWithTrackPlayer>
                  <CoverWrapper hasLongDescription={false}>
                    <img src={CoverFile} />
                  </CoverWrapper>
                </CoverWrapperWithTrackPlayer>
              </CoverWrapperWithName> */}
            {/* {false && (
                <LongDescriptionWrapper hasLongDescription={false}>
                  <h5>{product?.Title}</h5>
                  <p>{product?.LongDescription}</p>
                </LongDescriptionWrapper>
              )} */}

            <DesktopTrackPlayer>
              <DesktopTrackPlayerSvgWrapper>
                {/* <NewDesktopTrackPlayerSvg /> */}
                <NewMobileTrackPlayerSvg />
                {/* <img src={newMobileTrackPlayerSvg} /> */}
              </DesktopTrackPlayerSvgWrapper>
              {/* <img src={newDesktopTrackPlayerSvg} /> */}
              {product &&
                product?.DemoFiles?.filter((file) => file.ProductFileType === 3)
                  .length > 0 && (
                  <>
                    {isAudioPlayed ? (
                      <img src={pauseIcon} onClick={(e) => pauseAudio(audio)} />
                    ) : (
                      <img src={playIcon} onClick={(e) => playAudio(audio)} />
                    )}
                  </>
                )}
              {product &&
                product?.DemoFiles?.filter((file) => file.ProductFileType === 1)
                  .length > 0 &&
                product?.DemoFiles?.filter(
                  (file) => file.ProductFileType === 1,
                ).map((file, i) => {
                  const {FilePath, Id} = file;

                  if (i > 0) return;

                  return (
                    <DownloadDemoButton key={Id}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                      >
                        <path
                          id="Icon_open-data-transfer-download"
                          data-name="Icon open-data-transfer-download"
                          d="M5.625,0V5.625H1.875L7.5,11.25l5.625-5.625H9.375V0ZM0,13.125V15H15V13.125Z"
                          fill="#fff"
                        />
                      </svg>
                      <a href={FilePath} target="_blank" rel="noreferrer">
                        Pobierz demo
                      </a>
                    </DownloadDemoButton>
                  );
                })}
            </DesktopTrackPlayer>
            <MobileProducerCardWithAboutMe>
              <ProducerCardWithAboutMe>
                <SingleCardWrapper>
                  <SingleCardImageWrapper>
                    {producer.ProducerPhoto && (
                      <img src={producer.ProducerPhoto} />
                    )}
                  </SingleCardImageWrapper>
                  <SingleCardContentWrapper>
                    <DefaultBlockName
                      title={
                        producer.ProducerFirstName
                          ? `${producer.ProducerFirstName} ${producer.ProducerLastName}`
                          : `${producer.ProducerName}`
                      }
                    >
                      {producer.ProducerFirstName
                        ? `${producer.ProducerFirstName} ${producer.ProducerLastName}`
                        : `${producer.ProducerName}`}
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                    >
                      <path
                        id="Icon_material-stars"
                        data-name="Icon material-stars"
                        d="M8.495,3A5.5,5.5,0,1,0,14,8.5,5.5,5.5,0,0,0,8.495,3Zm2.332,8.8L8.5,10.4,6.174,11.8,6.79,9.155,4.738,7.378l2.706-.231L8.5,4.65,9.556,7.142l2.706.231L10.211,9.149Z"
                        transform="translate(-3 -3)"
                        fill="#fff"
                      />
                    </svg> */}
                    </DefaultBlockName>
                    <DefaultBlockRole>
                      {`${getSpecificRole(roles, producer.Role)} ${
                        producer.SecondaryRole
                          ? `, ${getSpecificRole(
                              roles,
                              producer.SecondaryRole,
                            )}`
                          : ''
                      }`}
                    </DefaultBlockRole>
                    <DefaultBlockLocation>
                      {/* <img src={locationIcon} /> */}
                      {cities.length > 0 ? cities[0].City : 'Nie znaleziono'}
                    </DefaultBlockLocation>
                    {decodedToken?.ProducerId &&
                      offer.ProducerId !== decodedToken?.ProducerId && (
                        <Link
                          style={{width: '100%'}}
                          to={'/messages'}
                          state={{
                            productIdFromOffe: offer.ProductId,
                            producerIdFromOffer: offer.ProducerId,
                            productNameFromOffer: offer.Name,
                            producerNameFromOffer: offer.ProducerFirstName
                              ? `${offer.ProducerFirstName} ${offer.ProducerSecondName}`
                              : offer.ProducerName,
                          }}
                        >
                          <SendMessageButton>
                            Wyślij wiadomość
                          </SendMessageButton>
                        </Link>
                      )}
                  </SingleCardContentWrapper>
                </SingleCardWrapper>
                <AboutMeWrapper>
                  <p>{producer.AboutMe}</p>
                </AboutMeWrapper>
              </ProducerCardWithAboutMe>
            </MobileProducerCardWithAboutMe>
          </LeftSection>
          <RightSection>
            <ConditionSelectWrapper
              howManyConditions={[
                firstCondition?.Id,
                secondCondition?.Id,
                thirdCondition?.Id,
              ].reduce((acc, next) => {
                if (!next) {
                  return acc;
                } else {
                  return (acc = acc + 1);
                }
              }, 0)}
            >
              {/* {!firstCondition && !secondCondition && !thirdCondition && (
                <SingleCondition
                  value={0}
                  currentCondition={currentCondition}
                  style={{cursor: 'default'}}
                >
                  <div>
                    <p>Brak warunków</p>
                  </div>
                </SingleCondition>
              )} */}
              {firstCondition?.Id && (
                <SingleCondition
                  value={1}
                  currentCondition={currentCondition}
                  onClick={() => handleCurrentConditionChange(1)}
                  title={firstCondition?.Name}
                >
                  <div>
                    {/* <p>{firstCondition?.Name}</p> */}
                    <p>Wariant A</p>
                  </div>
                </SingleCondition>
              )}
              {secondCondition?.Id && (
                <SingleCondition
                  value={2}
                  currentCondition={currentCondition}
                  onClick={() => handleCurrentConditionChange(2)}
                  title={secondCondition?.Name}
                >
                  <div>
                    {/* <p>{secondCondition?.Name}</p> */}
                    <p>Wariant B</p>
                  </div>
                </SingleCondition>
              )}
              {thirdCondition?.Id && (
                <SingleCondition
                  value={3}
                  currentCondition={currentCondition}
                  onClick={() => handleCurrentConditionChange(3)}
                  title={thirdCondition?.Name}
                >
                  <div>
                    {/* <p>{thirdCondition?.Name}</p> */}
                    <p>Wariant C</p>
                  </div>
                </SingleCondition>
              )}
            </ConditionSelectWrapper>
            <ConditionDetailsBox>
              {currentCondition === 1 && firstCondition && (
                <>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header={firstCondition?.Name}
                      text=""
                      isEditing={true}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={true}
                      >
                        <ConditionSectionDescriptionExample isEditing={true}>
                          <p>
                            {firstCondition?.Description
                              ? firstCondition?.Description
                              : 'Brak opisu'}
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <PriceWithWinWinWrapper>
                    <PriceWithTitleWrapper>
                      <h4>
                        {`${new Intl.NumberFormat('de-DE').format(
                          +firstCondition?.GrossValue / 100,
                        )} PLN`}
                      </h4>
                    </PriceWithTitleWrapper>
                    <WinWinInfo
                      isWinWin={
                        firstCondition?.WinWinDigital ||
                        firstCondition?.WinWinPhysical ||
                        firstCondition?.WinWinYoutube
                          ? true
                          : false
                      }
                    >
                      <WinWinSvg />
                    </WinWinInfo>
                  </PriceWithWinWinWrapper>
                  <ConditionDetailsWithButtonWrapper>
                    {/* <ConditionDetailsWrapper
                  // style={{paddingLeft: '10px', paddingBottom: '60px'}}
                > */}
                    <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                    <SectionHeaderNoEditing>
                      <p>Zobowiązania</p>
                    </SectionHeaderNoEditing>
                    {firstCondition?.SellerObligations.map(
                      (sellerObligation) => (
                        <SellerObligationWrapper
                          key={sellerObligation}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{left: '-2.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                            >
                              <path
                                id="Icon_awesome-check-circle"
                                data-name="Icon awesome-check-circle"
                                d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#00d10f"
                              />
                            </svg>
                            <p style={{paddingLeft: '6px'}}>
                              {sellerObligation}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    {firstCondition?.SellerRestrictions.length > 0 && (
                      <SectionHeaderNoEditing>
                        <p>Wykluczenia i ograniczenia</p>
                      </SectionHeaderNoEditing>
                    )}
                    {firstCondition?.SellerRestrictions.map(
                      (sellerRestriction) => (
                        <SellerObligationWrapper
                          key={sellerRestriction}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{top: '3px', left: '-4.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.729"
                              height="12.728"
                              viewBox="0 0 12.729 12.728"
                            >
                              <path
                                id="Icon_awesome-plus-circle"
                                data-name="Icon awesome-plus-circle"
                                d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                transform="translate(6.364 -0.796) rotate(45)"
                                fill="#ff0300"
                              />
                            </svg>

                            <p style={{paddingLeft: '6px'}}>
                              {sellerRestriction}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    <BlueHeaderComponent>
                      Kanały monetyzacji
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Serwisy cyfrowe</p>
                        <select
                          value={firstCondition?.ExploitationDigital}
                          disabled={true}
                        >
                          {ExploitationDigitalEnum.map(
                            (exploitationDigital) => {
                              const {FieldName, FlagValue} =
                                exploitationDigital;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>YouTube</p>
                        <select
                          value={firstCondition?.ExploitationYoutube}
                          disabled={true}
                        >
                          {ExploitationYoutubeEnum.map(
                            (exploitationYoutube) => {
                              const {FieldName, FlagValue} =
                                exploitationYoutube;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Dystrybucja Fizyczna</p>
                        <select
                          value={firstCondition?.ExploitationPhysical}
                          disabled={true}
                        >
                          {ExploitationPhysicalEnum.map(
                            (exploitationPhysical) => {
                              const {FieldName, FlagValue} =
                                exploitationPhysical;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <BlueHeaderComponent>
                      Warunki współpracy
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Rodzaj umowy</p>
                        <select
                          disabled={true}
                          value={firstCondition?.IsExclusive ? 1 : 2}
                        >
                          <option value={1}>Na wyłączność</option>
                          <option value={2}>Otwarta</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Licencja</p>
                        <select
                          disabled={true}
                          value={firstCondition?.RightsEnd}
                        >
                          {RightsEndEnum.map((rightEnd) => {
                            const {FieldName, FlagValue} = rightEnd;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Czas na wykonanie</p>
                        <select
                          disabled={true}
                          value={firstCondition?.TimeToFinish}
                        >
                          {TimeToFinishEnum.map((timeToFinish) => {
                            const {FieldName, FlagValue} = timeToFinish;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <>
                      <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                      {firstCondition?.IndicationOfContentAuthor ? (
                        <ConditionSectionDescription
                          isEditing={true}
                          style={{marginBottom: '5px'}}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wymaga oznaczenia treści
                            <br />
                            imieniem, nazwiskiem bądź pseudonimem
                          </p>
                        </ConditionSectionDescription>
                      ) : (
                        <ConditionSectionDescription isEditing={true}>
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                            udostępnianie
                          </p>
                        </ConditionSectionDescription>
                      )}
                      {firstCondition?.IndicationOfContentAuthor && (
                        <DisplayGrossValueNoEditing>
                          <p>Oznaczenie</p>
                          <div>
                            <p>{firstCondition?.IndicationOfContentAuthor}</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      )}
                    </>
                    <BlueHeaderComponent>
                      Rozliczenia-Warunki finansowe
                    </BlueHeaderComponent>
                    {firstCondition?.WinWinDigital ||
                    firstCondition?.WinWinPhysical ||
                    firstCondition?.WinWinYoutube ? (
                      <>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z serwisów cyfrowch</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={firstCondition?.WinWinDigital + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z YouTube</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            maxLength={3}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={firstCondition?.WinWinYoutube + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z dystrybucji fizycznej</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={firstCondition?.WinWinPhysical + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p style={{width: 'calc(100% - 80px)'}}>
                            Data wydania w MUGO
                          </p>
                          <input
                            style={{width: '116px'}}
                            disabled={true}
                            type="date"
                            value={
                              firstCondition?.DateToUploadForCustomer.split(
                                'T',
                              )[0]
                            }
                          />
                        </ConditionTitleWithInputWrapper>
                      </>
                    ) : null}

                    <>
                      {firstCondition?.GrossValue ? (
                        <DisplayGrossValueNoEditing>
                          <p>
                            {firstCondition?.WinWinDigital ||
                            firstCondition?.WinWinPhysical ||
                            firstCondition?.WinWinYoutube
                              ? 'Kwota gwarantowana'
                              : 'Cena (brutto)'}
                          </p>
                          <div>
                            <h5>{firstCondition?.GrossValue / 100}</h5>
                            <p>PLN</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      ) : (
                        !firstCondition?.GrossValue && null
                      )}

                      {/* {userMetaData.IsLegal && (
                        <ConditionTitleWithSliderAndSelectWrapper>
                          {isConditionEditing ? (
                            <>
                              <p>Faktura VAT</p>
                              <ConditionSlider
                                value={invoice}
                                onClick={() => handleInvoiceChange(!invoice)}
                              >
                                <div />
                              </ConditionSlider>
                            </>
                          ) : (
                            <CheckboxInfoWrapper
                              style={{
                                marginBottom: 'unset',
                                width: '196px',
                                height: '24px',
                              }}
                            >
                              <p>Faktura VAT</p>
                              <span>{invoice ? 'Tak' : 'Nie'}</span>
                            </CheckboxInfoWrapper>
                          )}

                          <SelectView
                            isEditing={isConditionEditing}
                            style={{width: '100px', marginTop: 'unset'}}
                          >
                            <select
                              value={tax}
                              onChange={handleTaxChange}
                              disabled={!isConditionEditing}
                            >
                              {TaxesEnum.map((tax) => {
                                const {FieldName, FlagValue} = tax;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              })}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </ConditionTitleWithSliderAndSelectWrapper>
                      )} */}
                      {firstCondition?.GrossValue > 0 && (
                        <>
                          <DisplayGrossValueNoEditing
                            style={{marginTop: '10px'}}
                          >
                            <p>Opłata transakcyjna</p>
                            <div>
                              <h5>
                                {firstCondition?.TransactionFee?.Brutto / 100}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                          <DisplayGrossValueWithTaxNoEditing>
                            <p>Razem do zapłaty</p>
                            <div>
                              <h5>
                                {firstCondition?.GrossValue
                                  ? `${
                                      firstCondition?.GrossValue / 100 +
                                      firstCondition?.TransactionFee?.Brutto /
                                        100
                                    }`
                                  : '0'}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueWithTaxNoEditing>
                        </>
                      )}
                    </>
                    {/* </ConditionDetailsWrapper> */}
                  </ConditionDetailsWithButtonWrapper>
                </>
              )}
              {currentCondition === 2 && secondCondition && (
                <>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header={secondCondition?.Name}
                      text=""
                      isEditing={true}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={true}
                      >
                        <ConditionSectionDescriptionExample isEditing={true}>
                          <p>
                            {secondCondition?.Description
                              ? secondCondition?.Description
                              : 'Brak opisu'}
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <PriceWithWinWinWrapper>
                    <PriceWithTitleWrapper>
                      <h4>
                        {`${new Intl.NumberFormat('de-DE').format(
                          +secondCondition?.GrossValue / 100,
                        )} PLN`}
                      </h4>
                    </PriceWithTitleWrapper>
                    <WinWinInfo
                      isWinWin={
                        secondCondition?.WinWinDigital ||
                        secondCondition?.WinWinPhysical ||
                        secondCondition?.WinWinYoutube
                          ? true
                          : false
                      }
                    >
                      <WinWinSvg />
                    </WinWinInfo>
                  </PriceWithWinWinWrapper>
                  <ConditionDetailsWithButtonWrapper>
                    {/* <ConditionDetailsWrapper
                // style={{paddingLeft: '10px', paddingBottom: '60px'}}
              > */}
                    <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                    <SectionHeaderNoEditing>
                      <p>Zobowiązania</p>
                    </SectionHeaderNoEditing>
                    {secondCondition?.SellerObligations.map(
                      (sellerObligation) => (
                        <SellerObligationWrapper
                          key={sellerObligation}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{left: '-2.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                            >
                              <path
                                id="Icon_awesome-check-circle"
                                data-name="Icon awesome-check-circle"
                                d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#00d10f"
                              />
                            </svg>
                            <p style={{paddingLeft: '6px'}}>
                              {sellerObligation}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    {secondCondition?.SellerRestrictions.length > 0 && (
                      <SectionHeaderNoEditing>
                        <p>Wykluczenia i ograniczenia</p>
                      </SectionHeaderNoEditing>
                    )}
                    {secondCondition?.SellerRestrictions.map(
                      (sellerRestriction) => (
                        <SellerObligationWrapper
                          key={sellerRestriction}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{top: '3px', left: '-4.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.729"
                              height="12.728"
                              viewBox="0 0 12.729 12.728"
                            >
                              <path
                                id="Icon_awesome-plus-circle"
                                data-name="Icon awesome-plus-circle"
                                d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                transform="translate(6.364 -0.796) rotate(45)"
                                fill="#ff0300"
                              />
                            </svg>

                            <p style={{paddingLeft: '6px'}}>
                              {sellerRestriction}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    <BlueHeaderComponent>
                      Kanały monetyzacji
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Serwisy cyfrowe</p>
                        <select
                          value={secondCondition?.ExploitationDigital}
                          disabled={true}
                        >
                          {ExploitationDigitalEnum.map(
                            (exploitationDigital) => {
                              const {FieldName, FlagValue} =
                                exploitationDigital;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>YouTube</p>
                        <select
                          value={secondCondition?.ExploitationYoutube}
                          disabled={true}
                        >
                          {ExploitationYoutubeEnum.map(
                            (exploitationYoutube) => {
                              const {FieldName, FlagValue} =
                                exploitationYoutube;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Dystrybucja Fizyczna</p>
                        <select
                          value={secondCondition?.ExploitationPhysical}
                          disabled={true}
                        >
                          {ExploitationPhysicalEnum.map(
                            (exploitationPhysical) => {
                              const {FieldName, FlagValue} =
                                exploitationPhysical;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <BlueHeaderComponent>
                      Warunki współpracy
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Rodzaj umowy</p>
                        <select
                          disabled={true}
                          value={secondCondition?.IsExclusive ? 1 : 2}
                        >
                          <option value={1}>Na wyłączność</option>
                          <option value={2}>Otwarta</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Licencja</p>
                        <select
                          disabled={true}
                          value={secondCondition?.RightsEnd}
                        >
                          {RightsEndEnum.map((rightEnd) => {
                            const {FieldName, FlagValue} = rightEnd;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Czas na wykonanie</p>
                        <select
                          disabled={true}
                          value={secondCondition?.TimeToFinish}
                        >
                          {TimeToFinishEnum.map((timeToFinish) => {
                            const {FieldName, FlagValue} = timeToFinish;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <>
                      <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                      {secondCondition?.IndicationOfContentAuthor ? (
                        <ConditionSectionDescription
                          isEditing={true}
                          style={{marginBottom: '5px'}}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wymaga oznaczenia treści
                            <br />
                            imieniem, nazwiskiem bądź pseudonimem
                          </p>
                        </ConditionSectionDescription>
                      ) : (
                        <ConditionSectionDescription isEditing={true}>
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                            udostępnianie
                          </p>
                        </ConditionSectionDescription>
                      )}
                      {secondCondition?.IndicationOfContentAuthor && (
                        <DisplayGrossValueNoEditing>
                          <p>Oznaczenie</p>
                          <div>
                            <p>{secondCondition?.IndicationOfContentAuthor}</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      )}
                    </>
                    <BlueHeaderComponent>
                      Rozliczenia-Warunki finansowe
                    </BlueHeaderComponent>
                    {secondCondition?.WinWinDigital ||
                    secondCondition?.WinWinPhysical ||
                    secondCondition?.WinWinYoutube ? (
                      <>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z serwisów cyfrowch</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={secondCondition?.WinWinDigital + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z YouTube</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            maxLength={3}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={secondCondition?.WinWinYoutube + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z dystrybucji fizycznej</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={secondCondition?.WinWinPhysical + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p style={{width: 'calc(100% - 80px)'}}>
                            Data wydania w MUGO
                          </p>
                          <input
                            style={{width: '116px'}}
                            disabled={true}
                            type="date"
                            value={
                              secondCondition?.DateToUploadForCustomer.split(
                                'T',
                              )[0]
                            }
                          />
                        </ConditionTitleWithInputWrapper>
                      </>
                    ) : null}
                    <>
                      {secondCondition?.GrossValue ? (
                        <DisplayGrossValueNoEditing>
                          <p>
                            {secondCondition?.WinWinDigital ||
                            secondCondition?.WinWinPhysical ||
                            secondCondition?.WinWinYoutube
                              ? 'Kwota gwarantowana'
                              : 'Cena (brutto)'}
                          </p>
                          <div>
                            <h5>{secondCondition?.GrossValue / 100}</h5>
                            <p>PLN</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      ) : (
                        !secondCondition?.GrossValue && null
                      )}

                      {/* {userMetaData.IsLegal && (
                      <ConditionTitleWithSliderAndSelectWrapper>
                        {isConditionEditing ? (
                          <>
                            <p>Faktura VAT</p>
                            <ConditionSlider
                              value={invoice}
                              onClick={() => handleInvoiceChange(!invoice)}
                            >
                              <div />
                            </ConditionSlider>
                          </>
                        ) : (
                          <CheckboxInfoWrapper
                            style={{
                              marginBottom: 'unset',
                              width: '196px',
                              height: '24px',
                            }}
                          >
                            <p>Faktura VAT</p>
                            <span>{invoice ? 'Tak' : 'Nie'}</span>
                          </CheckboxInfoWrapper>
                        )}

                        <SelectView
                          isEditing={isConditionEditing}
                          style={{width: '100px', marginTop: 'unset'}}
                        >
                          <select
                            value={tax}
                            onChange={handleTaxChange}
                            disabled={!isConditionEditing}
                          >
                            {TaxesEnum.map((tax) => {
                              const {FieldName, FlagValue} = tax;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </ConditionTitleWithSliderAndSelectWrapper>
                    )} */}
                      {secondCondition?.GrossValue > 0 && (
                        <>
                          <DisplayGrossValueNoEditing
                            style={{marginTop: '10px'}}
                          >
                            <p>Opłata transakcyjna</p>
                            <div>
                              <h5>
                                {secondCondition?.TransactionFee?.Brutto / 100}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                          <DisplayGrossValueWithTaxNoEditing>
                            <p>Razem do zapłaty</p>
                            <div>
                              <h5>
                                {secondCondition?.GrossValue
                                  ? `${
                                      secondCondition?.GrossValue / 100 +
                                      secondCondition?.TransactionFee?.Brutto /
                                        100
                                    }`
                                  : '0'}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueWithTaxNoEditing>
                        </>
                      )}
                    </>
                    {/* </ConditionDetailsWrapper> */}
                  </ConditionDetailsWithButtonWrapper>
                </>
              )}
              {currentCondition === 3 && thirdCondition && (
                <>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header={thirdCondition?.Name}
                      text=""
                      isEditing={true}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={true}
                      >
                        <ConditionSectionDescriptionExample isEditing={true}>
                          <p>
                            {thirdCondition?.Description
                              ? thirdCondition?.Description
                              : 'Brak opisu'}
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <PriceWithWinWinWrapper>
                    <PriceWithTitleWrapper>
                      <h4>
                        {`${new Intl.NumberFormat('de-DE').format(
                          +thirdCondition?.GrossValue / 100,
                        )} PLN`}
                      </h4>
                    </PriceWithTitleWrapper>
                    <WinWinInfo
                      isWinWin={
                        thirdCondition?.WinWinDigital ||
                        thirdCondition?.WinWinPhysical ||
                        thirdCondition?.WinWinYoutube
                          ? true
                          : false
                      }
                    >
                      <WinWinSvg />
                    </WinWinInfo>
                  </PriceWithWinWinWrapper>
                  <ConditionDetailsWithButtonWrapper>
                    {/* <ConditionDetailsWrapper
                // style={{paddingLeft: '10px', paddingBottom: '60px'}}
              > */}
                    <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
                    <SectionHeaderNoEditing>
                      <p>Zobowiązania</p>
                    </SectionHeaderNoEditing>
                    {thirdCondition?.SellerObligations.map(
                      (sellerObligation) => (
                        <SellerObligationWrapper
                          key={sellerObligation}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{left: '-2.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                            >
                              <path
                                id="Icon_awesome-check-circle"
                                data-name="Icon awesome-check-circle"
                                d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#00d10f"
                              />
                            </svg>
                            <p style={{paddingLeft: '6px'}}>
                              {sellerObligation}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    {thirdCondition?.SellerRestrictions.length > 0 && (
                      <SectionHeaderNoEditing>
                        <p>Wykluczenia i ograniczenia</p>
                      </SectionHeaderNoEditing>
                    )}
                    {thirdCondition?.SellerRestrictions.map(
                      (sellerRestriction) => (
                        <SellerObligationWrapper
                          key={sellerRestriction}
                          isEditing={false}
                        >
                          <>
                            <svg
                              style={{top: '3px', left: '-4.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.729"
                              height="12.728"
                              viewBox="0 0 12.729 12.728"
                            >
                              <path
                                id="Icon_awesome-plus-circle"
                                data-name="Icon awesome-plus-circle"
                                d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                transform="translate(6.364 -0.796) rotate(45)"
                                fill="#ff0300"
                              />
                            </svg>

                            <p style={{paddingLeft: '6px'}}>
                              {sellerRestriction}
                            </p>
                          </>
                        </SellerObligationWrapper>
                      ),
                    )}
                    <BlueHeaderComponent>
                      Kanały monetyzacji
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Serwisy cyfrowe</p>
                        <select
                          value={thirdCondition?.ExploitationDigital}
                          disabled={true}
                        >
                          {ExploitationDigitalEnum.map(
                            (exploitationDigital) => {
                              const {FieldName, FlagValue} =
                                exploitationDigital;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>YouTube</p>
                        <select
                          value={thirdCondition?.ExploitationYoutube}
                          disabled={true}
                        >
                          {ExploitationYoutubeEnum.map(
                            (exploitationYoutube) => {
                              const {FieldName, FlagValue} =
                                exploitationYoutube;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Dystrybucja Fizyczna</p>
                        <select
                          value={thirdCondition?.ExploitationPhysical}
                          disabled={true}
                        >
                          {ExploitationPhysicalEnum.map(
                            (exploitationPhysical) => {
                              const {FieldName, FlagValue} =
                                exploitationPhysical;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <BlueHeaderComponent>
                      Warunki współpracy
                    </BlueHeaderComponent>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Rodzaj umowy</p>
                        <select
                          disabled={true}
                          value={thirdCondition?.IsExclusive ? 1 : 2}
                        >
                          <option value={1}>Na wyłączność</option>
                          <option value={2}>Otwarta</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Licencja</p>
                        <select
                          disabled={true}
                          value={thirdCondition?.RightsEnd}
                        >
                          {RightsEndEnum.map((rightEnd) => {
                            const {FieldName, FlagValue} = rightEnd;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <SelectViewContainer isEditing={false}>
                      <SelectView isEditing={false}>
                        <p>Czas na wykonanie</p>
                        <select
                          disabled={true}
                          value={thirdCondition?.TimeToFinish}
                        >
                          {TimeToFinishEnum.map((timeToFinish) => {
                            const {FieldName, FlagValue} = timeToFinish;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <>
                      <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                      {thirdCondition?.IndicationOfContentAuthor ? (
                        <ConditionSectionDescription
                          isEditing={true}
                          style={{marginBottom: '5px'}}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wymaga oznaczenia treści
                            <br />
                            imieniem, nazwiskiem bądź pseudonimem
                          </p>
                        </ConditionSectionDescription>
                      ) : (
                        <ConditionSectionDescription isEditing={true}>
                          <p
                            style={{
                              textAlign: 'left',
                              width: '308px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                            udostępnianie
                          </p>
                        </ConditionSectionDescription>
                      )}
                      {thirdCondition?.IndicationOfContentAuthor && (
                        <DisplayGrossValueNoEditing>
                          <p>Oznaczenie</p>
                          <div>
                            <p>{thirdCondition?.IndicationOfContentAuthor}</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      )}
                    </>
                    <BlueHeaderComponent>
                      Rozliczenia-Warunki finansowe
                    </BlueHeaderComponent>
                    {thirdCondition?.WinWinDigital ||
                    thirdCondition?.WinWinPhysical ||
                    thirdCondition?.WinWinYoutube ? (
                      <>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z serwisów cyfrowch</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={thirdCondition?.WinWinDigital + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z YouTube</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            maxLength={3}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={thirdCondition?.WinWinYoutube + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p>Przychody z dystrybucji fizycznej</p>
                          <input
                            disabled={true}
                            placeholder="%"
                            type="text"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={3}
                            value={thirdCondition?.WinWinPhysical + '%'}
                          />
                        </ConditionTitleWithInputWrapper>
                        <ConditionTitleWithInputWrapper isEditing={false}>
                          <p style={{width: 'calc(100% - 80px)'}}>
                            Data wydania w MUGO
                          </p>
                          <input
                            style={{width: '116px'}}
                            disabled={true}
                            type="date"
                            value={
                              thirdCondition?.DateToUploadForCustomer.split(
                                'T',
                              )[0]
                            }
                          />
                        </ConditionTitleWithInputWrapper>
                      </>
                    ) : null}
                    <>
                      {thirdCondition?.GrossValue ? (
                        <DisplayGrossValueNoEditing>
                          <p>
                            {thirdCondition?.WinWinDigital ||
                            thirdCondition?.WinWinPhysical ||
                            thirdCondition?.WinWinYoutube
                              ? 'Kwota gwarantowana'
                              : 'Cena (brutto)'}
                          </p>
                          <div>
                            <h5>{thirdCondition?.GrossValue / 100}</h5>
                            <p>PLN</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      ) : (
                        !thirdCondition?.GrossValue && null
                      )}

                      {/* {userMetaData.IsLegal && (
                      <ConditionTitleWithSliderAndSelectWrapper>
                        {isConditionEditing ? (
                          <>
                            <p>Faktura VAT</p>
                            <ConditionSlider
                              value={invoice}
                              onClick={() => handleInvoiceChange(!invoice)}
                            >
                              <div />
                            </ConditionSlider>
                          </>
                        ) : (
                          <CheckboxInfoWrapper
                            style={{
                              marginBottom: 'unset',
                              width: '196px',
                              height: '24px',
                            }}
                          >
                            <p>Faktura VAT</p>
                            <span>{invoice ? 'Tak' : 'Nie'}</span>
                          </CheckboxInfoWrapper>
                        )}

                        <SelectView
                          isEditing={isConditionEditing}
                          style={{width: '100px', marginTop: 'unset'}}
                        >
                          <select
                            value={tax}
                            onChange={handleTaxChange}
                            disabled={!isConditionEditing}
                          >
                            {TaxesEnum.map((tax) => {
                              const {FieldName, FlagValue} = tax;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            })}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </ConditionTitleWithSliderAndSelectWrapper>
                    )} */}
                      {thirdCondition?.GrossValue > 0 && (
                        <>
                          <DisplayGrossValueNoEditing
                            style={{marginTop: '10px'}}
                          >
                            <p>Opłata transakcyjna</p>
                            <div>
                              <h5>
                                {thirdCondition?.TransactionFee?.Brutto / 100}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                          <DisplayGrossValueWithTaxNoEditing>
                            <p>Razem do zapłaty</p>
                            <div>
                              <h5>
                                {thirdCondition?.GrossValue
                                  ? `${
                                      thirdCondition?.GrossValue / 100 +
                                      thirdCondition?.TransactionFee?.Brutto /
                                        100
                                    }`
                                  : '0'}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueWithTaxNoEditing>
                        </>
                      )}
                    </>
                    {/* </ConditionDetailsWrapper> */}
                  </ConditionDetailsWithButtonWrapper>
                </>
              )}
              {/* {(firstCondition || secondCondition || thirdCondition) && ( */}
              {!decodedToken ? (
                <BuyButton style={{marginBottom: '14px'}}>
                  <Link
                    onClick={() => {
                      localStorage.setItem(
                        'productType',
                        `${offer.ProductType}`,
                      );
                      localStorage.setItem('productId', `${offer.Id}`);
                    }}
                    to={`/identity/login`}
                    state={{
                      productType: offer.ProductType,
                      productId: offer.Id,
                    }}
                  >
                    Kup
                  </Link>
                </BuyButton>
              ) : (
                decodedToken?.ProducerId &&
                offer.ProducerId !== decodedToken?.ProducerId && (
                  <BuyButton
                    onClick={() => {
                      if (offer.Id && product?.Id) {
                        setIsOrderPageOpen(true);
                        scrollToTop();
                        handleClick();
                      }
                    }}
                    style={{marginBottom: '14px'}}
                  >
                    {/* <p style={{color: 'white'}}>Kup</p> */}
                    Kup
                  </BuyButton>
                )
              )}
              <DownloadButton onClick={getPdf}>
                Pobierz draft warunków umowy PDF
              </DownloadButton>
              {/* )} */}
            </ConditionDetailsBox>
          </RightSection>
        </MobileMain>
        <MobileLoginPageWrapper
          isOpen={isLoginPageOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <LoginPage
            handleIsLoginPageOpenChange={handleIsLoginPageOpenChange}
            ProductId={params.id}
            ProductType={+offer.ProductType}
          />
        </MobileLoginPageWrapper>

        {decodedToken && (
          <SendMessageBottomSheet
            producerIdFromOffer={offer.ProducerId}
            productNameFromOffer={offer.Name}
            producerNameFromOffer={
              offer.ProducerFirstName
                ? `${offer.ProducerFirstName} ${offer.ProducerSecondName}`
                : offer.ProducerName
            }
            handleIsMobileNewMessageViewOpen={handleIsMobileNewMessageViewOpen}
            isMobileNewMessageViewOpen={isMobileNewMessageViewOpen}
          />
        )}
      </MobileMainWrapper>
    </>
  );
};

export default TrackDetail;
