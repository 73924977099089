interface Enum {
  FieldName: string;
  FlagValue: number;
}

export const ExploitationDigitalEnum: Enum[] = [
  {FieldName: 'Bez limitu', FlagValue: 1},
  {FieldName: 'Do 50000 streamów', FlagValue: 2},
  {FieldName: 'Do 100000 streamów', FlagValue: 4},
  {FieldName: 'Do 250000 streamów', FlagValue: 8},
];

export const ExploitationPhysicalEnum: Enum[] = [
  {FieldName: 'Bez limitu', FlagValue: 1},
  {FieldName: 'Do 1000', FlagValue: 2},
  {FieldName: 'Do 3000', FlagValue: 4},
  {FieldName: 'Do 5000', FlagValue: 8},
  {FieldName: 'Brak', FlagValue: 16},
];

export const ExploitationYoutubeEnum: Enum[] = [
  {FieldName: 'Bez limitu', FlagValue: 1},
  {FieldName: 'Nie można monetyzować', FlagValue: 2},
  {FieldName: 'Do 100000', FlagValue: 4},
  {FieldName: 'Do 300000', FlagValue: 8},
  {FieldName: 'Do 500000', FlagValue: 16},
  {FieldName: 'Do 1000000', FlagValue: 32},
];

export const TimeToFinishEnum: Enum[] = [
  {FieldName: 'Natychmiast', FlagValue: 1},
  {FieldName: '7 dni', FlagValue: 2},
  {FieldName: '14 dni', FlagValue: 4},
  {FieldName: '21 dni', FlagValue: 8},
];

export const CurrencyEnum: Enum[] = [{FieldName: 'PLN', FlagValue: 0}];

export const ProtectiveUmbrellaEnum: Enum[] = [
  {FieldName: 'Nie dłużej niż 2 dni', FlagValue: 1},
  {FieldName: 'Nie dłużej niż tydzień', FlagValue: 2},
  {FieldName: 'Nie dłużej niż miesiąc', FlagValue: 4},
];

export const RightsEndEnum: Enum[] = [
  {FieldName: 'Na czas nieokreślony', FlagValue: 0},
  {FieldName: 'Na 12 miesięcy', FlagValue: 1},
  {FieldName: 'Na 24 miesiące', FlagValue: 2},
  {FieldName: 'Na 36 miesięcy', FlagValue: 3},
];

export const ProductTypeEnum: Enum[] = [
  {FieldName: 'service', FlagValue: 4},
  {FieldName: 'musicProduct', FlagValue: 8},
  {FieldName: 'digitalProduct', FlagValue: 1},
  // {FieldName: 'physicalProduct', FlagValue: 2},
];

export const TaxesEnum: Enum[] = [
  {FieldName: '0%', FlagValue: 0},
  {FieldName: '5%', FlagValue: 5},
  {FieldName: '8%', FlagValue: 8},
  {FieldName: '23%', FlagValue: 23},
];

export const ProductStatusEnum: Enum[] = [
  {FieldName: 'draft', FlagValue: 0},
  {FieldName: 'ready', FlagValue: 1},
  {FieldName: 'removed', FlagValue: 2},
];

export const TimeToUploadForCustomer: Enum[] = [
  {FieldName: '2 tygodnie', FlagValue: 0},
  {FieldName: '1 miesiąc', FlagValue: 1},
  {FieldName: '2 miesiące', FlagValue: 2},
  {FieldName: '3 miesiące', FlagValue: 3},
  {FieldName: '4 miesiące', FlagValue: 4},
  {FieldName: '5 miesięcy', FlagValue: 5},
  {FieldName: '6 miesięcy', FlagValue: 6},
];
