import styled from 'styled-components';

export const ProMessageWapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  width: 472px;
  height: 568px;
  border-radius: 15px;
  background: ${({theme}) => theme.colors.bgWhite};
  /* filter: drop-shadow(0px 5px 5px black); */
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export const ProMessageHeader = styled.div`
  width: 472px;
  height: 42px;
  background: ${({theme}) => theme.colors.blueTheme};
  text-align: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  & > h2 {
    font-weight: normal;
    font-size: 15px;
    line-height: 42px;
    letter-spacing: 0.1px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const ProMessageContentWrapper = styled.div`
  width: 472px;
  height: 472px;

  padding-top: 16px;
  background: ${({theme}) => theme.colors.bodyBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export const ProMessageTitle = styled.div`
  padding: 0 28px 15px 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > p {
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: 24px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const BlueDataText = styled.p`
  position: absolute;
  font-size: 9px;
  letter-spacing: 0.25px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.blueTheme};
  right: 85px;
  top: 130px;
  @media (max-width: 102px) {
    right: 45px;
    top: 126px;
  }
`;

export const InfoText = styled.p`
  padding: 21px 88px 14px 88px;
  //padding-top: 27px;
  //padding-bottom: 14px;
  text-align: center;
  font-size: 9px;
  letter-spacing: 0.25px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
`;

export const PriceWrapper = styled.div`
  width: 296px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  & > p {
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    opacity: 0.5;
  }
  & > h4 {
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.blueTheme};
  }
`;

export const InputView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  padding-bottom: 22px;
  width: 312px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > input {
    outline: none;
    background: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? theme.colors.statusRed
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 5px'};
    color: ${({isEditing, theme}) =>
      isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    text-align: right;
    font-size: 11px;
    padding-right: 8.5px;
    padding-left: 110px;
    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }
  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    border: 1px solid rgb(225, 225, 225);
  }

  & > input:focus {
    background: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
    border: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? ` 1px solid ${theme.colors.blueTheme}`
        : notEdit && isEditing
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? `solid 1px ${theme.colors.fontBlue}`
        : notEdit
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
  }
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing
        ? `${theme.colors.fontBlackOp100}`
        : `${theme.colors.fontBlackOp100}`};
    font-size: 11px;
    line-height: 23px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }
`;

export const PayCheckOptionWrapper = styled.div<{isActive?: boolean}>`
  width: 272px;
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background: ${({theme}) => theme.colors.bgWhite};
  border: ${({isActive}) =>
    isActive ? 'solid 1px #0066FF' : 'solid 1px rgba(112, 112, 112, 0.3)'};

  margin-bottom: 11px;
  & > svg {
    width: 115px;
  }
  & > img {
    width: 115px;
  }
`;

export const PrimaryButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  height: 58px;
  border-radius: 15px;
  background: #0066ff;
  text-align: center;
  padding: 4px 8px;
  & > h4 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: white;
    line-height: 21px;
  }
  & > p {
    font-size: 9px;
    line-height: 13px;
    color: white;
    opacity: 0.7;
  }
`;

export const CheckBox = styled.div<{isActive?: boolean}>`
  cursor: pointer;
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  opacity: ${({isActive}) => (isActive ? 1 : 0.3)};
  border: ${({isActive}) =>
    isActive ? 'solid 3px #0066FF' : 'solid 3px #707070'};
  & > span {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #0066ff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const BackButton = styled.button`
  width: 55px;
  height: 42px;
  position: absolute;
  left: 0px;
  border: none;
  border-right: solid 1px white;
  background: ${({theme}) => theme.colors.blueTheme};
  cursor: pointer;
  border-radius: 15px 0 0 0;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  //color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;
