import React, {useState, useEffect, useContext, useRef} from 'react';
import {
  ArrowWrapper,
  FaqContentWrapper,
  FaqFilterContent,
  FaqFilterContentWrapper,
  FaqFilterHeader,
  FaqFilterSvgWrapper,
  FaqFilterWrapper,
  FaqHeaderWrapper,
  FaqItem,
  FaqItemHeader,
  FaqItemTextWrapper,
  FaqMain,
  FaqMainWrapper,
  FaqScroll,
  FaqSvgWrapper,
  FaqTitle,
  RegulaminBtnWrapper,
  RegulaminItem,
  RegulaminsWrapper,
} from './styles';
import {useCookies} from 'react-cookie';
import api from 'services/axiosConfig';
import {
  ClearSingleFilterSvg,
  FilterButtonWrapper,
  FilterMenuWrapper,
  PageHeaderContent,
  PageHeaderHeader,
  PageHeaderHeaderWrapper,
  PageHeaderWrapper,
  SelectWrapper,
  SingleFilterContentWrapper,
  SingleFilterHeaderWrapper,
  SingleFilterWrapper,
} from 'pages/Products';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

interface Faq {
  Answer: string;
  AnswerENG: string;
  Id: string;
  Question: string;
  QuestionENG: string;
}

const FaqComponent: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['token']);
  const [faqs, setFaqs] = useState([]);
  const [activeId, setActiveId] = useState<string>('');
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [faqCategory, setFaqCategory] = useState('');
  const [faqAllCategory, setFaqAllCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const targetRef = useRef(null);

  // useEffect(() => {
  //   // Przewijanie do elementu o podanym id
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({behavior: 'smooth'});
  //   }
  // }, [id]);
  useEffect(() => {
    if (!isLoading && id && targetRef.current) {
      targetRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [id, isLoading, activeId]);

  const handleClick = (newId) => {
    // Tutaj zmieniamy wartość id
    navigate(`/faq/${newId}`);
  };

  const handleActiveIdChange = (e, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    const element = document.getElementById(id);
    console.log(element);
    if (activeId === id) {
      setActiveId('');
      navigate(`/faq/default`);
    }
    if (activeId !== id) {
      setActiveId(id);
      handleClick(id);
      // setSearchParams(id);
    }
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  // useEffect(() => {
  //   setSearchParams({query});
  // }, [query]);

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handlefaqCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setFaqCategory('');
    } else {
      setFaqCategory(e.target.value);
    }
  };

  const getFaqs = async () => {
    try {
      const response = await api.get(`Faq`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      // console.log(response.data.Response);
      setFaqs(response.data.Response);
      // handleActiveFaqFromLink(id, response.data.Response);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getFaqCategory = async () => {
    try {
      const response = await api.get(`Faq/categories`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setFaqAllCategory(response.data.Response);
      // console.log(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getFaqFromSingleCategory = async () => {
    try {
      const response = await api.get(`Faq/${faqCategory}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setFaqs(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getFaqs();
    getFaqCategory();
  }, []);

  useEffect(() => {
    if (faqCategory === '') {
      getFaqs();
    } else {
      getFaqFromSingleCategory();
    }
    // console.log('pobieram po zmianie kategory');
  }, [faqCategory]);

  return (
    <FaqMainWrapper>
      <FaqMain>
        <FaqHeaderWrapper onMouseLeave={() => setIsFilterMenuOpen(false)}>
          FAQ
          <FaqFilterWrapper
            isMenuOpen={isFilterMenuOpen}
            onMouseEnter={() => setIsFilterMenuOpen(true)}
          >
            {isFilterMenuOpen ? (
              <FaqFilterContentWrapper>
                {' '}
                <FaqFilterHeader>FAQ Kategorie</FaqFilterHeader>{' '}
                <FaqFilterContent>
                  <SelectWrapper>
                    <select
                      value={faqCategory}
                      onChange={handlefaqCategoryChange}
                    >
                      <option value={''}>Wszystko</option>
                      {faqAllCategory.map((category) => (
                        <option key={category.Id} value={category.Id}>
                          {category.Name}
                        </option>
                      ))}
                    </select>
                  </SelectWrapper>
                </FaqFilterContent>
              </FaqFilterContentWrapper>
            ) : null}
            {isFilterMenuOpen ? null : (
              <>
                {' '}
                <p></p>
                <FaqFilterSvgWrapper>
                  <p>Filtry</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                  >
                    <g
                      id="Icon_ionic-md-options"
                      data-name="Icon ionic-md-options"
                      transform="translate(-2.25 -2.25)"
                    >
                      <g
                        id="Group_3803"
                        data-name="Group 3803"
                        transform="translate(2.25 9.321)"
                      >
                        <path
                          id="Path_3626"
                          data-name="Path 3626"
                          d="M2.25,27H8.929v.786H2.25Z"
                          transform="translate(-2.25 -25.429)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3627"
                          data-name="Path 3627"
                          d="M28.125,27h1.964v.786H28.125Z"
                          transform="translate(-19.089 -25.429)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3628"
                          data-name="Path 3628"
                          d="M24.071,25.631a.792.792,0,0,1-.786.8h0a.792.792,0,0,1-.786-.8V23.3a.792.792,0,0,1,.786-.8h0a.792.792,0,0,1,.786.8Z"
                          transform="translate(-15.429 -22.5)"
                          fill="#352f40"
                        />
                      </g>
                      <g
                        id="Group_3804"
                        data-name="Group 3804"
                        transform="translate(2.25 5.786)"
                      >
                        <path
                          id="Path_3629"
                          data-name="Path 3629"
                          d="M2.25,16.875H4.214v.786H2.25Z"
                          transform="translate(-2.25 -15.304)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3630"
                          data-name="Path 3630"
                          d="M14.625,16.875H21.3v.786H14.625Z"
                          transform="translate(-10.304 -15.304)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3631"
                          data-name="Path 3631"
                          d="M10.571,15.506a.792.792,0,0,1-.786.8h0a.792.792,0,0,1-.786-.8V13.173a.792.792,0,0,1,.786-.8h0a.792.792,0,0,1,.786.8Z"
                          transform="translate(-6.643 -12.375)"
                          fill="#352f40"
                        />
                      </g>
                      <g
                        id="Group_3805"
                        data-name="Group 3805"
                        transform="translate(2.25 2.25)"
                      >
                        <path
                          id="Path_3632"
                          data-name="Path 3632"
                          d="M2.25,6.75H8.929v.786H2.25Z"
                          transform="translate(-2.25 -5.179)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3633"
                          data-name="Path 3633"
                          d="M28.125,6.75h1.964v.786H28.125Z"
                          transform="translate(-19.089 -5.179)"
                          fill="#352f40"
                        />
                        <path
                          id="Path_3634"
                          data-name="Path 3634"
                          d="M24.071,5.381a.792.792,0,0,1-.786.8h0a.792.792,0,0,1-.786-.8V3.048a.792.792,0,0,1,.786-.8h0a.792.792,0,0,1,.786.8Z"
                          transform="translate(-15.429 -2.25)"
                          fill="#352f40"
                        />
                      </g>
                    </g>
                  </svg>
                </FaqFilterSvgWrapper>
              </>
            )}
            {/* <ArrowWrapper isActive={isFilterMenuOpen}>
              {isFilterMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="6.439"
                  viewBox="0 0 11 6.439"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(11) rotate(90)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="6.439"
                    viewBox="0 0 11 6.439"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0 6.439) rotate(-90)"
                      opacity="0.92"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#352f40"
                      />
                    </g>
                  </svg>
                </>
              )}
            </ArrowWrapper> */}
          </FaqFilterWrapper>
        </FaqHeaderWrapper>
        <FaqScroll>
          <FaqContentWrapper>
            <FaqTitle>Potrzebne informacje związane z MUGO STUDIO</FaqTitle>

            {isLoading
              ? null
              : faqs.map((item, index) => (
                  <FaqItem
                    ref={item.id === id || id === item.Id ? targetRef : null}
                    id={item.Id}
                    key={item.Id}
                  >
                    <FaqItemHeader
                      onClick={(e) => {
                        handleActiveIdChange(e, item.Id);
                      }}
                    >
                      <h4>{`${index + 1}. ${item.Question}`}</h4>{' '}
                      <p>
                        {activeId === item.Id || id === item.Id
                          ? 'Zwiń'
                          : 'Przeczytaj więcej'}
                      </p>
                      <FaqSvgWrapper>
                        {activeId === item.Id || id === item.Id ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.408"
                            height="9.204"
                            viewBox="0 0 17.408 9.204"
                          >
                            <path
                              id="Path_1524"
                              data-name="Path 1524"
                              d="M-160.786,11.078l-8-8-8,8"
                              transform="translate(177.487 -2.581)"
                              fill="none"
                              stroke="#07020f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.408"
                            height="9.204"
                            viewBox="0 0 17.408 9.204"
                          >
                            <path
                              id="Path_1523"
                              data-name="Path 1523"
                              d="M-160.786,3.081l-8,8-8-8"
                              transform="translate(177.487 -2.374)"
                              fill="none"
                              stroke="#07020f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </svg>
                        )}
                      </FaqSvgWrapper>
                    </FaqItemHeader>

                    <FaqItemTextWrapper
                      style={
                        activeId === item.Id || id === item.Id
                          ? {
                              transform: 'scaleY(1)',
                              marginBottom: '35px',
                              marginTop: '35px',
                              height: 'auto',
                            }
                          : null
                      }
                    >
                      <p>{item.Answer}</p>
                    </FaqItemTextWrapper>
                  </FaqItem>
                ))}
            <RegulaminsWrapper>
              <RegulaminItem>
                <h4>Regulamin konta użytkownika</h4>
                <RegulaminBtnWrapper>
                  <p>Zobacz</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.204"
                    height="17.408"
                    viewBox="0 0 9.204 17.408"
                  >
                    <path
                      id="Path_4044"
                      data-name="Path 4044"
                      d="M-160.786,3.081l-8,8-8-8"
                      transform="translate(-2.374 -160.079) rotate(-90)"
                      fill="none"
                      stroke="#07020f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </RegulaminBtnWrapper>
              </RegulaminItem>
              <RegulaminItem>
                <h4>Regulamin producenta</h4>
                <RegulaminBtnWrapper>
                  <p>Zobacz</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.204"
                    height="17.408"
                    viewBox="0 0 9.204 17.408"
                  >
                    <path
                      id="Path_4044"
                      data-name="Path 4044"
                      d="M-160.786,3.081l-8,8-8-8"
                      transform="translate(-2.374 -160.079) rotate(-90)"
                      fill="none"
                      stroke="#07020f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </RegulaminBtnWrapper>
              </RegulaminItem>
              <RegulaminItem>
                <h4>Zasady wystawionych warunków, licencji i ofert</h4>
                <RegulaminBtnWrapper>
                  <p>Zobacz</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.204"
                    height="17.408"
                    viewBox="0 0 9.204 17.408"
                  >
                    <path
                      id="Path_4044"
                      data-name="Path 4044"
                      d="M-160.786,3.081l-8,8-8-8"
                      transform="translate(-2.374 -160.079) rotate(-90)"
                      fill="none"
                      stroke="#07020f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </RegulaminBtnWrapper>
              </RegulaminItem>
              <RegulaminItem>
                <h4>Klauzula informacyjna</h4>
                <RegulaminBtnWrapper>
                  <p>Zobacz</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.204"
                    height="17.408"
                    viewBox="0 0 9.204 17.408"
                  >
                    <path
                      id="Path_4044"
                      data-name="Path 4044"
                      d="M-160.786,3.081l-8,8-8-8"
                      transform="translate(-2.374 -160.079) rotate(-90)"
                      fill="none"
                      stroke="#07020f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </RegulaminBtnWrapper>
              </RegulaminItem>
              <RegulaminItem>
                <h4>Szczegółowy cennik</h4>
                <RegulaminBtnWrapper>
                  <p>Zobacz</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.204"
                    height="17.408"
                    viewBox="0 0 9.204 17.408"
                  >
                    <path
                      id="Path_4044"
                      data-name="Path 4044"
                      d="M-160.786,3.081l-8,8-8-8"
                      transform="translate(-2.374 -160.079) rotate(-90)"
                      fill="none"
                      stroke="#07020f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </svg>
                </RegulaminBtnWrapper>
              </RegulaminItem>
            </RegulaminsWrapper>
          </FaqContentWrapper>
        </FaqScroll>
      </FaqMain>
    </FaqMainWrapper>
  );
};

export default FaqComponent;
