import React from 'react';

export const NoFileSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98.5"
    height="43.66"
    viewBox="0 0 98.5 43.66"
  >
    <g
      id="Group_6779"
      data-name="Group 6779"
      transform="translate(-158.75 -203.95)"
    >
      <path
        id="Line_483"
        data-name="Line 483"
        d="M0,22.331a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V21.581A.75.75,0,0,1,0,22.331Z"
        transform="translate(184.427 214.989)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_498"
        data-name="Line 498"
        d="M0,42.91a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V42.16A.75.75,0,0,1,0,42.91Z"
        transform="translate(209.355 204.7)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_506"
        data-name="Line 506"
        d="M0,4.738a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V3.988A.75.75,0,0,1,0,4.738Z"
        transform="translate(234.824 223.651)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_514"
        data-name="Line 514"
        d="M0,7.427a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.677A.75.75,0,0,1,0,7.427Z"
        transform="translate(159.5 222.568)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_490"
        data-name="Line 490"
        d="M0,13.634a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V12.884A.75.75,0,0,1,0,13.634Z"
        transform="translate(196.891 219.32)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_494"
        data-name="Line 494"
        d="M0,24.154a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V23.4A.75.75,0,0,1,0,24.154Z"
        transform="translate(221.818 214.268)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_502"
        data-name="Line 502"
        d="M0,9.484a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V8.734A.75.75,0,0,1,0,9.484Z"
        transform="translate(247.288 221.486)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_510"
        data-name="Line 510"
        d="M0,3.745A.75.75,0,0,1-.75,3V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V3A.75.75,0,0,1,0,3.745Z"
        transform="translate(171.964 224.373)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_486"
        data-name="Line 486"
        d="M0,13.634a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V12.884A.75.75,0,0,1,0,13.634Z"
        transform="translate(190.93 219.32)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_496"
        data-name="Line 496"
        d="M0,8.878a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V8.128A.75.75,0,0,1,0,8.878Z"
        transform="translate(215.857 221.309)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_504"
        data-name="Line 504"
        d="M0,15.908a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V15.158A.75.75,0,0,1,0,15.908Z"
        transform="translate(240.785 218.238)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_512"
        data-name="Line 512"
        d="M0,8.329a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V7.579A.75.75,0,0,1,0,8.329Z"
        transform="translate(166.003 222.208)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_488"
        data-name="Line 488"
        d="M0,26.518a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V25.768A.75.75,0,0,1,0,26.518Z"
        transform="translate(203.394 212.824)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_492"
        data-name="Line 492"
        d="M0,7.535a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.785A.75.75,0,0,1,0,7.535Z"
        transform="translate(228.321 222.568)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_500"
        data-name="Line 500"
        d="M0,4.672a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V3.922A.75.75,0,0,1,0,4.672Z"
        transform="translate(253.248 224.012)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_508"
        data-name="Line 508"
        d="M0,8.329a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V7.579A.75.75,0,0,1,0,8.329Z"
        transform="translate(178.467 222.208)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_484"
        data-name="Line 484"
        d="M0,19.3a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V18.55A.75.75,0,0,1,0,19.3Z"
        transform="translate(187.679 216.433)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_497"
        data-name="Line 497"
        d="M0,24.154a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V23.4A.75.75,0,0,1,0,24.154Z"
        transform="translate(212.606 214.268)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_505"
        data-name="Line 505"
        d="M0,7.535a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V6.785A.75.75,0,0,1,0,7.535Z"
        transform="translate(237.533 222.568)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_513"
        data-name="Line 513"
        d="M0,15.908a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V15.158A.75.75,0,0,1,0,15.908Z"
        transform="translate(162.751 218.238)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_489"
        data-name="Line 489"
        d="M0,22.331a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V21.581A.75.75,0,0,1,0,22.331Z"
        transform="translate(200.143 214.989)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_493"
        data-name="Line 493"
        d="M0,15.908a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V15.158A.75.75,0,0,1,0,15.908Z"
        transform="translate(225.07 218.238)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_501"
        data-name="Line 501"
        d="M0,6.043a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V5.293A.75.75,0,0,1,0,6.043Z"
        transform="translate(249.997 223.29)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_509"
        data-name="Line 509"
        d="M0,6.633a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V5.883A.75.75,0,0,1,0,6.633Z"
        transform="translate(175.215 222.929)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_485"
        data-name="Line 485"
        d="M0,9.917a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V9.167A.75.75,0,0,1,0,9.917Z"
        transform="translate(194.182 221.125)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_495"
        data-name="Line 495"
        d="M0,17.5a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V16.754A.75.75,0,0,1,0,17.5Z"
        transform="translate(219.109 217.516)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_503"
        data-name="Line 503"
        d="M0,9.484a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V8.734A.75.75,0,0,1,0,9.484Z"
        transform="translate(244.036 221.486)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_511"
        data-name="Line 511"
        d="M0,6.633a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V5.883A.75.75,0,0,1,0,6.633Z"
        transform="translate(168.712 222.929)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_487"
        data-name="Line 487"
        d="M0,35.757a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V35.007A.75.75,0,0,1,0,35.757Z"
        transform="translate(206.645 208.493)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_491"
        data-name="Line 491"
        d="M0,4.738a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V3.988A.75.75,0,0,1,0,4.738Z"
        transform="translate(231.573 223.651)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_499"
        data-name="Line 499"
        d="M0,4.672a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V3.922A.75.75,0,0,1,0,4.672Z"
        transform="translate(256.5 224.012)"
        fill="rgba(23,22,29,0.21)"
      />
      <path
        id="Line_507"
        data-name="Line 507"
        d="M0,15.908a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V15.158A.75.75,0,0,1,0,15.908Z"
        transform="translate(181.176 218.238)"
        fill="rgba(23,22,29,0.21)"
      />
    </g>
  </svg>
);

export const TrackPlaceholderSvg: React.FC = () => (
  <svg
    id="Group_6883"
    data-name="Group 6883"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="44.513"
    height="38"
    viewBox="0 0 44.513 38"
  >
    <g id="Group_6460" data-name="Group 6460" clipPath="url(#clip-path)">
      <path
        id="Path_4022"
        data-name="Path 4022"
        d="M21.915,85.14a3.778,3.778,0,0,0-7.41-.719H4.271a1.02,1.02,0,0,0,0,2.038H14.5a3.78,3.78,0,0,0,7.411-.716,1.541,1.541,0,0,0,0-.6m-5.521.3c0-2.271,3.391-2.251,3.512,0-.121,2.251-3.512,2.27-3.512,0"
        transform="translate(-2.062 -51.229)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4023"
        data-name="Path 4023"
        d="M79.559,89.1H60.36a1.02,1.02,0,0,0,0,2.038h19.2a1.02,1.02,0,0,0,0-2.038"
        transform="translate(-37.255 -55.903)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4024"
        data-name="Path 4024"
        d="M104.659,24.084c-.717,1.1,1.047,2.125,1.76,1.029a22.584,22.584,0,0,0,0-24.642c-.712-1.1-2.477-.076-1.76,1.028a20.567,20.567,0,0,1,0,22.585"
        transform="translate(-65.563 -0.001)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4025"
        data-name="Path 4025"
        d="M93.371,26c-.713,1.106,1.051,2.128,1.76,1.029a17.5,17.5,0,0,0,0-19.037c-.708-1.1-2.473-.078-1.76,1.029a15.47,15.47,0,0,1,0,16.98"
        transform="translate(-58.481 -4.716)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4026"
        data-name="Path 4026"
        d="M5.416,24.083a20.567,20.567,0,0,1,0-22.585C6.133.4,4.369-.627,3.657.47a22.583,22.583,0,0,0,0,24.642c.712,1.1,2.477.075,1.76-1.029"
        transform="translate(0 0)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4027"
        data-name="Path 4027"
        d="M16.366,27.024c.708,1.1,2.473.078,1.76-1.029a15.47,15.47,0,0,1,0-16.98c.713-1.105-1.051-2.128-1.76-1.028a17.5,17.5,0,0,0,0,19.037"
        transform="translate(-8.502 -4.715)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4028"
        data-name="Path 4028"
        d="M43.41,27.378a11.108,11.108,0,0,0,8.862-10.765A10.97,10.97,0,1,0,31.9,22.276a11.1,11.1,0,0,0,11.508,5.1M41.3,7.68a9.035,9.035,0,0,1,8.933,8.933,8.933,8.933,0,0,1-17.867,0A9.013,9.013,0,0,1,41.3,7.68"
        transform="translate(-19.044 -3.547)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4029"
        data-name="Path 4029"
        d="M50.507,31.488a1.76,1.76,0,0,0,1.816-.095l4.7-3.167a1.766,1.766,0,0,0,0-2.929l-4.7-3.167A1.766,1.766,0,0,0,49.57,23.6v6.333a1.762,1.762,0,0,0,.937,1.559m1.1-7.381,3.94,2.656-3.94,2.655Z"
        transform="translate(-31.103 -13.697)"
        fill="rgba(220,224,233,0.3)"
      />
    </g>
  </svg>
);

export const ServicePlaceholderSvg: React.FC = () => (
  <svg
    id="Group_6882"
    data-name="Group 6882"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="45.3"
    height="38"
    viewBox="0 0 45.3 38"
  >
    <g id="Group_6458" data-name="Group 6458" clipPath="url(#clip-path)">
      <path
        id="Path_4020"
        data-name="Path 4020"
        d="M100.7,0c-4.011,0-6.705,3.144-6.705,7.823s2.695,7.823,6.705,7.823S107.4,12.5,107.4,7.823,104.707,0,100.7,0m0,13.411c-3.3,0-4.47-2.887-4.47-5.588S97.4,2.235,100.7,2.235s4.47,2.887,4.47,5.588-1.174,5.588-4.47,5.588"
        transform="translate(-62.101)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_4021"
        data-name="Path 4021"
        d="M41.82,48.348H36.4a11.259,11.259,0,0,0-9,4.528l-1.881-5.014a2.754,2.754,0,0,0-2.574-1.75H2.124a2.123,2.123,0,0,0-1.952,2.96l6.1,14.243a1.117,1.117,0,0,0,1.027.678h6.8a1.118,1.118,0,0,0,0-2.235H8.04L2.293,48.348H22.944a.526.526,0,0,1,.49.319l2.537,6.762a11.031,11.031,0,0,0-.551,1.833c-.022.1-.669,3-1,4.493l-4.394,0a3.783,3.783,0,0,0-3.78,3.779v.691H2.833a1.118,1.118,0,1,0,0,2.235H17.361l13.738,0c.025,0,.051,0,.076,0h7.42a1.118,1.118,0,0,0,0-2.235h-6.1l1.05-4.742A1.118,1.118,0,0,0,31.36,61L30.2,66.229H18.479v-.691a1.547,1.547,0,0,1,1.546-1.544l5.289,0a1.117,1.117,0,0,0,1.09-.874s1.2-5.36,1.2-5.39a9.008,9.008,0,0,1,8.79-7.142H41.82a1.118,1.118,0,1,0,0-2.235"
        transform="translate(0 -30.468)"
        fill="rgba(220,224,233,0.3)"
      />
    </g>
  </svg>
);

export const DigitalProductPlaceholderSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="48.847"
    viewBox="0 0 47 48.847"
  >
    <path
      id="Path_4016"
      data-name="Path 4016"
      d="M72.726,0H45.142a1.017,1.017,0,0,0-1.017,1.017V28.6a1.017,1.017,0,0,0,1.017,1.017h4.044a1.017,1.017,0,1,0,0-2.034H46.159V2.034H71.708V15.613l-1.725-1.725a2.282,2.282,0,0,0-3.224,0L64.443,16.2a1.017,1.017,0,0,0,1.439,1.438L68.2,15.327a.246.246,0,0,1,.347,0L71.419,18.2a1.02,1.02,0,0,0,.29.2v9.184H56.251a1.017,1.017,0,0,0,0,2.034H72.726A1.017,1.017,0,0,0,73.743,28.6V1.017A1.017,1.017,0,0,0,72.726,0"
      transform="translate(-26.743)"
      fill="rgba(220,224,233,0.3)"
    />
    <path
      id="Path_4017"
      data-name="Path 4017"
      d="M26.658,41.813H25.014l5.651-5.651a.245.245,0,0,1,.347,0L38.253,43.4a1.017,1.017,0,1,0,1.438-1.439l-7.241-7.241a2.28,2.28,0,0,0-3.224,0L22.045,41.9a1.183,1.183,0,0,0,.744,1.942H25.64V67.453H2.034V43.847H15.37a1.017,1.017,0,1,0,0-2.034H1.017A1.017,1.017,0,0,0,0,42.83V68.47a1.017,1.017,0,0,0,1.017,1.017h25.64a1.017,1.017,0,0,0,1.017-1.017V42.83a1.017,1.017,0,0,0-1.017-1.017"
      transform="translate(0 -20.64)"
      fill="rgba(220,224,233,0.3)"
    />
    <path
      id="Path_4018"
      data-name="Path 4018"
      d="M24.513,72.445a2.047,2.047,0,0,0-1.088,1.812v8.776a2.051,2.051,0,0,0,3.2,1.7l6.511-4.388a2.052,2.052,0,0,0,0-3.4l-6.511-4.388a2.05,2.05,0,0,0-2.111-.11m.946,10.588V74.257l.028-.015L32,78.66Z"
      transform="translate(-14.197 -43.761)"
      fill="rgba(220,224,233,0.3)"
    />
    <path
      id="Path_4019"
      data-name="Path 4019"
      d="M64.005,17.659a3.56,3.56,0,1,0-3.56,3.56,3.565,3.565,0,0,0,3.56-3.56m-5.086,0a1.526,1.526,0,1,1,1.526,1.526,1.527,1.527,0,0,1-1.526-1.526"
      transform="translate(-34.476 -8.545)"
      fill="rgba(220,224,233,0.3)"
    />
  </svg>
);

export const WinWinSvg: React.FC = () => (
  <svg
    id="Group_6984"
    data-name="Group 6984"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="67.429"
    height="17"
    viewBox="0 0 67.429 17"
  >
    <g id="Group_6972" data-name="Group 6972" clipPath="url(#clip-path)">
      <path
        id="Path_4111"
        data-name="Path 4111"
        d="M211.954,39.406l-1.083,2.352c-.271.588-.551,1.214-.812,1.811h-.019c-.047-.579-.1-1.195-.168-1.783l-.261-2.38H208.2l-1.12,2.39c-.271.579-.56,1.213-.8,1.773h-.019c-.037-.56-.093-1.185-.149-1.764l-.233-2.4h-2.007l.849,6.758h1.988l.747-1.6c.271-.579.551-1.195.812-1.792h.019c.047.588.1,1.167.168,1.764l.177,1.624h2.025l3.248-6.758Z"
        transform="translate(-177.376 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4112"
        data-name="Path 4112"
        d="M274.511,39.406l-1.195,6.758h1.923l1.195-6.758Z"
        transform="translate(-237.797 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4113"
        data-name="Path 4113"
        d="M298.476,39.406l-.336,1.923c-.121.691-.243,1.428-.336,2.035l-.019.009c-.327-.625-.672-1.26-1.017-1.885l-1.148-2.082h-1.792l-1.195,6.758h1.848l.336-1.923c.121-.691.243-1.428.336-2.035l.019-.009c.327.625.672,1.26,1.017,1.885l1.148,2.082h1.792l1.195-6.758Z"
        transform="translate(-254.603 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4114"
        data-name="Path 4114"
        d="M349.151,61.438h2.343l.252-1.419H349.4Z"
        transform="translate(-303.776 -52.219)"
        fill="#fb9614"
      />
      <path
        id="Path_4115"
        data-name="Path 4115"
        d="M379,39.406l-1.083,2.352c-.27.588-.551,1.214-.812,1.811h-.019c-.047-.579-.1-1.195-.168-1.783l-.261-2.38h-1.409l-1.12,2.39c-.271.579-.56,1.213-.8,1.773H373.3c-.037-.56-.093-1.185-.149-1.764l-.233-2.4h-2.007l.849,6.758h1.988l.747-1.6c.271-.579.551-1.195.812-1.792h.019c.047.588.1,1.167.168,1.764l.177,1.624H377.7l3.248-6.758Z"
        transform="translate(-322.709 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4116"
        data-name="Path 4116"
        d="M441.551,39.406l-1.195,6.758h1.923l1.195-6.758Z"
        transform="translate(-383.129 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4117"
        data-name="Path 4117"
        d="M465.516,39.406l-.336,1.923c-.121.691-.243,1.428-.336,2.035l-.019.009c-.327-.625-.672-1.26-1.017-1.885l-1.148-2.082h-1.792l-1.195,6.758h1.848l.336-1.923c.121-.691.243-1.428.336-2.035l.019-.009c.327.625.672,1.26,1.017,1.885l1.148,2.082h1.792l1.195-6.758Z"
        transform="translate(-399.935 -34.285)"
        fill="#fb9614"
      />
      <path
        id="Path_4118"
        data-name="Path 4118"
        d="M20.1,2.647H18.781l-1-1H14.476l3.383,3.383.926-.047H20.1a.911.911,0,0,1,.91.91v5.2A.911.911,0,0,1,20.1,12l-3.56,0-2.4,2.4a.911.911,0,0,1-1.287,0L9.182,10.721a.912.912,0,0,1-.209-.963l6.518-.208.327-.327a3.249,3.249,0,0,0,0-4.595L12.143.953a3.249,3.249,0,0,0-4.595,0L5.839,2.662l-2.59,0A3.253,3.253,0,0,0,0,5.909v5.2a3.253,3.253,0,0,0,3.249,3.249H4.365L5.511,15.5H8.82L5.285,11.968l-.982.049H3.249a.911.911,0,0,1-.91-.91v-5.2A.911.911,0,0,1,3.248,5l3.56,0,2.4-2.4a.91.91,0,0,1,1.286,0l3.676,3.676a.912.912,0,0,1,.209.963l-6.519.208-.327.327a3.249,3.249,0,0,0,0,4.595L11.2,16.051a3.253,3.253,0,0,0,4.595,0l1.709-1.709,2.59,0a3.253,3.253,0,0,0,3.249-3.249V5.9A3.253,3.253,0,0,0,20.1,2.647"
        transform="translate(0 -0.001)"
        fill="#fb9614"
      />
    </g>
  </svg>
);

export const NewDesktopTrackPlayerSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="597.878"
    height="96.6"
    viewBox="0 0 597.878 96.6"
  >
    <g
      id="Group_6099"
      data-name="Group 6099"
      transform="translate(429.28 -13129.4)"
    >
      <path
        id="Path_3821"
        data-name="Path 3821"
        d="M2682.912,133.179a2.306,2.306,0,0,1-2.306-2.306V51.979a2.306,2.306,0,1,1,4.613,0v78.893a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2756.593 13086.273)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3822"
        data-name="Path 3822"
        d="M2632.335,204.749a2.306,2.306,0,0,1-2.307-2.306V149.167a2.307,2.307,0,0,1,4.613,0v53.275a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2712.681 13001.895)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3824"
        data-name="Path 3824"
        d="M2733.49,185.664a2.306,2.306,0,0,1-2.306-2.306V123.25a2.306,2.306,0,1,1,4.613,0v60.107a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2800.505 13024.396)"
        fill="rgba(220,224,233,0.3)"
      />
      <g
        id="Group_6098"
        data-name="Group 6098"
        transform="translate(-62.655 13129.4)"
      >
        <path
          id="Path_3808"
          data-name="Path 3808"
          d="M4402.548,157.964a2.306,2.306,0,0,1-2.307-2.306V85.635a2.306,2.306,0,1,1,4.612,0v70.022a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-4186.932 -72.347)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3809"
          data-name="Path 3809"
          d="M4453.124,242.257a2.307,2.307,0,0,1-2.307-2.306V200.1a2.306,2.306,0,1,1,4.613,0v39.85a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-4230.843 -171.726)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3810"
          data-name="Path 3810"
          d="M4351.97,122.677a2.307,2.307,0,0,1-2.307-2.306V37.718a2.307,2.307,0,0,1,4.613,0v82.652a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-4143.02 -30.745)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3811"
          data-name="Path 3811"
          d="M4301.392,172.807a2.306,2.306,0,0,1-2.306-2.306V105.791a2.307,2.307,0,0,1,4.613,0V170.5a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-4099.109 -89.846)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3812"
          data-name="Path 3812"
          d="M3997.927,175.313a2.307,2.307,0,0,1-2.306-2.306V109.194a2.307,2.307,0,0,1,4.613,0v63.812a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-3835.639 -92.801)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3813"
          data-name="Path 3813"
          d="M4099.082,278.867a2.306,2.306,0,0,1-2.306-2.306V249.815a2.306,2.306,0,1,1,4.613,0v26.745a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-3923.462 -214.888)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3814"
          data-name="Path 3814"
          d="M4048.5,205.39a2.307,2.307,0,0,1-2.306-2.306V150.037a2.307,2.307,0,0,1,4.613,0v53.046a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-3879.55 -128.261)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3815"
          data-name="Path 3815"
          d="M4250.815,181.984a2.306,2.306,0,0,1-2.306-2.306V118.254a2.306,2.306,0,1,1,4.613,0v61.424a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-4055.198 -100.667)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3816"
          data-name="Path 3816"
          d="M4503.7,285.6a2.307,2.307,0,0,1-2.307-2.306V258.952a2.306,2.306,0,1,1,4.613,0v24.337a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-4274.755 -222.821)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3817"
          data-name="Path 3817"
          d="M2986.377,157.964a2.307,2.307,0,0,1-2.306-2.306V85.635a2.306,2.306,0,1,1,4.613,0v70.022a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-2957.407 -72.347)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3818"
          data-name="Path 3818"
          d="M2935.8,115.684a2.307,2.307,0,0,1-2.306-2.306V28.222a2.306,2.306,0,1,1,4.613,0v85.155a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-2913.496 -22.5)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3819"
          data-name="Path 3819"
          d="M2885.222,133.179a2.306,2.306,0,0,1-2.306-2.306V51.979a2.306,2.306,0,1,1,4.613,0v78.893a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-2869.584 -43.126)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3820"
          data-name="Path 3820"
          d="M2834.645,96.6a2.306,2.306,0,0,1-2.306-2.306V2.306a2.306,2.306,0,1,1,4.613,0V94.293a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-2825.673)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3823"
          data-name="Path 3823"
          d="M2784.067,140.337a2.306,2.306,0,0,1-2.306-2.306V61.7a2.306,2.306,0,1,1,4.613,0v76.331a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-2781.761 -51.565)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3825"
          data-name="Path 3825"
          d="M3036.955,242.257a2.307,2.307,0,0,1-2.307-2.306V200.1a2.307,2.307,0,0,1,4.613,0v39.85a2.307,2.307,0,0,1-2.306,2.306"
          transform="translate(-3001.319 -171.726)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3826"
          data-name="Path 3826"
          d="M3087.532,285.6a2.306,2.306,0,0,1-2.306-2.306V258.952a2.306,2.306,0,1,1,4.613,0v24.337a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3045.231 -222.821)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3827"
          data-name="Path 3827"
          d="M4200.237,262.539a2.306,2.306,0,0,1-2.306-2.306v-32.59a2.306,2.306,0,1,1,4.613,0v32.59a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-4011.286 -195.638)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3828"
          data-name="Path 3828"
          d="M3947.351,131.172a2.307,2.307,0,0,1-2.307-2.306V49.254a2.306,2.306,0,1,1,4.613,0v79.611a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3791.728 -40.76)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3829"
          data-name="Path 3829"
          d="M3896.773,235.578a2.307,2.307,0,0,1-2.307-2.306v-42.24a2.307,2.307,0,0,1,4.613,0v42.24a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3747.817 -163.852)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3830"
          data-name="Path 3830"
          d="M3846.2,281.586a2.306,2.306,0,0,1-2.306-2.306V253.507a2.306,2.306,0,1,1,4.613,0v25.772a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3703.905 -218.094)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3831"
          data-name="Path 3831"
          d="M4149.66,239.111a2.306,2.306,0,0,1-2.306-2.306V195.829a2.306,2.306,0,1,1,4.613,0V236.8a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-3967.375 -168.018)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3832"
          data-name="Path 3832"
          d="M3694.463,282.064a2.307,2.307,0,0,1-2.307-2.306v-25.6a2.306,2.306,0,1,1,4.613,0v25.6a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3572.17 -218.658)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3833"
          data-name="Path 3833"
          d="M3745.04,207.077a2.306,2.306,0,0,1-2.306-2.306V152.328a2.306,2.306,0,1,1,4.613,0V204.77a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3616.082 -130.25)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3834"
          data-name="Path 3834"
          d="M3643.885,214.416a2.306,2.306,0,0,1-2.306-2.306V162.294a2.306,2.306,0,1,1,4.613,0v49.815a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3528.259 -138.902)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3835"
          data-name="Path 3835"
          d="M3593.308,236.039a2.306,2.306,0,0,1-2.306-2.306V191.657a2.306,2.306,0,1,1,4.613,0v42.075a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3484.347 -164.395)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3836"
          data-name="Path 3836"
          d="M3289.844,168.574a2.306,2.306,0,0,1-2.306-2.306V100.044a2.306,2.306,0,1,1,4.613,0v66.223a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3220.878 -84.857)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3837"
          data-name="Path 3837"
          d="M3391,280.157a2.306,2.306,0,0,1-2.306-2.306V251.567a2.306,2.306,0,1,1,4.613,0v26.283a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3308.701 -216.41)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3838"
          data-name="Path 3838"
          d="M3340.42,199.789a2.307,2.307,0,0,1-2.307-2.306v-55.05a2.307,2.307,0,0,1,4.613,0v55.05a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3264.789 -121.658)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3839"
          data-name="Path 3839"
          d="M3542.73,184.948a2.306,2.306,0,0,1-2.306-2.306V122.278a2.306,2.306,0,1,1,4.613,0v60.363a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3440.435 -104.16)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3840"
          data-name="Path 3840"
          d="M3795.619,264.11a2.306,2.306,0,0,1-2.307-2.306V229.776a2.306,2.306,0,1,1,4.613,0V261.8a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3659.994 -197.49)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3841"
          data-name="Path 3841"
          d="M3492.153,264.11a2.307,2.307,0,0,1-2.307-2.306V229.776a2.307,2.307,0,0,1,4.613,0V261.8a2.307,2.307,0,0,1-2.307,2.306"
          transform="translate(-3396.524 -197.49)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3842"
          data-name="Path 3842"
          d="M3239.265,220.6a2.307,2.307,0,0,1-2.307-2.306v-47.6a2.307,2.307,0,0,1,4.613,0v47.6a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3176.965 -146.19)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3843"
          data-name="Path 3843"
          d="M3188.688,264.11a2.306,2.306,0,0,1-2.306-2.306V229.776a2.306,2.306,0,1,1,4.613,0V261.8a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3133.055 -197.49)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3844"
          data-name="Path 3844"
          d="M3138.11,298.994a2.307,2.307,0,0,1-2.306-2.306V277.146a2.306,2.306,0,0,1,4.613,0v19.541a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3089.142 -238.617)"
          fill="rgba(220,224,233,0.3)"
        />
        <path
          id="Path_3845"
          data-name="Path 3845"
          d="M3441.575,241.087a2.306,2.306,0,0,1-2.306-2.306V198.512a2.306,2.306,0,1,1,4.613,0V238.78a2.306,2.306,0,0,1-2.306,2.306"
          transform="translate(-3352.612 -170.347)"
          fill="rgba(220,224,233,0.3)"
        />
      </g>
      <path
        id="Path_3846"
        data-name="Path 3846"
        d="M2430.025,246.991a2.307,2.307,0,0,1-2.306-2.306V206.529a2.306,2.306,0,1,1,4.613,0v38.155a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2537.035 12952.094)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3847"
        data-name="Path 3847"
        d="M2531.18,311.28a2.306,2.306,0,0,1-2.306-2.306V293.83a2.306,2.306,0,1,1,4.613,0v15.143a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2624.859 12876.299)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3848"
        data-name="Path 3848"
        d="M2480.6,264.976a2.307,2.307,0,0,1-2.306-2.306V230.951a2.307,2.307,0,0,1,4.613,0v31.718a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-2580.946 12930.89)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3849"
        data-name="Path 3849"
        d="M2379.448,276.965a2.306,2.306,0,0,1-2.306-2.306V247.232a2.306,2.306,0,1,1,4.613,0v27.426a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2493.124 12916.755)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3850"
        data-name="Path 3850"
        d="M2328.87,302.035a2.306,2.306,0,0,1-2.306-2.306V281.275a2.306,2.306,0,1,1,4.613,0v18.453a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2449.212 12887.198)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3851"
        data-name="Path 3851"
        d="M2278.293,315.246a2.306,2.306,0,0,1-2.306-2.306V299.215a2.306,2.306,0,0,1,4.613,0v13.724a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2405.301 12871.623)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3852"
        data-name="Path 3852"
        d="M2581.757,288.77a2.306,2.306,0,0,1-2.306-2.306v-23.2a2.306,2.306,0,1,1,4.613,0v23.2a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2668.769 12902.838)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3853"
        data-name="Path 3853"
        d="M2126.56,186.886a2.306,2.306,0,0,1-2.306-2.306V124.91a2.306,2.306,0,1,1,4.613,0V184.58a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2273.566 13022.955)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3854"
        data-name="Path 3854"
        d="M2177.138,258.717a2.306,2.306,0,0,1-2.306-2.306V222.452a2.306,2.306,0,1,1,4.613,0v33.958a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2317.478 12938.27)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3855"
        data-name="Path 3855"
        d="M2075.983,156.817a2.306,2.306,0,0,1-2.306-2.306V84.078a2.307,2.307,0,0,1,4.613,0v70.432a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-2229.655 13058.406)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3856"
        data-name="Path 3856"
        d="M2025.406,199.534a2.307,2.307,0,0,1-2.307-2.306V142.085a2.306,2.306,0,0,1,4.613,0v55.142a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2185.742 13008.044)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3857"
        data-name="Path 3857"
        d="M1721.94,187.889a2.306,2.306,0,0,1-2.306-2.306V126.271a2.306,2.306,0,0,1,4.613,0v59.311a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1922.273 13021.773)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3858"
        data-name="Path 3858"
        d="M1823.1,250.471a2.306,2.306,0,0,1-2.306-2.306v-36.91a2.306,2.306,0,0,1,4.613,0v36.91a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-2010.096 12947.989)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3859"
        data-name="Path 3859"
        d="M1772.518,193.806a2.306,2.306,0,0,1-2.306-2.306V134.306a2.306,2.306,0,1,1,4.613,0V191.5a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1966.185 13014.797)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3860"
        data-name="Path 3860"
        d="M1974.828,182.457a2.306,2.306,0,0,1-2.306-2.306V118.9a2.306,2.306,0,1,1,4.613,0V180.15a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2141.831 13028.177)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3861"
        data-name="Path 3861"
        d="M2227.715,295.649a2.306,2.306,0,0,1-2.306-2.306V272.6a2.306,2.306,0,0,1,4.613,0v20.739a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2361.389 12894.727)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3862"
        data-name="Path 3862"
        d="M710.391,169.52a2.306,2.306,0,0,1-2.306-2.306V101.328a2.306,2.306,0,0,1,4.613,0v65.886a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1044.042 13043.43)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3863"
        data-name="Path 3863"
        d="M659.813,129.738a2.306,2.306,0,0,1-2.306-2.306V47.306a2.306,2.306,0,0,1,4.613,0v80.125a2.307,2.307,0,0,1-2.306,2.306"
        transform="translate(-1000.13 13090.332)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3864"
        data-name="Path 3864"
        d="M609.236,165.767a2.306,2.306,0,0,1-2.306-2.306V96.231a2.306,2.306,0,1,1,4.613,0V163.46a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-956.219 13047.854)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3865"
        data-name="Path 3865"
        d="M558.658,134.6a2.306,2.306,0,0,1-2.306-2.306V53.9a2.306,2.306,0,0,1,4.613,0v78.386a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-912.307 13084.604)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3866"
        data-name="Path 3866"
        d="M406.926,165.767a2.306,2.306,0,0,1-2.306-2.306V96.231a2.306,2.306,0,1,1,4.613,0V163.46a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-780.572 13047.854)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3867"
        data-name="Path 3867"
        d="M356.348,226.755a2.306,2.306,0,0,1-2.306-2.306v-45.4a2.306,2.306,0,1,1,4.613,0v45.4a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-736.66 12975.951)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3868"
        data-name="Path 3868"
        d="M508.081,171.865a2.306,2.306,0,0,1-2.306-2.306V104.512a2.306,2.306,0,1,1,4.613,0v65.046a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-868.395 13040.665)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3869"
        data-name="Path 3869"
        d="M457.5,210.492a2.306,2.306,0,0,1-2.306-2.306v-51.22a2.306,2.306,0,1,1,4.613,0v51.22a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-824.483 12995.125)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3870"
        data-name="Path 3870"
        d="M760.968,211.478a2.306,2.306,0,0,1-2.306-2.306V158.305a2.306,2.306,0,1,1,4.613,0v50.867a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1087.953 12993.961)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3871"
        data-name="Path 3871"
        d="M811.546,266.8a2.306,2.306,0,0,1-2.306-2.306V233.428a2.306,2.306,0,0,1,4.613,0v31.065a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1131.865 12928.738)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3872"
        data-name="Path 3872"
        d="M1924.25,262.789a2.307,2.307,0,0,1-2.307-2.306v-32.5a2.306,2.306,0,0,1,4.613,0v32.5a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2097.919 12933.468)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3873"
        data-name="Path 3873"
        d="M1671.363,234.481a2.307,2.307,0,0,1-2.307-2.306V189.541a2.306,2.306,0,0,1,4.613,0v42.633a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1878.362 12966.843)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3874"
        data-name="Path 3874"
        d="M1620.785,250.472a2.306,2.306,0,0,1-2.306-2.306V211.256a2.306,2.306,0,1,1,4.613,0v36.909a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1834.45 12947.988)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3875"
        data-name="Path 3875"
        d="M1570.208,265.293a2.306,2.306,0,0,1-2.306-2.306v-31.6a2.306,2.306,0,0,1,4.613,0v31.6a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1790.538 12930.516)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3876"
        data-name="Path 3876"
        d="M1873.673,239.426a2.306,2.306,0,0,1-2.306-2.306V196.256a2.306,2.306,0,1,1,4.613,0V237.12a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-2054.008 12961.012)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3877"
        data-name="Path 3877"
        d="M1418.476,273.176a2.306,2.306,0,0,1-2.307-2.306V242.086a2.306,2.306,0,0,1,4.613,0v28.783a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1658.804 12921.222)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3878"
        data-name="Path 3878"
        d="M1469.053,173.922a2.306,2.306,0,0,1-2.306-2.306v-64.31a2.306,2.306,0,0,1,4.613,0v64.31a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1702.715 13038.239)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3879"
        data-name="Path 3879"
        d="M1367.9,197.118a2.306,2.306,0,0,1-2.306-2.306V138.8a2.306,2.306,0,1,1,4.613,0v56.007a2.306,2.306,0,0,1-2.307,2.306"
        transform="translate(-1614.892 13010.892)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3880"
        data-name="Path 3880"
        d="M1317.32,221.429a2.306,2.306,0,0,1-2.307-2.306V171.817a2.306,2.306,0,0,1,4.613,0v47.305a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1570.98 12982.23)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3881"
        data-name="Path 3881"
        d="M1013.856,145.58a2.306,2.306,0,0,1-2.306-2.306V68.818a2.306,2.306,0,1,1,4.613,0v74.455a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1307.511 13071.654)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3882"
        data-name="Path 3882"
        d="M1115.01,238.873a2.306,2.306,0,0,1-2.307-2.306V195.505a2.306,2.306,0,1,1,4.613,0v41.061a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1395.334 12961.664)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3883"
        data-name="Path 3883"
        d="M1064.433,180.673a2.306,2.306,0,0,1-2.306-2.306V116.473a2.306,2.306,0,1,1,4.613,0v61.893a2.306,2.306,0,0,1-2.307,2.306"
        transform="translate(-1351.423 13030.28)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3884"
        data-name="Path 3884"
        d="M1266.743,200.433a2.306,2.306,0,0,1-2.306-2.306v-54.82a2.306,2.306,0,1,1,4.613,0v54.82a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1527.069 13006.983)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3885"
        data-name="Path 3885"
        d="M1519.63,243.864a2.306,2.306,0,0,1-2.306-2.306V202.282a2.306,2.306,0,0,1,4.613,0v39.275a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1746.626 12955.78)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3886"
        data-name="Path 3886"
        d="M1216.166,252.99a2.306,2.306,0,0,1-2.307-2.306V214.675a2.306,2.306,0,0,1,4.613,0v36.008a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1483.157 12945.021)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3887"
        data-name="Path 3887"
        d="M963.278,204.07a2.306,2.306,0,0,1-2.306-2.306V148.244a2.306,2.306,0,1,1,4.613,0v53.519a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1263.599 13002.696)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3888"
        data-name="Path 3888"
        d="M912.7,242.509a2.306,2.306,0,0,1-2.307-2.306V200.443a2.306,2.306,0,0,1,4.613,0V240.2a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1219.688 12957.377)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3889"
        data-name="Path 3889"
        d="M862.123,261.648a2.306,2.306,0,0,1-2.306-2.306V226.432a2.306,2.306,0,1,1,4.613,0v32.909a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-1175.776 12934.813)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3890"
        data-name="Path 3890"
        d="M1165.588,227.106a2.306,2.306,0,0,1-2.306-2.306V179.526a2.306,2.306,0,1,1,4.613,0V224.8a2.307,2.307,0,0,1-2.307,2.306"
        transform="translate(-1439.246 12975.538)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3891"
        data-name="Path 3891"
        d="M154.038,262.751a2.306,2.306,0,0,1-2.306-2.306V227.93a2.306,2.306,0,1,1,4.613,0v32.514a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-561.014 12933.513)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3892"
        data-name="Path 3892"
        d="M255.193,317.535a2.306,2.306,0,0,1-2.306-2.306v-12.9a2.306,2.306,0,0,1,4.613,0v12.9a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-648.837 12868.923)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3893"
        data-name="Path 3893"
        d="M204.616,278.076a2.306,2.306,0,0,1-2.306-2.306V248.741a2.306,2.306,0,1,1,4.613,0V275.77a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-604.926 12915.444)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3894"
        data-name="Path 3894"
        d="M103.461,288.294a2.306,2.306,0,0,1-2.306-2.306V262.616a2.306,2.306,0,0,1,4.613,0v23.371a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-517.103 12903.397)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3895"
        data-name="Path 3895"
        d="M52.883,309.657a2.306,2.306,0,0,1-2.306-2.306V291.626a2.306,2.306,0,0,1,4.613,0v15.724a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-473.191 12878.212)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3896"
        data-name="Path 3896"
        d="M2.306,326.784A2.306,2.306,0,0,1,0,324.477v-9.594a2.306,2.306,0,0,1,4.613,0v9.594a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-429.28 12858.02)"
        fill="rgba(220,224,233,0.3)"
      />
      <path
        id="Path_3897"
        data-name="Path 3897"
        d="M305.771,298.353a2.306,2.306,0,0,1-2.306-2.306V276.276a2.306,2.306,0,1,1,4.613,0v19.771a2.306,2.306,0,0,1-2.306,2.306"
        transform="translate(-692.749 12891.538)"
        fill="rgba(220,224,233,0.3)"
      />
    </g>
  </svg>
);

export const WhiteWinWinSvg: React.FC = () => (
  <svg
    id="Group_7382"
    data-name="Group 7382"
    xmlns="http://www.w3.org/2000/svg"
    width="111.06"
    height="28"
    viewBox="0 0 111.06 28"
  >
    <g id="Group_6972" data-name="Group 6972">
      <path
        id="Path_4111"
        data-name="Path 4111"
        d="M217.184,39.406,215.4,43.28c-.446.969-.907,2-1.338,2.983h-.03c-.077-.953-.169-1.968-.277-2.937l-.431-3.92H211l-1.845,3.936c-.446.953-.922,2-1.322,2.921h-.031c-.061-.922-.154-1.953-.246-2.906l-.385-3.951h-3.305l1.4,11.13h3.275l1.23-2.629c.446-.953.907-1.968,1.337-2.952h.031c.077.969.169,1.922.277,2.906l.292,2.675h3.336l5.35-11.13Z"
        transform="translate(-160.233 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4112"
        data-name="Path 4112"
        d="M275.284,39.406l-1.968,11.13h3.167l1.968-11.13Z"
        transform="translate(-214.813 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4113"
        data-name="Path 4113"
        d="M302.257,39.406l-.553,3.167c-.2,1.138-.4,2.352-.553,3.352l-.031.015c-.538-1.03-1.107-2.075-1.676-3.105l-1.891-3.428H294.6l-1.968,11.13h3.044l.554-3.167c.2-1.138.4-2.352.553-3.351l.031-.015c.538,1.03,1.107,2.075,1.675,3.105l1.891,3.428h2.952l1.968-11.13Z"
        transform="translate(-229.995 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4114"
        data-name="Path 4114"
        d="M349.151,62.356h3.859l.415-2.337h-3.859Z"
        transform="translate(-274.416 -47.172)"
        fill="#fff"
      />
      <path
        id="Path_4115"
        data-name="Path 4115"
        d="M384.225,39.406l-1.783,3.874c-.446.969-.907,2-1.337,2.983h-.031c-.077-.953-.169-1.968-.277-2.937l-.431-3.92h-2.321L376.2,43.342c-.446.953-.922,2-1.322,2.921h-.031c-.061-.922-.154-1.953-.246-2.906l-.384-3.951h-3.305l1.4,11.13h3.275l1.23-2.629c.446-.953.907-1.968,1.337-2.952h.031c.077.969.169,1.922.276,2.906l.292,2.675h3.336l5.35-11.13Z"
        transform="translate(-291.519 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4116"
        data-name="Path 4116"
        d="M442.324,39.406l-1.968,11.13h3.167l1.968-11.13Z"
        transform="translate(-346.099 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4117"
        data-name="Path 4117"
        d="M469.3,39.406l-.554,3.167c-.2,1.138-.4,2.352-.553,3.352l-.031.015c-.538-1.03-1.107-2.075-1.676-3.105l-1.891-3.428h-2.952l-1.968,11.13h3.044l.553-3.167c.2-1.138.4-2.352.553-3.351l.031-.015c.538,1.03,1.107,2.075,1.676,3.105l1.891,3.428h2.952l1.968-11.13Z"
        transform="translate(-361.281 -30.971)"
        fill="#fff"
      />
      <path
        id="Path_4118"
        data-name="Path 4118"
        d="M33.1,4.36h-2.17L29.29,2.716H23.843L29.415,8.29l1.525-.077H33.1a1.5,1.5,0,0,1,1.5,1.5v8.562a1.5,1.5,0,0,1-1.5,1.5l-5.863,0L23.3,23.712a1.5,1.5,0,0,1-2.119,0l-6.054-6.054a1.5,1.5,0,0,1-.344-1.587l10.736-.342.539-.539a5.351,5.351,0,0,0,0-7.568L20,1.568a5.351,5.351,0,0,0-7.568,0L9.618,4.384l-4.267,0A5.357,5.357,0,0,0,0,9.732v8.562a5.357,5.357,0,0,0,5.351,5.351H7.189l1.889,1.889h5.449L8.7,19.711l-1.618.08H5.351a1.5,1.5,0,0,1-1.5-1.5V9.732a1.5,1.5,0,0,1,1.5-1.5l5.863,0,3.945-3.945a1.5,1.5,0,0,1,2.119,0l6.054,6.054a1.5,1.5,0,0,1,.344,1.586l-10.736.342-.539.539a5.352,5.352,0,0,0,0,7.568l6.054,6.054a5.358,5.358,0,0,0,7.568,0l2.815-2.816,4.267,0a5.357,5.357,0,0,0,5.351-5.351V9.711A5.357,5.357,0,0,0,33.1,4.36"
        transform="translate(0 -0.001)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const ConditionSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.625"
    height="70"
    viewBox="0 0 65.625 70"
  >
    <g id="Group_7587" data-name="Group 7587" transform="translate(-1054 -543)">
      <g id="Group_6465" data-name="Group 6465" transform="translate(1054 543)">
        <g id="Group_6464" data-name="Group 6464">
          <path
            id="Path_4030"
            data-name="Path 4030"
            d="M38.177,51.315a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,55.86l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441L38.455,52.7a1,1,0,0,0-.278-1.388"
            transform="translate(-18.598 -32.124)"
            fill="#07020f"
          />
          <path
            id="Path_4031"
            data-name="Path 4031"
            d="M38.177,89a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,93.547l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441l4.006-6.007A1,1,0,0,0,38.177,89"
            transform="translate(-18.598 -55.794)"
            fill="#07020f"
          />
          <path
            id="Path_4032"
            data-name="Path 4032"
            d="M38.177,126.69a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14a1,1,0,1,0-1.416,1.416l3,3a1,1,0,0,0,.708.293c.033,0,.066,0,.1,0a1,1,0,0,0,.734-.441l4.006-6.007a1,1,0,0,0-.278-1.388"
            transform="translate(-18.598 -79.464)"
            fill="#07020f"
          />
          <path
            id="Path_4033"
            data-name="Path 4033"
            d="M51.063,7.009H38.047V4a1,1,0,0,0-1-1h-6.44a4.984,4.984,0,0,0-9.148,0h-6.44a1,1,0,0,0-1,1v3H1a1,1,0,0,0-1,1V66.082a1,1,0,0,0,1,1H37.046a1,1,0,1,0,0-2H2V9.011H14.017v3H6.007a1,1,0,0,0-1,1v48.06a1,1,0,0,0,1,1H35.044a1,1,0,1,0,0-2H7.009V14.017H45.056V39.049a1,1,0,1,0,2,0V13.016a1,1,0,0,0-1-1h-8.01v-3H50.062V39.049a1,1,0,1,0,2,0V8.01a1,1,0,0,0-1-1m-35.044-2h6.149a1,1,0,0,0,.97-.751,2.985,2.985,0,0,1,5.787,0,1,1,0,0,0,.97.751h6.149v7.009H16.02Z"
            fill="#07020f"
          />
          <path
            id="Path_4034"
            data-name="Path 4034"
            d="M79.627,59.224H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
            transform="translate(-40.578 -37.197)"
            fill="#07020f"
          />
          <path
            id="Path_4035"
            data-name="Path 4035"
            d="M79.627,96.912H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
            transform="translate(-40.578 -60.867)"
            fill="#07020f"
          />
          <path
            id="Path_4036"
            data-name="Path 4036"
            d="M65.609,134.6a1,1,0,0,0,0,2h7.009a1,1,0,0,0,0-2Z"
            transform="translate(-40.578 -84.538)"
            fill="#07020f"
          />
        </g>
      </g>
      <g
        id="Group_6466"
        data-name="Group 6466"
        transform="translate(1093.375 586.75)"
      >
        <path
          id="Path_3927"
          data-name="Path 3927"
          d="M6.965-1.484A1.039,1.039,0,0,0,8.006-2.525,1.039,1.039,0,0,0,6.965-3.567H3.9l3.973-4.9a.9.9,0,0,0,.192-.577.918.918,0,0,0-.961-.929H1.645A1.039,1.039,0,0,0,.6-8.934,1.039,1.039,0,0,0,1.645-7.893H4.69L.732-2.99a.9.9,0,0,0-.192.577.918.918,0,0,0,.961.929Zm2.788-.9a1.073,1.073,0,0,0,1.073,1.073A1.073,1.073,0,0,0,11.9-2.381V-5.842l1.089-.513a.788.788,0,0,0,.368-1.025.788.788,0,0,0-1.025-.368l-.433.192v-4.87A1.073,1.073,0,0,0,10.826-13.5a1.073,1.073,0,0,0-1.073,1.073v5.88l-1.089.5a.775.775,0,0,0-.368,1.009.78.78,0,0,0,1.025.385l.433-.192Z"
          transform="translate(6.752 20.062)"
          fill="#07020f"
        />
        <path
          id="Path_4037"
          data-name="Path 4037"
          d="M13.125,2.188A10.938,10.938,0,1,0,24.063,13.125,10.95,10.95,0,0,0,13.125,2.188m0-2.187A13.125,13.125,0,1,1,0,13.125,13.125,13.125,0,0,1,13.125,0Z"
          fill="#07020f"
        />
      </g>
    </g>
  </svg>
);

export const NewMobileTrackPlayerSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="300.875"
    height="87"
    viewBox="0 0 300.875 87"
  >
    <g
      id="Group_6072"
      data-name="Group 6072"
      transform="translate(303.5 -13198.5)"
    >
      <path
        id="Line_483"
        data-name="Line 483"
        d="M.524,45.346A2.016,2.016,0,0,1-1.5,43.338V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V43.338A2.016,2.016,0,0,1,.524,45.346Z"
        transform="translate(-119.856 13220.08)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_498"
        data-name="Line 498"
        d="M.524,85.5A2.016,2.016,0,0,1-1.5,83.492V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V83.492A2.016,2.016,0,0,1,.524,85.5Z"
        transform="translate(-65.887 13200)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_506"
        data-name="Line 506"
        d="M.524,10.546A2.016,2.016,0,0,1-1.5,8.538V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V8.538A2.016,2.016,0,0,1,.524,10.546Z"
        transform="translate(-227.793 13237.478)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_514"
        data-name="Line 514"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-173.825 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_901"
        data-name="Line 901"
        d="M2.024,16.062A2.016,2.016,0,0,1,0,14.054V2.008a2.024,2.024,0,0,1,4.048,0V14.054A2.016,2.016,0,0,1,2.024,16.062Z"
        transform="translate(-265.722 13249.358) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_490"
        data-name="Line 490"
        d="M.524,26.608A2.016,2.016,0,0,1-1.5,24.6V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V24.6A2.016,2.016,0,0,1,.524,26.608Z"
        transform="translate(-92.872 13229.442)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_494"
        data-name="Line 494"
        d="M.524,50.7A2.016,2.016,0,0,1-1.5,48.692V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V48.692A2.016,2.016,0,0,1,.524,50.7Z"
        transform="translate(-38.903 13217.398)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_502"
        data-name="Line 502"
        d="M.524,18.577A2.016,2.016,0,0,1-1.5,16.569V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V16.569A2.016,2.016,0,0,1,.524,18.577Z"
        transform="translate(-200.809 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_902"
        data-name="Line 902"
        d="M2.024,20.077A2.016,2.016,0,0,1,0,18.069V2.008a2.024,2.024,0,0,1,4.048,0V18.069A2.016,2.016,0,0,1,2.024,20.077Z"
        transform="translate(-238.738 13252.04) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_510"
        data-name="Line 510"
        d="M.524,9.208A2.016,2.016,0,0,1-1.5,7.2V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V7.2A2.016,2.016,0,0,1,.524,9.208Z"
        transform="translate(-146.84 13238.814)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_903"
        data-name="Line 903"
        d="M2.024,10.708A2.016,2.016,0,0,1,0,8.7V2.008a2.024,2.024,0,0,1,4.048,0V8.7A2.016,2.016,0,0,1,2.024,10.708Z"
        transform="translate(-292.706 13246.685) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_486"
        data-name="Line 486"
        d="M.524,26.608A2.016,2.016,0,0,1-1.5,24.6V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V24.6A2.016,2.016,0,0,1,.524,26.608Z"
        transform="translate(-106.364 13229.442)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_496"
        data-name="Line 496"
        d="M.524,19.915A2.016,2.016,0,0,1-1.5,17.908V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508v17.4A2.016,2.016,0,0,1,.524,19.915Z"
        transform="translate(-52.395 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_504"
        data-name="Line 504"
        d="M.524,30.623A2.016,2.016,0,0,1-1.5,28.615V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V28.615A2.016,2.016,0,0,1,.524,30.623Z"
        transform="translate(-214.301 13228.106)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_512"
        data-name="Line 512"
        d="M.524,18.577A2.016,2.016,0,0,1-1.5,16.569V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V16.569A2.016,2.016,0,0,1,.524,18.577Z"
        transform="translate(-160.332 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_904"
        data-name="Line 904"
        d="M2.024,20.077A2.016,2.016,0,0,1,0,18.069V2.008a2.024,2.024,0,0,1,4.048,0V18.069A2.016,2.016,0,0,1,2.024,20.077Z"
        transform="translate(-279.214 13252.04) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_488"
        data-name="Line 488"
        d="M.524,50.7A2.016,2.016,0,0,1-1.5,48.692V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V48.692A2.016,2.016,0,0,1,.524,50.7Z"
        transform="translate(-79.38 13217.398)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_492"
        data-name="Line 492"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-25.411 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_500"
        data-name="Line 500"
        d="M.524,10.546A2.016,2.016,0,0,1-1.5,8.538V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V8.538A2.016,2.016,0,0,1,.524,10.546Z"
        transform="translate(-187.317 13237.478)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_905"
        data-name="Line 905"
        d="M2.024,12.046A2.016,2.016,0,0,1,0,10.038V2.008a2.024,2.024,0,0,1,4.048,0v8.031A2.016,2.016,0,0,1,2.024,12.046Z"
        transform="translate(-252.23 13248.022) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_898"
        data-name="Line 898"
        d="M.524,10.546A2.016,2.016,0,0,1-1.5,8.538V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V8.538A2.016,2.016,0,0,1,.524,10.546Z"
        transform="translate(-11.919 13237.478)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_508"
        data-name="Line 508"
        d="M.524,18.577A2.016,2.016,0,0,1-1.5,16.569V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V16.569A2.016,2.016,0,0,1,.524,18.577Z"
        transform="translate(-133.348 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_484"
        data-name="Line 484"
        d="M.524,39.992A2.016,2.016,0,0,1-1.5,37.985V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V37.985A2.016,2.016,0,0,1,.524,39.992Z"
        transform="translate(-113.11 13222.752)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_497"
        data-name="Line 497"
        d="M.524,50.7A2.016,2.016,0,0,1-1.5,48.692V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V48.692A2.016,2.016,0,0,1,.524,50.7Z"
        transform="translate(-59.141 13217.398)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_505"
        data-name="Line 505"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-221.047 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_513"
        data-name="Line 513"
        d="M.524,34.638A2.016,2.016,0,0,1-1.5,32.631V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V32.631A2.016,2.016,0,0,1,.524,34.638Z"
        transform="translate(-167.079 13225.434)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_900"
        data-name="Line 900"
        d="M2.024,36.138A2.016,2.016,0,0,1,0,34.131V2.008a2.024,2.024,0,0,1,4.048,0V34.131A2.016,2.016,0,0,1,2.024,36.138Z"
        transform="translate(-272.468 13260.066) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_489"
        data-name="Line 489"
        d="M.524,45.346A2.016,2.016,0,0,1-1.5,43.338V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V43.338A2.016,2.016,0,0,1,.524,45.346Z"
        transform="translate(-86.126 13220.08)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_493"
        data-name="Line 493"
        d="M.524,30.623A2.016,2.016,0,0,1-1.5,28.615V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V28.615A2.016,2.016,0,0,1,.524,30.623Z"
        transform="translate(-32.157 13228.106)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_501"
        data-name="Line 501"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-194.063 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_906"
        data-name="Line 906"
        d="M2.024,16.062A2.016,2.016,0,0,1,0,14.054V2.008a2.024,2.024,0,0,1,4.048,0V14.054A2.016,2.016,0,0,1,2.024,16.062Z"
        transform="translate(-245.484 13249.358) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_897"
        data-name="Line 897"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-18.665 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_509"
        data-name="Line 509"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-140.094 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_907"
        data-name="Line 907"
        d="M2.024,16.062A2.016,2.016,0,0,1,0,14.054V2.008a2.024,2.024,0,0,1,4.048,0V14.054A2.016,2.016,0,0,1,2.024,16.062Z"
        transform="translate(-299.453 13249.358) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_485"
        data-name="Line 485"
        d="M.524,18.577A2.016,2.016,0,0,1-1.5,16.569V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V16.569A2.016,2.016,0,0,1,.524,18.577Z"
        transform="translate(-99.618 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_495"
        data-name="Line 495"
        d="M.524,34.638A2.016,2.016,0,0,1-1.5,32.631V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V32.631A2.016,2.016,0,0,1,.524,34.638Z"
        transform="translate(-45.649 13225.434)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_503"
        data-name="Line 503"
        d="M.524,18.577A2.016,2.016,0,0,1-1.5,16.569V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V16.569A2.016,2.016,0,0,1,.524,18.577Z"
        transform="translate(-207.555 13233.46)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_511"
        data-name="Line 511"
        d="M.524,14.562A2.016,2.016,0,0,1-1.5,12.554V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V12.554A2.016,2.016,0,0,1,.524,14.562Z"
        transform="translate(-153.586 13236.142)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_908"
        data-name="Line 908"
        d="M2.024,16.062A2.016,2.016,0,0,1,0,14.054V2.008a2.024,2.024,0,0,1,4.048,0V14.054A2.016,2.016,0,0,1,2.024,16.062Z"
        transform="translate(-285.96 13249.358) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_487"
        data-name="Line 487"
        d="M.524,72.115A2.016,2.016,0,0,1-1.5,70.108V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508v69.6A2.016,2.016,0,0,1,.524,72.115Z"
        transform="translate(-72.633 13206.69)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_491"
        data-name="Line 491"
        d="M.524,10.546A2.016,2.016,0,0,1-1.5,8.538V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V8.538A2.016,2.016,0,0,1,.524,10.546Z"
        transform="translate(-234.539 13237.478)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_499"
        data-name="Line 499"
        d="M.524,6.531A2.016,2.016,0,0,1-1.5,4.523V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V4.523A2.016,2.016,0,0,1,.524,6.531Z"
        transform="translate(-180.571 13240.15)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_909"
        data-name="Line 909"
        d="M2.024,8.031A2.016,2.016,0,0,1,0,6.023V2.008a2.024,2.024,0,0,1,4.048,0V6.023A2.016,2.016,0,0,1,2.024,8.031Z"
        transform="translate(-258.976 13245.35) rotate(180)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_899"
        data-name="Line 899"
        d="M.524,6.531A2.016,2.016,0,0,1-1.5,4.523V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V4.523A2.016,2.016,0,0,1,.524,6.531Z"
        transform="translate(-5.173 13240.15)"
        fill="rgba(112,112,112,0.15)"
      />
      <path
        id="Line_507"
        data-name="Line 507"
        d="M.524,30.623A2.016,2.016,0,0,1-1.5,28.615V.508A2.016,2.016,0,0,1,.524-1.5,2.016,2.016,0,0,1,2.548.508V28.615A2.016,2.016,0,0,1,.524,30.623Z"
        transform="translate(-126.602 13228.106)"
        fill="rgba(112,112,112,0.15)"
      />
    </g>
  </svg>
);
