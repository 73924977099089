export enum OrderStatus {
  Pending = 0,
  AwaitingPayment = 1,
  AwaitingDocumentAdvanceInvoice = 2,
  AwaitingDocumentContractStart = 3,
  InRealisation = 4,
  OnHold = 5,
  RealizationDateExtensionRequest = 6,
  Submitted = 7,
  CommentsFromCustomer = 8,
  CommentsFRejectedBySeller = 9,
  InConflict = 10,
  Completed = 11,
  CompletedWithDocument = 12,
  AwaitingWinWinAction = 13,
  Closed = 14,
  AwaitingDocumentContractEnding = 15,
  Cancelled = 16,
  AwaitingRefund = 17,
  Refunded = 18,
  Failed = 19,
  Trash = 20,
}

export enum OrderType {
  Purchase = 1,
  SellerPlan = 2,
  Sale = 3,
  PayoutFee = 4,
}

export enum Tax {
  Zero = 0,
  Five = 5,
  Eight = 8,
  TwentyThree = 23,
}

export enum PaymentMethod {
  'Przelewy24' = 0,
  CashOnDelivery = 1,
  'MangoPay' = 2,
}

export enum FileTypeEnum {
  Faktura = 0,
  Umowa = 1,
  Korekta = 2,
  Produkt = 3,
  Pit = 4,
}
