import styled, {keyframes} from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  border: 5px solid rgba(137, 190, 255, 0.5);
  border-left-color: #89bfff;
  margin: 0px auto 50px;
  animation: ${spin} 1s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  z-index: 5;
`;
