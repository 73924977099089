import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import {Navigate, useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {ResolutionContext} from 'contexts/ResolutionContext';

import {
  AdditionalInformation,
  ButtonWrapper,
  CenterSection,
  Grid,
  InputWrapper,
  LeftSectionDescriptions,
  ListPro,
  Main,
  MainWrapper,
  RightSection,
  TitlePro,
  Header,
  HeaderIconsWrapper,
  RightSectionHeader,
  OneTextWrapper,
  PlanPriceWrapper,
  PromotionBlueTextWrapper,
  PlanPriceWithPromotion,
  RedLine,
  PlanData,
  DescriptionWrapper,
  MobileWrapper,
  MobileHeader,
  MobileHeaderPlanName,
  MobileContentWrapper,
  MobileListItemWrapper,
  MobileListItemBig,
  MobileListItemSmall,
  MobileBgStart,
  MobileBgOptymalny,
  MobileBgPro,
  MobileBlueBtn,
  MobileBlueGreen,
  MobileBtnWrapper,
  MobileLegendWrapper,
} from './styles';
import PopupButton from '../../../components/PopUp/PopupButton';
import useAlert from '../../../hooks/useAlert';
import api from '../../../services/axiosConfig';
import {ProMessage} from './ProMessage';
import {isEndDateNear} from '../../../utils/isEndDateNear';
import {UserDataContext} from '../../../contexts/UserDataContext';
import {LoadingCircle} from 'utils/loadingCircle';

interface Order {
  Id: string;
  Price: string | number;
  Time: string | number;
  Name: string;
}

interface Plan {
  AvaliableBlocks: number;
  CanHighlightCard: boolean;
  Cost: number;
  Description: string;
  Id: string;
  MaxActiveOffers: number;
  MaxOffersHighlights: number;
  Name: string;
  ValidDays: number;
  MaxServiceOffers: number;
  MaxDigitalOffers: number;
  FreeWithdrawalsThreshold: number;
  MUGOStudioAILimit: number;
  IsPromotionActive: boolean;
  Promotion: {
    EndDate: string;
    PromoCost: number;
    StartDate: string;
  };
}

interface MangopayAccount {
  KYCLevel: string;
  Type: number;
}

const planIds = {
  start: '0b9da0bf-cd5d-11ed-9742-000d3a44fa94',
  optymalny: '0ba17510-cd5d-11ed-9742-000d3a44fa94',
  pro: '8eb9ff9a-cd5d-11ed-9742-000d3a44fa94',
};

const PROComponent: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const [buyView, setBuyView] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataForOrder, setDataForOrder] = useState<Order>({
    Id: '',
    Price: '',
    Time: '',
    Name: '',
  });
  const [sellerPlans, setSellerPlans] = useState<Plan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<Plan>({
    AvaliableBlocks: 0,
    CanHighlightCard: false,
    Cost: 0,
    Description: '',
    Id: '',
    MaxActiveOffers: 0,
    MaxOffersHighlights: 0,
    Name: '',
    ValidDays: 0,
    MaxServiceOffers: 0,
    MaxDigitalOffers: 0,
    FreeWithdrawalsThreshold: 0,
    MUGOStudioAILimit: 0,
    IsPromotionActive: false,
    Promotion: {
      EndDate: '',
      PromoCost: 0,
      StartDate: '',
    },
  });
  const [activeTo, setActiveTo] = useState(new Date());
  const [activeToViewPlan, setActiveToViewPlan] = useState<any>(null);

  const [mangoAccount, setMangoAccount] = useState<MangopayAccount>({
    KYCLevel: '',
    Type: -1,
  });

  const {Alert} = useAlert();

  const [userData, setUserData] = useContext(UserDataContext);
  const {decodedToken} = useContext(ProducerDataContext);
  const {windowWidth} = useContext(ResolutionContext);

  const goToPayView = (
    id: string,
    name: string,
    cost: number | string,
    time: number | string,
  ) => {
    if (mangoAccount.Type === -1)
      return Alert(
        'Aby kupić plan, musisz założyć konto mangopay',
        'Komunikat',
      );
    if (buyView) return;
    setDataForOrder({
      Id: id,
      Price: cost,
      Time: time,
      Name: name,
    });
    setBuyView(true);
  };

  const closePayView = () => {
    setBuyView(false);
    setDataForOrder({
      Id: '',
      Price: '',
      Time: '',
      Name: '',
    });
  };

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }
  if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
    return <Navigate to="/producer" />;
  }

  const getSellerPlans = async () => {
    try {
      const response = await api.get('Seller/GetPlans', {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      if (response.data.Response) {
        setSellerPlans(
          [...response.data.Response].sort((a, b) => a.Cost - b.Cost),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSeller = async () => {
    try {
      const response = await api.get('Seller', {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      if (response.data.Response.CurrentPlan.Plan === null) {
        return setCurrentPlan({
          AvaliableBlocks: 0,
          CanHighlightCard: false,
          Cost: 0,
          Description: '',
          Id: '',
          MaxActiveOffers: 0,
          MaxOffersHighlights: 0,
          Name: '',
          ValidDays: 0,
          MaxServiceOffers: 0,
          MaxDigitalOffers: 0,
          FreeWithdrawalsThreshold: 0,
          MUGOStudioAILimit: 0,
          IsPromotionActive: false,
          Promotion: {
            EndDate: '',
            PromoCost: 0,
            StartDate: '',
          },
        });
      }
      setCurrentPlan(response.data.Response.CurrentPlan.Plan);
      setActiveTo(new Date(response.data.Response.CurrentPlan.ActiveTo));
      setActiveToViewPlan(response.data.Response.CurrentPlan.ActiveTo);
    } catch (error) {
      console.error(error);
    }
  };

  const getMangopayAccount = async () => {
    try {
      const response = await api.get(`mangopayaccount`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      if (response.data.Response.Type === 0) {
        setMangoAccount({
          KYCLevel: response.data.Response.NaturalUser.KYCLevel,
          Type: response.data.Response.NaturalUser.Type,
        });
      }
      if (response.data.Response.Type === 1) {
        setMangoAccount({
          KYCLevel: response.data.Response.LegalUser.KYCLevel,
          Type: response.data.Response.LegalUser.Type,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isNear = isEndDateNear(activeTo);

  useEffect(() => {
    getSeller();
    getSellerPlans();
    getMangopayAccount();
  }, []);

  if (windowWidth <= 1024)
    return (
      <MobileWrapper isBuy={buyView}>
        {buyView && (
          <ProMessage
            OrderData={dataForOrder}
            close={closePayView}
            KYCLevel={mangoAccount.KYCLevel}
          />
        )}
        <MobileHeader isBuy={buyView}>
          <h4>Plany działania</h4>
          <MobileHeaderPlanName
            style={
              currentPlan.Id === planIds.start ? {color: ' #0091ff'} : null
            }
          >
            Start
          </MobileHeaderPlanName>
          <MobileHeaderPlanName
            style={
              currentPlan.Id === planIds.optymalny ? {color: ' #0091ff'} : null
            }
            isActive={true}
          >
            Optymalny
          </MobileHeaderPlanName>
          <MobileHeaderPlanName
            style={currentPlan.Id === planIds.pro ? {color: ' #0091ff'} : null}
          >
            PRO
          </MobileHeaderPlanName>
        </MobileHeader>
        <MobileContentWrapper isBuy={buyView}>
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Aktywne oferty*</h4>
            </MobileListItemBig>
            {/* <MobileListItemSmall>
              <p>2</p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <p>10</p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <p>Bez limitu</p>
            </MobileListItemSmall> */}
          </MobileListItemWrapper>
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4>Usługi</h4>
            </MobileListItemBig>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[0].MaxServiceOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[1].MaxServiceOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[2].MaxServiceOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
          </MobileListItemWrapper>
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4>Tracki/produkty cyfrowe</h4>
            </MobileListItemBig>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[0].MaxDigitalOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[1].MaxDigitalOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[2].MaxDigitalOffers
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
          </MobileListItemWrapper>
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4> W tym liczba ofert promo.</h4>
            </MobileListItemBig>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[0].MaxOffersHighlights
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[1].MaxOffersHighlights
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[2].MaxOffersHighlights
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>BEZPIECZNE ZAKUPY</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig isBold={true}>
              <h4>WIN-WIN</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig isBold={true}>
              <h4>MANGOPAY</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Aktywacja portfela</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <p>0 zł</p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <p>0 zł</p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <p>0 zł</p>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Bezpłatne wypłaty **</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <p>
                Od{' '}
                {sellerPlans.length > 0
                  ? sellerPlans[0].FreeWithdrawalsThreshold / 100
                  : 0}{' '}
                zł*
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <p>
                Od{' '}
                {sellerPlans.length > 0
                  ? sellerPlans[1].FreeWithdrawalsThreshold / 100
                  : 0}{' '}
                zł*
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Historia transakcji</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Natychmiastowa płatność</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Własna strona Twórcy</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.728"
                height="12.728"
                viewBox="0 0 12.728 12.728"
              >
                <path
                  id="Icon_awesome-plus-circle"
                  data-name="Icon awesome-plus-circle"
                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                  transform="translate(6.364 -0.796) rotate(45)"
                  fill="red"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4> W tym blok komentarzy</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.728"
                height="12.728"
                viewBox="0 0 12.728 12.728"
              >
                <path
                  id="Icon_awesome-plus-circle"
                  data-name="Icon awesome-plus-circle"
                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                  transform="translate(6.364 -0.796) rotate(45)"
                  fill="red"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4> Możliwość edycji strony</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.728"
                height="12.728"
                viewBox="0 0 12.728 12.728"
              >
                <path
                  id="Icon_awesome-plus-circle"
                  data-name="Icon awesome-plus-circle"
                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                  transform="translate(6.364 -0.796) rotate(45)"
                  fill="red"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.728"
                height="12.728"
                viewBox="0 0 12.728 12.728"
              >
                <path
                  id="Icon_awesome-plus-circle"
                  data-name="Icon awesome-plus-circle"
                  d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                  transform="translate(6.364 -0.796) rotate(45)"
                  fill="red"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig>
              <h4>Support</h4>
            </MobileListItemBig>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
            <MobileListItemSmall>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <path
                  id="Icon_awesome-check-circle"
                  data-name="Icon awesome-check-circle"
                  d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  transform="translate(-0.563 -0.563)"
                  fill="#00ff46"
                />
              </svg>
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper>
            <MobileListItemBig isGrey={true}>
              <h4>Generowanie AI</h4>
            </MobileListItemBig>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[0].MUGOStudioAILimit
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[1].MUGOStudioAILimit
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>
                {' '}
                {sellerPlans.length > 0
                  ? sellerPlans[2].MUGOStudioAILimit
                  : 0}{' '}
              </p>
            </MobileListItemSmall>
          </MobileListItemWrapper>
          <MobileListItemWrapper style={{borderColor: 'rgba(0,145,255,0.3)'}}>
            <MobileListItemBig>
              <h4 style={{color: '#0091ff'}}>Promocja***</h4>
            </MobileListItemBig>
            <MobileListItemSmall isGrey={true}>
              {sellerPlans.length > 0 ? (
                sellerPlans[0].IsPromotionActive ? (
                  <p>{sellerPlans[0].Promotion.PromoCost / 100} zł</p>
                ) : (
                  <p></p>
                )
              ) : null}
            </MobileListItemSmall>
            <MobileListItemSmall>
              {sellerPlans.length > 0 ? (
                sellerPlans[1].IsPromotionActive ? (
                  <p style={{color: '#0091ff'}}>
                    {sellerPlans[1].Promotion.PromoCost / 100} zł
                  </p>
                ) : (
                  <p></p>
                )
              ) : null}
            </MobileListItemSmall>
            <MobileListItemSmall>
              {sellerPlans.length > 0 ? (
                sellerPlans[2].IsPromotionActive ? (
                  <p style={{color: '#0091ff'}}>
                    {sellerPlans[2].Promotion.PromoCost / 100} zł
                  </p>
                ) : (
                  <p></p>
                )
              ) : null}
            </MobileListItemSmall>
          </MobileListItemWrapper>{' '}
          <MobileListItemWrapper style={{border: 'none'}}>
            <MobileListItemBig>
              <h4>Ceny</h4>
            </MobileListItemBig>
            <MobileListItemSmall isBold={true}>
              <p>{sellerPlans.length > 0 ? sellerPlans[0].Cost / 100 : 0} zł</p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>{sellerPlans.length > 0 ? sellerPlans[1].Cost / 100 : 0} zł</p>
            </MobileListItemSmall>
            <MobileListItemSmall isBold={true}>
              <p>{sellerPlans.length > 0 ? sellerPlans[2].Cost / 100 : 0} zł</p>
            </MobileListItemSmall>
          </MobileListItemWrapper>
          <MobileListItemWrapper style={{border: 'none'}}>
            <MobileListItemBig isGrey={true} textSpacing={true}>
              <h4>Podano wartość miesięczną</h4>
            </MobileListItemBig>
          </MobileListItemWrapper>
          <MobileBgStart
            style={
              currentPlan.Id === planIds.start
                ? {border: '1px solid #0091ff'}
                : null
            }
          ></MobileBgStart>
          <MobileBgOptymalny
            style={
              currentPlan.Id === planIds.optymalny
                ? {border: '1px solid #0091ff'}
                : null
            }
            isActive={true}
          ></MobileBgOptymalny>
          <MobileBgPro
            style={
              currentPlan.Id === planIds.pro
                ? {border: '1px solid #0091ff'}
                : null
            }
          ></MobileBgPro>
        </MobileContentWrapper>
        <MobileBtnWrapper>
          {currentPlan.Id === planIds.pro ? null : (
            <MobileBlueBtn
              onClick={() =>
                goToPayView(
                  sellerPlans[2].Id,
                  sellerPlans[2].Name,
                  sellerPlans[2].Cost,
                  sellerPlans[2].ValidDays,
                )
              }
            >
              Przejdź do zakupu PRO
            </MobileBlueBtn>
          )}
          {/* <MobileBlueBtn>
            {currentPlan.Id === planIds.pro
              ? 'Dokup wyrażenie oferty'
              : 'Przejdź do zakupu PRO'}
          </MobileBlueBtn> */}
          {currentPlan.Id === planIds.start ? (
            <MobileBlueGreen
              onClick={() =>
                goToPayView(
                  sellerPlans[1].Id,
                  sellerPlans[1].Name,
                  sellerPlans[1].Cost,
                  sellerPlans[1].ValidDays,
                )
              }
            >
              Przejdź do zakupu Optymalnego
            </MobileBlueGreen>
          ) : currentPlan.Id === planIds.optymalny ? (
            <MobileBlueGreen
              onClick={() =>
                goToPayView(
                  sellerPlans[1].Id,
                  sellerPlans[1].Name,
                  sellerPlans[1].Cost,
                  sellerPlans[1].ValidDays,
                )
              }
            >
              Przedłuż Optymalny
            </MobileBlueGreen>
          ) : (
            <MobileBlueGreen
              onClick={() =>
                goToPayView(
                  sellerPlans[2].Id,
                  sellerPlans[2].Name,
                  sellerPlans[2].Cost,
                  sellerPlans[2].ValidDays,
                )
              }
            >
              Przedłuż PRO
            </MobileBlueGreen>
          )}
          {/* <MobileBlueGreen>
            {currentPlan.Id === planIds.start
              ? 'Przejdź do zakupu Optymalnego'
              : currentPlan.Id === planIds.optymalny
              ? 'Przedłuż Optymalny'
              : 'Przedłuż PRO'}
          </MobileBlueGreen> */}
          {/* <p>Twój plan wygasa: 10-10-2024</p> */}
          <p>
            {' '}
            Twój plan wygasa:{' '}
            {new Intl.DateTimeFormat('default', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
              .format(new Date(activeToViewPlan))
              .replace(',', '')}
          </p>
        </MobileBtnWrapper>
        <MobileLegendWrapper>
          <p>* ilość jednocześnie aktywnych ofert w systemie</p>
          <p>** od wypłaty poniżej kwoty pobrana opłata w wysokości 2%</p>
          <p>
            *** Twórca musi posiadać aktywne konto wydawcy w MUGO, oraz minimum
            jedno aktywne wydanie
          </p>
        </MobileLegendWrapper>
      </MobileWrapper>
    );

  return (
    <MainWrapper>
      <Main>
        {isLoading && <LoadingCircle />}
        {buyView && (
          <ProMessage
            OrderData={dataForOrder}
            close={closePayView}
            KYCLevel={mangoAccount.KYCLevel}
          />
        )}

        <Header>
          <header>
            {' '}
            <p>Plany działania</p>
          </header>
          <HeaderIconsWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="59.29"
              height="16"
              viewBox="0 0 59.29 16"
            >
              <g
                id="Group_6975"
                data-name="Group 6975"
                transform="translate(-1219.498 -107)"
              >
                <path
                  id="Path_3322"
                  data-name="Path 3322"
                  d="M-33.034-19.024a.638.638,0,0,0-.542.313l-3.2,5.083L-39.972-18.7a.638.638,0,0,0-.578-.325.544.544,0,0,0-.554.6v7.142a.416.416,0,0,0,.434.47.422.422,0,0,0,.434-.47v-6.155h.024l2.975,4.721a.5.5,0,0,0,.458.265.5.5,0,0,0,.446-.277l2.951-4.673h.024v6.107a.428.428,0,0,0,.446.482c.277,0,.434-.181.434-.482v-7.142A.535.535,0,0,0-33.034-19.024Zm8.552,0a.422.422,0,0,0-.434.47v4.2c0,1.855-.9,2.806-2.566,2.806-1.686,0-2.59-.952-2.59-2.806v-4.192a.428.428,0,0,0-.446-.482c-.277,0-.434.181-.434.482v4.192c0,2.373,1.217,3.613,3.469,3.613,2.228,0,3.433-1.241,3.433-3.613v-4.2A.422.422,0,0,0-24.482-19.024Zm9.78,4.553a.447.447,0,0,0-.482-.494h-2.493a.391.391,0,0,0-.446.41c0,.253.169.4.446.4h2.12a2.884,2.884,0,0,1-3.12,2.614,3.159,3.159,0,0,1-3.264-3.373,3.159,3.159,0,0,1,3.264-3.373,3.141,3.141,0,0,1,2.59,1.192.428.428,0,0,0,.349.169.4.4,0,0,0,.422-.41.541.541,0,0,0-.12-.325,3.989,3.989,0,0,0-3.24-1.433,3.964,3.964,0,0,0-4.155,4.179,3.964,3.964,0,0,0,4.155,4.179A3.653,3.653,0,0,0-14.7-14.411Zm.952-.446A3.964,3.964,0,0,0-9.6-10.737a3.967,3.967,0,0,0,4.167-4.179A3.967,3.967,0,0,0-9.6-19.1,3.964,3.964,0,0,0-13.751-14.917Zm.891,0A3.159,3.159,0,0,1-9.6-18.289a3.169,3.169,0,0,1,3.276,3.373A3.169,3.169,0,0,1-9.6-11.544,3.159,3.159,0,0,1-12.859-14.917Z"
                  transform="translate(1284.216 129.558)"
                  fill="#565564"
                />
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(1219.498 107)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M2452.714-463.741a.04.04,0,0,0,0-.02.549.549,0,0,0-.016-.066l-.011-.029c-.006-.017-.016-.033-.023-.049a.4.4,0,0,0-.022-.043s0-.01-.006-.013l-.019-.02c-.011-.015-.023-.029-.036-.043a.227.227,0,0,0-.03-.026c-.01-.007-.017-.016-.029-.026a7.822,7.822,0,0,0-8.949-.147,7.18,7.18,0,0,1-1.136-10.091,7.177,7.177,0,0,1,3.445-2.368.5.5,0,0,0,.328-.614.483.483,0,0,0-.595-.338l-.009,0a8.18,8.18,0,0,0-5.328,10.268,8.185,8.185,0,0,0,2.958,4.124.482.482,0,0,0,.079.059,7.818,7.818,0,0,0,9.207-.089.266.266,0,0,0,.029-.027l0,0a.636.636,0,0,0,.078-.086h0a.426.426,0,0,0,.048-.086,0,0,0,0,1,0,0,.63.63,0,0,0,.029-.086s0-.007,0-.01a.361.361,0,0,0,.01-.083v-.013C2452.718-463.692,2452.718-463.715,2452.714-463.741Zm-8.26.122a6.857,6.857,0,0,1,6.881-.047A6.856,6.856,0,0,1,2444.454-463.62Z"
                    transform="translate(-2439.89 477.726)"
                    fill="#565564"
                  />
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M2510.5-478.47a.471.471,0,0,0-.285-.093.5.5,0,0,0-.328.134.494.494,0,0,0-.152.545l.035,5.189a2.433,2.433,0,0,0-1.521-.52,2.538,2.538,0,0,0-2.573,2.5,2.538,2.538,0,0,0,2.5,2.572,2.537,2.537,0,0,0,2.572-2.5c0-.017,0-.035,0-.052s0-.039,0-.059,0-.02,0-.029l-.044-6.573a7.143,7.143,0,0,1,4.547,6.629.483.483,0,0,0,.487.481.485.485,0,0,0,.482-.488A8.113,8.113,0,0,0,2510.5-478.47Zm-2.226,9.337a1.549,1.549,0,0,1-1.59-1.507,1.549,1.549,0,0,1,1.507-1.59,1.549,1.549,0,0,1,1.59,1.507c0,.01,0,.021,0,.031a1.536,1.536,0,0,1-1.506,1.559Z"
                    transform="translate(-2500.311 478.563)"
                    fill="#565564"
                  />
                </g>
              </g>
            </svg>
            <svg
              id="Group_6973"
              data-name="Group 6973"
              xmlns="http://www.w3.org/2000/svg"
              width="65.446"
              height="16.5"
              viewBox="0 0 65.446 16.5"
            >
              <defs className="BUG">
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_3780"
                    data-name="Rectangle 3780"
                    width="65.446"
                    height="16.5"
                    fill="#565564"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_6972"
                data-name="Group 6972"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Path_4111"
                  data-name="Path 4111"
                  d="M211.717,39.406l-1.051,2.283c-.263.571-.535,1.178-.788,1.758h-.018c-.045-.562-.1-1.16-.163-1.73l-.254-2.31h-1.368l-1.087,2.319c-.263.562-.544,1.178-.779,1.721h-.018c-.036-.544-.091-1.151-.145-1.712l-.227-2.328h-1.948l.824,6.559h1.93l.725-1.549c.263-.562.534-1.16.788-1.74h.018c.045.571.1,1.132.163,1.712l.172,1.576h1.966l3.153-6.559Z"
                  transform="translate(-178.156 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4112"
                  data-name="Path 4112"
                  d="M274.476,39.406l-1.16,6.559h1.866l1.16-6.559Z"
                  transform="translate(-238.841 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4113"
                  data-name="Path 4113"
                  d="M298.3,39.406l-.326,1.866c-.118.67-.235,1.386-.326,1.975l-.018.009c-.317-.607-.652-1.223-.988-1.83l-1.114-2.02h-1.739l-1.16,6.559h1.794l.326-1.866c.118-.67.236-1.386.326-1.975l.018-.009c.317.607.652,1.223.987,1.83l1.114,2.02h1.739l1.16-6.559Z"
                  transform="translate(-255.722 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4114"
                  data-name="Path 4114"
                  d="M349.151,61.4h2.274l.245-1.377H349.4Z"
                  transform="translate(-305.111 -52.449)"
                  fill="#565564"
                />
                <path
                  id="Path_4115"
                  data-name="Path 4115"
                  d="M378.757,39.406l-1.051,2.283c-.263.571-.535,1.178-.788,1.758H376.9c-.045-.562-.1-1.16-.163-1.73l-.254-2.31h-1.368l-1.087,2.319c-.263.562-.544,1.178-.779,1.721h-.018c-.036-.544-.091-1.151-.145-1.712l-.227-2.328h-1.948l.824,6.559h1.93l.725-1.549c.263-.562.534-1.16.788-1.74h.018c.045.571.1,1.132.163,1.712l.172,1.576H377.5l3.153-6.559Z"
                  transform="translate(-324.127 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4116"
                  data-name="Path 4116"
                  d="M441.516,39.406l-1.16,6.559h1.866l1.16-6.559Z"
                  transform="translate(-384.812 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4117"
                  data-name="Path 4117"
                  d="M465.344,39.406l-.326,1.866c-.118.67-.236,1.386-.326,1.975l-.018.009c-.317-.607-.652-1.223-.988-1.83l-1.114-2.02h-1.739l-1.16,6.559h1.794l.326-1.866c.118-.67.236-1.386.326-1.975l.018-.009c.317.607.652,1.223.987,1.83l1.114,2.02h1.739l1.16-6.559Z"
                  transform="translate(-401.692 -34.436)"
                  fill="#565564"
                />
                <path
                  id="Path_4118"
                  data-name="Path 4118"
                  d="M19.507,2.57H18.229L17.26,1.6H14.05l3.284,3.284.9-.045h1.275a.884.884,0,0,1,.883.883v5.045a.884.884,0,0,1-.882.883l-3.455,0-2.325,2.325a.884.884,0,0,1-1.249,0L8.912,10.406a.885.885,0,0,1-.2-.935l6.327-.2.318-.317a3.153,3.153,0,0,0,0-4.46L11.786.925a3.153,3.153,0,0,0-4.46,0L5.668,2.584l-2.514,0A3.157,3.157,0,0,0,0,5.735v5.045a3.157,3.157,0,0,0,3.153,3.153H4.236l1.113,1.113H8.56L5.13,11.616l-.953.047H3.153a.884.884,0,0,1-.883-.883V5.735a.884.884,0,0,1,.882-.883l3.455,0L8.932,2.53a.883.883,0,0,1,1.249,0L13.748,6.1a.885.885,0,0,1,.2.935l-6.327.2-.318.318a3.154,3.154,0,0,0,0,4.46l3.568,3.568a3.157,3.157,0,0,0,4.46,0l1.659-1.659,2.514,0a3.157,3.157,0,0,0,3.153-3.153V5.723A3.157,3.157,0,0,0,19.507,2.57"
                  transform="translate(0 -0.001)"
                  fill="#565564"
                />
              </g>
            </svg>
            <svg
              id="logo"
              xmlns="http://www.w3.org/2000/svg"
              width="61.245"
              height="9"
              viewBox="0 0 61.245 9"
            >
              <path
                id="Path_3733"
                data-name="Path 3733"
                d="M58.492.3A2.464,2.464,0,0,0,57.3,0a2.026,2.026,0,0,0-1.083.261,2.345,2.345,0,0,0-.784.71h-.037V.149H53.6V6.461h1.793V2.838a1.279,1.279,0,0,1,.149-.672,1.187,1.187,0,0,1,.411-.448,1.29,1.29,0,0,1,.672-.187,1.1,1.1,0,0,1,.822.336,1.191,1.191,0,0,1,.3.9v3.66H59.5V2.539a3.149,3.149,0,0,0-.261-1.382A2.4,2.4,0,0,0,58.492.3Z"
                transform="translate(-33.583 0)"
                fill="#565564"
              />
              <path
                id="Path_3734"
                data-name="Path 3734"
                d="M9.971,0h.075a2.207,2.207,0,0,1,2.2,2.2v4.22H10.494V2.091a.574.574,0,0,0-.56-.56h0a.527.527,0,0,0-.523.411L8.589,5.266a1.688,1.688,0,0,1-3.324-.411V2.091a.574.574,0,0,0-.56-.56.527.527,0,0,0-.523.411L3.4,5.228a1.717,1.717,0,0,1-1.68,1.307A1.682,1.682,0,0,1,0,4.855V.149H1.755v5h0l.859-3.4A2.208,2.208,0,0,1,6.983,2.2V5.116h0l.822-3.4A2.2,2.2,0,0,1,9.971,0Z"
                transform="translate(0 0)"
                fill="#565564"
              />
              <path
                id="Path_3735"
                data-name="Path 3735"
                d="M38.792.934h0l.037-.784h1.793V6.461H38.867V5.676h-.075a1.758,1.758,0,0,1-.56.485,2.386,2.386,0,0,1-.71.3,2.792,2.792,0,0,1-2.241-.3A2.81,2.81,0,0,1,34.273,5,3.74,3.74,0,0,1,33.9,3.286a4.273,4.273,0,0,1,.336-1.718A2.544,2.544,0,0,1,35.244.411,2.794,2.794,0,0,1,36.738,0a2.912,2.912,0,0,1,.822.112,2.8,2.8,0,0,1,.672.3A1.529,1.529,0,0,1,38.792.934ZM38.12,4.855a1.729,1.729,0,0,0,.56-.6h0a2.306,2.306,0,0,0,.187-.934,2.446,2.446,0,0,0-.187-.934,1.318,1.318,0,0,0-.56-.635,1.506,1.506,0,0,0-.822-.224,1.6,1.6,0,0,0-.822.224,1.729,1.729,0,0,0-.56.6,2.306,2.306,0,0,0-.187.934,2.446,2.446,0,0,0,.187.934,1.453,1.453,0,0,0,.56.6,1.381,1.381,0,0,0,.784.224A2.075,2.075,0,0,0,38.12,4.855Z"
                transform="translate(-21.24 0)"
                fill="#565564"
                fillRule="evenodd"
              />
              <path
                id="Path_3736"
                data-name="Path 3736"
                d="M75.492.971h.037V.149h1.793V6.237a2.891,2.891,0,0,1-.411,1.494,2.239,2.239,0,0,1-1.158.934A4.147,4.147,0,0,1,74.036,9a4.965,4.965,0,0,1-1.568-.224,2.881,2.881,0,0,1-1.12-.71,2.073,2.073,0,0,1-.523-1.158h1.755a.924.924,0,0,0,.523.6,2.5,2.5,0,0,0,.971.187,1.732,1.732,0,0,0,.784-.149,1.022,1.022,0,0,0,.485-.485,1.736,1.736,0,0,0,.187-.784V5.415h-.037a2.159,2.159,0,0,1-.859.672,2.452,2.452,0,0,1-1.2.261,2.824,2.824,0,0,1-1.456-.373,3.129,3.129,0,0,1-1.008-1.083A3.933,3.933,0,0,1,70.6,3.249a3.335,3.335,0,0,1,.373-1.643A2.676,2.676,0,0,1,71.982.485,2.05,2.05,0,0,1,73.4,0a2.551,2.551,0,0,1,1.2.261A2.694,2.694,0,0,1,75.492.971Zm-.71,3.585a2,2,0,0,0,.56-.56h0a1.947,1.947,0,0,0,.187-.859,2.075,2.075,0,0,0-.187-.859,1.35,1.35,0,0,0-.56-.56,1.487,1.487,0,0,0-.822-.187,1.9,1.9,0,0,0-.822.187,2,2,0,0,0-.56.56,2.069,2.069,0,0,0,0,1.718,1.03,1.03,0,0,0,.56.56,1.487,1.487,0,0,0,.822.187A1.279,1.279,0,0,0,74.783,4.556Z"
                transform="translate(-44.235 0)"
                fill="#565564"
                fillRule="evenodd"
              />
              <path
                id="Path_3737"
                data-name="Path 3737"
                d="M94.979.411A3.343,3.343,0,0,0,93.261,0a3.648,3.648,0,0,0-1.718.411,2.837,2.837,0,0,0-1.2,1.2A3.787,3.787,0,0,0,89.9,3.324a3.369,3.369,0,0,0,.448,1.718A3.068,3.068,0,0,0,91.58,6.2a3.8,3.8,0,0,0,3.436,0,2.755,2.755,0,0,0,1.2-1.158,3.274,3.274,0,0,0,.448-1.718,3.369,3.369,0,0,0-.448-1.718A3.757,3.757,0,0,0,94.979.411Zm-.373,3.846a1.859,1.859,0,0,1-.56.635,1.484,1.484,0,0,1-1.568,0,1.859,1.859,0,0,1-.56-.635,1.819,1.819,0,0,1-.224-.934,1.819,1.819,0,0,1,.224-.934,1.859,1.859,0,0,1,.56-.635,1.291,1.291,0,0,1,.747-.261,1.6,1.6,0,0,1,.822.224,1.859,1.859,0,0,1,.56.635,1.918,1.918,0,0,1,.224.934,2.648,2.648,0,0,1-.224.971Z"
                transform="translate(-56.327 0)"
                fill="#565564"
                fillRule="evenodd"
              />
              <path
                id="Path_3738"
                data-name="Path 3738"
                d="M112.884,0a2.891,2.891,0,0,1,1.494.411,3.069,3.069,0,0,1,1.008,1.158,4.028,4.028,0,0,1,.336,1.718A3.537,3.537,0,0,1,115.349,5a2.749,2.749,0,0,1-1.008,1.158,2.664,2.664,0,0,1-1.456.411,2.912,2.912,0,0,1-.822-.112,2.477,2.477,0,0,1-.71-.336,2.386,2.386,0,0,1-.56-.485h0V8.813H109V.149h1.793V.934h0a3.46,3.46,0,0,1,.523-.523,2.477,2.477,0,0,1,.71-.336A7.291,7.291,0,0,1,112.884,0Zm.3,4.855a1.729,1.729,0,0,0,.56-.6h0a1.818,1.818,0,0,0,.187-.934,2.306,2.306,0,0,0-.187-.934,1.249,1.249,0,0,0-.56-.6,1.914,1.914,0,0,0-.822-.224,1.42,1.42,0,0,0-.822.224,1.568,1.568,0,0,0-.56.635,1.9,1.9,0,0,0-.187.934,2.447,2.447,0,0,0,.187.934,1.239,1.239,0,0,0,.56.6,1.506,1.506,0,0,0,.822.224A2.12,2.12,0,0,0,113.183,4.855Z"
                transform="translate(-68.295 0)"
                fill="#565564"
                fillRule="evenodd"
              />
              <path
                id="Path_3739"
                data-name="Path 3739"
                d="M132.892,1h0a2.277,2.277,0,0,0-.56-.485,2.177,2.177,0,0,0-.672-.3,2.97,2.97,0,0,0-2.315.3,3.069,3.069,0,0,0-1.008,1.158A4.148,4.148,0,0,0,128,3.386a3.636,3.636,0,0,0,.373,1.718,2.749,2.749,0,0,0,1.008,1.158,2.664,2.664,0,0,0,1.456.411,2.483,2.483,0,0,0,.784-.112,4.047,4.047,0,0,0,.71-.3,3.484,3.484,0,0,0,.56-.485h.037v.784h1.755V.212h-1.793V1Zm-.187,3.286a1.239,1.239,0,0,1-.56.6,1.506,1.506,0,0,1-.822.224,1.381,1.381,0,0,1-.784-.224,1.729,1.729,0,0,1-.56-.6,1.819,1.819,0,0,1-.187-.934,2.447,2.447,0,0,1,.187-.934,1.1,1.1,0,0,1,.56-.6,1.618,1.618,0,0,1,1.643,0,1.567,1.567,0,0,1,.56.635,2.306,2.306,0,0,1,.187.934,1.68,1.68,0,0,1-.224.9Z"
                transform="translate(-80.199 -0.063)"
                fill="#565564"
                fillRule="evenodd"
              />
              <path
                id="Path_3740"
                data-name="Path 3740"
                d="M150,3.649,151.08.4h1.83l-2.763,7.17a2.18,2.18,0,0,1-.9,1.158,2.977,2.977,0,0,1-1.643.373h-.672V7.682h.672a1.488,1.488,0,0,0,.635-.112,1.01,1.01,0,0,0,.373-.411l.149-.411L146.3.437h1.83l1.12,3.249c.112.336.224.71.373,1.158h.037C149.773,4.359,149.885,3.985,150,3.649Z"
                transform="translate(-91.665 -0.251)"
                fill="#565564"
              />
            </svg>
          </HeaderIconsWrapper>
        </Header>
        <Grid>
          <LeftSectionDescriptions style={isLoading ? {display: 'none'} : null}>
            <RightSectionHeader isLeft={true}></RightSectionHeader>
            <OneTextWrapper bgColorType="grey100">
              <p>Aktywne oferty*</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Usługi</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p>Tracki/produkty cyfrowe</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Liczba ofert promowanych</p>
            </OneTextWrapper>
            <OneTextWrapper></OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Bezpieczne zakupy</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p>
                Oferta <span>WIN-WIN***</span>
              </p>
            </OneTextWrapper>
            <OneTextWrapper></OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p> Portfel Mango Pay</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p> Aktywacja portfela Mango Pay</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Darmowe wypłaty**</p>
            </OneTextWrapper>
            {/* <OneTextWrapper
              style={{height: '54px', padding: '10px'}}
            ></OneTextWrapper> */}
            <OneTextWrapper></OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p> Historia transakcji</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p>Szybkie płatności</p>
            </OneTextWrapper>
            <OneTextWrapper></OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p> Własna strona Twórcy</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Komentarz na stronie twórcy</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p> Zaawansowana edycja strony Twórcy</p>
            </OneTextWrapper>
            <OneTextWrapper></OneTextWrapper>
            <OneTextWrapper bgColorType="grey100">
              <p> Wspracie techniczne</p>
            </OneTextWrapper>
            <OneTextWrapper bgColorType="grey50">
              <p>Generowanie okładek ofert MUGO Studio AI</p>
            </OneTextWrapper>
            <PlanPriceWrapper isStartPlan={false}></PlanPriceWrapper>
            <DescriptionWrapper>
              <div>
                <span>*</span>
                <p>ilość jednocześnie aktywnych ofert w systemie</p>
              </div>
              <div>
                <span>**</span>
                <p>każda wypłata poniżej tej kwoty to koszt 10 zł</p>
              </div>
              <div>
                <span>***</span>{' '}
                <p>Twórca musi posiadać aktywne konto wydawcy w MUGO</p>
              </div>
            </DescriptionWrapper>
          </LeftSectionDescriptions>
          {/*<LeftSection>*/}
          {/*  <TitlePro isActive={true}>*/}
          {/*    <h4>IDEA</h4> <p>Aktywny</p>*/}
          {/*  </TitlePro>*/}
          {/*  <ListPro isActive={true}>*/}
          {/*    <li>podstawowa wizytówka twórcy</li>*/}
          {/*    <li>*/}
          {/*      5 darmowych aktywnych ofert w kategorii muzyka, 1 w innych*/}
          {/*      kategoriach*/}
          {/*    </li>*/}
          {/*    <li>szablony warunków</li>*/}
          {/*    <li>ochrona sprzedającego</li>*/}
          {/*    <li>oferta WIN-WIN</li>*/}
          {/*    <li>darmowe wypłaty od 500 pln</li>*/}
          {/*  </ListPro>*/}
          {/*  <AdditionalInformation>*/}
          {/*    <p>*/}
          {/*      There are many variations of passages of Lorem Ipsum available,*/}
          {/*      but themajority have suffered alteration in some form, by*/}
          {/*      injected humour, or randomised words which*/}
          {/*    </p>*/}
          {/*  </AdditionalInformation>*/}
          {/*</LeftSection>*/}
          {/*<CenterSection>*/}
          {/*  <TitlePro isActive={false}>*/}
          {/*    <h4>KONKRET</h4> <p>Dostępny</p>*/}
          {/*  </TitlePro>*/}
          {/*  <ListPro isActive={false}>*/}
          {/*    <li>personalizacja wizytówki twórcy</li>*/}
          {/*    <li>moduł komentarzy i recenzji</li>*/}
          {/*    <li>*/}
          {/*      25 darmowych aktywnych ofert w kategorii muzyka, 5 w innych*/}
          {/*      kategoriach*/}
          {/*    </li>*/}
          {/*    <li>15% rabatu na pozostałe ogłoszenia</li>*/}
          {/*    <li>15% rabatu na wyróżnienie oferty</li>*/}
          {/*    <li>ochrona sprzedającego</li>*/}
          {/*    <li>oferta WIN-WIN</li>*/}
          {/*    <li>brak reklam</li>*/}
          {/*    <li>darmowe wypłaty od 400pln</li>*/}
          {/*  </ListPro>*/}
          {/*  <AdditionalInformation>*/}
          {/*    <InputWrapper>*/}
          {/*      <p>Kod promocyjny</p>*/}
          {/*      <input type="text" placeholder="Opcjonalne" />*/}
          {/*    </InputWrapper>*/}
          {/*    <p>*/}
          {/*      There are many variations of passages of Lorem Ipsum available,*/}
          {/*      but themajority have suffered alteration in some form, by*/}
          {/*      injected humour, or randomised words which*/}
          {/*    </p>*/}
          {/*  </AdditionalInformation>*/}
          {/*  <ButtonWrapper>*/}
          {/*    <button onClick={() => setBuyView(true)}>Zakup</button>*/}
          {/*  </ButtonWrapper>*/}
          {/*</CenterSection>*/}
          {/*<RightSection>*/}
          {/*  <TitlePro isActive={false}>*/}
          {/*    <h4>BEZ LIMITU</h4> <p>Dostępny</p>*/}
          {/*  </TitlePro>*/}
          {/*  <ListPro isActive={false}>*/}
          {/*    <li>zaawansowana personalizacja wizytówki twórcy</li>*/}
          {/*    <li>wyróżnienie wizytówki twórcy</li>*/}
          {/*    <li>moduł komentarzy i recenzji</li>*/}
          {/*    <li>nielimitowana ilość aktywnych ofert</li>*/}
          {/*    <li>ochrona sprzedającego</li>*/}
          {/*    <li>oferta WIN-WIN</li>*/}
          {/*    <li>brak reklam</li>*/}
          {/*    <li>darmowe wypłaty od 100 pln</li>*/}
          {/*  </ListPro>*/}
          {/*  <AdditionalInformation>*/}
          {/*    <InputWrapper>*/}
          {/*      <p>Kod promocyjny</p>*/}
          {/*      <input type="text" placeholder="Opcjonalne" />*/}
          {/*    </InputWrapper>*/}
          {/*    <p>*/}
          {/*      There are many variations of passages of Lorem Ipsum available,*/}
          {/*      but themajority have suffered alteration in some form, by*/}
          {/*      injected humour, or randomised words which*/}
          {/*    </p>*/}
          {/*  </AdditionalInformation>*/}
          {/*  <ButtonWrapper>*/}
          {/*    <button onClick={() => payForPlan('Bez')}>Zakup</button>*/}
          {/*  </ButtonWrapper>*/}
          {/*</RightSection>*/}
          {sellerPlans.map((plan) => (
            <RightSection key={plan.Id}>
              <RightSectionHeader
                isActive={plan.Id === currentPlan.Id}
                planId={plan.Id}
              >
                <h4>{plan.Name.toUpperCase()}</h4>
              </RightSectionHeader>
              <OneTextWrapper bgColorType="grey100">
                {/* <p>{`${plan.MaxActiveOffers} ofert`}</p> */}
                <p></p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p>{plan.MaxServiceOffers}</p>
              </OneTextWrapper>{' '}
              <OneTextWrapper bgColorType="grey100">
                <p>{plan.MaxDigitalOffers}</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p> {`${plan.MaxOffersHighlights}`}</p>
              </OneTextWrapper>
              <OneTextWrapper></OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p> Tak</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p>Tak</p>
              </OneTextWrapper>
              <OneTextWrapper></OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p> Tak</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p>Bezpłatnie</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p>
                  {plan.FreeWithdrawalsThreshold === 0
                    ? 'Bezpłatnie'
                    : `Od ${plan.FreeWithdrawalsThreshold / 100} zł`}
                </p>
              </OneTextWrapper>
              {/* <OneTextWrapper style={{height: '54px', padding: '10px'}}>
                {plan.Id === '8eb9ff9a-cd5d-11ed-9742-000d3a44fa94' ? null : (
                  <p>poniżej tej kwoty 2% prowizji od kwoty wypłaty</p>
                )}
              </OneTextWrapper> */}
              <OneTextWrapper></OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p>Tak</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p> Tak</p>
              </OneTextWrapper>
              <OneTextWrapper></OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p style={plan.Id === planIds.start ? {opacity: '0.5'} : null}>
                  {plan.Id === planIds.start ? 'Brak' : 'Tak'}
                </p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p style={plan.Id === planIds.start ? {opacity: '0.5'} : null}>
                  {plan.Id === planIds.start ? 'Brak' : 'Tak'}
                </p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p
                  style={
                    plan.Id === planIds.start || plan.Id === planIds.optymalny
                      ? {opacity: '0.5'}
                      : null
                  }
                >
                  {plan.Id === planIds.start || plan.Id === planIds.optymalny
                    ? 'Brak'
                    : 'Tak'}
                </p>
              </OneTextWrapper>
              <OneTextWrapper></OneTextWrapper>
              <OneTextWrapper bgColorType="grey100">
                <p>Tak</p>
              </OneTextWrapper>
              <OneTextWrapper bgColorType="grey50">
                <p>{plan.MUGOStudioAILimit}</p>
              </OneTextWrapper>
              <PlanPriceWrapper isStartPlan={plan.Id === planIds.start}>
                {plan.Id === planIds.start && plan.IsPromotionActive ? (
                  <RedLine></RedLine>
                ) : null}
                <p>
                  {plan.Cost / 100} zł<span>/m-c</span>
                </p>
              </PlanPriceWrapper>
              {plan.IsPromotionActive && (
                <PromotionBlueTextWrapper>
                  <p>Promocja</p>
                  {plan.Id === planIds.start ? null : (
                    <span>Dla Wydawców Mugo ***</span>
                  )}
                </PromotionBlueTextWrapper>
              )}
              {plan.IsPromotionActive && (
                <PlanPriceWithPromotion>
                  <p>
                    {plan.Promotion.PromoCost / 100} zł<span>/m-c</span>
                  </p>
                </PlanPriceWithPromotion>
              )}
              {plan.IsPromotionActive && (
                <PlanData>
                  Oferta ważna do{' '}
                  {plan.IsPromotionActive &&
                    new Date(plan.Promotion.EndDate).toLocaleDateString()}
                </PlanData>
              )}
              {/* <TitlePro isActive={plan.Id === currentPlan.Id}>
                <h4>{plan.Name}</h4>
                {currentPlan.Cost > plan.Cost ? null : (
                  <p>{plan.Id === currentPlan.Id ? 'Aktywny' : 'Dostępny'}</p>
                )}
              </TitlePro>
              <ListPro isActive={plan.Id === currentPlan.Id}>
                <li>{plan.Description}</li>
              </ListPro>
              <AdditionalInformation>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but themajority have suffered alteration in some
                  form, by injected humour, or randomised words which
                </p>
              </AdditionalInformation> */}
              {currentPlan.Cost > plan.Cost ? null : plan.Cost ===
                0 ? null : currentPlan.Cost === 0 ? (
                <ButtonWrapper isActive={false}>
                  <button
                    onClick={() =>
                      goToPayView(plan.Id, plan.Name, plan.Cost, plan.ValidDays)
                    }
                  >
                    KUP
                  </button>
                </ButtonWrapper>
              ) : currentPlan.Cost === plan.Cost && isNear ? (
                <ButtonWrapper isActive={true}>
                  <button
                    onClick={() =>
                      goToPayView(plan.Id, plan.Name, plan.Cost, plan.ValidDays)
                    }
                  >
                    Przedłuż
                  </button>
                  <p style={isNear ? {color: '#ff2320'} : null}>
                    {isNear ? `Twój plan wygaśnie - ` : 'Twój plan wygasł - '}
                    {new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })
                      .format(new Date(activeToViewPlan))
                      .replace(',', '')}
                  </p>
                </ButtonWrapper>
              ) : plan.Cost > currentPlan.Cost ? (
                <ButtonWrapper isActive={false}>
                  <button
                    onClick={() =>
                      goToPayView(plan.Id, plan.Name, plan.Cost, plan.ValidDays)
                    }
                  >
                    KUP
                  </button>
                </ButtonWrapper>
              ) : null}
            </RightSection>
          ))}
        </Grid>
      </Main>
    </MainWrapper>
  );
};

export default PROComponent;
