import React, {useState, useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {CookiesProvider} from 'react-cookie';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import useAnalytics from 'services/useAnalytics';

import Navbar from 'components/Navbar';
import MyStudioNavbar from 'components/MyStudioNavbar';
import GlobalStyles from './globalStyles';
// import theme from 'constants/theme';
import {light, dark} from 'constants/theme';
import BusinessCard from 'pages/MyStudio/BusinessCard';
import Account from 'pages/MyStudio/Account';
import Messages from 'pages/MyStudio/Messages';
import Conditions from 'pages/MyStudio/Conditions';
import MyProducts from 'pages/MyStudio/MyProducts';
import Tracks from 'pages/Tracks';
import Products from 'pages/Products';
import {useMenuContext} from 'contexts/MenuContext';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import PROComponent from 'pages/MyStudio/PRO';
import ResolutionProvider from 'contexts/ResolutionContext';
import ProducerDataProvider from 'contexts/ProducerDataContext';
import UserDataProvider from 'contexts/UserDataContext';
import VisitedProducerProvider from './contexts/VisitedProducerContext';
import MyAccountNavbar from 'components/MyAccountNavbar';
import Regulations from 'pages/MyAccount/Regulations';
import Settings from 'pages/MyAccount/Settings';
import LoginComponent from 'pages/Identity/Login';
import RecoverComponent from 'pages/Identity/Recover';
import RegisterComponent from 'pages/Identity/Register';
import Home from 'pages/Home';
import HomeNavbar from 'components/HomeNavbar';
import Producers from 'pages/Producers';
import LanguageProvider from 'contexts/LanguageContext';
import StatisticsProvider from 'contexts/StatisticsContext';
import UserThemeProvider from 'contexts/UserThemeContext';
import ProducerCard from 'pages/Producers/ProducerCard';
import ProductDetail from 'pages/Products/ProductDetail';
import TrackDetail from 'pages/Tracks/TrackDetail';
import TransactionsComponent from 'pages/MyStudio/Transactions';
import Footer from 'components/Footer';
import EditProduct from 'pages/MyStudio/MyProducts/EditProduct';
import MyProductDetail from 'pages/MyStudio/MyProducts/ProductDetail';
import EditCondition from 'pages/MyStudio/Conditions/EditCondition';
import ConfirmationModalContextProvider from './hooks/modalConfirmationContext';
import {AlertProvider} from './contexts/AlertContext';
import {AlertPopup} from './components/PopUp';
import {ConfirmSettings} from './pages/MyAccount/Settings/Confirm';
import {ConfirmRegister} from './pages/Identity/Register/Confirm';
import {ConfrimRecoverPassword} from './pages/Identity/Recover/Confirm';
import {ResendEmail} from './pages/Identity/ResendEmail';
import {ExternalLogin} from './pages/Identity/Login/ExternalLogin';
import BottomMobileNavbar from 'components/BottomMobileNavbar';
import {FilterMenuProvider} from 'contexts/FilterMenuContext';
import {IsOrderPageOpenProvider} from 'contexts/IsOrderPageOpenContext';
import {SearchBarProvider} from 'contexts/SearchBarContext';
import AccountProducerProvider from 'contexts/AccountProducerContext';
import {TracksAudioProvider} from 'contexts/TracksAudioContext';
import EditHoverProvider from 'contexts/EditHoverContext';
import BusinessCardColorsProvider from 'contexts/BusinessCardColorsContext';
import {HelmetProvider} from 'react-helmet-async';
import TransactionDetail from 'pages/MyStudio/Transactions/TransactionDetail';
import {divide} from 'lodash';
import FaqComponent from 'pages/MyAccount/Faq';
import Articles from 'pages/Articles';
import ArticleDetail from 'pages/Articles/ArticleDetail';
import {SupportMessage} from 'pages/MyStudio/Messages/supportMessage';
import EditCard from 'pages/MyStudio/AdminCard/EditCard';
import ProducerList from 'pages/MyStudio/AdminCard/ProducerList';
import {ImageProvider} from 'contexts/ImageContext';
import EnlargedImage from 'components/EnlargedImage';
import ProtectedRoute from 'pages/ProtectedRoute';
import Protectedlogin from 'pages/Protectedlogin';
import {AcceptCookiesComponent} from 'components/AcceptCookies';

const App: React.FC = () => {
  useAnalytics();
  const {isMenuOpen} = useMenuContext();
  const {isBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem('current-theme')) || light,
  );
  const [accessGranted, setAccessGranted] = useState(
    localStorage.getItem('access-devgranted') === 'true',
  );

  const handleAccessGranted = (value: boolean) => {
    setAccessGranted(value);
    localStorage.setItem('access-devgranted', value.toString());
  };

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
    localStorage.setItem('current-theme', JSON.stringify(theme));
  };

  useEffect(() => {
    if (localStorage.getItem('current-theme') === null) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) =>
          handleThemeChange(e.matches ? dark : light),
        );
      handleThemeChange(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? dark
          : light,
      );
    }

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', () => {
          console.log('delete color');
        });
    };
  }, []);

  return (
    <ThemeProvider theme={selectedTheme}>
      <HelmetProvider>
        <LanguageProvider>
          <IsOrderPageOpenProvider>
            <StatisticsProvider>
              <UserDataProvider>
                <AccountProducerProvider>
                  <ProducerDataProvider>
                    <UserThemeProvider>
                      <ResolutionProvider>
                        <CookiesProvider>
                          <VisitedProducerProvider>
                            <BusinessCardColorsProvider>
                              <EditHoverProvider>
                                <ConfirmationModalContextProvider>
                                  <AlertProvider>
                                    <ImageProvider>
                                      <TracksAudioProvider>
                                        <FilterMenuProvider>
                                          <SearchBarProvider>
                                            <AcceptCookiesComponent />
                                            <GlobalStyles
                                              isMenuOpen={isBodyOverflowBlocked}
                                              bodyBg={
                                                selectedTheme.colors.bodyBg
                                              }
                                            />
                                            {window.location.search.split(
                                              '?',
                                            )[2] === 'isMobile=true' ? null : (
                                              <Navbar
                                                handleThemeChange={
                                                  handleThemeChange
                                                }
                                                activeTheme={selectedTheme.name}
                                              />
                                            )}
                                            <Routes>
                                              <Route
                                                path="/Protectedlogin"
                                                element={
                                                  <Protectedlogin
                                                    setAccessGranted={
                                                      handleAccessGranted
                                                    }
                                                  />
                                                }
                                              />
                                              <Route
                                                path="*"
                                                element={
                                                  <ProtectedRoute
                                                    accessGranted={
                                                      accessGranted
                                                    }
                                                  >
                                                    <Routes>
                                                      <Route
                                                        path="/"
                                                        element={
                                                          <>
                                                            <Home />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/card"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <BusinessCard />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/editCard"
                                                        element={
                                                          <>
                                                            <ProducerList />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/editCard/:id"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <EditCard />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/producer"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <Account />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/messages"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <Messages />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/messages/support"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <SupportMessage />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/myproducts"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <MyProducts />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/myproducts/add"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <MyProductDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/myproducts/product/:id"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <MyProductDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/conditions"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <Conditions />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/conditions/add"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <EditCondition />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/conditions/condition/:id"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <EditCondition />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/transactions"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <TransactionsComponent />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/transactions/:id"
                                                        element={
                                                          <>
                                                            <TransactionDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/pro"
                                                        element={
                                                          <>
                                                            <MyStudioNavbar />
                                                            <PROComponent />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/login"
                                                        element={
                                                          <>
                                                            <LoginComponent />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/recover"
                                                        element={
                                                          <>
                                                            <RecoverComponent />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/recover/password"
                                                        element={
                                                          <>
                                                            <ConfrimRecoverPassword />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/recover/email"
                                                        element={
                                                          <>
                                                            <ResendEmail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/register"
                                                        element={
                                                          <>
                                                            <RegisterComponent />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/identity/register/active"
                                                        element={
                                                          <>
                                                            <ConfirmRegister />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/external-login-callback/:active/:token"
                                                        element={
                                                          <>
                                                            <ExternalLogin />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/account/settings"
                                                        element={
                                                          <div>
                                                            <Settings
                                                              handleThemeChange={
                                                                handleThemeChange
                                                              }
                                                            />
                                                          </div>
                                                        }
                                                      />
                                                      <Route
                                                        path="/account/settings/confirm/:type"
                                                        element={
                                                          <div
                                                            style={{
                                                              marginTop: '36px',
                                                            }}
                                                          >
                                                            <ConfirmSettings />
                                                          </div>
                                                        }
                                                      />
                                                      <Route
                                                        path="/account/regulations"
                                                        element={
                                                          <div
                                                            style={{
                                                              marginTop: '36px',
                                                            }}
                                                          >
                                                            <Regulations />
                                                          </div>
                                                        }
                                                      />
                                                      <Route
                                                        path="/faq/:id"
                                                        element={
                                                          <div>
                                                            <FaqComponent />
                                                          </div>
                                                        }
                                                      />
                                                      <Route
                                                        path="/tracks/main"
                                                        element={
                                                          <>
                                                            <Tracks />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/products/main"
                                                        element={
                                                          <>
                                                            <Products />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/producers/main"
                                                        element={
                                                          <>
                                                            <Producers />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/products/main/:id"
                                                        element={
                                                          <>
                                                            <ProductDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/tracks/main/:id"
                                                        element={
                                                          <>
                                                            <TrackDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/producers/card/:id"
                                                        element={
                                                          <>
                                                            <ProducerCard />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/czytelnia"
                                                        element={
                                                          <>
                                                            <Articles />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/czytelnia/:category"
                                                        element={
                                                          <>
                                                            <Articles />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="/czytelnia/:category/:id"
                                                        element={
                                                          <>
                                                            <ArticleDetail />
                                                          </>
                                                        }
                                                      />
                                                      <Route
                                                        path="*"
                                                        element={
                                                          <Navigate to="/" />
                                                        }
                                                      />
                                                    </Routes>
                                                  </ProtectedRoute>
                                                }
                                              ></Route>
                                            </Routes>
                                            <EnlargedImage />
                                            {/* <Footer /> */}
                                            <BottomMobileNavbar />
                                          </SearchBarProvider>
                                        </FilterMenuProvider>
                                      </TracksAudioProvider>
                                    </ImageProvider>
                                    <AlertPopup />
                                  </AlertProvider>
                                </ConfirmationModalContextProvider>
                              </EditHoverProvider>
                            </BusinessCardColorsProvider>
                          </VisitedProducerProvider>
                        </CookiesProvider>
                      </ResolutionProvider>
                    </UserThemeProvider>
                  </ProducerDataProvider>
                </AccountProducerProvider>
              </UserDataProvider>
            </StatisticsProvider>
          </IsOrderPageOpenProvider>
        </LanguageProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
