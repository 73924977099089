import React, {useState, useEffect, useContext, useRef} from 'react';
import styled, {css} from 'styled-components';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams, Link} from 'react-router-dom';

import {TransactionFee} from 'pages/MyStudio/Conditions/EditCondition';
import arrowRight from 'images/svg/select-arrow-right.svg';
import {
  ConditionSectionDescriptionExample,
  UserMetaData,
} from 'pages/MyStudio/Conditions/EditCondition';
import {LoadingCircle} from 'utils/loadingCircle';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {ResolutionContext} from 'contexts/ResolutionContext';
import {getProductDisplayStatusDescription} from '../../index';
import {ExpandableHeaderWithDescriptionWrapper} from 'pages/Tracks/TrackDetail';
import {
  CheckboxInfoWrapper,
  ConditionDetailsWrapper,
  BlueHeaderComponent,
  ConditionSectionDescription,
  SectionHeaderNoEditing,
  SellerObligationWrapper,
  AddSellerObligationWrapper,
  AddSellerObligationInputWrapper,
  SelectViewContainer,
  SmallSectionTitleWithSlider,
  ConditionSlider,
  ConditionTitleWithInputWrapper,
  ConditionTitleWithInputAndSelectWrapper,
  DisplayGrossValueNoEditing,
  ConditionTitleWithSliderAndSelectWrapper,
  DisplayGrossValueWithTaxNoEditing,
  StyledInputView,
} from 'pages/MyStudio/Conditions/EditCondition';

import {
  CurrencyEnum,
  ExploitationDigitalEnum,
  ExploitationPhysicalEnum,
  ExploitationYoutubeEnum,
  ProductTypeEnum,
  ProtectiveUmbrellaEnum,
  RightsEndEnum,
  TimeToFinishEnum,
  TaxesEnum,
  ProductStatusEnum,
  TimeToUploadForCustomer as TimeToUploadForCustomerEnum,
} from 'constants/conditionEnums';
import {ErrorStar} from 'components/ErrorStar';
import useAlert from 'hooks/useAlert';
import noImage from 'images/noimage.png';
import trackPlaceholder from 'images/svg/track-placeholder.svg';
import PopupButton from 'components/PopUp/PopupButton';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {ExpandableDescription} from 'components/ExpandableDescription';
import {ExpandableDescriptionOneLine} from 'components/ExpandableDescriptionOneLine';
import pauseIcon from 'images/svg/pause-icon.svg';
import playIcon from 'images/svg/play-icon.svg';
import {ExpandableHeaderWithDescription} from 'components/ExpandableHeaderWithDescription';
import {ExpandableArrowWithDescription} from 'components/ExpandableArrowWithDescription';
import {ConditionWithHelpers, ConditionList} from '../index';

import {
  AddTagButton,
  ConditionStyledTextarea,
  ConditionTextareaWrapper,
  ConditionsBannerWrapper,
  ConditionsContentWrapper,
  MobileConditionsContentWrapper,
  CreateConditionsButton,
  DeleteTagButton,
  SaveProductConditionButton,
  TextWithLink,
  TextareaLettersCount,
  DeleteConditionButton,
  StyledExpandableHeaderWithDescriptionWrapper,
  StyledConditionSectionDescription,
  StyledBlueHeaderWrapper,
  StyledLeftDot,
  MobileStyledInputView,
  StyledDisplayGrossValueWithTaxNoEditing,
  StyledDisplayGrossValueNoEditing,
  StyledSelectViewContainer,
  StyledSellerObligationWrapper,
  StyledSectionHeaderNoEditing,
} from '../styles';

interface Props {
  condition: ConditionWithHelpers;
  setCondition: React.Dispatch<React.SetStateAction<ConditionWithHelpers>>;
  isConditionEditing: boolean;
  conditionsList: ConditionList[];
  createNewProductStep: number;
  isProductEditing: boolean;
  userMetaData: UserMetaData;
  selectedCondition: number;
  productTypeFromProduct: number | string;
  conditionNumber: number;
  productId?: string;
  initialCondition: ConditionWithHelpers;
  setInitialCondition: React.Dispatch<
    React.SetStateAction<ConditionWithHelpers>
  >;
  conditionOne: ConditionWithHelpers;
  conditionTwo: ConditionWithHelpers;
  conditionThree: ConditionWithHelpers;
}

export const ConditionComponent: React.FC<Props> = ({
  condition,
  setCondition,
  isConditionEditing,
  conditionsList,
  createNewProductStep,
  isProductEditing,
  userMetaData,
  productTypeFromProduct,
  conditionNumber,
  selectedCondition,
  productId,
  initialCondition,
  setInitialCondition,
  conditionOne,
  conditionThree,
  conditionTwo,
}) => {
  const [sellerObligation, setSellerObligation] = useState<string>('');
  const [sellerRestriction, setSellerRestriction] = useState<string>('');

  const params = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['token']);
  const {Alert} = useAlert();

  const getTransactionFee = async (
    grossValue: number,
    productType: number | string,
  ) => {
    if (!condition || !cookies.token) return;

    try {
      const response = await api.get(`condition/transactional-fee`, {
        headers: {Authorization: `${cookies.token}`},
        params: {
          BruttoAmount: grossValue,
          ProductType: productType,
        },
      });

      setCondition((prevState) => {
        if (!prevState) return null;

        return {
          ...prevState,
          TransactionFee: response.data.Response,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    field: keyof ConditionWithHelpers,
  ) => {
    if (condition) {
      let value: any;
      if (field === 'GrossValue') {
        // value = +e.target.value;
        const inputValue = e.target.value;

        // Konwersja na liczbę, ale najpierw sprawdzenie czy wartość jest poprawna
        value = inputValue === '' ? '' : +inputValue;
        if (value < 0) return;
      } else if (
        field === 'WinWinDigital' ||
        field === 'WinWinPhysical' ||
        field === 'WinWinYoutube'
      ) {
        value = +e.target.value.replace('%', '');
        if (value > 99) return;
      } else if (e.target.type === 'number') {
        value = +e.target.value;
      } else {
        value = e.target.value;
      }

      setCondition((prevState) => {
        const updatedCondition = {
          ...prevState!,
          [field]: value,
        };

        if (field === 'GrossValue') {
          getTransactionFee(value * 100, updatedCondition.ProductType);
        }

        return updatedCondition;
      });
    }
  };

  const handleBooleanChange = (
    field: keyof ConditionWithHelpers,
    value: boolean,
  ) => {
    if (condition) {
      setCondition((prevState) => ({
        ...prevState!,
        [field]: value,
      }));
    }
  };

  const returnConditionDataToDefault = (
    conditionNumber: number,
    productTypeFromProduct,
  ) => {
    setCondition({
      AnotherLimits: '',
      AssignedProducts: [],
      AssignedProductsCount: 0,
      CanPriceBeNegotiated: true,
      Currency: 0,
      DateToUploadForCustomer: '',
      Description: '',
      ExploitationDigital: 1,
      ExploitationPhysical: 1,
      ExploitationYoutube: 1,
      GrossValue: 0,
      Id: `0000-${conditionNumber}`,
      IndicationOfContentAuthor: '',
      Invoice: false,
      IsDefault: false,
      IsExclusive: 1,
      isIndicationOfContentAuthor: false,
      Name: '',
      NetValue: 0,
      ProducerId: '',
      ProductType: productTypeFromProduct,
      ProtectiveUmbrella: 1,
      RightsEnd: 0,
      SellerObligations: [],
      SellerRestrictions: [],
      Tax: 23,
      TimeToFinish: 1,
      TimeToFinishDate: '',
      TimeToUploadForCustomer: '',
      TotalAmount: 0,
      TransactionFee: {
        Brutto: 0,
        Name: '',
        Netto: 0,
        Tax: 0,
        TaxTotal: 0,
      },
      userWinWin: false,
      WinWinDigital: 0,
      WinWinPhysical: 0,
      WinWinYoutube: 0,
      IsEdited: false,
    });
  };

  const handleSellerObligationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSellerObligation(e.target.value);

  const handleSellerRestrictionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSellerRestriction(e.target.value);

  const deleteSellerObligations = (sellerObligation: string) => {
    setCondition((prevState) => {
      if (!prevState) return null;

      const updatedSellerObligations = prevState.SellerObligations.filter(
        (prevSellerObligation) => prevSellerObligation !== sellerObligation,
      );

      return {
        ...prevState,
        SellerObligations: updatedSellerObligations,
      };
    });
  };

  const deleteSellerRestrictions = (sellerRestriction: string) => {
    setCondition((prevState) => {
      if (!prevState) return null;

      const updatedSellerRestrictions = prevState.SellerRestrictions.filter(
        (prevSellerRestriction) => prevSellerRestriction !== sellerRestriction,
      );

      return {
        ...prevState,
        SellerRestrictions: updatedSellerRestrictions,
      };
    });
  };

  const addSellerObligation = () => {
    if (!sellerObligation || !condition) return;

    if (
      condition.SellerObligations.some(
        (prevSellerObligation) =>
          prevSellerObligation.toLowerCase() === sellerObligation.toLowerCase(),
      )
    )
      return;

    setCondition((prevState) => {
      if (!prevState) return null;

      const updatedSellerObligations = [
        ...prevState.SellerObligations,
        sellerObligation,
      ];

      return {
        ...prevState,
        SellerObligations: updatedSellerObligations,
      };
    });

    setSellerObligation('');
  };

  const addSellerRestriction = () => {
    if (!sellerRestriction || !condition) return;

    if (
      condition.SellerRestrictions.some(
        (prevSellerRestriction) =>
          prevSellerRestriction.toLowerCase() ===
          sellerRestriction.toLowerCase(),
      )
    )
      return;

    setCondition((prevState) => {
      if (!prevState) return null;

      const updatedSellerRestrictions = [
        ...prevState.SellerRestrictions,
        sellerRestriction,
      ];

      return {
        ...prevState,
        SellerRestrictions: updatedSellerRestrictions,
      };
    });

    setSellerRestriction('');
  };

  // const handleInvoiceChange = (value: boolean) => setInvoice(value);

  const getSingleCondition = async (id: string) => {
    // setIsConditionLoading(true);
    try {
      const response = await api.get(`Condition/Get/${id}`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });

      const {
        AnotherLimits,
        AssignedProducts,
        AssignedProductsCount,
        CanPriceBeNegotiated,
        Currency,
        Description,
        ExploitationDigital,
        ExploitationPhysical,
        ExploitationYoutube,
        GrossValue,
        Id,
        Invoice,
        NetValue,
        Name,
        ProducerId,
        IsDefault,
        RightsEnd,
        SellerObligations,
        Tax,
        WinWinDigital,
        WinWinPhysical,
        WinWinYoutube,
        TimeToFinish,
        ProtectiveUmbrella,
        IsExclusive,
        SellerRestrictions,
        DateToUploadForCustomer,
        IndicationOfContentAuthor,
        TransactionFee,
        TimeToUploadForCustomer,
        ProductType,
        TimeToFinishDate,
        TotalAmount,
      } = response.data;

      setCondition({
        AnotherLimits,
        AssignedProducts,
        AssignedProductsCount,
        CanPriceBeNegotiated,
        Currency,
        Description,
        ExploitationDigital,
        ExploitationPhysical,
        ExploitationYoutube,
        GrossValue: GrossValue / 100,
        Id,
        Invoice,
        NetValue,
        Name,
        ProducerId,
        IsDefault,
        RightsEnd,
        SellerObligations,
        Tax,
        WinWinDigital,
        WinWinPhysical,
        WinWinYoutube,
        TimeToFinish,
        ProtectiveUmbrella,
        IsExclusive,
        SellerRestrictions,
        DateToUploadForCustomer,
        IndicationOfContentAuthor,
        TransactionFee,
        TimeToUploadForCustomer,
        isIndicationOfContentAuthor: IndicationOfContentAuthor ? true : false,
        ProductType,
        TimeToFinishDate,
        TotalAmount,
        userWinWin:
          WinWinDigital || WinWinPhysical || WinWinYoutube ? true : false,
        IsEdited: false,
      });
      setInitialCondition({
        AnotherLimits,
        AssignedProducts,
        AssignedProductsCount,
        CanPriceBeNegotiated,
        Currency,
        Description,
        ExploitationDigital,
        ExploitationPhysical,
        ExploitationYoutube,
        GrossValue: GrossValue / 100,
        Id,
        Invoice,
        NetValue,
        Name,
        ProducerId,
        IsDefault,
        RightsEnd,
        SellerObligations,
        Tax,
        WinWinDigital,
        WinWinPhysical,
        WinWinYoutube,
        TimeToFinish,
        ProtectiveUmbrella,
        IsExclusive,
        SellerRestrictions,
        DateToUploadForCustomer,
        IndicationOfContentAuthor,
        TransactionFee,
        TimeToUploadForCustomer,
        isIndicationOfContentAuthor: IndicationOfContentAuthor ? true : false,
        ProductType,
        TimeToFinishDate,
        TotalAmount,
        userWinWin:
          WinWinDigital || WinWinPhysical || WinWinYoutube ? true : false,
        IsEdited: false,
      });
    } catch (error) {
      console.error(error);
    } finally {
      // setIsConditionLoading(false);
    }
  };

  if (!condition)
    return (
      <>
        <ConditionsContentWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '259px',
            }}
          >
            <ConditionsBannerWrapper
              style={isConditionEditing ? {width: '294px'} : {}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="65.625"
                height="70"
                viewBox="0 0 65.625 70"
              >
                <g
                  id="Group_6595"
                  data-name="Group 6595"
                  transform="translate(-1054 -543)"
                >
                  <g
                    id="Group_6465"
                    data-name="Group 6465"
                    transform="translate(1054 543)"
                  >
                    <g
                      id="Group_6464"
                      data-name="Group 6464"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4030"
                        data-name="Path 4030"
                        d="M38.177,51.315a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,55.86l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441L38.455,52.7a1,1,0,0,0-.278-1.388"
                        transform="translate(-18.598 -32.124)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4031"
                        data-name="Path 4031"
                        d="M38.177,89a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,93.547l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441l4.006-6.007A1,1,0,0,0,38.177,89"
                        transform="translate(-18.598 -55.794)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4032"
                        data-name="Path 4032"
                        d="M38.177,126.69a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14a1,1,0,1,0-1.416,1.416l3,3a1,1,0,0,0,.708.293c.033,0,.066,0,.1,0a1,1,0,0,0,.734-.441l4.006-6.007a1,1,0,0,0-.278-1.388"
                        transform="translate(-18.598 -79.464)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4033"
                        data-name="Path 4033"
                        d="M51.063,7.009H38.047V4a1,1,0,0,0-1-1h-6.44a4.984,4.984,0,0,0-9.148,0h-6.44a1,1,0,0,0-1,1v3H1a1,1,0,0,0-1,1V66.082a1,1,0,0,0,1,1H37.046a1,1,0,1,0,0-2H2V9.011H14.017v3H6.007a1,1,0,0,0-1,1v48.06a1,1,0,0,0,1,1H35.044a1,1,0,1,0,0-2H7.009V14.017H45.056V39.049a1,1,0,1,0,2,0V13.016a1,1,0,0,0-1-1h-8.01v-3H50.062V39.049a1,1,0,1,0,2,0V8.01a1,1,0,0,0-1-1m-35.044-2h6.149a1,1,0,0,0,.97-.751,2.985,2.985,0,0,1,5.787,0,1,1,0,0,0,.97.751h6.149v7.009H16.02Z"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4034"
                        data-name="Path 4034"
                        d="M79.627,59.224H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                        transform="translate(-40.578 -37.197)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4035"
                        data-name="Path 4035"
                        d="M79.627,96.912H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                        transform="translate(-40.578 -60.867)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4036"
                        data-name="Path 4036"
                        d="M65.609,134.6a1,1,0,0,0,0,2h7.009a1,1,0,0,0,0-2Z"
                        transform="translate(-40.578 -84.538)"
                        fill="#07020f"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_6466"
                    data-name="Group 6466"
                    transform="translate(1093.375 586.75)"
                  >
                    <path
                      id="Path_3927"
                      data-name="Path 3927"
                      d="M6.965-1.484A1.039,1.039,0,0,0,8.006-2.525,1.039,1.039,0,0,0,6.965-3.567H3.9l3.973-4.9a.9.9,0,0,0,.192-.577.918.918,0,0,0-.961-.929H1.645A1.039,1.039,0,0,0,.6-8.934,1.039,1.039,0,0,0,1.645-7.893H4.69L.732-2.99a.9.9,0,0,0-.192.577.918.918,0,0,0,.961.929Zm2.788-.9a1.073,1.073,0,0,0,1.073,1.073A1.073,1.073,0,0,0,11.9-2.381V-5.842l1.089-.513a.788.788,0,0,0,.368-1.025.788.788,0,0,0-1.025-.368l-.433.192v-4.87A1.073,1.073,0,0,0,10.826-13.5a1.073,1.073,0,0,0-1.073,1.073v5.88l-1.089.5a.775.775,0,0,0-.368,1.009.78.78,0,0,0,1.025.385l.433-.192Z"
                      transform="translate(6.752 20.062)"
                      fill="#07020f"
                    />
                    <path
                      id="Path_4037"
                      data-name="Path 4037"
                      d="M13.125,2.188A10.938,10.938,0,1,0,24.063,13.125,10.95,10.95,0,0,0,13.125,2.188m0-2.187A13.125,13.125,0,1,1,0,13.125,13.125,13.125,0,0,1,13.125,0Z"
                      fill="#07020f"
                    />
                  </g>
                </g>
              </svg>
              {isConditionEditing ? (
                <>
                  <h1>Warunki dostępne</h1>
                  <p>
                    Zaczytaj lub stwórz własne warunki dla kontentu aby móc
                    wycenić swoją twórczość i opublikować ofertę.
                  </p>
                  <p style={{marginTop: '10px'}}>
                    Skorzystaj z zakładki <span>Warunki</span> i rozpisz więcej
                    spersonalizowanych szablonów.
                  </p>
                </>
              ) : (
                <>
                  <h1>Warunki oferty</h1>
                </>
              )}
            </ConditionsBannerWrapper>
            {createNewProductStep === 2 && isProductEditing ? null : (
              <>
                <SelectView
                  isEditing={true}
                  style={isConditionEditing ? {} : {opacity: '0.5'}}
                >
                  <p>Zaczytaj szablon</p>
                  <select
                    style={
                      isConditionEditing
                        ? {border: '1px solid rgba(0,0,0,0.12)'}
                        : {
                            userSelect: 'none',
                            pointerEvents: 'none',
                            border: '1px solid rgba(0,0,0,0.12)',
                          }
                    }
                    placeholder="Wybierz"
                    // value={selectedConditionOne}
                    onChange={(e) => {
                      getSingleCondition(e.target.value);
                    }}
                  >
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    {conditionsList
                      .filter(
                        (condition) =>
                          condition.ProductType === productTypeFromProduct,
                      )
                      .map((condition) => {
                        const {Id, Name} = condition;

                        // if (
                        //   Id === selectedConditionTwo ||
                        //   Id === selectedConditionThree
                        // )
                        //   return;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </>
            )}
          </div>
          {createNewProductStep === 2 && isProductEditing ? null : (
            <CreateConditionsButton
              isReadyForOffer={isConditionEditing}
              onClick={() => {
                if (isConditionEditing) {
                  returnConditionDataToDefault(
                    conditionNumber,
                    productTypeFromProduct,
                  );
                }
              }}
            >
              Stwórz nowe warunki
            </CreateConditionsButton>
          )}
        </ConditionsContentWrapper>
        <MobileConditionsContentWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '259px',
            }}
          >
            <ConditionsBannerWrapper
              style={isConditionEditing ? {width: '294px'} : {}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="65.625"
                height="70"
                viewBox="0 0 65.625 70"
              >
                <g
                  id="Group_6595"
                  data-name="Group 6595"
                  transform="translate(-1054 -543)"
                >
                  <g
                    id="Group_6465"
                    data-name="Group 6465"
                    transform="translate(1054 543)"
                  >
                    <g
                      id="Group_6464"
                      data-name="Group 6464"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4030"
                        data-name="Path 4030"
                        d="M38.177,51.315a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,55.86l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441L38.455,52.7a1,1,0,0,0-.278-1.388"
                        transform="translate(-18.598 -32.124)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4031"
                        data-name="Path 4031"
                        d="M38.177,89a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,93.547l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441l4.006-6.007A1,1,0,0,0,38.177,89"
                        transform="translate(-18.598 -55.794)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4032"
                        data-name="Path 4032"
                        d="M38.177,126.69a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14a1,1,0,1,0-1.416,1.416l3,3a1,1,0,0,0,.708.293c.033,0,.066,0,.1,0a1,1,0,0,0,.734-.441l4.006-6.007a1,1,0,0,0-.278-1.388"
                        transform="translate(-18.598 -79.464)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4033"
                        data-name="Path 4033"
                        d="M51.063,7.009H38.047V4a1,1,0,0,0-1-1h-6.44a4.984,4.984,0,0,0-9.148,0h-6.44a1,1,0,0,0-1,1v3H1a1,1,0,0,0-1,1V66.082a1,1,0,0,0,1,1H37.046a1,1,0,1,0,0-2H2V9.011H14.017v3H6.007a1,1,0,0,0-1,1v48.06a1,1,0,0,0,1,1H35.044a1,1,0,1,0,0-2H7.009V14.017H45.056V39.049a1,1,0,1,0,2,0V13.016a1,1,0,0,0-1-1h-8.01v-3H50.062V39.049a1,1,0,1,0,2,0V8.01a1,1,0,0,0-1-1m-35.044-2h6.149a1,1,0,0,0,.97-.751,2.985,2.985,0,0,1,5.787,0,1,1,0,0,0,.97.751h6.149v7.009H16.02Z"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4034"
                        data-name="Path 4034"
                        d="M79.627,59.224H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                        transform="translate(-40.578 -37.197)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4035"
                        data-name="Path 4035"
                        d="M79.627,96.912H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                        transform="translate(-40.578 -60.867)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4036"
                        data-name="Path 4036"
                        d="M65.609,134.6a1,1,0,0,0,0,2h7.009a1,1,0,0,0,0-2Z"
                        transform="translate(-40.578 -84.538)"
                        fill="#07020f"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_6466"
                    data-name="Group 6466"
                    transform="translate(1093.375 586.75)"
                  >
                    <path
                      id="Path_3927"
                      data-name="Path 3927"
                      d="M6.965-1.484A1.039,1.039,0,0,0,8.006-2.525,1.039,1.039,0,0,0,6.965-3.567H3.9l3.973-4.9a.9.9,0,0,0,.192-.577.918.918,0,0,0-.961-.929H1.645A1.039,1.039,0,0,0,.6-8.934,1.039,1.039,0,0,0,1.645-7.893H4.69L.732-2.99a.9.9,0,0,0-.192.577.918.918,0,0,0,.961.929Zm2.788-.9a1.073,1.073,0,0,0,1.073,1.073A1.073,1.073,0,0,0,11.9-2.381V-5.842l1.089-.513a.788.788,0,0,0,.368-1.025.788.788,0,0,0-1.025-.368l-.433.192v-4.87A1.073,1.073,0,0,0,10.826-13.5a1.073,1.073,0,0,0-1.073,1.073v5.88l-1.089.5a.775.775,0,0,0-.368,1.009.78.78,0,0,0,1.025.385l.433-.192Z"
                      transform="translate(6.752 20.062)"
                      fill="#07020f"
                    />
                    <path
                      id="Path_4037"
                      data-name="Path 4037"
                      d="M13.125,2.188A10.938,10.938,0,1,0,24.063,13.125,10.95,10.95,0,0,0,13.125,2.188m0-2.187A13.125,13.125,0,1,1,0,13.125,13.125,13.125,0,0,1,13.125,0Z"
                      fill="#07020f"
                    />
                  </g>
                </g>
              </svg>
              {isConditionEditing ? (
                <>
                  <h1>Warunki dostępne</h1>
                  <p>
                    Zaczytaj lub stwórz własne warunki dla kontentu aby móc
                    wycenić swoją twórczość i opublikować ofertę.
                  </p>
                  <p style={{marginTop: '10px'}}>
                    Skorzystaj z zakładki <span>Warunki</span> i rozpisz więcej
                    spersonalizowanych szablonów.
                  </p>
                </>
              ) : (
                <>
                  <h1>Warunki oferty</h1>
                </>
              )}
            </ConditionsBannerWrapper>
            {createNewProductStep === 2 && isProductEditing ? null : (
              <>
                <SelectView
                  isEditing={true}
                  style={isConditionEditing ? {} : {opacity: '0.5'}}
                >
                  <p>Zaczytaj szablon</p>
                  <select
                    style={
                      isConditionEditing
                        ? {border: '1px solid rgba(0,0,0,0.12)'}
                        : {
                            userSelect: 'none',
                            pointerEvents: 'none',
                            border: '1px solid rgba(0,0,0,0.12)',
                          }
                    }
                    placeholder="Wybierz"
                    // value={selectedConditionOne}
                    onChange={(e) => {
                      getSingleCondition(e.target.value);
                    }}
                  >
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    {conditionsList
                      .filter(
                        (condition) =>
                          condition.ProductType === productTypeFromProduct,
                      )
                      .map((condition) => {
                        const {Id, Name} = condition;

                        // if (
                        //   Id === selectedConditionTwo ||
                        //   Id === selectedConditionThree
                        // )
                        //   return;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </>
            )}
          </div>
          {createNewProductStep === 2 && isProductEditing ? null : (
            <CreateConditionsButton
              isReadyForOffer={isConditionEditing}
              onClick={() => {
                if (isConditionEditing) {
                  returnConditionDataToDefault(
                    conditionNumber,
                    productTypeFromProduct,
                  );
                }
              }}
            >
              Stwórz nowe warunki
            </CreateConditionsButton>
          )}
        </MobileConditionsContentWrapper>
      </>
    );

  const {
    AnotherLimits,
    AssignedProducts,
    AssignedProductsCount,
    CanPriceBeNegotiated,
    Currency,
    DateToUploadForCustomer,
    Description,
    ExploitationDigital,
    ExploitationPhysical,
    ExploitationYoutube,
    GrossValue,
    Id,
    IndicationOfContentAuthor,
    Invoice,
    IsDefault,
    IsExclusive,
    Name,
    NetValue,
    ProducerId,
    ProductType,
    ProtectiveUmbrella,
    RightsEnd,
    SellerObligations,
    SellerRestrictions,
    Tax,
    TimeToFinish,
    TimeToFinishDate,
    TimeToUploadForCustomer,
    TotalAmount,
    TransactionFee,
    WinWinDigital,
    WinWinPhysical,
    WinWinYoutube,
    isIndicationOfContentAuthor,
    userWinWin,
  } = condition;

  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const adjustAssignedProductsCount = (
    condition: ConditionWithHelpers,
    productId: string,
  ) => {
    console.log('condition:', condition);
    console.log('productId:', productId);

    if (!condition?.Id) {
      console.log('Return 0 because of missing condition Id');
      return 0;
    }

    if (!condition?.AssignedProducts) {
      console.log('Return 0 because of missing AssignedProducts');
      return 0;
    }

    if (condition?.AssignedProducts.length === 0) {
      console.log('Return 0 because AssignedProducts length is 0');
      return 0;
    }
    if (condition?.AssignedProducts.some((p) => p.Id === productId)) {
      console.log(
        `${condition?.Name} Found product in assigned products, decrementing count`,
      );
      return condition?.AssignedProductsCount - 1;
    }
    console.log(
      `${condition?.Name} Product not found in assigned products, returning count`,
    );
    return condition?.AssignedProductsCount;
  };

  const newAssignedProductsCount = adjustAssignedProductsCount(
    condition,
    productId,
  );

  console.log('newAssignedProductsCount:', newAssignedProductsCount);

  return (
    <>
      <ConditionsContentWrapper style={condition?.Id ? {height: '470px'} : {}}>
        {(isConditionEditing &&
          conditionNumber === 2 &&
          condition?.Id === conditionOne?.Id) ||
        (conditionNumber === 3 &&
          (condition?.Id === conditionOne?.Id ||
            condition?.Id === conditionTwo?.Id)) ? (
          <ConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p style={{color: 'red'}}>
              Uwaga:
              <br />
              Ten warunek jest już wykorzystany na tym produkcie, nadaj unikalną
              nazwę nowemu szablonowi.
            </p>
          </ConditionSectionDescription>
        ) : !deepEqual(initialCondition, condition) &&
          isConditionEditing &&
          newAssignedProductsCount > 0 ? (
          <ConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p style={{color: 'red'}}>
              Uwaga:
              <br />
              Wprowadziłeś zmiany w warunkach, nadaj unikalną nazwę nowemu
              szablonowi.
            </p>
          </ConditionSectionDescription>
        ) : (
          !deepEqual(initialCondition, condition) &&
          isConditionEditing &&
          IsDefault && (
            <ConditionSectionDescription
              isEditing={isConditionEditing}
              style={{marginTop: '10px'}}
            >
              <p style={{color: 'red'}}>
                Uwaga:
                <br />
                Wprowadziłeś zmiany w warunkach, nadaj unikalną nazwę nowemu
                szablonowi.
              </p>
            </ConditionSectionDescription>
          )
        )}
        {isConditionEditing && (
          <DeleteConditionButton
            onClick={() => setCondition(null)}
            style={{top: '6px'}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.9"
              height="12"
              viewBox="0 0 10.9 12"
            >
              <g
                id="Icon_feather-trash"
                data-name="Icon feather-trash"
                transform="translate(0.5 0.5)"
              >
                <path
                  id="Path_3663"
                  data-name="Path 3663"
                  d="M4.5,9h9.9"
                  transform="translate(-4.5 -6.8)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3664"
                  data-name="Path 3664"
                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                  transform="translate(-6.4 -3)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </DeleteConditionButton>
        )}
        <ConditionDetailsWrapper
          style={{paddingLeft: '10px', paddingBottom: '60px'}}
        >
          <ConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p>
              Nazwij szablon, dobrze oddając zawarte w nim ustalenia. Uwaga -
              nazwa będzie widoczna dla użytkowników.
            </p>
          </ConditionSectionDescription>
          {isConditionEditing && (
            <InputView
              style={{marginTop: '18px'}}
              isEditing={isConditionEditing}
            >
              <p>Nazwa warunków</p>
              <input
                type="text"
                readOnly={false}
                placeholder="Uzupełnij"
                value={Name}
                onChange={(e) => handleChange(e, 'Name')}
              />
            </InputView>
          )}
          <ConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p>
              Opisz szablon, podając największe korzyści z jego wyboru. Uwaga -
              opis znajdzie się w ofercie.
            </p>
          </ConditionSectionDescription>
          {isConditionEditing && (
            <ConditionTextareaWrapper
              isEditing={isConditionEditing}
              style={{marginTop: '10px'}}
            >
              <h5>Opis</h5>
              <ConditionStyledTextarea
                maxLength={250}
                readOnly={false}
                placeholder="Uzupełnij"
                value={Description}
                onChange={(e) => handleChange(e, 'Description')}
              />
              <TextareaLettersCount>
                {Description.length}/250
              </TextareaLettersCount>
            </ConditionTextareaWrapper>
          )}
          {!isConditionEditing && (
            <>
              <ExpandableHeaderWithDescriptionWrapper>
                <ExpandableHeaderWithDescription
                  header={Name}
                  text=""
                  isEditing={true}
                >
                  <ConditionSectionDescription
                    style={{flexDirection: 'column'}}
                    isEditing={true}
                  >
                    <ConditionSectionDescriptionExample isEditing={true}>
                      <p>{Description ? Description : 'Brak opisu'}</p>
                    </ConditionSectionDescriptionExample>
                  </ConditionSectionDescription>
                </ExpandableHeaderWithDescription>
              </ExpandableHeaderWithDescriptionWrapper>
            </>
          )}

          <BlueHeaderComponent>Deklaracje</BlueHeaderComponent>
          <ConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              Opisz w punktach, co wykonasz w ramach swojej oferty, czyli do
              czego się zobowiązujesz i co wykluczasz, a więc czego Twoja oferta
              nie zawiera.
            </p>
          </ConditionSectionDescription>
          <ExpandableHeaderWithDescription
            text=""
            isEditing={isConditionEditing}
            header="Zobowiązania"
            isLongDescription
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To bardzo ważne, żeby szczegółowo opisać usługę czy produkt -
                  unikniesz dzięki temu nieporozumień.
                </p>
                <br />
                <p>Przykłady: </p>
              </ConditionSectionDescriptionExample>

              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA FAN PAGE’A
                  NA FACEBOOKU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Facebook poniedziałek-piątek{' '}
                </p>
                <span>Zobowiązania:</span>
                <span>- Zamieszczę 3 posty w tygodniu. </span>
                <span>- Do postów dodam grafikę. </span>
                <span>- Posty będą publikowane o godzinie 10:00. </span>
                <span>
                  - Będę moderował posty od 09:00 do 21:00 w dni powszednie.{' '}
                </span>
                <span>- Każdy wpis będzie zawierał unikalną treść. </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO UTWORU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> WIN-WIN
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Zobowiązuję się stworzyć rymowany tekst utworu po wskazaniu
                  tematyki przez zamawiającego.{' '}
                </span>
                <span>
                  - Tekst będzie składał się z trzech zwrotek przeplatanych
                  refrenem (zwrotki 16 taktów, refren 8 taktów).{' '}
                </span>
                <span>
                  - Usługę zrealizuję w ciągu pięciu dni roboczych od złożenia
                  zamówienia.{' '}
                </span>
                <span>
                  - Dopuszczam dwie sesje poprawkowe po zbiorowym wskazaniu
                  poprawek w tekście przez zamawiającego.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW MUZYCZNYCH
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Prosty teledysk
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Zapewniam kamerę lub aparat z rozdzielczością 4K oraz
                  podstawowy zestaw oświetleniowy.{' '}
                </span>
                <span>
                  - Zapewniam 10 godzin montażu materiału po ewentualnych
                  sugestiach klienta.{' '}
                </span>
                <span>
                  - Zdjęcia będą trwały w jednym dniu przez osiem godzin po
                  wcześniejszym ustaleniu daty z klientem.{' '}
                </span>
                <span>
                  - Dopuszczam dwie darmowe poprawki po montażu, każda kolejna
                  będzie kosztować 300 złotych.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> SEO COPYWRITING - ARTYKUŁY
                  BLOGOWE
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO do 5
                  tysięcy znaków ze spacjami
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Tekst będzie dotyczył wybranej przez klienta tematyki.{' '}
                </span>
                <span>
                  - Tekst będzie zawierał unikalną treść, która nie jest
                  skopiowana z innych źródeł.{' '}
                </span>
                <span>
                  - Artykuł będzie liczył do 5000 znaków ze spacjami.{' '}
                </span>
                <span>
                  - Tekst będzie zawierał frazę główną w nagłówku H1 oraz
                  pomocnicze słowa kluczowe w nagłówkach H2 i H3.
                </span>
                <span>
                  - Zezwalam na to, by klient podpisał się pod moim tekstem
                  własnym imieniem i nazwiskiem.{' '}
                </span>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {!isConditionEditing && (
            <SectionHeaderNoEditing>
              <p>Zobowiązania</p>
            </SectionHeaderNoEditing>
          )}
          {SellerObligations.map((sellerObligation) => (
            <SellerObligationWrapper
              key={sellerObligation}
              isEditing={isConditionEditing}
            >
              {isConditionEditing ? (
                <>
                  <p>{sellerObligation}</p>
                  <DeleteTagButton
                    style={{
                      marginTop: '1px',
                      marginLeft: '1px',
                    }}
                    onClick={() => deleteSellerObligations(sellerObligation)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </DeleteTagButton>
                </>
              ) : (
                <>
                  <svg
                    style={{left: '13.5px'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Icon_awesome-check-circle"
                      data-name="Icon awesome-check-circle"
                      d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                      transform="translate(-0.563 -0.563)"
                      fill="#00d10f"
                    />
                  </svg>
                  <p>{sellerObligation}</p>
                </>
              )}
            </SellerObligationWrapper>
          ))}
          {isConditionEditing && (
            <AddSellerObligationWrapper>
              <AddSellerObligationInputWrapper>
                <input
                  placeholder="Napisz treść zobowiązania..."
                  value={sellerObligation}
                  onChange={handleSellerObligationChange}
                />
              </AddSellerObligationInputWrapper>
              <AddTagButton onClick={() => addSellerObligation()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.138"
                  height="15"
                  viewBox="0 0 15.138 15"
                >
                  <path
                    id="Path_3683"
                    data-name="Path 3683"
                    d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                    transform="translate(-171.869 -100.292)"
                    fill="#fff"
                  />
                </svg>
              </AddTagButton>
            </AddSellerObligationWrapper>
          )}
          <ExpandableHeaderWithDescription
            text=""
            header="Wykluczenia, ograniczenia"
            isEditing={isConditionEditing}
            isLongDescription
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Podaj ograniczenia. <br />
                  To zapobiegnie ewentualnym sporom.
                </p>
                <br />
                <p>Przykłady: </p>
              </ConditionSectionDescriptionExample>

              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA FAN PAGE’A
                  NA FACEBOOKU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Facebook poniedziałek-piątek{' '}
                </p>
                <span>Ograniczenia:</span>
                <span>- Nie będę dokonywał moderacji postów w weekendy. </span>
                <span>
                  - Nie będę prowadził korespondencji z klientami firmy za
                  pośrednictwem wiadomości prywatnych w serwisie Facebook.{' '}
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO UTWORU
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> WIN-WIN
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Tekst nie będzie zawierał sformułowań rasistowskich,
                  seksistowskich i nawołujących do nienawiści na tle religijnym.
                </span>
                <span>
                  - Tekst nie może być wykorzystany w reklamie komercyjnej.
                </span>
                <span>
                  - Nie zgadzam się na umieszczenie utworu z moim tekstem na
                  innych wydawnictwach, niż dyskografia zamawiającego (a więc
                  między innymi na składankach i mixtape’ach).
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW MUZYCZNYCH
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Prosty teledysk
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Teledysk lub jego fragmenty nie mogą być wykorzystywane w
                  reklamach.
                </span>
                <span>
                  - Dobór aktorów i aktorek do teledysku pozostaje po stronie
                  klienta.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> SEO COPYWRITING - ARTYKUŁY
                  BLOGOWE
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO do 5
                  tysięcy znaków ze spacjami
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Tekst nie może ukazać się w innym miejscu, niż blog klienta.
                </span>
                <span>
                  - Klient nie może dokonać zmian w artykule - dotyczy to
                  zarówno treści, jak i tytułu oraz nagłówków.
                </span>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {!isConditionEditing && SellerRestrictions.length > 0 && (
            <SectionHeaderNoEditing>
              <p>Wykluczenia i ograniczenia</p>
            </SectionHeaderNoEditing>
          )}
          {SellerRestrictions.map((sellerRestriction) => (
            <SellerObligationWrapper
              key={sellerRestriction}
              isEditing={isConditionEditing}
            >
              {isConditionEditing ? (
                <>
                  <p>{sellerRestriction}</p>
                  <DeleteTagButton
                    style={{
                      marginTop: '1px',
                      marginLeft: '1px',
                    }}
                    onClick={() => deleteSellerRestrictions(sellerRestriction)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </DeleteTagButton>
                </>
              ) : (
                <>
                  <svg
                    style={{top: '3px', left: '11.5px'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.729"
                    height="12.728"
                    viewBox="0 0 12.729 12.728"
                  >
                    <path
                      id="Icon_awesome-plus-circle"
                      data-name="Icon awesome-plus-circle"
                      d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                      transform="translate(6.364 -0.796) rotate(45)"
                      fill="#ff0300"
                    />
                  </svg>

                  <p>{sellerRestriction}</p>
                </>
              )}
            </SellerObligationWrapper>
          ))}
          {isConditionEditing && (
            <AddSellerObligationWrapper>
              <AddSellerObligationInputWrapper>
                <input
                  placeholder="Napisz treść ograniczeń..."
                  value={sellerRestriction}
                  onChange={handleSellerRestrictionChange}
                />
              </AddSellerObligationInputWrapper>
              <AddTagButton onClick={() => addSellerRestriction()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.138"
                  height="15"
                  viewBox="0 0 15.138 15"
                >
                  <path
                    id="Path_3683"
                    data-name="Path 3683"
                    d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                    transform="translate(-171.869 -100.292)"
                    fill="#fff"
                  />
                </svg>
              </AddTagButton>
            </AddSellerObligationWrapper>
          )}
          {ProductType !== 4 && ProductType !== 1 && (
            <>
              <BlueHeaderComponent>Kanały monetyzacji</BlueHeaderComponent>
              <ConditionSectionDescription isEditing={isConditionEditing}>
                <p>
                  To obszary, gdzie Twoi klienci będą mogli zamieścić
                  wyprodukowane treści. W ramach poniższych kanałów, możesz
                  dodatkowo ograniczyć ich prawa do monetyzacji.
                </p>
              </ConditionSectionDescription>
              <ExpandableHeaderWithDescription
                header="Serwisy cyfrowe"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      To popularne platformy streamingowe, np. Spotify, Tidal.
                      Możesz ograniczyć maksymalną liczbę odtworzeń.
                      <br />
                      Zdecyduj czy klient może bez ograniczeń monetyzować
                      treści, czy ograniczasz mu liczbę dopuszczalnych odtworzeń
                      w serwisach. <br />
                      Zwróć uwagę, że w przypadku ofert WIN-WIN nie warto
                      ograniczać możliwości monetyzacji, ponieważ będziesz
                      czerpać z tego zyski.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <SelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>Serwisy cyfrowe</p>}
                  <select
                    value={ExploitationDigital}
                    onChange={(e) => handleChange(e, 'ExploitationDigital')}
                    disabled={!isConditionEditing}
                  >
                    {ExploitationDigitalEnum.map((exploitationDigital) => {
                      const {FieldName, FlagValue} = exploitationDigital;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </SelectViewContainer>
              <ExpandableHeaderWithDescription
                header="YouTube"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      Zdecyduj czy dopuszczasz możliwość monetyzacji (w tym
                      ContentID) w serwisie YouTube.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <SelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>YouTube</p>}
                  <select
                    value={ExploitationYoutube}
                    disabled={!isConditionEditing}
                    onChange={(e) => handleChange(e, 'ExploitationYoutube')}
                  >
                    {ExploitationYoutubeEnum.map((exploitationYoutube) => {
                      const {FieldName, FlagValue} = exploitationYoutube;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </SelectViewContainer>
              <ExpandableHeaderWithDescription
                header="Dystrybucja fizyczna"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      To możliwość wydania Twojej treści w formie np. CD, MC czy
                      winyl.
                      <br />
                      Zdecyduj czy ograniczasz maksymalny nakład w ramach tego
                      szablonu warunków.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <SelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>Dystrybucja Fizyczna</p>}
                  <select
                    value={ExploitationPhysical}
                    onChange={(e) => handleChange(e, 'ExploitationPhysical')}
                    disabled={!isConditionEditing}
                  >
                    {ExploitationPhysicalEnum.map((exploitationPhysical) => {
                      const {FieldName, FlagValue} = exploitationPhysical;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </SelectViewContainer>
            </>
          )}
          <BlueHeaderComponent>Warunki współpracy</BlueHeaderComponent>
          <ConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              Wykonując usługę, dostarczając track lub produkt cyfrowy,
              zawierasz z klientem umowę. W tej sekcji określisz jej szczegółowe
              warunki.
            </p>
          </ConditionSectionDescription>
          <ExpandableHeaderWithDescription
            header="Rodzaj umowy"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To określenie czy umowa ma charakter otwarty czy na
                  wyłączność.
                  <br />
                  <br />
                  Umowa otwarta - oznacza to, że ten sam track, produkt cyfrowy
                  lub usługę możesz sprzedać wielu klientom. <br />
                  <br />
                  Umowa na wyłączność - oznacza to, że produkt cyfrowy, track
                  lub usługę może nabyć tylko jeden klient.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <SelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Rodzaj umowy</p>
              <select
                disabled={!isConditionEditing}
                value={IsExclusive}
                onChange={(e) => handleChange(e, 'IsExclusive')}
              >
                <option value={1}>Na wyłączność</option>
                <option value={2}>Otwarta</option>
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </SelectViewContainer>
          <ExpandableHeaderWithDescription
            header="Czas trwania licencji"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Określ na jaki czas klient nabędzie prawa do wykorzystania
                  tego tracku, produktu cyfrowego lub wykonanej usługi.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <SelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Licencja</p>
              <select
                disabled={!isConditionEditing}
                value={RightsEnd}
                onChange={(e) => handleChange(e, 'RightsEnd')}
              >
                {RightsEndEnum.map((rightEnd) => {
                  const {FieldName, FlagValue} = rightEnd;

                  return (
                    <option value={FlagValue} key={FlagValue}>
                      {FieldName}
                    </option>
                  );
                })}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </SelectViewContainer>
          <ExpandableHeaderWithDescription
            header="Czas realizacji"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To określenie ile czasu potrzebujesz na wykonanie usługi bądź
                  dostarczenie produktu cyfrowego lub tracku.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <SelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Czas na wykonanie</p>
              <select
                disabled={!isConditionEditing}
                value={TimeToFinish}
                onChange={(e) => handleChange(e, 'TimeToFinish')}
              >
                {TimeToFinishEnum.map((timeToFinish) => {
                  const {FieldName, FlagValue} = timeToFinish;

                  return (
                    <option value={FlagValue} key={FlagValue}>
                      {FieldName}
                    </option>
                  );
                })}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </SelectViewContainer>
          <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
          {!isConditionEditing ? (
            <>
              {IndicationOfContentAuthor ? (
                <ConditionSectionDescription
                  isEditing={true}
                  style={{marginBottom: '5px'}}
                >
                  <p>
                    Sprzedawca wymaga oznaczenia treści
                    <br />
                    imieniem, nazwiskiem bądź pseudonimem
                  </p>
                </ConditionSectionDescription>
              ) : (
                <ConditionSectionDescription isEditing={true}>
                  <p>
                    Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                    udostępnianie
                  </p>
                </ConditionSectionDescription>
              )}
              {IndicationOfContentAuthor && (
                <DisplayGrossValueNoEditing>
                  <p>Oznaczenie</p>
                  <div>
                    <p>{IndicationOfContentAuthor}</p>
                  </div>
                </DisplayGrossValueNoEditing>
              )}
            </>
          ) : (
            <>
              <ConditionSectionDescription isEditing={isConditionEditing}>
                <p>
                  Oczekujesz aby kupujący podczas dystrybucji powstałej treści
                  umieszczał Twoje dane w tzw. kredytach?
                </p>
              </ConditionSectionDescription>
              <SmallSectionTitleWithSlider>
                <h5>Wymagam oznaczenia treści</h5>
                <ConditionSlider
                  isDisabled={false}
                  value={isIndicationOfContentAuthor}
                  onClick={() =>
                    handleBooleanChange(
                      'isIndicationOfContentAuthor',
                      !isIndicationOfContentAuthor,
                    )
                  }
                >
                  <div />
                </ConditionSlider>
              </SmallSectionTitleWithSlider>
              {isIndicationOfContentAuthor && (
                <>
                  <ConditionSectionDescription
                    isEditing={true}
                    style={{marginBottom: '5px'}}
                  >
                    <p
                      style={{
                        textAlign: 'left',
                        width: '259px',
                        margin: '0 auto',
                        paddingLeft: '10px',
                      }}
                    >
                      Imię i nazwisko lub pseudonim
                    </p>
                  </ConditionSectionDescription>
                  <StyledInputView
                    style={{
                      marginTop: '0px',
                      width: '259px',
                      margin: '0 auto',
                    }}
                    isEditing={isConditionEditing}
                  >
                    <input
                      type="text"
                      readOnly={false}
                      value={IndicationOfContentAuthor}
                      onChange={(e) =>
                        handleChange(e, 'IndicationOfContentAuthor')
                      }
                    />
                  </StyledInputView>
                </>
              )}
            </>
          )}
          <BlueHeaderComponent>
            Rozliczenia-Warunki finansowe
          </BlueHeaderComponent>
          <ConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              W tej sekcji określ swoje <br />
              oczekiwania finansowe.
            </p>
          </ConditionSectionDescription>
          {ProductType !== 4 && ProductType !== 1 && (
            <>
              {isConditionEditing && (
                <SmallSectionTitleWithSlider>
                  <h5>Oferta WIN-WIN</h5>
                  <ConditionSlider
                    isDisabled={!userMetaData.HasMugoLabel}
                    value={!userMetaData.HasMugoLabel ? false : userWinWin}
                    onClick={() =>
                      handleBooleanChange(
                        'userWinWin',
                        !userMetaData.HasMugoLabel ? false : !userWinWin,
                      )
                    }
                  >
                    <div />
                  </ConditionSlider>
                </SmallSectionTitleWithSlider>
              )}
              {!userMetaData.HasMugoLabel ? (
                <ConditionSectionDescription isEditing={isConditionEditing}>
                  <p>
                    WIN-WIN to nowy perspektywiczny model współpracy z
                    kupującym, w którym nabywasz udział w prawach do
                    wyprodukowanego w przyszłości dzieła.
                  </p>
                  <p style={{color: '#0091FF'}}>
                    WIN-WIN wymaga posiadania aktywnej umowy wydawniczej w MUGO
                  </p>
                </ConditionSectionDescription>
              ) : userMetaData.HasMugoLabel && userWinWin ? (
                <>
                  <ExpandableArrowWithDescription
                    isEditing={isConditionEditing}
                  >
                    <ConditionSectionDescription
                      style={{flexDirection: 'column'}}
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescriptionExample
                        isEditing={isConditionEditing}
                      >
                        <p>
                          Pamiętaj, że należne Tobie przychody będą
                          automatycznie rozliczane i pojawiać się będą w MUGO.
                          Oznacza to, że nie musisz domagać się należnych Tobie
                          zysków - pieniądze będą automatycznie rozliczane i
                          wypłacane. Od tego momentu nie musisz się o to martwić
                          i narażać się na często nieprzyjemne upominanie się o
                          swoje należności od partnera.
                          <br />
                          Określ swój udział w jego ewentualnych przychodach za
                          wykorzystanie Twojego przedmiotu usługi.
                        </p>
                      </ConditionSectionDescriptionExample>
                    </ConditionSectionDescription>
                  </ExpandableArrowWithDescription>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing
                        ? 'Przychody z serwisów cyfrowch'
                        : 'Serwisy cyfrowe'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={3}
                      onChange={(e) => handleChange(e, 'WinWinDigital')}
                      value={`${WinWinDigital}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing ? 'Przychody z YouTube' : 'YouTube'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      maxLength={3}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => handleChange(e, 'WinWinYoutube')}
                      value={`${WinWinYoutube}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing
                        ? 'Przychody z dystrybucji fizycznej'
                        : 'Dystrybucja fizyczna'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={3}
                      onChange={(e) => handleChange(e, 'WinWinPhysical')}
                      value={`${WinWinPhysical}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  {isConditionEditing && (
                    <ExpandableHeaderWithDescription
                      header="Data wydania w MUGO"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Wprowadź datę, do której kupujący musi opublikować
                            utwór. Jeśli do tego czasu kupujący nie dostarczy
                            wydania do MUGO, Wasza umowa WIN-WIN przestaje być
                            ważna.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  )}
                  {isConditionEditing ? (
                    <SelectViewContainer isEditing={isConditionEditing}>
                      <SelectView isEditing={isConditionEditing}>
                        <p>Data wydania w Mugo</p>
                        <select
                          disabled={!isConditionEditing}
                          value={TimeToUploadForCustomer}
                          onChange={(e) =>
                            handleChange(e, 'TimeToUploadForCustomer')
                          }
                        >
                          <option value="">Wybierz</option>
                          {TimeToUploadForCustomerEnum.map(
                            (exploitationPhysical) => {
                              const {FieldName, FlagValue} =
                                exploitationPhysical;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                  ) : (
                    <ConditionTitleWithInputWrapper
                      isEditing={isConditionEditing}
                    >
                      <p style={{width: 'calc(100% - 80px)'}}>
                        Data wydania w MUGO
                      </p>
                      <input
                        style={{width: '116px'}}
                        disabled={!isConditionEditing}
                        type="date"
                        value={DateToUploadForCustomer.split('T')[0]}
                        onChange={(e) =>
                          handleChange(e, 'DateToUploadForCustomer')
                        }
                      />
                    </ConditionTitleWithInputWrapper>
                  )}
                  <ConditionSectionDescription isEditing={isConditionEditing}>
                    <p>
                      W systemie WIN-WIN oprócz procentowego udziału w
                      przychodach, możesz opcjonalnie wybrać gwarantowaną kwotę
                      wynagrodzenia, którą kupujący od razu musi wpłacić na
                      Twoje konto w MUGO Studio. W tym przypadku musisz dopisać
                      oczekiwaną kwotę w polu &quot;Kwota brutto&quot;.
                    </p>
                  </ConditionSectionDescription>
                </>
              ) : (
                <ConditionSectionDescription isEditing={isConditionEditing}>
                  <p>
                    WIN-WIN to nowy, perspektywiczny model współpracy z
                    kupującym, w którym możesz ustalić swój udział w prawach do
                    wyprodukowanego w przyszłości dzieła, na którym będziesz po
                    prostu zarabiać!
                  </p>
                </ConditionSectionDescription>
              )}
            </>
          )}
          <ExpandableHeaderWithDescription
            header="Cena usługi, produktu"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Każda usługa, produkt ma swoją wartość. Podaj kwotę, jakiej
                  oczekujesz od klienta. <br />
                  <br />
                  Kwota brutto? <br />W przypadku firm, to kwota z podatkiem
                  VAT. Natomiast w przypadku osób fizycznych to kwota, jaką
                  otrzymasz od klienta i od której powinieneś odprowadzić
                  należny podatek dochodowy
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {userMetaData.HasIBAN ? (
            <>
              {isConditionEditing ? (
                <ConditionTitleWithInputAndSelectWrapper
                  isEditing={isConditionEditing}
                  style={{marginBottom: '10px'}}
                >
                  <p>Kwota brutto</p>
                  <InputView
                    isEditing={isConditionEditing}
                    style={{marginTop: 'unset'}}
                  >
                    <input
                      disabled={!isConditionEditing}
                      placeholder="0"
                      min={0}
                      // onBlur={() => getTransactionFee(grossValue)}
                      style={{paddingLeft: 'unset'}}
                      type="number"
                      value={GrossValue}
                      onChange={(e) => handleChange(e, 'GrossValue')}
                    />
                  </InputView>

                  <SelectView
                    isEditing={isConditionEditing}
                    style={{marginTop: 'unset'}}
                  >
                    <select
                      value={Currency}
                      onChange={(e) => handleChange(e, 'Currency')}
                      disabled={!isConditionEditing}
                    >
                      {CurrencyEnum.map((currency) => {
                        const {FieldName, FlagValue} = currency;

                        if (FlagValue !== 0) return;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {FieldName}
                          </option>
                        );
                      })}
                    </select>
                  </SelectView>
                </ConditionTitleWithInputAndSelectWrapper>
              ) : !isConditionEditing && GrossValue ? (
                <DisplayGrossValueNoEditing>
                  <p>
                    {!isConditionEditing && !userWinWin
                      ? 'Cena (brutto)'
                      : !isConditionEditing &&
                        userWinWin &&
                        'Kwota gwarantowana'}
                  </p>
                  <div>
                    <h5>{GrossValue}</h5>
                    <p>PLN</p>
                  </div>
                </DisplayGrossValueNoEditing>
              ) : (
                !isConditionEditing && !GrossValue && null
              )}

              {userMetaData.IsLegal && (
                <ConditionTitleWithSliderAndSelectWrapper>
                  {isConditionEditing ? (
                    <>
                      <p>Faktura VAT</p>
                      <ConditionSlider
                        value={Invoice}
                        onClick={() => handleBooleanChange('Invoice', !Invoice)}
                      >
                        <div />
                      </ConditionSlider>
                    </>
                  ) : (
                    <CheckboxInfoWrapper
                      style={{
                        marginBottom: 'unset',
                        width: '196px',
                        height: '24px',
                      }}
                    >
                      <p>Faktura VAT</p>
                      <span>{Invoice ? 'Tak' : 'Nie'}</span>
                    </CheckboxInfoWrapper>
                  )}

                  <SelectView
                    isEditing={isConditionEditing}
                    style={{width: '100px', marginTop: 'unset'}}
                  >
                    <select
                      value={Tax}
                      onChange={(e) => handleChange(e, 'Tax')}
                      disabled={!isConditionEditing}
                    >
                      {TaxesEnum.map((tax) => {
                        const {FieldName, FlagValue} = tax;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {FieldName}
                          </option>
                        );
                      })}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#3986ef"
                        />
                      </g>
                    </svg>
                  </SelectView>
                </ConditionTitleWithSliderAndSelectWrapper>
              )}
              {isConditionEditing && (
                <>
                  <ExpandableHeaderWithDescription
                    header="Opłata transakcyjna"
                    text=""
                    isEditing={isConditionEditing}
                  >
                    <ConditionSectionDescription
                      style={{flexDirection: 'column'}}
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescriptionExample
                        isEditing={isConditionEditing}
                      >
                        <p>
                          Opłata transakcyjna to kwota, która zostanie doliczona
                          kupującemu, pokrywająca koszty obsługi systemu MUGO.{' '}
                          <br />
                          Kwota nie stanowi przychodu sprzedającego.
                        </p>
                      </ConditionSectionDescriptionExample>
                    </ConditionSectionDescription>
                  </ExpandableHeaderWithDescription>
                </>
              )}
              {GrossValue > 0 && (
                <>
                  <DisplayGrossValueNoEditing
                    style={!isConditionEditing ? {marginTop: '10px'} : {}}
                  >
                    <p>Opłata transakcyjna</p>
                    <div>
                      <h5>
                        {Number((TransactionFee?.Brutto / 100).toFixed(2))}
                      </h5>
                      <p>PLN</p>
                    </div>
                  </DisplayGrossValueNoEditing>
                  <DisplayGrossValueWithTaxNoEditing>
                    <p>Razem do zapłaty</p>
                    <div>
                      <h5>
                        {GrossValue
                          ? `${Number(
                              (
                                GrossValue +
                                TransactionFee?.Brutto / 100
                              ).toFixed(2),
                            )}`
                          : '0'}
                      </h5>
                      <p>PLN</p>
                    </div>
                  </DisplayGrossValueWithTaxNoEditing>
                </>
              )}
            </>
          ) : (
            <TextWithLink style={{marginBottom: '40px'}}>
              <p>Załóż konto Mangopay</p>

              <span>
                <Link to={'/producer'} target="_blank">
                  tutaj.
                </Link>
              </span>
            </TextWithLink>
          )}
        </ConditionDetailsWrapper>
      </ConditionsContentWrapper>
      <MobileConditionsContentWrapper
        style={condition?.Id ? {height: '470px'} : {}}
      >
        {isConditionEditing && newAssignedProductsCount > 0 ? (
          <ConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p style={{color: 'red'}}>
              Uwaga:
              <br />
              Obecnie edytujesz warunek, który jest już używany w innej ofercie.
              W związku z tym ten warunek nie zostanie zedytowany, lecz zostanie
              stworzony nowy, zachowując oryginalny warunek bez zmian.
              <br />
              Prosimy o zmianę nazwy tego warunku, ponieważ nazwy warunków muszą
              być unikalne.
            </p>
          </ConditionSectionDescription>
        ) : (
          isConditionEditing &&
          IsDefault && (
            <ConditionSectionDescription
              isEditing={isConditionEditing}
              style={{marginTop: '10px'}}
            >
              <p style={{color: 'red'}}>
                Uwaga:
                <br />
                Obecnie edytujesz warunek domyślny. W związku z tym ten warunek
                nie zostanie zedytowany, lecz zostanie stworzony nowy,
                zachowując oryginalny warunek bez zmian.
                <br />
                Prosimy o zmianę nazwy tego warunku, ponieważ nazwy warunków
                muszą być unikalne.
              </p>
            </ConditionSectionDescription>
          )
        )}
        {isConditionEditing && (
          <DeleteConditionButton
            onClick={() => setCondition(null)}
            style={{top: '6px'}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.9"
              height="12"
              viewBox="0 0 10.9 12"
            >
              <g
                id="Icon_feather-trash"
                data-name="Icon feather-trash"
                transform="translate(0.5 0.5)"
              >
                <path
                  id="Path_3663"
                  data-name="Path 3663"
                  d="M4.5,9h9.9"
                  transform="translate(-4.5 -6.8)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3664"
                  data-name="Path 3664"
                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                  transform="translate(-6.4 -3)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </DeleteConditionButton>
        )}
        <ConditionDetailsWrapper
          style={{paddingLeft: '10px', paddingBottom: '60px'}}
        >
          <StyledConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p>
              Nazwij szablon, dobrze oddając zawarte w nim ustalenia. Uwaga -
              nazwa będzie widoczna dla użytkowników.
            </p>
          </StyledConditionSectionDescription>
          {isConditionEditing && (
            <MobileStyledInputView
              style={{marginTop: '18px'}}
              isEditing={isConditionEditing}
            >
              <p>Nazwa warunków</p>
              <input
                type="text"
                readOnly={false}
                placeholder="Uzupełnij"
                value={Name}
                onChange={(e) => handleChange(e, 'Name')}
              />
            </MobileStyledInputView>
          )}
          <StyledConditionSectionDescription
            isEditing={isConditionEditing}
            style={{marginTop: '10px'}}
          >
            <p>
              Opisz szablon, podając największe korzyści z jego wyboru. Uwaga -
              opis znajdzie się w ofercie.
            </p>
          </StyledConditionSectionDescription>
          {isConditionEditing && (
            <ConditionTextareaWrapper
              isEditing={isConditionEditing}
              style={{marginTop: '10px'}}
            >
              <h5>Opis</h5>
              <ConditionStyledTextarea
                maxLength={250}
                readOnly={false}
                placeholder="Uzupełnij"
                value={Description}
                onChange={(e) => handleChange(e, 'Description')}
              />
              <TextareaLettersCount>
                {Description.length}/250
              </TextareaLettersCount>
            </ConditionTextareaWrapper>
          )}
          {!isConditionEditing && (
            <>
              <StyledExpandableHeaderWithDescriptionWrapper>
                <ExpandableHeaderWithDescription
                  header={Name}
                  text=""
                  isEditing={true}
                >
                  <StyledConditionSectionDescription
                    style={{flexDirection: 'column'}}
                    isEditing={true}
                  >
                    <ConditionSectionDescriptionExample isEditing={true}>
                      <p>{Description ? Description : 'Brak opisu'}</p>
                    </ConditionSectionDescriptionExample>
                  </StyledConditionSectionDescription>
                </ExpandableHeaderWithDescription>
              </StyledExpandableHeaderWithDescriptionWrapper>
            </>
          )}

          <StyledBlueHeaderComponent>Deklaracje</StyledBlueHeaderComponent>
          <StyledConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              Opisz w punktach, co wykonasz w ramach swojej oferty, czyli do
              czego się zobowiązujesz i co wykluczasz, a więc czego Twoja oferta
              nie zawiera.
            </p>
          </StyledConditionSectionDescription>
          <ExpandableHeaderWithDescription
            text=""
            isEditing={isConditionEditing}
            header="Zobowiązania"
            isLongDescription
          >
            <StyledConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To bardzo ważne, żeby szczegółowo opisać usługę czy produkt -
                  unikniesz dzięki temu nieporozumień.
                </p>
                <br />
                <p>Przykłady: </p>
              </ConditionSectionDescriptionExample>

              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA FAN PAGE’A
                  NA FACEBOOKU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Facebook poniedziałek-piątek{' '}
                </p>
                <span>Zobowiązania:</span>
                <span>- Zamieszczę 3 posty w tygodniu. </span>
                <span>- Do postów dodam grafikę. </span>
                <span>- Posty będą publikowane o godzinie 10:00. </span>
                <span>
                  - Będę moderował posty od 09:00 do 21:00 w dni powszednie.{' '}
                </span>
                <span>- Każdy wpis będzie zawierał unikalną treść. </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO UTWORU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> WIN-WIN
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Zobowiązuję się stworzyć rymowany tekst utworu po wskazaniu
                  tematyki przez zamawiającego.{' '}
                </span>
                <span>
                  - Tekst będzie składał się z trzech zwrotek przeplatanych
                  refrenem (zwrotki 16 taktów, refren 8 taktów).{' '}
                </span>
                <span>
                  - Usługę zrealizuję w ciągu pięciu dni roboczych od złożenia
                  zamówienia.{' '}
                </span>
                <span>
                  - Dopuszczam dwie sesje poprawkowe po zbiorowym wskazaniu
                  poprawek w tekście przez zamawiającego.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW MUZYCZNYCH
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Prosty teledysk
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Zapewniam kamerę lub aparat z rozdzielczością 4K oraz
                  podstawowy zestaw oświetleniowy.{' '}
                </span>
                <span>
                  - Zapewniam 10 godzin montażu materiału po ewentualnych
                  sugestiach klienta.{' '}
                </span>
                <span>
                  - Zdjęcia będą trwały w jednym dniu przez osiem godzin po
                  wcześniejszym ustaleniu daty z klientem.{' '}
                </span>
                <span>
                  - Dopuszczam dwie darmowe poprawki po montażu, każda kolejna
                  będzie kosztować 300 złotych.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> SEO COPYWRITING - ARTYKUŁY
                  BLOGOWE
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO do 5
                  tysięcy znaków ze spacjami
                </p>
                <span>Zobowiązania:</span>
                <span>
                  - Tekst będzie dotyczył wybranej przez klienta tematyki.{' '}
                </span>
                <span>
                  - Tekst będzie zawierał unikalną treść, która nie jest
                  skopiowana z innych źródeł.{' '}
                </span>
                <span>
                  - Artykuł będzie liczył do 5000 znaków ze spacjami.{' '}
                </span>
                <span>
                  - Tekst będzie zawierał frazę główną w nagłówku H1 oraz
                  pomocnicze słowa kluczowe w nagłówkach H2 i H3.
                </span>
                <span>
                  - Zezwalam na to, by klient podpisał się pod moim tekstem
                  własnym imieniem i nazwiskiem.{' '}
                </span>
              </ConditionSectionDescriptionExample>
            </StyledConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {!isConditionEditing && (
            <StyledSectionHeaderNoEditing>
              <p>Zobowiązania</p>
            </StyledSectionHeaderNoEditing>
          )}
          {SellerObligations.map((sellerObligation) => (
            <StyledSellerObligationWrapper
              key={sellerObligation}
              isEditing={isConditionEditing}
            >
              {isConditionEditing ? (
                <>
                  <p>{sellerObligation}</p>
                  <DeleteTagButton
                    style={{
                      marginTop: '1px',
                      marginLeft: '1px',
                    }}
                    onClick={() => deleteSellerObligations(sellerObligation)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </DeleteTagButton>
                </>
              ) : (
                <>
                  <svg
                    style={{left: '13.5px'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Icon_awesome-check-circle"
                      data-name="Icon awesome-check-circle"
                      d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                      transform="translate(-0.563 -0.563)"
                      fill="#00d10f"
                    />
                  </svg>
                  <p>{sellerObligation}</p>
                </>
              )}
            </StyledSellerObligationWrapper>
          ))}
          {isConditionEditing && (
            <AddSellerObligationWrapper>
              <AddSellerObligationInputWrapper>
                <input
                  placeholder="Napisz treść zobowiązania..."
                  value={sellerObligation}
                  onChange={handleSellerObligationChange}
                />
              </AddSellerObligationInputWrapper>
              <AddTagButton onClick={() => addSellerObligation()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.138"
                  height="15"
                  viewBox="0 0 15.138 15"
                >
                  <path
                    id="Path_3683"
                    data-name="Path 3683"
                    d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                    transform="translate(-171.869 -100.292)"
                    fill="#fff"
                  />
                </svg>
              </AddTagButton>
            </AddSellerObligationWrapper>
          )}
          <ExpandableHeaderWithDescription
            text=""
            header="Wykluczenia, ograniczenia"
            isEditing={isConditionEditing}
            isLongDescription
          >
            <StyledConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Podaj ograniczenia. <br />
                  To zapobiegnie ewentualnym sporom.
                </p>
                <br />
                <p>Przykłady: </p>
              </ConditionSectionDescriptionExample>

              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA FAN PAGE’A
                  NA FACEBOOKU{' '}
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Facebook poniedziałek-piątek{' '}
                </p>
                <span>Ograniczenia:</span>
                <span>- Nie będę dokonywał moderacji postów w weekendy. </span>
                <span>
                  - Nie będę prowadził korespondencji z klientami firmy za
                  pośrednictwem wiadomości prywatnych w serwisie Facebook.{' '}
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO UTWORU
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> WIN-WIN
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Tekst nie będzie zawierał sformułowań rasistowskich,
                  seksistowskich i nawołujących do nienawiści na tle religijnym.
                </span>
                <span>
                  - Tekst nie może być wykorzystany w reklamie komercyjnej.
                </span>
                <span>
                  - Nie zgadzam się na umieszczenie utworu z moim tekstem na
                  innych wydawnictwach, niż dyskografia zamawiającego (a więc
                  między innymi na składankach i mixtape’ach).
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW MUZYCZNYCH
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Prosty teledysk
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Teledysk lub jego fragmenty nie mogą być wykorzystywane w
                  reklamach.
                </span>
                <span>
                  - Dobór aktorów i aktorek do teledysku pozostaje po stronie
                  klienta.
                </span>
              </ConditionSectionDescriptionExample>
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p style={{marginBottom: '0px'}}>
                  <strong>Nazwa oferty:</strong> SEO COPYWRITING - ARTYKUŁY
                  BLOGOWE
                </p>
                <p>
                  <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO do 5
                  tysięcy znaków ze spacjami
                </p>
                <span>Ograniczenia: </span>
                <span>
                  - Tekst nie może ukazać się w innym miejscu, niż blog klienta.
                </span>
                <span>
                  - Klient nie może dokonać zmian w artykule - dotyczy to
                  zarówno treści, jak i tytułu oraz nagłówków.
                </span>
              </ConditionSectionDescriptionExample>
            </StyledConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {!isConditionEditing && SellerRestrictions.length > 0 && (
            <StyledSectionHeaderNoEditing>
              <p>Wykluczenia i ograniczenia</p>
            </StyledSectionHeaderNoEditing>
          )}
          {SellerRestrictions.map((sellerRestriction) => (
            <StyledSellerObligationWrapper
              key={sellerRestriction}
              isEditing={isConditionEditing}
            >
              {isConditionEditing ? (
                <>
                  <p>{sellerRestriction}</p>
                  <DeleteTagButton
                    style={{
                      marginTop: '1px',
                      marginLeft: '1px',
                    }}
                    onClick={() => deleteSellerRestrictions(sellerRestriction)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </DeleteTagButton>
                </>
              ) : (
                <>
                  <svg
                    style={{top: '3px', left: '11.5px'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.729"
                    height="12.728"
                    viewBox="0 0 12.729 12.728"
                  >
                    <path
                      id="Icon_awesome-plus-circle"
                      data-name="Icon awesome-plus-circle"
                      d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                      transform="translate(6.364 -0.796) rotate(45)"
                      fill="#ff0300"
                    />
                  </svg>

                  <p>{sellerRestriction}</p>
                </>
              )}
            </StyledSellerObligationWrapper>
          ))}
          {isConditionEditing && (
            <AddSellerObligationWrapper>
              <AddSellerObligationInputWrapper>
                <input
                  placeholder="Napisz treść ograniczeń..."
                  value={sellerRestriction}
                  onChange={handleSellerRestrictionChange}
                />
              </AddSellerObligationInputWrapper>
              <AddTagButton onClick={() => addSellerRestriction()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.138"
                  height="15"
                  viewBox="0 0 15.138 15"
                >
                  <path
                    id="Path_3683"
                    data-name="Path 3683"
                    d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                    transform="translate(-171.869 -100.292)"
                    fill="#fff"
                  />
                </svg>
              </AddTagButton>
            </AddSellerObligationWrapper>
          )}
          {ProductType !== 4 && ProductType !== 1 && (
            <>
              <StyledBlueHeaderComponent>
                Kanały monetyzacji
              </StyledBlueHeaderComponent>
              <StyledConditionSectionDescription isEditing={isConditionEditing}>
                <p>
                  To obszary, gdzie Twoi klienci będą mogli zamieścić
                  wyprodukowane treści. W ramach poniższych kanałów, możesz
                  dodatkowo ograniczyć ich prawa do monetyzacji.
                </p>
              </StyledConditionSectionDescription>
              <ExpandableHeaderWithDescription
                header="Serwisy cyfrowe"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      To popularne platformy streamingowe, np. Spotify, Tidal.
                      Możesz ograniczyć maksymalną liczbę odtworzeń.
                      <br />
                      Zdecyduj czy klient może bez ograniczeń monetyzować
                      treści, czy ograniczasz mu liczbę dopuszczalnych odtworzeń
                      w serwisach. <br />
                      Zwróć uwagę, że w przypadku ofert WIN-WIN nie warto
                      ograniczać możliwości monetyzacji, ponieważ będziesz
                      czerpać z tego zyski.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <StyledSelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>Serwisy cyfrowe</p>}
                  <select
                    value={ExploitationDigital}
                    onChange={(e) => handleChange(e, 'ExploitationDigital')}
                    disabled={!isConditionEditing}
                  >
                    {ExploitationDigitalEnum.map((exploitationDigital) => {
                      const {FieldName, FlagValue} = exploitationDigital;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </StyledSelectViewContainer>
              <ExpandableHeaderWithDescription
                header="YouTube"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      Zdecyduj czy dopuszczasz możliwość monetyzacji (w tym
                      ContentID) w serwisie YouTube.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <StyledSelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>YouTube</p>}
                  <select
                    value={ExploitationYoutube}
                    disabled={!isConditionEditing}
                    onChange={(e) => handleChange(e, 'ExploitationYoutube')}
                  >
                    {ExploitationYoutubeEnum.map((exploitationYoutube) => {
                      const {FieldName, FlagValue} = exploitationYoutube;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </StyledSelectViewContainer>
              <ExpandableHeaderWithDescription
                header="Dystrybucja fizyczna"
                text=""
                isEditing={isConditionEditing}
              >
                <ConditionSectionDescription
                  style={{flexDirection: 'column'}}
                  isEditing={isConditionEditing}
                >
                  <ConditionSectionDescriptionExample
                    isEditing={isConditionEditing}
                  >
                    <p>
                      To możliwość wydania Twojej treści w formie np. CD, MC czy
                      winyl.
                      <br />
                      Zdecyduj czy ograniczasz maksymalny nakład w ramach tego
                      szablonu warunków.
                    </p>
                  </ConditionSectionDescriptionExample>
                </ConditionSectionDescription>
              </ExpandableHeaderWithDescription>
              <StyledSelectViewContainer isEditing={isConditionEditing}>
                <SelectView isEditing={isConditionEditing}>
                  {!isConditionEditing && <p>Dystrybucja Fizyczna</p>}
                  <select
                    value={ExploitationPhysical}
                    onChange={(e) => handleChange(e, 'ExploitationPhysical')}
                    disabled={!isConditionEditing}
                  >
                    {ExploitationPhysicalEnum.map((exploitationPhysical) => {
                      const {FieldName, FlagValue} = exploitationPhysical;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </SelectView>
              </StyledSelectViewContainer>
            </>
          )}
          <StyledBlueHeaderComponent>
            Warunki współpracy
          </StyledBlueHeaderComponent>
          <StyledConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              Wykonując usługę, dostarczając track lub produkt cyfrowy,
              zawierasz z klientem umowę. W tej sekcji określisz jej szczegółowe
              warunki.
            </p>
          </StyledConditionSectionDescription>
          <ExpandableHeaderWithDescription
            header="Rodzaj umowy"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To określenie czy umowa ma charakter otwarty czy na
                  wyłączność.
                  <br />
                  <br />
                  Umowa otwarta - oznacza to, że ten sam track, produkt cyfrowy
                  lub usługę możesz sprzedać wielu klientom. <br />
                  <br />
                  Umowa na wyłączność - oznacza to, że produkt cyfrowy, track
                  lub usługę może nabyć tylko jeden klient.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <StyledSelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Rodzaj umowy</p>
              <select
                disabled={!isConditionEditing}
                value={IsExclusive}
                onChange={(e) => handleChange(e, 'IsExclusive')}
              >
                <option value={1}>Na wyłączność</option>
                <option value={2}>Otwarta</option>
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </StyledSelectViewContainer>
          <ExpandableHeaderWithDescription
            header="Czas trwania licencji"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Określ na jaki czas klient nabędzie prawa do wykorzystania
                  tego tracku, produktu cyfrowego lub wykonanej usługi.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <StyledSelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Licencja</p>
              <select
                disabled={!isConditionEditing}
                value={RightsEnd}
                onChange={(e) => handleChange(e, 'RightsEnd')}
              >
                {RightsEndEnum.map((rightEnd) => {
                  const {FieldName, FlagValue} = rightEnd;

                  return (
                    <option value={FlagValue} key={FlagValue}>
                      {FieldName}
                    </option>
                  );
                })}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </StyledSelectViewContainer>
          <ExpandableHeaderWithDescription
            header="Czas realizacji"
            text=""
            isEditing={isConditionEditing}
          >
            <ConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  To określenie ile czasu potrzebujesz na wykonanie usługi bądź
                  dostarczenie produktu cyfrowego lub tracku.
                </p>
              </ConditionSectionDescriptionExample>
            </ConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          <StyledSelectViewContainer isEditing={isConditionEditing}>
            <SelectView isEditing={isConditionEditing}>
              <p>Czas na wykonanie</p>
              <select
                disabled={!isConditionEditing}
                value={TimeToFinish}
                onChange={(e) => handleChange(e, 'TimeToFinish')}
              >
                {TimeToFinishEnum.map((timeToFinish) => {
                  const {FieldName, FlagValue} = timeToFinish;

                  return (
                    <option value={FlagValue} key={FlagValue}>
                      {FieldName}
                    </option>
                  );
                })}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.439"
                height="11"
                viewBox="0 0 6.439 11"
              >
                <g
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  transform="translate(6.439 11) rotate(180)"
                >
                  <path
                    id="Arrow"
                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                    transform="translate(0 0)"
                    fill="#3986ef"
                  />
                </g>
              </svg>
            </SelectView>
          </StyledSelectViewContainer>
          <StyledBlueHeaderComponent>Oznaczenia</StyledBlueHeaderComponent>
          {!isConditionEditing ? (
            <>
              {IndicationOfContentAuthor ? (
                <StyledConditionSectionDescription
                  isEditing={true}
                  style={{marginBottom: '5px'}}
                >
                  <p>
                    Sprzedawca wymaga oznaczenia treści
                    <br />
                    imieniem, nazwiskiem bądź pseudonimem
                  </p>
                </StyledConditionSectionDescription>
              ) : (
                <StyledConditionSectionDescription isEditing={true}>
                  <p>
                    Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                    udostępnianie
                  </p>
                </StyledConditionSectionDescription>
              )}
              {IndicationOfContentAuthor && (
                <StyledDisplayGrossValueNoEditing>
                  <p>Oznaczenie</p>
                  <div>
                    <p>{IndicationOfContentAuthor}</p>
                  </div>
                </StyledDisplayGrossValueNoEditing>
              )}
            </>
          ) : (
            <>
              <StyledConditionSectionDescription isEditing={isConditionEditing}>
                <p>
                  Oczekujesz aby kupujący podczas dystrybucji powstałej treści
                  umieszczał Twoje dane w tzw. kredytach?
                </p>
              </StyledConditionSectionDescription>
              <SmallSectionTitleWithSlider>
                <h5>Wymagam oznaczenia treści</h5>
                <ConditionSlider
                  isDisabled={false}
                  value={isIndicationOfContentAuthor}
                  onClick={() =>
                    handleBooleanChange(
                      'isIndicationOfContentAuthor',
                      !isIndicationOfContentAuthor,
                    )
                  }
                >
                  <div />
                </ConditionSlider>
              </SmallSectionTitleWithSlider>
              {isIndicationOfContentAuthor && (
                <>
                  <StyledConditionSectionDescription
                    isEditing={true}
                    style={{marginBottom: '5px'}}
                  >
                    <p
                      style={{
                        textAlign: 'left',
                        width: '259px',
                        margin: '0 auto',
                        paddingLeft: '10px',
                      }}
                    >
                      Imię i nazwisko lub pseudonim
                    </p>
                  </StyledConditionSectionDescription>
                  <StyledInputView
                    style={{
                      marginTop: '0px',
                      width: '259px',
                      margin: '0 auto',
                    }}
                    isEditing={isConditionEditing}
                  >
                    <input
                      type="text"
                      readOnly={false}
                      value={IndicationOfContentAuthor}
                      onChange={(e) =>
                        handleChange(e, 'IndicationOfContentAuthor')
                      }
                    />
                  </StyledInputView>
                </>
              )}
            </>
          )}
          <StyledBlueHeaderComponent>
            Rozliczenia-Warunki finansowe
          </StyledBlueHeaderComponent>
          <StyledConditionSectionDescription isEditing={isConditionEditing}>
            <p>
              W tej sekcji określ swoje <br />
              oczekiwania finansowe.
            </p>
          </StyledConditionSectionDescription>
          {ProductType !== 4 && ProductType !== 1 && (
            <>
              {isConditionEditing && (
                <SmallSectionTitleWithSlider>
                  <h5>Oferta WIN-WIN</h5>
                  <ConditionSlider
                    isDisabled={!userMetaData.HasMugoLabel}
                    value={!userMetaData.HasMugoLabel ? false : userWinWin}
                    onClick={() =>
                      handleBooleanChange(
                        'userWinWin',
                        !userMetaData.HasMugoLabel ? false : !userWinWin,
                      )
                    }
                  >
                    <div />
                  </ConditionSlider>
                </SmallSectionTitleWithSlider>
              )}
              {!userMetaData.HasMugoLabel ? (
                <StyledConditionSectionDescription
                  isEditing={isConditionEditing}
                >
                  <p>
                    WIN-WIN to nowy perspektywiczny model współpracy z
                    kupującym, w którym nabywasz udział w prawach do
                    wyprodukowanego w przyszłości dzieła.
                  </p>
                  <p style={{color: '#0091FF'}}>
                    WIN-WIN wymaga posiadania aktywnej umowy wydawniczej w MUGO
                  </p>
                </StyledConditionSectionDescription>
              ) : userMetaData.HasMugoLabel && userWinWin ? (
                <>
                  <ExpandableArrowWithDescription
                    isEditing={isConditionEditing}
                  >
                    <StyledConditionSectionDescription
                      style={{flexDirection: 'column'}}
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescriptionExample
                        isEditing={isConditionEditing}
                      >
                        <p>
                          Pamiętaj, że należne Tobie przychody będą
                          automatycznie rozliczane i pojawiać się będą w MUGO.
                          Oznacza to, że nie musisz domagać się należnych Tobie
                          zysków - pieniądze będą automatycznie rozliczane i
                          wypłacane. Od tego momentu nie musisz się o to martwić
                          i narażać się na często nieprzyjemne upominanie się o
                          swoje należności od partnera.
                          <br />
                          Określ swój udział w jego ewentualnych przychodach za
                          wykorzystanie Twojego przedmiotu usługi.
                        </p>
                      </ConditionSectionDescriptionExample>
                    </StyledConditionSectionDescription>
                  </ExpandableArrowWithDescription>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing
                        ? 'Przychody z serwisów cyfrowch'
                        : 'Serwisy cyfrowe'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={3}
                      onChange={(e) => handleChange(e, 'WinWinDigital')}
                      value={`${WinWinDigital}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing ? 'Przychody z YouTube' : 'YouTube'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      maxLength={3}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => handleChange(e, 'WinWinYoutube')}
                      value={`${WinWinYoutube}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  <ConditionTitleWithInputWrapper
                    isEditing={isConditionEditing}
                  >
                    <p>
                      {!isConditionEditing
                        ? 'Przychody z dystrybucji fizycznej'
                        : 'Dystrybucja fizyczna'}
                    </p>
                    <input
                      style={
                        !userWinWin
                          ? {
                              filter: 'brightness(0.5)',
                              userSelect: 'none',
                            }
                          : {}
                      }
                      disabled={!isConditionEditing}
                      placeholder="%"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={3}
                      onChange={(e) => handleChange(e, 'WinWinPhysical')}
                      value={`${WinWinPhysical}%`}
                    />
                  </ConditionTitleWithInputWrapper>
                  {isConditionEditing && (
                    <ExpandableHeaderWithDescription
                      header="Data wydania w MUGO"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Wprowadź datę, do której kupujący musi opublikować
                            utwór. Jeśli do tego czasu kupujący nie dostarczy
                            wydania do MUGO, Wasza umowa WIN-WIN przestaje być
                            ważna.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  )}
                  {isConditionEditing ? (
                    <SelectViewContainer isEditing={isConditionEditing}>
                      <SelectView isEditing={isConditionEditing}>
                        <p>Data wydania w Mugo</p>
                        <select
                          disabled={!isConditionEditing}
                          value={TimeToUploadForCustomer}
                          onChange={(e) =>
                            handleChange(e, 'TimeToUploadForCustomer')
                          }
                        >
                          <option value="">Wybierz</option>
                          {TimeToUploadForCustomerEnum.map(
                            (exploitationPhysical) => {
                              const {FieldName, FlagValue} =
                                exploitationPhysical;

                              return (
                                <option value={FlagValue} key={FlagValue}>
                                  {FieldName}
                                </option>
                              );
                            },
                          )}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                  ) : (
                    <ConditionTitleWithInputWrapper
                      isEditing={isConditionEditing}
                    >
                      <p style={{width: 'calc(100% - 80px)'}}>
                        Data wydania w MUGO
                      </p>
                      <input
                        style={{width: '116px'}}
                        disabled={!isConditionEditing}
                        type="date"
                        value={DateToUploadForCustomer.split('T')[0]}
                        onChange={(e) =>
                          handleChange(e, 'DateToUploadForCustomer')
                        }
                      />
                    </ConditionTitleWithInputWrapper>
                  )}
                  <StyledConditionSectionDescription
                    isEditing={isConditionEditing}
                  >
                    <p>
                      W systemie WIN-WIN oprócz procentowego udziału w
                      przychodach, możesz opcjonalnie wybrać gwarantowaną kwotę
                      wynagrodzenia, którą kupujący od razu musi wpłacić na
                      Twoje konto w MUGO Studio. W tym przypadku musisz dopisać
                      oczekiwaną kwotę w polu &quot;Kwota brutto&quot;.
                    </p>
                  </StyledConditionSectionDescription>
                </>
              ) : (
                <StyledConditionSectionDescription
                  isEditing={isConditionEditing}
                >
                  <p>
                    WIN-WIN to nowy, perspektywiczny model współpracy z
                    kupującym, w którym możesz ustalić swój udział w prawach do
                    wyprodukowanego w przyszłości dzieła, na którym będziesz po
                    prostu zarabiać!
                  </p>
                </StyledConditionSectionDescription>
              )}
            </>
          )}
          <ExpandableHeaderWithDescription
            header="Cena usługi, produktu"
            text=""
            isEditing={isConditionEditing}
          >
            <StyledConditionSectionDescription
              style={{flexDirection: 'column'}}
              isEditing={isConditionEditing}
            >
              <ConditionSectionDescriptionExample
                isEditing={isConditionEditing}
              >
                <p>
                  Każda usługa, produkt ma swoją wartość. Podaj kwotę, jakiej
                  oczekujesz od klienta. <br />
                  <br />
                  Kwota brutto? <br />W przypadku firm, to kwota z podatkiem
                  VAT. Natomiast w przypadku osób fizycznych to kwota, jaką
                  otrzymasz od klienta i od której powinieneś odprowadzić
                  należny podatek dochodowy
                </p>
              </ConditionSectionDescriptionExample>
            </StyledConditionSectionDescription>
          </ExpandableHeaderWithDescription>
          {userMetaData.HasIBAN ? (
            <>
              {isConditionEditing ? (
                <ConditionTitleWithInputAndSelectWrapper
                  isEditing={isConditionEditing}
                  style={{marginBottom: '10px'}}
                >
                  <p>Kwota brutto</p>
                  <InputView
                    isEditing={isConditionEditing}
                    style={{
                      marginTop: 'unset',
                      background: 'transparent',
                      border: 'none',
                    }}
                  >
                    <input
                      disabled={!isConditionEditing}
                      placeholder="0"
                      min={0}
                      // onBlur={() => getTransactionFee(grossValue)}
                      style={{paddingLeft: 'unset'}}
                      type="number"
                      value={GrossValue}
                      onChange={(e) => handleChange(e, 'GrossValue')}
                    />
                  </InputView>

                  <SelectView
                    isEditing={isConditionEditing}
                    style={{marginTop: 'unset'}}
                  >
                    <select
                      value={Currency}
                      onChange={(e) => handleChange(e, 'Currency')}
                      disabled={!isConditionEditing}
                    >
                      {CurrencyEnum.map((currency) => {
                        const {FieldName, FlagValue} = currency;

                        if (FlagValue !== 0) return;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {FieldName}
                          </option>
                        );
                      })}
                    </select>
                  </SelectView>
                </ConditionTitleWithInputAndSelectWrapper>
              ) : !isConditionEditing && GrossValue ? (
                <StyledDisplayGrossValueNoEditing>
                  <p>
                    {!isConditionEditing && !userWinWin
                      ? 'Cena (brutto)'
                      : !isConditionEditing &&
                        userWinWin &&
                        'Kwota gwarantowana'}
                  </p>
                  <div>
                    <h5>{GrossValue}</h5>
                    <p>PLN</p>
                  </div>
                </StyledDisplayGrossValueNoEditing>
              ) : (
                !isConditionEditing && !GrossValue && null
              )}

              {userMetaData.IsLegal && (
                <ConditionTitleWithSliderAndSelectWrapper>
                  {isConditionEditing ? (
                    <>
                      <p>Faktura VAT</p>
                      <ConditionSlider
                        value={Invoice}
                        onClick={() => handleBooleanChange('Invoice', !Invoice)}
                      >
                        <div />
                      </ConditionSlider>
                    </>
                  ) : (
                    <CheckboxInfoWrapper
                      style={{
                        marginBottom: 'unset',
                        width: '196px',
                        height: '24px',
                      }}
                    >
                      <p>Faktura VAT</p>
                      <span>{Invoice ? 'Tak' : 'Nie'}</span>
                    </CheckboxInfoWrapper>
                  )}

                  <SelectView
                    isEditing={isConditionEditing}
                    style={{width: '100px', marginTop: 'unset'}}
                  >
                    <select
                      value={Tax}
                      onChange={(e) => handleChange(e, 'Tax')}
                      disabled={!isConditionEditing}
                    >
                      {TaxesEnum.map((tax) => {
                        const {FieldName, FlagValue} = tax;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {FieldName}
                          </option>
                        );
                      })}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#3986ef"
                        />
                      </g>
                    </svg>
                  </SelectView>
                </ConditionTitleWithSliderAndSelectWrapper>
              )}
              {isConditionEditing && (
                <>
                  <ExpandableHeaderWithDescription
                    header="Opłata transakcyjna"
                    text=""
                    isEditing={isConditionEditing}
                  >
                    <StyledConditionSectionDescription
                      style={{flexDirection: 'column'}}
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescriptionExample
                        isEditing={isConditionEditing}
                      >
                        <p>
                          Opłata transakcyjna to kwota, która zostanie doliczona
                          kupującemu, pokrywająca koszty obsługi systemu MUGO.{' '}
                          <br />
                          Kwota nie stanowi przychodu sprzedającego.
                        </p>
                      </ConditionSectionDescriptionExample>
                    </StyledConditionSectionDescription>
                  </ExpandableHeaderWithDescription>
                </>
              )}
              {GrossValue > 0 && (
                <>
                  <StyledDisplayGrossValueNoEditing
                    style={!isConditionEditing ? {marginTop: '10px'} : {}}
                  >
                    <p>Opłata transakcyjna</p>
                    <div>
                      <h5>
                        {Number((TransactionFee?.Brutto / 100).toFixed(2))}
                      </h5>
                      <p>PLN</p>
                    </div>
                  </StyledDisplayGrossValueNoEditing>
                  <StyledDisplayGrossValueWithTaxNoEditing>
                    <p>Razem do zapłaty</p>
                    <div>
                      <h5>
                        {GrossValue
                          ? `${Number(
                              (
                                GrossValue +
                                TransactionFee?.Brutto / 100
                              ).toFixed(2),
                            )}`
                          : '0'}
                      </h5>
                      <p>PLN</p>
                    </div>
                  </StyledDisplayGrossValueWithTaxNoEditing>
                </>
              )}
            </>
          ) : (
            <TextWithLink style={{marginBottom: '40px'}}>
              <p>Załóż konto Mangopay</p>

              <span>
                <Link to={'/producer'} target="_blank">
                  tutaj.
                </Link>
              </span>
            </TextWithLink>
          )}
        </ConditionDetailsWrapper>
      </MobileConditionsContentWrapper>
    </>
  );
};

export const StyledBlueHeaderComponent: React.FC = ({children}) => {
  return (
    <StyledBlueHeaderWrapper>
      <StyledLeftDot />
      <h1>{children}</h1>
    </StyledBlueHeaderWrapper>
  );
};
