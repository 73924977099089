import React from 'react';
import {
  OfferBlockRowCategoryWrapper,
  OfferBlockRowContentWrapper,
  OfferBlockRowDescription,
  OfferBlockRowFooter,
  OfferBlockRowImgWrapper,
  OfferBlockRowNameWrapper,
  OfferBlockRowTypeInfo,
  OfferBlockRowWinWinWrapper,
  OfferBlockRowWrapper,
  OfferEndDataWrapper,
} from './styles';
import {Offer} from '../../RecomendedProductionBlock';
import {DeleteButton} from '../styles';
import styled from 'styled-components';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../../RecomendedProductionBlock/styles';
import {hexToRgb} from '../../../index';
import {useNavigate} from 'react-router-dom';

interface Props {
  isEditing: boolean;
  isMobile: boolean;
  offer: Offer;
  Width: number;
  Height: number;
  fontColor: string;
  bgColor: string;
  productId: string;

  removeOffer: () => void;
}

const OfferWinWinWrapper = styled.div<{
  Width: number;
  Height: number;
  isMobile: boolean;
}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  margin-right: ${({isMobile}) => (isMobile ? '0px' : '5px')};
  width: ${({isMobile}) => (isMobile ? '82px' : '73px')};
  height: 20px;
  gap: 5px;

  & > p {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.35px;
  }
  @media (max-width: 1024px) {
    height: 20px;
    width: auto;
  }
  @media (max-width: 360px) {
    height: 18px;
  }
`;

export const OfferBlockRowView: React.FC<Props> = ({
  isEditing,
  offer,
  Width,
  Height,
  fontColor,
  isMobile,
  bgColor,
  removeOffer,

  productId,
}) => {
  const navigate = useNavigate();
  const toProductInfo = (id: string) => {
    navigate(`/myproducts/product/${id}`);
  };
  console.log(
    'teraz',
    new Date().getTime(),
    'koniec za',
    new Date(offer.AvailabilityEndDate).getTime(),
    new Date().getTime() > new Date(offer.AvailabilityEndDate).getTime(),
  );

  console.log(
    offer.IsActive &&
      new Date().getTime() > new Date(offer.AvailabilityEndDate).getTime()
      ? 'promowana'
      : 'wygasła/wyfocna',
  );

  return (
    <OfferBlockRowWrapper isMobile={isMobile} bgColor={bgColor}>
      {isEditing && (
        <OfferEndDataWrapper onClick={() => toProductInfo(offer.ProductId)}>
          {new Date().getTime() >
            new Date(offer.AvailabilityEndDate).getTime() ||
          (!offer.IsActive &&
            offer.Name ===
              'Ilustracja wygenerowana przez sztuczną inteligencję') ? (
            <p>Oferta wygasła Kliknij aby odnowić</p>
          ) : null}
        </OfferEndDataWrapper>
      )}
      <OfferBlockRowImgWrapper
        isMobile={isMobile}
        Height={Height}
        Width={Width}
      >
        <img src={offer.CoverFile} alt="CoverFile" />
      </OfferBlockRowImgWrapper>
      <OfferBlockRowContentWrapper isMobile={isMobile}>
        {/* <OfferBlockRowTypeInfo style={{color: `rgb(${fontColor})`}}>
          {offer.ProductType === 1
            ? 'Produkt cyfrowy'
            : offer.ProductType === 2
            ? 'Produkt fizyczny'
            : offer.ProductType === 4
            ? 'Usługa'
            : offer.ProductType === 8
            ? 'Track'
            : ''}
        </OfferBlockRowTypeInfo> */}
        <OfferBlockRowNameWrapper
          isMobile={isMobile}
          style={{color: `rgb(${fontColor})`}}
          Width={Width}
        >
          {offer.Name}
        </OfferBlockRowNameWrapper>

        {!isMobile && (
          <OfferBlockRowDescription style={{color: `rgb(${fontColor})`}}>
            {offer.Description}
          </OfferBlockRowDescription>
        )}

        <OfferBlockRowFooter>
          <OfferBlockRowWinWinWrapper
            isMobile={isMobile}
            Width={Width}
            Height={Height}
          >
            <p style={{color: `rgb(${fontColor})`}}>{`od ${
              offer.GrossValue / 100
            }zł`}</p>
            {offer.IsWinWin && (
              <OfferWinWinWrapper
                isMobile={isMobile}
                Height={Height}
                Width={Width}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71.396"
                  height="18"
                  viewBox="0 0 71.396 18"
                >
                  {/* <defs className="BUG">
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_3780"
                      data-name="Rectangle 3780"
                      width="71.396"
                      height="18"
                      fill="#fb9614"
                    />
                  </clipPath>
                </defs> */}
                  <g
                    id="Group_6972"
                    data-name="Group 6972"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_4111"
                      data-name="Path 4111"
                      d="M212.43,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52h-1.492l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868L206,39.406h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72h2.145l3.439-7.155Z"
                      transform="translate(-175.818 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4112"
                      data-name="Path 4112"
                      d="M274.581,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                      transform="translate(-235.707 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4113"
                      data-name="Path 4113"
                      d="M298.82,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                      transform="translate(-252.366 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4114"
                      data-name="Path 4114"
                      d="M349.151,61.521h2.481l.267-1.5h-2.481Z"
                      transform="translate(-301.107 -51.76)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4115"
                      data-name="Path 4115"
                      d="M379.471,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52H375.5l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868l-.247-2.54h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.108,1.235.178,1.868l.188,1.72H378.1l3.439-7.155Z"
                      transform="translate(-319.874 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4116"
                      data-name="Path 4116"
                      d="M441.621,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                      transform="translate(-379.762 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4117"
                      data-name="Path 4117"
                      d="M465.86,39.406l-.356,2.036c-.129.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.711-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.128-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                      transform="translate(-396.421 -33.984)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4118"
                      data-name="Path 4118"
                      d="M21.281,2.8H19.886L18.829,1.746h-3.5L18.91,5.33l.981-.05h1.391a.964.964,0,0,1,.963.963v5.5a.964.964,0,0,1-.962.963l-3.769,0-2.536,2.536a.964.964,0,0,1-1.362,0L9.723,11.352a.966.966,0,0,1-.221-1.02l6.9-.22.346-.346a3.44,3.44,0,0,0,0-4.865L12.858,1.009a3.44,3.44,0,0,0-4.865,0l-1.81,1.81-2.743,0A3.444,3.444,0,0,0,0,6.256v5.5A3.444,3.444,0,0,0,3.44,15.2H4.622l1.214,1.214h3.5L5.6,12.672l-1.04.052H3.44a.964.964,0,0,1-.963-.963v-5.5a.964.964,0,0,1,.962-.963l3.769,0L9.744,2.76a.963.963,0,0,1,1.362,0L15,6.652a.965.965,0,0,1,.221,1.02l-6.9.22-.346.346a3.44,3.44,0,0,0,0,4.865L11.863,17a3.444,3.444,0,0,0,4.865,0l1.81-1.81,2.743,0a3.444,3.444,0,0,0,3.44-3.44v-5.5a3.444,3.444,0,0,0-3.44-3.44"
                      transform="translate(0 -0.001)"
                      fill="#fb9614"
                    />
                  </g>
                </svg>
              </OfferWinWinWrapper>
            )}
          </OfferBlockRowWinWinWrapper>
        </OfferBlockRowFooter>
        <OfferBlockRowCategoryWrapper Width={Width} Height={Height}>
          <p style={{color: `rgb(${fontColor})`, paddingRight: '5px'}}>
            {offer.Category === null ? '' : offer.Category.Name}
            {offer.SecondCategoryId === null ? '' : ' -  '}
          </p>
          <p style={{color: `rgb(${fontColor})`}}>
            {offer.SecondCategoryId === null ? '' : offer.SecondCategoryId.Name}
            {/* {offer.ThirdCategoryId === null ? '' : ' - '} */}
          </p>
          {/* <p style={{color: `rgb(${fontColor})`}}>
            {offer.ThirdCategoryId === null ? '' : offer.ThirdCategoryId.Name}
          </p> */}
        </OfferBlockRowCategoryWrapper>
      </OfferBlockRowContentWrapper>
    </OfferBlockRowWrapper>
  );
};
