import React, {useState, useEffect, useContext, useRef} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams} from 'react-router-dom';
import PopupButton from 'components/PopUp/PopupButton';
import {LoadingCircle} from 'utils/loadingCircle';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {ExpandableDescription} from 'components/ExpandableDescription';
import {
  TrackPlaceholderSvg,
  DigitalProductPlaceholderSvg,
  ServicePlaceholderSvg,
} from 'components/SvgComponents';

import arrowRight from 'images/svg/select-arrow-right.svg';
import noImage from 'images/noimage.png';
import {
  CurrencyEnum,
  ExploitationDigitalEnum,
  ExploitationPhysicalEnum,
  ExploitationYoutubeEnum,
  ProductTypeEnum,
  ProtectiveUmbrellaEnum,
  RightsEndEnum,
  TimeToFinishEnum,
  TaxesEnum,
  TimeToUploadForCustomer,
} from 'constants/conditionEnums';

import {ProducerDataContext} from 'contexts/ProducerDataContext';
import api from 'services/axiosConfig';
import useAlert from 'hooks/useAlert';
import {ErrorStar} from 'components/ErrorStar';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';

import {
  BasicDataContent,
  BasicDataHeader,
  BasicDataWrapper,
  ConditionBlockHeader,
  ConditionBlockWrapper,
  ConditionCheckbox,
  ConditionContent,
  ConditionContentHeader,
  ConditionHeaderTitle,
  ConditionMainContent,
  ConditionSecondBlockWrapper,
  ConditionSelectWrapper,
  ConditionThirdBlockWrapper,
  ConditionWinWinOfferWrapper,
  DescriptionWrapper,
  FirstSection,
  Grid,
  HeaderButton,
  InlineSpan,
  InputWrapper,
  Main,
  MainHeaderWrapper,
  MainWrapper,
  MobileConditionBlockWrapper,
  MobileConditionMainContent,
  MobileFirstSection,
  MobileInputWithSelectSpan,
  MobileSelectSpan,
  MobileSliderWrapper,
  MobileTitle,
  NameWrapper,
  SectionSelectSpan,
  SectionTextWrapper,
  SectionTextareaWrapper,
  SectionWrapper,
  SelectWrapper,
  SingleConditionWrapper,
  WinWinOfferCheckbox,
  WinWinOfferSectionTextWrapper,
  WinWinOfferSectionWrapper,
  ProductsWrapper,
  ProductsHeader,
  InfoIcon,
  ProductsContent,
  SingleProductWrapper,
  ClientSelectWrapper,
  InvoiceInlineSpan,
  MobileInvoiceInlineSpan,
  PriceSectionWrapper,
  TwoInputsSpan,
  WinWinSectionTextWrapper,
  WinWinSectionWrapper,
  StyledTextarea,
  TextareaWrapper,
} from './styles';

import {
  ConditionOption,
  FieldOfUseData,
  Props,
  TakenFieldOfUse,
} from './interfaces';
import {ExpandableDescriptionOneLine} from 'components/ExpandableDescriptionOneLine';
import {ExpandableHeaderWithDescription} from 'components/ExpandableHeaderWithDescription';
import {ExpandableArrowWithDescription} from 'components/ExpandableArrowWithDescription';

export const ExpandableHeaderWithDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 326px;

    & > div {
      width: 326px;
    }
  }
`;

export const MobileSaveConditionButton = styled.button`
  width: 312px;
  height: 44px;
  border: none;
  border-radius: 16px;
  background: #0091ff;
  margin: 38px auto 0px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.07px;
  color: white;
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  background: ${({theme}) => theme.colors.mobileConditionDetailBackground};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileMain = styled.div`
  width: 367px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    padding: 8px;
    gap: 5px;
  }
`;

export const SaveConditionButton = styled.div`
  width: 44px;
  height: 44px;
  background: #3a87f0;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  cursor: pointer;
`;

export const SaveConditionButtonWrapper = styled.div`
  z-index: 2;
  position: absolute;
  right: 10px;
  bottom: 10px;

  & > button {
    width: 44px;
    height: 44px;
    background: #3a87f0;
    border: 1px solid rgba(112, 112, 112, 0.3);
    border-radius: 15px;
    display: flex;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  }
`;

export const ArrowButtonLeft = styled.button`
  position: absolute;
  top: 50%;
  width: 19px;
  height: 19px;
  /* transform: translateY(-50%); */
  background: #0091ff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10px;
  top: 16px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px 24px 24px 24px;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: ${({theme}) => theme.colors.pageHeader};
  box-shadow: 0px 15px 30px ${({theme}) => theme.colors.blackBoxShadow};
`;

export const Header = styled.div`
  width: 100%;
  height: 55px;
  position: relative;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.headerBlackFont};
  }
`;

export const DeleteConditionButton = styled.div`
  background: #0091ff;
  border-radius: 15px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);

  & > button {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 105px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 24px 24px;
  padding: 24px 154px 0px;

  & > p {
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 24px;
  }
`;

export const BlueButton = styled.button`
  width: 100%;
  min-height: 44px;
  border-radius: 16px;
  border: none;
  background: #0091ff;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.07;
  color: #ffffff;
`;

export const MobileBlueButton = styled.button`
  width: 312px;
  min-height: 44px;
  border-radius: 16px;
  border: none;
  background: transparent;
  border: 2px solid #0091ff;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.07;
  color: #0091ff;

  & p {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.07;
    color: #0091ff;
  }
`;

export const SingleColumnWrapper = styled.div<{isEditing?: boolean}>`
  width: 100%;
  height: 571px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 24px 24px 24px 24px;
  box-shadow: ${({theme, isEditing}) =>
    isEditing
      ? `0px 15px 30px ${theme.colors.headerBoxShadow}`
      : `0px 15px 30px ${theme.colors.blackBoxShadow}`};
  border: 1px solid
    ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.columnBorder};
`;

export const SecondColumnWrapper = styled(SingleColumnWrapper)`
  position: relative;
`;

export const SingleColumnHeader = styled.div<{isEditing?: boolean}>`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid
    ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.columnBorder};
  border-radius: 24px 24px 0px 0px;

  & > p {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    letter-spacing: -0.15px;
    color: ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.grayFontOp100};
  }
`;

export const MobileAssignedProduct = styled.div`
  width: 100%;
  height: 116px;
  border-radius: 14px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: ${({theme}) => theme.colors.headerContentBackground};
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  & > img {
    width: 102px;
    height: 102px;
    margin-left: 8px;
    margin-right: 12px;
    border-radius: 9px;
  }

  & > svg {
    margin-left: 32px;
    margin-right: 24.5px;
  }

  & > div {
    position: relative;
    height: 100%;
    display: flex;
    width: 284px;
    flex-direction: column;

    & > p {
      font-size: 11px;
      margin-top: 11px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.05px;
    }

    & > h5 {
      font-size: 18px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      width: 236px;
      height: 42px;
      /* display: flex;
      align-items: center; */
      letter-spacing: -0.15px;
      font-weight: bold;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & > svg {
      position: absolute;
      top: 12.5px;
      right: 13px;
    }

    & > button {
      width: 12px;
      height: 13px;
      background: transparent;
      border: none;
      position: absolute;
      cursor: pointer;
      bottom: 17px;
      right: 18.8px;
    }
  }
`;

export const StyledStatusWrapper = styled.h2<{ProductDisplayStatus?: number}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
          return css`
            color: ${theme.colors.statusNameBlack};
          `;
        case 1:
          return css`
            color: #00d10f;
          `;
        case 3:
        case 4:
          return css`
            color: #0091ff;
          `;
        case 2:
        case 5:
          return css`
            color: #fb9614;
          `;
        case 6:
          return css`
            color: #ff0300;
          `;

        default:
          return css`
            color: #fb9614;
          `;
      }
    }}
  }
`;

export const StyledStatusLine = styled.div<{ProductDisplayStatus?: number}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    border-radius: 25px;
    height: 3px;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
          return css`
            background: ${theme.colors.statusLineBlack};
            width: 30px;
          `;
        case 1:
          return css`
            background: #00d10f;
            width: 60px;
          `;
        case 3:
        case 4:
          return css`
            background: #0091ff;
            width: 220px;
          `;
        case 2:
        case 5:
          return css`
            background: #fb9614;
            width: 155px;
          `;
        case 6:
          return css`
            background: #ff0300;
            width: 220px;
          `;

        default:
          return css`
            background: #fb9614;
            width: 155px;
          `;
      }
    }}
  }
`;

export const MobileConditionDetailHeader = styled.h1`
  font-weight: 400;
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 29px auto;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
`;

export const SingleColumnContentWrapper = styled.div`
  width: 100%;
  height: 516px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 24px 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
  }
`;

export const FirstColumnContentWrapper = styled(SingleColumnContentWrapper)`
  padding: 19px 11px 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h5 {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 18px;
    text-align: center;
    width: 289px;
  }

  & > i {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 18px;
    text-align: center;
  }

  & > p {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    line-height: 18px;
    text-align: right;
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: unset;
    border-radius: unset;
    overflow: unset;
  }
`;

export const ThirdColumnContentWrapper = styled(SingleColumnContentWrapper)`
  align-items: flex-start;
  padding: 19px 22px;

  & > p {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 18px;
  }

  & > h5 {
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 18px;
  }

  & > h4 {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.25px;
    text-align: center;
    color: ${({theme}) => theme.colors.grayFontOp100};
    line-height: 24px;
  }
`;

export const AssignedProductsColumnContentWrapper = styled(
  SingleColumnContentWrapper,
)`
  padding: 15px 18px 0px;

  & > p {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.25px;
    color: #352f40;
    line-height: 18px;
  }

  & > h5 {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.25px;
    color: #352f40;
    line-height: 18px;
  }

  & > h4 {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.25px;
    text-align: center;
    color: #352f40;
    line-height: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: unset;
    margin-top: 38px;
  }
`;

export const ConditionBannerWrapper = styled.div`
  width: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 99px;

  & > h5 {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.15px;
    text-align: center;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 21px;
    margin-top: 14px;
  }

  & > p {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.05px;
    text-align: center;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 18px;
    margin-top: 9px;
  }

  & path {
    fill: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const StyledSelectView = styled(SelectView)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  border-radius: 5px;
  background: ${({theme}) => theme.colors.bgWhite};
  border: solid 1px ${({theme}) => theme.colors.fontBlackOp15};

  & > p {
    position: unset;
    font-size: 13px;
  }

  & select {
    width: unset;
    border: none;
    font-size: 13px;
    border-radius: 5px;
    background: ${({theme, isError}) =>
      !isError ? theme.colors.textareaBackground : theme.colors.statusRed};
  }
`;

export const StyledInputView = styled(InputView)`
  & input {
    border-radius: 5px;
    background: ${({theme, isError}) =>
      !isError ? theme.colors.textareaBackground : theme.colors.statusRed};
  }
`;

export const CheckboxInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(225, 225, 225);
  margin-bottom: 5px;

  & > span {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.625px;
    margin-right: 8.5px;
  }

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-size: 11px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 0.625px;
    margin-left: 8.5px;
  }
`;

export const TextWithLink = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
  margin-bottom: 19px;

  & > p {
    font-size: 9px;
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-weight: 400;
  }

  & > span {
    font-size: 9px;
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-weight: 400;
    text-decoration: underline;

    & > a {
      color: ${({theme}) => theme.colors.grayFontOp100};
    }
  }
`;

export const DeleteButtonWrapper = styled.div`
  width: 55px;
  height: 100%;
  position: absolute;
  right: 55px;

  & > button {
    width: 55px;
    height: 100%;
    border: none;
    background: #92c5ff;
    border-radius: 0 9px 0 0;
    border-left: 1px solid white;
    cursor: pointer;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    color: #352f40;
    transition: all 0.15s;

    &:hover {
      background: rgba(248, 248, 248, 0.15);
    }
  }
`;

export const SelectWithTitle = styled.div`
  width: 312px;
  height: 22px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11.5px;
  padding-right: 7.5px;

  & > p {
    font-size: 11px;
    letter-spacing: 0.625px;
    font-weight: 400;
    color: #07020f;
  }

  & > select {
    width: 100px;
    background: transparent;
    border: none;
    outline: none;
    text-align: center;
    color: #07020f;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.625px;
    appearance: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 94px 2px;
    cursor: pointer;

    & > option {
      background: white;
    }
  }

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    color: #7270f2;
  }
`;

export const TextareaLettersCount = styled.h4`
  font-size: 11px;
  position: absolute;
  right: 9px;
  bottom: 8px;
  color: #3a87f0;
  font-weight: 400;
  letter-spacing: 0.625px;
`;

export const InputWithTitle = styled.div<{haveError?: boolean}>`
  width: 100%;
  height: 22px;
  border-radius: 5px;
  border: ${({haveError}) =>
    haveError ? `1px solid #FF0300` : '1px solid rgba(0,0,0,0.12)'};
  margin-bottom: ${({haveError}) => (haveError ? `0px` : '9px')};
  background: ${({haveError}) => (haveError ? `#FF7E7D` : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11.5px;
  padding-right: 7.5px;

  & > p {
    font-size: 11px;
    letter-spacing: 0.625px;
    font-weight: 400;
    color: #07020f;
  }

  & > input {
    width: calc(100% - 60px);
    background: transparent;
    border: none;
    outline: none;
    text-align: right;
    color: rgba(7, 2, 15, 0.5);
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }
  }

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    color: #7270f2;
  }
`;

export const BlockContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.86);
    line-height: 21px;
  }
`;

export const SecondBlockContent = styled(BlockContent)`
  padding: 14px 23px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
`;

export const ThirdBlockSelectSpan = styled.div`
  display: grid;
  width: 100%;
  padding: 21px 11px 0px;
  grid-template-columns: 396px 148px;
  justify-content: space-between;

  @media (max-width: 1045px) {
    padding: 21px 0 0;
    grid-template-columns: 1fr;
    gap: 8px;

    & > button {
      width: 144px;
      margin: 0 auto;
    }
  }
`;

export const DisplayDataWrapper = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    color: white;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
`;

export const BlockButton = styled.button`
  cursor: pointer;
  background: transparent;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  color: white;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.15s;
  margin-right: 14px;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 1045px) {
    margin-right: 0;
  }
`;

export const BlockSpanInColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2/4;
  gap: 3px;

  @media (max-width: 1045px) {
    gap: 11px;
    grid-column: span 1;
  }
`;

export const BlockSpanInOneColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  gap: 3px;

  @media (max-width: 1045px) {
    gap: 11px;
    grid-column: span 1;
  }
`;

export const BackButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  background: #92c5ff;
  border-radius: 9px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  border-right: 1px solid white;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const UpdateButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  background: #92c5ff;
  border-radius: 0 9px 0 0;
  border-left: 1px solid white;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const UpdateButtonWrapper = styled.div`
  width: 55px;
  height: 100%;
  position: absolute;
  right: 0px;

  & > button {
    width: 100%;
    height: 100%;
    border: none;
    background: #92c5ff;
    border-radius: 0 9px 0 0;
    border-left: 1px solid white;
    cursor: pointer;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    color: #352f40;
    transition: all 0.15s;

    &:hover {
      background: rgba(248, 248, 248, 0.15);
    }
  }
`;

export const MobileFirstConditionStepHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 20px;
  margin-top: 30px;

  & > p {
    font-size: 13px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const ConditionDetailsWrapper = styled.div`
  height: calc(100%);
  padding: 0 5px;
  overflow-y: auto;
  width: 101%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: unset;
    width: 100%;
  }
`;

export const DeleteTagButton = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: #ff7e7d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const AddTagButton = styled.div`
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0091ff;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SellerObligationWrapper = styled.div<{isEditing?: boolean}>`
  width: ${({isEditing}) => (isEditing ? '259px' : '100%')};
  margin-left: auto;
  margin-right: auto;
  /* min-height: 25px; */
  position: relative;
  text-align: left;
  /* padding-left: 4px; */
  gap: 16px;
  padding-right: 1px;
  padding-left: 6px;
  border-radius: 5px;
  background: transparent;
  border: ${({isEditing, theme}) =>
    isEditing ? `1px solid ${theme.colors.fontBlackOp15}` : `none`};
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({isEditing, theme}) =>
    isEditing
      ? css`
          & > p {
            width: 93%;
            font-weight: 400;
            letter-spacing: 0.1px;
            line-height: 20px;
            font-size: 11px;
            color: ${({theme}) => theme.colors.fontBlackOp100};
          }

          & > svg {
            position: absolute;
            cursor: pointer;
            top: 2px;
            left: 4px;
          }
        `
      : css`
          & > p {
            width: 93%;
            font-weight: 400;
            letter-spacing: -0.25px;
            line-height: 18px;
            font-size: 13px;
            color: ${({theme}) => theme.colors.fontBlackOp100};
          }

          & > svg {
            position: absolute;
            top: 5px;
            left: 12px;

            /* & path {
              fill: ${theme.colors.conditionDeclarationSvg};
            } */
          }
        `}

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* padding-left: 0px; */

    & > p {
      width: 100% !important;
    }
  }
`;

export const AddSellerObligationInputWrapper = styled.div`
  width: calc(100% - 30px);
  background: transparent;
  /* margin-bottom: 5px; */

  & > input {
    text-align: left;
    border: none;
    outline: none;
    background: transparent;
    padding-left: 6px;
    padding-right: 9px;
    width: 100%;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};

    &::placeholder {
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 20px;
      font-size: 11px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const AddSellerObligationWrapper = styled.div`
  width: 259px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: left;
  padding-right: 1px;
  /* padding-bottom: 24px; */
  margin-bottom: 18px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.borderInsideForEditBtn};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 4px;
  }
`;

export const ConditionSmallSectionDescription = styled.div<{
  isEditing: boolean;
}>`
  width: 100%;
  display: flex;
  /* padding-right: 15px; */
  margin-bottom: 17.5px;
  align-items: center;
  justify-content: center;
  position: relative;
  display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};

  & > p {
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const ConditionTitleWithFullSelectWrapper = styled.div`
  width: 100%;
  height: 22px;
  margin-bottom: 14px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.5px;
  border-radius: 5px;
  padding: 0 7px 0 11.5px;
  background: rgba(93, 93, 97, 0.3);

  & > p {
    width: calc(100% - 211px);
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 15px;
    color: white;
  }

  & > select {
    width: 207px;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    text-align: right;
    color: white;
    padding: 0 8px;
    margin-right: 4px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.625px;
    appearance: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 199px 6px;
    cursor: pointer;

    & > option {
      background: #060817;
    }
  }
`;

export const ConditionTitleWithSelectWrapper = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.5px;

  & > p {
    width: calc(100% - 211px);
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 15px;
    color: white;
  }

  & > select {
    width: 207px;
    height: 100%;
    background: transparent;
    border: none;
    border-radius: 5px;
    background: rgba(93, 93, 97, 0.3);
    outline: none;
    text-align: left;
    color: white;
    padding: 0 14px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.625px;
    appearance: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 190px 6px;
    cursor: pointer;

    & > option {
      background: #060817;
    }
  }
`;

export const ConditionTitleWithInputWrapper = styled.div<{isEditing?: boolean}>`
  width: 259px;
  height: 23px;
  display: flex;
  gap: 13px;
  /* margin-bottom: 14px; */
  margin-left: auto;
  margin-bottom: 12px;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;

  ${({isEditing}) =>
    isEditing
      ? css`
          & > p {
            width: calc(100%);
            text-align: left;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 0.05px;
            line-height: 15px;
            color: ${({theme}) => theme.colors.fontBlackOp100};
          }
        `
      : css`
          & > p {
            width: calc(100%);
            text-align: left;
            font-weight: 400;
            font-size: 11px;
            letter-spacing: 0.05px;
            line-height: 15px;
            color: ${({theme}) => theme.colors.fontBlackOp50To100};
          }
        `}

  ${({isEditing}) =>
    isEditing
      ? css`
          & > input {
            color: ${({theme}) => theme.colors.fontBlackOp50To100};
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 0.1px;
          }
        `
      : css`
          & > input {
            font-size: 11px;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: ${({theme}) => theme.colors.fontBlackOp100};
          }
        `}

  & > input {
    height: 22px;
    width: ${({isEditing}) => (isEditing ? '58px' : '36px')};
    padding-right: unset;
    background: transparent;
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
    border-radius: 5px;
    background: transparent;
    outline: none;
    text-align: right;
    padding: ${({isEditing}) => (isEditing ? '0 10px' : 'unset')};

    &:disabled {
      border: none;
      border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
      border-radius: 0px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 308px;

    & > p {
      font-size: 13px;
    }

    & > input {
      font-size: 13px;
    }
  }
`;

export const ConditionTitleWithInputAndSelectWrapper = styled.div<{
  isEditing?: boolean;
}>`
  width: 259px;
  height: 23px;
  display: flex;
  gap: 13px;
  /* margin-bottom: 14px; */
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;

  & > p {
    width: calc(100% - 80px);
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > div {
    width: 58px;
    height: 22px;

    & > input {
      width: 58px;
      padding-right: unset;
      background: transparent;
      border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
      border-radius: 5px;
      background: transparent;
      outline: none;
      text-align: right;
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.1px;
    }

    & > select {
      padding: 0px 10px;
      width: 58px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
  }

  /* & > select {
    width: 100px;
    height: 100%;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    outline: none;
    text-align: left;
    border-radius: 5px;
    background: transparent;
    color: #352f40;
    padding: 0 8px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.625px;
    appearance: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 87px 6px;
    cursor: pointer;

    & > option {
      background: #060817;
    }
  } */
`;

export const DisplayGrossValueNoEditing = styled.div`
  width: 259px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

  & > p {
    text-align: left;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.05px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp50To100};
  }

  & > div {
    display: flex;
    gap: 16.5px;

    & > h5 {
      font-size: 11px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }

    & > p {
      font-size: 11px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 308px;

    & > p {
      font-size: 13px;
    }

    & > div {
      & > h5 {
        font-size: 13px;
      }

      & > p {
        font-size: 13px;
      }
    }
  }
`;

export const DisplayGrossValueWithTaxNoEditing = styled(
  DisplayGrossValueNoEditing,
)`
  margin-top: 15px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 13px;
    font-weight: bold;
  }
`;

export const ConditionSlider = styled.div<{
  value: boolean;
  isDisabled?: boolean;
}>`
  width: 34px;
  height: 20px;
  border-radius: 15px;
  border: 1px solid
    ${({value, isDisabled, theme}) =>
      isDisabled
        ? 'rgba(93,93,97,0.3)'
        : value
        ? '#3A87F0'
        : theme.colors.fontBlackOp100};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-out;
  position: relative;
  cursor: pointer;

  & > div {
    width: 14px;
    transition: all 0.15s ease-out;
    height: 14px;
    border-radius: 50%;
    background: ${({value, isDisabled, theme}) =>
      isDisabled
        ? 'rgba(93,93,97,0.3)'
        : value
        ? '#3A87F0'
        : theme.colors.fontBlackOp100};
    position: absolute;
    right: ${({value}) => (value ? '3.5px' : '15.5px')};
  }
`;

export const InputErrorMessage = styled.p<{
  isActive: boolean;
}>`
  color: #d62220;
  font-size: 10px;
  line-height: 21px;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  margin-bottom: ${({isActive}) => (isActive ? '0px' : '5px')};
  display: ${({isActive}) => (isActive ? 'flex' : 'none')};

  @media (max-width: 1100px) {
    font-size: 7px;
    line-height: 14px;
  }
`;

export const ConditionTitleWithSliderAndSelectWrapper = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  margin-bottom: 14px;
  gap: 3px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.5px;

  & > p {
    width: calc(100% - 140px);
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 15px;
    color: #352f40;
  }

  & > select {
    width: 100px;
    height: 100%;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    outline: none;
    text-align: left;
    border-radius: 5px;
    background: transparent;
    color: #352f40;
    padding: 0 8px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.625px;
    appearance: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 87px 6px;
    cursor: pointer;

    & > option {
      background: #060817;
    }
  }
`;

export const AssignedProductsWrapper = styled.div`
  width: 297px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  overflow-y: auto;
  gap: 9px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h5 {
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: #707070;
  }

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.625px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: unset;
    width: 100%;
  }
`;

export const SingleAssignedProductWrapper = styled.div`
  width: 100%;
  min-height: 92px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.fileBorderColor};
  background: ${({theme}) => theme.colors.fileBackgroundColor};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.15s;

  &:hover {
    border: 1px solid #3986ef;
  }

  &:active {
    border: 1px solid #3986ef;
    filter: brightness(0.95);
  }

  & > img {
    margin-right: 11px;
    height: 100px;
    width: 100px;
    border-radius: 9px;
  }
`;

export const SingleAssignedProductContentWrapper = styled.div`
  width: 169px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    /* line-height: 36px; */
  }

  & > p {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: -0.25px;
    color: #0091ff;
    line-height: 24px;
  }
`;

export const TrackPlaceholderSvgWrapper = styled.div`
  margin-right: 11px;
  height: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const MobileTrackPlaceholderSvgWrapper = styled.div`
  width: 102px !important;
  height: 102px !important;
  margin-left: 18px !important;
  margin-right: 18px !important;
  border-radius: 9px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  & > svg {
    position: unset !important;

    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const ConditionTextarea = styled.textarea`
  width: 100%;
  height: 70px;
  background: transparent;
  border-radius: 5px;
  padding: 5.5px 11.5px;
  color: rgba(7, 2, 15, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 11px;
  line-height: 24px;
  resize: none;
  outline: none;
  font-weight: 400;
  background: transparent;

  &::placeholder {
    color: rgba(7, 2, 15, 0.5);
  }
`;

export const WinWinInfo = styled.div`
  height: 20px;
  width: 82px;
  border: 1px solid #fb9614;
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  & > p {
    color: #fb9614;
    font-size: 9px;
    font-weight: 400;
  }

  & > img {
    width: 12.35px;
    height: 8px;
  }

  @media (max-width: 1100px) {
    margin-right: unset;
  }
`;

export const AddSellerObligationButton = styled.button`
  cursor: pointer;
  background: #89bfff;
  height: 19px;
  width: 80px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0.625px;
  font-weight: 500;
  transition: all 0.15s;
  text-align: center;
`;

export const SelectViewContainer = styled.div<{isEditing?: boolean}>`
  width: 259px;
  margin-top: 0px;
  margin-bottom: ${({isEditing}) => (isEditing ? '21px' : '12px')};
  margin-left: auto;
  margin-right: auto;

  & > div {
    margin-top: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 308px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      border-radius: 5px;
      border: solid 1px ${({theme}) => theme.colors.fontBlackOp15};

      & > p {
        position: unset;
        font-size: 13px;
      }

      & > select {
        width: 55%;
        border: none;
        font-size: 13px;
      }
    }

    ${({isEditing, theme}) =>
      !isEditing &&
      css`
        & > div {
          display: flex;
          justify-content: space-between;
          border: none;
          border-radius: 0px;
          border-bottom: solid 1px ${theme.colors.fontBlackOp15};

          & > p {
            font-size: 13px;
            position: unset;
          }

          & > select {
            font-size: 13px;
            border-bottom: unset;
          }
        }
      `}
  }
`;

export const SectionHeaderNoEditing = styled.div`
  width: 259px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 19px; */
  margin-bottom: 12px;

  & > p {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 308px;
  }
`;

export const ConditionSectionDescription = styled.div<{isEditing: boolean}>`
  width: 100%;
  display: flex;
  padding: 0 15px;
  margin-bottom: 22px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};

  & > p {
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
  & > span {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const ConditionSectionDescriptionExample = styled.div<{
  isEditing: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  & > p {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  & > span {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > h5 {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const SmallSectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  justify-content: center;
  text-align: center;

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const SmallSectionTitleWithWinWinAndSlider = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 170px 82px 34px;
  align-items: center;
  margin-bottom: 19px;
  justify-content: space-between;
  text-align: left;

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const MobileSectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  & > h2 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > div {
    position: unset;
  }
`;

export const DisplayInputWrapper = styled.div`
  width: 100%;
  height: 19.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  margin-bottom: 20px;

  & > h5 {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }

  & > p {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.1px;
    text-align: right;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 28px;
    margin-bottom: 10px;
  }
`;

export const DisplayTextareaWrapper = styled.div`
  width: 100%;
  min-height: 19.5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);

  & > h5 {
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    position: absolute;
    left: 0;
    top: 0;
  }

  & > p {
    width: 100%;
    text-indent: 50px;
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.1px;
    text-align: right;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;

    & > h5 {
      position: unset;
    }

    & > p {
      text-indent: unset;
      text-align: left;
    }
  }
`;

export const SmallSectionTitleWithSlider = styled.div`
  width: 259px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-bottom: 9px;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  & > h5 {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const DateHeader = styled.h5`
  text-align: left;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.625px;
  line-height: 15px;
  color: #707070;
  margin-bottom: 10px;
`;

export const LeftSection = styled.div`
  padding: 10px 20px 7px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: auto;
  width: 101%;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > h1 {
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    font-weight: 700;
    color: #352f40;
    text-align: center;
  }

  & > h2 {
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 18px;
    font-weight: 700;
    color: #352f40;
    text-align: justify;
  }

  & > p {
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 14px;
    font-weight: 400;
    color: rgba(53, 47, 64, 0.7);
    text-align: left;
  }
`;

export const CenterSection = styled.div`
  width: 100%;
  height: calc(100% - 10px - 14px);
  margin-top: 10px;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  /* border-left: 1px solid rgba(255, 255, 255, 0.21); */
  /* border-right: 1px solid rgba(255, 255, 255, 0.21); */

  @media (max-width: 1100px) {
    border-left: none;
    border-right: none;
    border-top: 1px solid rgba(255, 255, 255, 0.21);
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    padding: 8px 14px;
  }
`;

export const RightSection = styled.div`
  padding: 10px 7px 0px;
  /* margin: 8px 8px 0px 0px; */
  display: flex;
  height: calc(100% - 14px);
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }

  @media (max-width: 1100px) {
    margin: 0px 8px;
  }
`;

export const DisplayConditionDescriptionWrapper = styled.div<{
  lettersCount?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 27px;
  position: relative;

  & > h5 {
    color: #707070;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.625px;
    font-weight: 400;
    padding-left: 9px;
    border-bottom: solid 1px #e1e1e1;
  }

  & > p {
    /* display: inline-block; */
    /* width: 100%; */
    color: #07020f;
    font-size: 11px;
    line-height: 34px;
    font-weight: 400;
    padding: 0px 9px;
    /* border-bottom: 1px solid #e1e1e1; */
  }

  & > div {
    width: 100%;
    height: 0px;
    position: absolute;
    border-bottom: 1px solid #e1e1e1;

    &:first-of-type {
      display: ${({lettersCount}) => (lettersCount > 0 ? 'unset' : 'none')};
      top: 55px;
    }

    &:nth-of-type(2) {
      display: ${({lettersCount}) => (lettersCount > 58 ? 'unset' : 'none')};
      top: 86px;
    }

    &:nth-of-type(3) {
      display: ${({lettersCount}) => (lettersCount > 116 ? 'unset' : 'none')};
      top: 120px;
    }

    &:nth-of-type(4) {
      display: ${({lettersCount}) => (lettersCount > 174 ? 'unset' : 'none')};
      top: 156px;
    }
  }
`;

interface CoverFile {
  FilePath: string;
  Id: string;
  Name: string;
  ProductId: string;
  Position: number;
}

interface AssignedProducts {
  AvailabilityEndDate: string;
  Id: string;
  Name: string;
  ProductType: number;
  CoverFiles: CoverFile[];
  ProductDisplayStatus: number;
}

interface ErrorVerification {
  nameError: string | null;
  categoryError: string | null;
}

export interface UserMetaData {
  IsLegal: boolean;
  HasIBAN: boolean;
  HasMugoLabel: boolean;
}

export interface TransactionFee {
  Brutto: number;
  Name: string;
  Netto: number;
  Tax: number;
  TaxTotal: number;
}

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getProductDisplayStatusDescription = (status: number) => {
  switch (status) {
    case 0:
      return 'Draft';
    case 1:
      return 'Gotowa';
    case 2:
      return 'Oferta gotowa do wystawienia';
    case 3:
      return 'Oferta aktywna';
    case 4:
      return 'Oferta promowana';
    case 5:
      return 'Oferta wygasła';
    case 6:
      return 'Nieaktywna';
    case 7:
      return 'Oferta wycofana';
    default:
      return 'Undefined';
  }
};

const EditCondition: React.FC = () => {
  const {Alert} = useAlert();
  const [singleConditionId, setSingleConditionId] = useState<
    string | undefined
  >(undefined);
  const [isConditionCreating, setIsConditionCreating] =
    useState<boolean>(false);
  const [isConditionUpdating, setIsConditionUpdating] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [selectedFieldOfUse, setSelectedFieldOfUse] = useState<number>(0);
  const [fieldsOfUseData, setFieldsOfUseData] = useState<FieldOfUseData[]>([]);
  const [productType, setProductType] = useState<number | string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [userMetaData, setUserMetaData] = useState<UserMetaData>({
    HasIBAN: false,
    HasMugoLabel: false,
    IsLegal: false,
  });
  const [isConditionEditing, setIsConditionEditing] = useState<boolean>(false);
  const [tax, setTax] = useState<number>(23);
  const [currency, setCurrency] = useState<number>(0);
  const [dateToUploadForCustomer, setDateToUploadForCustomer] = useState<
    string | null
  >(null);
  const [timeToUploadForCustomer, setTimeToUploadForCustomer] = useState<
    number | string
  >('');
  const [winWinDigital, setWinWinDigital] = useState<number>(0);
  const [winWinPhysical, setWinWinPhysical] = useState<number>(0);
  const [winWinYoutube, setWinWinYoutube] = useState<number>(0);
  const [isExclusive, setIsExclusive] = useState<number>(1);
  const [exploitationDigital, setExploitationDigital] = useState<number>(1);
  const [exploitationPhysical, setExploitationPhysical] = useState<number>(1);
  const [timeToFinish, setTimeToFinish] = useState<number>(1);
  const [protectiveUmbrella, setProtectiveUmbrella] = useState<number>(1);
  const [exploitationYoutube, setExploitationYoutube] = useState<number>(1);
  const [paymentDeadline, setPaymentDeadline] = useState<number>(0);
  const [rightEnd, setRightEnd] = useState<number>(0);
  const [anotherLimits, setAnotherLimits] = useState<string>('');
  const [grossValue, setGrossValue] = useState<number>(0);
  const [invoice, setInvoice] = useState<boolean>(false);
  const [canPriceBeNegotiated, setCanPriceBeNegotiated] =
    useState<boolean>(true);
  const [paymentDeadlineValue, setPaymentDeadlineValue] = useState<number>(0);
  const [sellerObligations, setSellerObligations] = useState<string[]>([]);
  const [sellerRestrictions, setSellerRestrictions] = useState<string[]>([]);
  const [sellerObligation, setSellerObligation] = useState<string>('');
  const [sellerRestriction, setSellerRestriction] = useState<string>('');
  const [assignedProducts, setAssignedProducts] = useState<AssignedProducts[]>(
    [],
  );
  const [indicationOfContentAuthor, setIndicationOfContentAuthor] =
    useState<string>('');
  const [transactionFee, setTransactionFee] = useState<TransactionFee>({
    Brutto: 0,
    Name: '',
    Netto: 0,
    Tax: 0,
    TaxTotal: 0,
  });
  const [isIndicationOfContentAuthor, setIsIndicationOfContentAuthor] =
    useState<boolean>(true);
  const [assignedProductsCount, setAssignedProductsCount] = useState<number>(0);
  const [userWinWin, setUserWinWin] = useState<boolean>(false);
  const [createConditionStep, setCreateConditionStep] = useState<number>(1);
  const [errorVerification, setErrorVerification] = useState<ErrorVerification>(
    {
      nameError: null,
      categoryError: null,
    },
  );

  const params = useParams();

  const [cookies, setCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);
  const navigate = useNavigate();

  const getFieldsOfUse = async () => {
    setFieldsOfUseData([]);
    try {
      const response = await api.get(`Condition/FieldsOfUse`, {
        headers: {Authorization: `${cookies.token}`},
      });
      response.data.Response.map((fieldOfUse) =>
        setFieldsOfUseData((prev) => [
          ...prev,
          {
            name: fieldOfUse.Name,
            fieldId: fieldOfUse.Id,
            canPriceBeNegotiated: true,
            grossValue: 0,
            end: 0,
            inIncomeParticipation: 0,
            type: 1,
          },
        ]),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getTransactionFee = async (
    grossValue: number,
    productType: number | string,
  ) => {
    try {
      const response = await api.get(`condition/transactional-fee`, {
        headers: {Authorization: `${cookies.token}`},
        params: {
          BruttoAmount: grossValue,
          ProductType: productType,
        },
      });

      setTransactionFee(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserMetaData = async () => {
    try {
      const response = await api.get(
        `/Account/GetUserMetadata
  `,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setUserMetaData(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getSingleCondition = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await api.get(`Condition/Get/${id}`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const {
        AnotherLimits,
        AssignedProducts,
        AssignedProductsCount,
        CanPriceBeNegotiated,
        Currency,
        Description,
        ExploitationDigital,
        ExploitationPhysical,
        ExploitationYoutube,
        GrossValue,
        Id,
        Invoice,
        NetValue,
        Name,
        PaymentDeadline,
        PaymentDeadlineValue,
        ProducerId,
        RightsEnd,
        SellerObligations,
        Tax,
        WinWinDigital,
        WinWinPhysical,
        WinWinYoutube,
        TimeToFinish,
        ProductType,
        ProtectiveUmbrella,
        IsExclusive,
        DateToUploadForCustomer,
        TimeToUploadForCustomer,
        IsDefault,
        SellerRestrictions,
        IndicationOfContentAuthor,
        TransactionFee,
      } = response.data;

      setDateToUploadForCustomer(
        DateToUploadForCustomer
          ? getFormattedDate(DateToUploadForCustomer)
          : null,
      );
      setTimeToUploadForCustomer(TimeToUploadForCustomer);
      if (WinWinDigital || WinWinPhysical || WinWinYoutube) {
        setUserWinWin(true);
      } else {
        setUserWinWin(false);
      }
      setTransactionFee(TransactionFee);
      setIsIndicationOfContentAuthor(IndicationOfContentAuthor ? true : false);
      setIndicationOfContentAuthor(IndicationOfContentAuthor);
      setProductType(ProductType);
      setAssignedProductsCount(AssignedProductsCount);
      setIsExclusive(IsExclusive ? 1 : 2);
      setProtectiveUmbrella(ProtectiveUmbrella);
      setTimeToFinish(TimeToFinish);
      setAnotherLimits(AnotherLimits);
      setCanPriceBeNegotiated(CanPriceBeNegotiated);
      setCurrency(Currency);
      setDescription(Description);
      setExploitationDigital(ExploitationDigital);
      setExploitationPhysical(ExploitationPhysical);
      setExploitationYoutube(ExploitationYoutube);
      setGrossValue(GrossValue ? GrossValue / 100 : 0);
      setInvoice(Invoice);
      setName(Name);
      setPaymentDeadline(PaymentDeadline);
      setPaymentDeadlineValue(PaymentDeadlineValue);
      setRightEnd(RightsEnd);
      setTax(Tax);
      setAssignedProducts(AssignedProducts);
      setSellerObligations(SellerObligations);
      setSellerRestrictions(SellerRestrictions);
      setWinWinDigital(WinWinDigital);
      setWinWinPhysical(WinWinPhysical);
      setWinWinYoutube(WinWinYoutube);
      setIsDefault(IsDefault);
      setSellerObligation('');
      setSellerRestriction('');
    } catch (error) {
      console.error(error);
      navigate('/conditions');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSellerObligations = (sellerObligation: string) =>
    setSellerObligations((prev) => [
      ...prev.filter(
        (prevSellerObligation) => prevSellerObligation !== sellerObligation,
      ),
    ]);

  const deleteSellerRestrictions = (sellerRestriction: string) =>
    setSellerRestrictions((prev) => [
      ...prev.filter(
        (prevSellerRestriction) => prevSellerRestriction !== sellerRestriction,
      ),
    ]);

  const addSellerObligation = () => {
    if (!sellerObligation) return;
    if (
      sellerObligations.filter(
        (prevSellerObligation) =>
          prevSellerObligation.toLocaleLowerCase() ===
          sellerObligation.toLocaleLowerCase(),
      ).length > 0
    )
      return;
    setSellerObligations((prev) => [...prev, sellerObligation]);
    setSellerObligation('');
  };

  const addSellerRestriction = () => {
    if (!sellerRestriction) return;
    if (
      sellerRestrictions.filter(
        (prevSellerRestriction) =>
          prevSellerRestriction.toLocaleLowerCase() ===
          sellerRestriction.toLocaleLowerCase(),
      ).length > 0
    )
      return;
    setSellerRestrictions((prev) => [...prev, sellerRestriction]);
    setSellerRestriction('');
  };

  const goToStep2 = async () => {
    setErrorVerification({
      nameError: null,
      categoryError: null,
    });
    let areThereErrors = false;

    if (!name) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Uzupełnij nazwe szablonu',
      }));
      areThereErrors = true;
    }

    if (name.length > 30) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Nazwa warunków nie może mieć więcej niż 30 znaków.',
      }));
      areThereErrors = true;
    }

    if (!productType || productType === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        categoryError: 'Wybierz typ produktu',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;

    // return Alert('Wybierz typ produktu', 'Komunikat');

    setCreateConditionStep(2);
  };

  const backToStep1 = async () => {
    setCreateConditionStep(1);
  };

  const createCondition = async () => {
    setErrorVerification({
      nameError: null,
      categoryError: null,
    });
    let areThereErrors = false;

    if (!name) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Uzupełnij nazwe szablonu',
      }));
      areThereErrors = true;
    }

    if (name.length > 30) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Nazwa warunków nie może mieć więcej niż 30 znaków.',
      }));
      areThereErrors = true;
    }

    if (!productType || productType === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        categoryError: 'Wybierz typ produktu',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;

    if (sellerObligations.length === 0)
      return Alert('Dodaj conajmniej jedno zobowiązanie', 'Komunikat');

    // if (!productType || productType === 0)
    //   return Alert('Wybierz typ produktu', 'Komunikat');

    if (productType !== 4 && !userWinWin && !grossValue)
      return Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );

    if (productType === 4 && !grossValue)
      return Alert('Wypełnij kwotę gwarantowaną', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !timeToUploadForCustomer &&
      timeToUploadForCustomer !== 0
    )
      return Alert('Wybierz date wydania w mugo', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !winWinDigital &&
      !winWinPhysical &&
      !winWinYoutube
    )
      return Alert('Uzupełnij udział w przychodach', 'Komunikat');

    if (isConditionCreating) return;
    setIsConditionCreating(true);
    try {
      const body = {
        ProducerId: decodedToken.ProducerId,
        IsExclusive: isExclusive === 1,
        CanPriceBeNegotiated: canPriceBeNegotiated,
        GrossValue: grossValue * 100,
        ProductType: productType,
        Tax: tax,
        TransactionFee: transactionFee,
        // Currency: userGuaranteedAmount ? currency : 0,
        Currency: 0,
        Description: description,
        PaymentDeadline: paymentDeadline,
        PaymentDeadlineValue: paymentDeadlineValue,
        RightsEnd: rightEnd,
        Name: name,
        Invoice: invoice,
        AnotherLimits: anotherLimits,
        ExploitationDigital: exploitationDigital,
        ExploitationPhysical: exploitationPhysical,
        IndicationOfContentAuthor: isIndicationOfContentAuthor
          ? indicationOfContentAuthor
          : '',
        ExploitationYoutube: exploitationYoutube,
        DateToUploadForCustomer:
          productType !== 4 && userWinWin
            ? new Date(dateToUploadForCustomer)
            : productType === 4
            ? null
            : null,
        TimeToUploadForCustomer: timeToUploadForCustomer,
        WinWinDigital:
          productType !== 4 && userWinWin
            ? winWinDigital
            : productType === 4
            ? 0
            : 0,
        WinWinPhysical:
          productType !== 4 && userWinWin
            ? winWinPhysical
            : productType === 4
            ? 0
            : 0,
        WinWinYoutube:
          productType !== 4 && userWinWin
            ? winWinYoutube
            : productType === 4
            ? 0
            : 0,
        SellerObligations: sellerObligations,
        SellerRestrictions: sellerRestrictions,
        TimeToFinish: timeToFinish,
        ProtectiveUmbrella: protectiveUmbrella,
      };

      const response = await api.post(`Condition/Create`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      navigate(`/conditions/condition/${response.data.Response.Id}`);
      setIsConditionEditing(false);
      setSingleConditionId(response.data.Response.Id);
      setCreateConditionStep(0);
      getSingleCondition(response.data.Response.Id);
      Alert('Stworzono nowy warunek', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsConditionCreating(false);
    }
  };

  const createConditionFromDefault = async () => {
    setErrorVerification({
      nameError: null,
      categoryError: null,
    });
    let areThereErrors = false;

    if (!name) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Uzupełnij nazwe szablonu',
      }));
      areThereErrors = true;
    }

    if (name.length > 30) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Nazwa warunków nie może mieć więcej niż 30 znaków.',
      }));
      areThereErrors = true;
    }

    if (!productType || productType === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        categoryError: 'Wybierz typ produktu',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;

    if (!userWinWin && !grossValue)
      return Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );

    if (sellerObligations.length === 0)
      return Alert('Dodaj conajmniej jedno zobowiązanie', 'Komunikat');

    if (productType !== 4 && !userWinWin && !grossValue)
      return Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );

    if (productType === 4 && !grossValue)
      return Alert('Wypełnij kwotę gwarantowaną', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !timeToUploadForCustomer &&
      timeToUploadForCustomer !== 0
    )
      return Alert('Wybierz date wydania w mugo', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !winWinDigital &&
      !winWinPhysical &&
      !winWinYoutube
    )
      return Alert('Uzupełnij udział w przychodach', 'Komunikat');

    if (isConditionCreating) return;
    setIsConditionCreating(true);
    try {
      const body = {
        ProducerId: decodedToken.ProducerId,
        IsExclusive: isExclusive === 1,
        CanPriceBeNegotiated: canPriceBeNegotiated,
        GrossValue: grossValue * 100,
        ProductType: productType,
        Tax: tax,
        TransactionFee: transactionFee,
        // Currency: userGuaranteedAmount ? currency : 0,
        Currency: 0,
        Description: description,
        PaymentDeadline: paymentDeadline,
        PaymentDeadlineValue: paymentDeadlineValue,
        RightsEnd: rightEnd,
        Name: name,
        Invoice: invoice,
        AnotherLimits: anotherLimits,
        IndicationOfContentAuthor: isIndicationOfContentAuthor
          ? indicationOfContentAuthor
          : '',
        ExploitationDigital: exploitationDigital,
        ExploitationPhysical: exploitationPhysical,
        ExploitationYoutube: exploitationYoutube,
        DateToUploadForCustomer:
          productType !== 4 && userWinWin
            ? new Date(dateToUploadForCustomer)
            : productType === 4
            ? null
            : null,
        TimeToUploadForCustomer: timeToUploadForCustomer,
        WinWinDigital:
          productType !== 4 && userWinWin
            ? winWinDigital
            : productType === 4
            ? 0
            : 0,
        WinWinPhysical:
          productType !== 4 && userWinWin
            ? winWinPhysical
            : productType === 4
            ? 0
            : 0,
        WinWinYoutube:
          productType !== 4 && userWinWin
            ? winWinYoutube
            : productType === 4
            ? 0
            : 0,
        SellerObligations: sellerObligations,
        SellerRestrictions: sellerRestrictions,
        TimeToFinish: timeToFinish,
        ProtectiveUmbrella: protectiveUmbrella,
      };

      const response = await api.post(`Condition/Create`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      navigate(`/conditions/condition/${response.data.Response.Id}`);
      setIsConditionEditing(false);
      setSingleConditionId(response.data.Response.Id);
      setCreateConditionStep(0);
      getSingleCondition(response.data.Response.Id);
      Alert('Stworzono nowy warunek', 'Komunikat');
      getSingleCondition(singleConditionId);
      navigate(0);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsConditionCreating(false);
    }
  };

  const updateCondition = async () => {
    setErrorVerification({
      nameError: null,
      categoryError: null,
    });
    let areThereErrors = false;

    if (!name) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Uzupełnij nazwe szablonu',
      }));
      areThereErrors = true;
    }

    if (name.length > 30) {
      setErrorVerification((prev) => ({
        ...prev,
        nameError: 'Nazwa warunków nie może mieć więcej niż 30 znaków.',
      }));
      areThereErrors = true;
    }

    if (!productType || productType === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        categoryError: 'Wybierz typ produktu',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;

    // if (!productType || productType === 0)
    //   return Alert('Wybierz typ produktu', 'Komunikat');

    if (!userWinWin && !grossValue)
      return Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );

    if (sellerObligations.length === 0)
      return Alert('Dodaj conajmniej jedno zobowiązanie', 'Komunikat');

    if (productType !== 4 && !userWinWin && !grossValue)
      return Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );

    if (productType === 4 && !grossValue)
      return Alert('Wypełnij kwotę gwarantowaną', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !timeToUploadForCustomer &&
      timeToUploadForCustomer !== 0
    )
      return Alert('Wybierz date wydania w mugo', 'Komunikat');

    if (
      productType !== 4 &&
      userWinWin &&
      !winWinDigital &&
      !winWinPhysical &&
      !winWinYoutube
    )
      return Alert('Uzupełnij udział w przychodach', 'Komunikat');

    if (isConditionUpdating) return;
    setIsConditionUpdating(true);
    try {
      const body = {
        Id: params.id,
        ProducerId: decodedToken.ProducerId,
        IsExclusive: isExclusive === 1,
        ProductType: productType,
        CanPriceBeNegotiated: canPriceBeNegotiated,
        GrossValue: grossValue * 100,
        Tax: tax,
        TransactionFee: transactionFee,
        // Currency: userGuaranteedAmount ? currency : 0,
        Currency: 0,
        Description: description,
        PaymentDeadline: paymentDeadline,
        PaymentDeadlineValue: paymentDeadlineValue,
        RightsEnd: rightEnd,
        Name: name,
        Invoice: invoice,
        IndicationOfContentAuthor: isIndicationOfContentAuthor
          ? indicationOfContentAuthor
          : '',
        AnotherLimits: anotherLimits,
        ExploitationDigital: exploitationDigital,
        ExploitationPhysical: exploitationPhysical,
        ExploitationYoutube: exploitationYoutube,
        DateToUploadForCustomer:
          productType !== 4 && userWinWin
            ? new Date(dateToUploadForCustomer)
            : productType === 4
            ? null
            : null,
        TimeToUploadForCustomer: timeToUploadForCustomer,
        WinWinDigital:
          productType !== 4 && userWinWin
            ? winWinDigital
            : productType === 4
            ? 0
            : 0,
        WinWinPhysical:
          productType !== 4 && userWinWin
            ? winWinPhysical
            : productType === 4
            ? 0
            : 0,
        WinWinYoutube:
          productType !== 4 && userWinWin
            ? winWinYoutube
            : productType === 4
            ? 0
            : 0,
        SellerObligations: sellerObligations,
        SellerRestrictions: sellerRestrictions,
        TimeToFinish: timeToFinish,
        ProtectiveUmbrella: protectiveUmbrella,
      };

      const response = await api.put(`Condition/Update`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      getSingleCondition(singleConditionId);
      setIsConditionEditing(false);
      Alert('Zaktualizowano warunek', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error, 'Błąd');
    } finally {
      setIsConditionUpdating(false);
    }
  };

  useEffect(() => {
    setSingleConditionId(params.id);
    if (!params.id) {
      setIsConditionEditing(true);
    }
  }, []);

  useEffect(() => {
    if (singleConditionId) {
      getSingleCondition(singleConditionId);
      setCreateConditionStep(0);
    }
  }, [singleConditionId]);

  useEffect(() => {
    getUserMetaData();
  }, []);

  const handleSellerObligationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSellerObligation(e.target.value);

  const handleIndicationOfContentAuthorChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setIndicationOfContentAuthor(e.target.value);

  const handleSellerRestrictionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSellerRestriction(e.target.value);

  const handleInvoiceChange = (value: boolean) => setInvoice(value);

  const handleIsConditionEditingChange = (value: boolean) =>
    setIsConditionEditing(value);

  const handleUserWinWinChange = (value: boolean) => setUserWinWin(value);

  const handleIsIndicationOfContentAuthorChange = (value: boolean) =>
    setIsIndicationOfContentAuthor(value);

  const handleCanPriceBeNegotiatedChange = (value: boolean) =>
    setCanPriceBeNegotiated(value);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setErrorVerification((prev) => ({
      ...prev,
      nameError: null,
    }));
  };

  const handleDateToUploadForCustomerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setDateToUploadForCustomer(e.target.value);

  const handleTimeToUploadForCustomerChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (+e.target.value < 0) return;
    setTimeToUploadForCustomer(+e.target.value);
  };

  const handleGrossValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value < 0) return;
    setGrossValue(+e.target.value);
    getTransactionFee(+e.target.value * 100, productType);
  };

  const handlePaymentDeadlineValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setPaymentDeadlineValue(+e.target.value);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescription(e.target.value);

  const handleAnotherLimitsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAnotherLimits(e.target.value);

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCurrency(+e.target.value);

  const handleWinWinDigitalChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (+e.target.value.replace('%', '') > 99) return;
    setWinWinDigital(+e.target.value.replace('%', ''));
  };

  const handleWinWinPhysicalChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (+e.target.value.replace('%', '') > 99) return;
    setWinWinPhysical(+e.target.value.replace('%', ''));
  };

  const handleWinWinYoutubeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (+e.target.value.replace('%', '') > 99) return;
    setWinWinYoutube(+e.target.value.replace('%', ''));
  };

  const handleExploitationDigitalChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setExploitationDigital(+e.target.value);

  const handleIsExclusiveChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setIsExclusive(+e.target.value);

  const handleExploitationPhysicalChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setExploitationPhysical(+e.target.value);

  const handleExploitationYoutubeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setExploitationYoutube(+e.target.value);

  const handleProtectiveUmbrellaChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setProtectiveUmbrella(+e.target.value);

  const handleTimeToFinishChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setTimeToFinish(+e.target.value);

  const handleTaxChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setTax(+e.target.value);

  const handlePaymentDeadlineChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setPaymentDeadline(+e.target.value);

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductType(+e.target.value);

    setErrorVerification((prev) => ({
      ...prev,
      categoryError: null,
    }));
  };

  const handleRightEndChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setRightEnd(+e.target.value);

  const handleFieldOfUseCanPriceBeNegotiatedChange = (
    value: boolean,
    id: string,
  ) => {
    setFieldsOfUseData((prev) =>
      prev.map((fieldOfUse) =>
        fieldOfUse.fieldId === id
          ? {...fieldOfUse, canPriceBeNegotiated: value}
          : fieldOfUse,
      ),
    );
  };

  const handleFieldOfUseTypeChange = (value: number, id: string) => {
    setFieldsOfUseData((prev) =>
      prev.map((fieldOfUse) =>
        fieldOfUse.fieldId === id ? {...fieldOfUse, type: value} : fieldOfUse,
      ),
    );
  };

  const handleFieldOfUseGrossValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    setFieldsOfUseData((prev) =>
      prev.map((fieldOfUse) =>
        fieldOfUse.fieldId === id
          ? {...fieldOfUse, grossValue: +e.target.value}
          : fieldOfUse,
      ),
    );
  };

  const handleFieldOfUseInIncomeParticipationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    setFieldsOfUseData((prev) =>
      prev.map((fieldOfUse) =>
        fieldOfUse.fieldId === id
          ? {...fieldOfUse, inIncomeParticipation: +e.target.value}
          : fieldOfUse,
      ),
    );
  };

  const deleteCondition = async (id: string) => {
    try {
      const response = await api.delete(`Condition/Delete/${id}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      navigate('/conditions');
      Alert('Warunek został usunięty.', 'Komunikat');
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldOfUseEndChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string,
  ) => {
    setFieldsOfUseData((prev) =>
      prev.map((fieldOfUse) =>
        fieldOfUse.fieldId === id
          ? {...fieldOfUse, end: +e.target.value}
          : fieldOfUse,
      ),
    );
  };

  const nextFieldOfUse = () => {
    if (selectedFieldOfUse === fieldsOfUseData.length - 1) {
      return setSelectedFieldOfUse(0);
    }
    return setSelectedFieldOfUse((prev) => prev + 1);
  };

  const prevFieldOfUse = () => {
    if (selectedFieldOfUse === 0) {
      return setSelectedFieldOfUse(fieldsOfUseData.length - 1);
    }
    return setSelectedFieldOfUse((prev) => prev - 1);
  };

  return (
    <>
      <MainWrapper>
        <Main>
          {(isLoading || isConditionCreating || isConditionUpdating) && (
            <LoadingCircle />
          )}
          <HeaderWrapper>
            <Header>
              <p>Szablon warunków oferty</p>
              {params.id && !isDefault && assignedProductsCount === 0 && (
                <DeleteConditionButton>
                  <PopupButton
                    buttons={['Anuluj', 'Usuń']}
                    title={'Uwaga'}
                    text={` Czy na pewno chcesz usunąć warunek ${name}?
                     `}
                    onClick={() => deleteCondition(params.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.5"
                      height="17.056"
                      viewBox="0 0 15.5 17.056"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.75 0.75)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h14"
                          transform="translate(-4.5 -5.889)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M18.389,6.111V17a1.556,1.556,0,0,1-1.556,1.556H9.056A1.556,1.556,0,0,1,7.5,17V6.111m2.333,0V4.556A1.556,1.556,0,0,1,11.389,3H14.5a1.556,1.556,0,0,1,1.556,1.556V6.111"
                          transform="translate(-5.944 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </PopupButton>
                </DeleteConditionButton>
              )}
            </Header>
            <HeaderContent>
              <p>
                Przygotuj szablon warunków, by łatwiej i szybciej tworzyć
                kolejne oferty.
              </p>
            </HeaderContent>
          </HeaderWrapper>
          <Grid>
            <SingleColumnWrapper
              isEditing={
                createConditionStep !== 0
                  ? createConditionStep === 1
                  : isConditionEditing
              }
            >
              <SingleColumnHeader
                isEditing={
                  createConditionStep !== 0
                    ? createConditionStep === 1
                    : isConditionEditing
                }
              >
                <p>Opis szablonu</p>
                {createConditionStep !== 0 && (
                  <EditBtnWrapper
                    style={{top: '2px', right: '4px'}}
                    isEditing={false}
                  >
                    <EditSvgWrapper
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                )}
              </SingleColumnHeader>
              <FirstColumnContentWrapper>
                {createConditionStep === 1 ||
                (createConditionStep === 0 && isConditionEditing) ? (
                  <>
                    <h5>Wybierz kategorię oferty</h5>
                    <StyledSelectView
                      isError={errorVerification.categoryError !== null}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                      style={{marginTop: '9px'}}
                    >
                      <p>Kategoria oferty</p>

                      <select
                        value={productType}
                        onChange={handleProductTypeChange}
                        disabled={
                          !(createConditionStep !== 0
                            ? createConditionStep === 1
                            : isConditionEditing)
                        }
                      >
                        <option hidden value={''}>
                          Wybierz
                        </option>
                        {ProductTypeEnum.map((productType) => {
                          const {FieldName, FlagValue} = productType;

                          if (FlagValue === 2) return;

                          return (
                            <option value={FlagValue} key={FlagValue}>
                              {getProductTypePolishName(FlagValue)}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                      {errorVerification.categoryError !== null && (
                        <ErrorStar
                          text={errorVerification.categoryError}
                          fixed={true}
                        />
                      )}
                    </StyledSelectView>
                    <h5 style={{marginTop: '16px'}}>
                      Nazwij szablon,
                      <br />
                      dobrze oddając zawarte w nim ustalenia.
                      <br />
                      <i>Uwaga - nazwa będzie widoczna dla użytkowników. </i>
                    </h5>
                    <StyledInputView
                      style={{marginTop: '18px'}}
                      isError={errorVerification.nameError !== null}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                    >
                      <p>Nazwa</p>
                      <input
                        type="text"
                        readOnly={
                          !(createConditionStep !== 0
                            ? createConditionStep === 1
                            : isConditionEditing)
                        }
                        placeholder="Uzupełnij"
                        value={name}
                        onChange={handleNameChange}
                      />
                      {errorVerification.nameError !== null && (
                        <ErrorStar
                          text={errorVerification.nameError}
                          fixed={true}
                        />
                      )}
                    </StyledInputView>
                    {/* <p style={{marginTop: '6px'}}>
                    Np. track na wyłączność, nagranie prostego teledysku, pakiet
                    10 postów na Facebooku
                  </p> */}
                    <h5 style={{marginTop: '9px'}}>
                      Opisz szablon,
                      <br />
                      podając największe korzyści z jego wyboru.
                      <br />
                      <i>Uwaga - opis znajdzie w ofercie. </i>
                    </h5>
                    <TextareaWrapper
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                      style={{marginTop: '10px'}}
                    >
                      <h5>Opis</h5>
                      <StyledTextarea
                        maxLength={250}
                        readOnly={
                          !(createConditionStep !== 0
                            ? createConditionStep === 1
                            : isConditionEditing)
                        }
                        placeholder="Uzupełnij"
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                      <TextareaLettersCount>
                        {description.length}/250
                      </TextareaLettersCount>
                    </TextareaWrapper>
                    {createConditionStep === 1 && (
                      <BlueButton
                        style={{marginTop: '32px'}}
                        onClick={() => goToStep2()}
                      >
                        Dalej
                      </BlueButton>
                    )}
                  </>
                ) : (
                  <>
                    <DisplayInputWrapper>
                      <h5>Kategoria oferty</h5>
                      <p>
                        {ProductTypeEnum.length > 0 &&
                        ProductTypeEnum.filter(
                          (type) => type.FlagValue === productType,
                        ).length > 0
                          ? getProductTypePolishName(
                              ProductTypeEnum.filter(
                                (type) => type.FlagValue === productType,
                              )[0].FlagValue,
                            )
                          : ''}
                      </p>
                    </DisplayInputWrapper>
                    <DisplayInputWrapper>
                      <h5>Nazwa</h5>
                      <p>{name}</p>
                    </DisplayInputWrapper>
                    <DisplayTextareaWrapper>
                      <h5>Opis</h5>
                      <p>{description}</p>
                    </DisplayTextareaWrapper>
                  </>
                )}
              </FirstColumnContentWrapper>
            </SingleColumnWrapper>

            <SecondColumnWrapper
              isEditing={
                createConditionStep !== 0
                  ? createConditionStep === 2
                  : isConditionEditing
              }
            >
              {createConditionStep === 2 && (
                <SaveConditionButton
                  onClick={() => {
                    createCondition();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.5"
                    height="15.5"
                    viewBox="0 0 15.5 15.5"
                  >
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M18.278,25.722V19.5H10.5v6.222"
                        transform="translate(-2.889 -7.222)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V8.389h6.222"
                        transform="translate(-2.889)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </SaveConditionButton>
              )}
              {createConditionStep === 0 &&
              isConditionEditing &&
              assignedProductsCount < 2 &&
              !isDefault ? (
                <SaveConditionButton
                  onClick={() => {
                    if (isDefault) {
                      createConditionFromDefault();
                    } else {
                      updateCondition();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.5"
                    height="15.5"
                    viewBox="0 0 15.5 15.5"
                  >
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M18.278,25.722V19.5H10.5v6.222"
                        transform="translate(-2.889 -7.222)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V8.389h6.222"
                        transform="translate(-2.889)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </SaveConditionButton>
              ) : (
                createConditionStep === 0 &&
                isConditionEditing &&
                isDefault && (
                  <SaveConditionButton
                    onClick={() => {
                      if (isDefault) {
                        createConditionFromDefault();
                      } else {
                        updateCondition();
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.5"
                      height="15.5"
                      viewBox="0 0 15.5 15.5"
                    >
                      <g
                        id="Icon_feather-save"
                        data-name="Icon feather-save"
                        transform="translate(-3.75 -3.75)"
                      >
                        <path
                          id="Path_3700"
                          data-name="Path 3700"
                          d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_3701"
                          data-name="Path 3701"
                          d="M18.278,25.722V19.5H10.5v6.222"
                          transform="translate(-2.889 -7.222)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_3702"
                          data-name="Path 3702"
                          d="M10.5,4.5V8.389h6.222"
                          transform="translate(-2.889)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </SaveConditionButton>
                )
              )}
              {createConditionStep === 0 &&
                isConditionEditing &&
                assignedProductsCount > 1 &&
                !isDefault && (
                  <>
                    <SaveConditionButtonWrapper>
                      <PopupButton
                        buttons={['Anuluj', 'Tak, zamień wszędzie']}
                        title={'Uwaga'}
                        text={`Warunek zostanie zmieniony na wszystkich produktach. Czy na pewno chcesz to zrobić?
                     `}
                        onClick={() => updateCondition()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.5"
                          height="15.5"
                          viewBox="0 0 15.5 15.5"
                        >
                          <g
                            id="Icon_feather-save"
                            data-name="Icon feather-save"
                            transform="translate(-3.75 -3.75)"
                          >
                            <path
                              id="Path_3700"
                              data-name="Path 3700"
                              d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3701"
                              data-name="Path 3701"
                              d="M18.278,25.722V19.5H10.5v6.222"
                              transform="translate(-2.889 -7.222)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3702"
                              data-name="Path 3702"
                              d="M10.5,4.5V8.389h6.222"
                              transform="translate(-2.889)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </PopupButton>
                    </SaveConditionButtonWrapper>
                    {/* <SaveConditionButton
                  onClick={() => {
                    if (isDefault) {
                      createConditionFromDefault();
                    } else {
                      updateCondition();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.5"
                    height="15.5"
                    viewBox="0 0 15.5 15.5"
                  >
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M18.278,25.722V19.5H10.5v6.222"
                        transform="translate(-2.889 -7.222)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V8.389h6.222"
                        transform="translate(-2.889)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </SaveConditionButton> */}
                  </>
                )}
              <SingleColumnHeader
                isEditing={
                  createConditionStep !== 0
                    ? createConditionStep === 2
                    : isConditionEditing
                }
              >
                {createConditionStep === 2 && (
                  <ArrowButtonLeft onClick={() => backToStep1()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117.224.157,5.127a.523.523,0,0,0,0,.747l4.961,4.9a.781.781,0,0,0,1.095,0,.76.76,0,0,0,0-1.082L1.969,5.5,6.212,1.306a.76.76,0,0,0,0-1.082.781.781,0,0,0-1.095,0"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </ArrowButtonLeft>
                )}
                <p>Warunki oferty</p>
                {createConditionStep !== 0 ? (
                  <EditBtnWrapper
                    style={{top: '2px', right: '4px'}}
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 2
                        : isConditionEditing
                    }
                  >
                    <EditSvgWrapper
                      onClick={() => {
                        if (createConditionStep === 0) {
                          handleIsConditionEditingChange(!isConditionEditing);
                        }
                      }}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                ) : createConditionStep === 0 && !isConditionEditing ? (
                  <EditBtnWrapper
                    style={{top: '2px'}}
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 2
                        : isConditionEditing
                    }
                  >
                    <EditSvgWrapper
                      onClick={() => {
                        if (createConditionStep === 0) {
                          handleIsConditionEditingChange(!isConditionEditing);
                        }
                      }}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                ) : (
                  <PopupButton
                    buttons={['Kontynuuj', 'Zamknij']}
                    title={'Uwaga'}
                    text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                    onClick={() => {
                      getSingleCondition(singleConditionId);
                      setIsConditionEditing(false);
                    }}
                  >
                    <EditBtnWrapper
                      style={{top: '0px'}}
                      isEditing={isConditionEditing}
                    >
                      <EditSvgWrapper isEditing={isConditionEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  </PopupButton>
                )}
              </SingleColumnHeader>
              <SingleColumnContentWrapper>
                {createConditionStep === 1 ? (
                  <ConditionBannerWrapper>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="65.625"
                      height="70"
                      viewBox="0 0 65.625 70"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_3503"
                            data-name="Rectangle 3503"
                            width="52.065"
                            height="67.083"
                            fill="#07020f"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_6595"
                        data-name="Group 6595"
                        transform="translate(-1054 -543)"
                      >
                        <g
                          id="Group_6465"
                          data-name="Group 6465"
                          transform="translate(1054 543)"
                        >
                          <g
                            id="Group_6464"
                            data-name="Group 6464"
                            clipPath="url(#clip-path)"
                          >
                            <path
                              id="Path_4030"
                              data-name="Path 4030"
                              d="M38.177,51.315a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,55.86l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441L38.455,52.7a1,1,0,0,0-.278-1.388"
                              transform="translate(-18.598 -32.124)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4031"
                              data-name="Path 4031"
                              d="M38.177,89a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14A1,1,0,1,0,29.9,93.547l3,3a1,1,0,0,0,.708.293.978.978,0,0,0,.1,0,1,1,0,0,0,.734-.441l4.006-6.007A1,1,0,0,0,38.177,89"
                              transform="translate(-18.598 -55.794)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4032"
                              data-name="Path 4032"
                              d="M38.177,126.69a1,1,0,0,0-1.388.278l-3.328,4.991-2.14-2.14a1,1,0,1,0-1.416,1.416l3,3a1,1,0,0,0,.708.293c.033,0,.066,0,.1,0a1,1,0,0,0,.734-.441l4.006-6.007a1,1,0,0,0-.278-1.388"
                              transform="translate(-18.598 -79.464)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4033"
                              data-name="Path 4033"
                              d="M51.063,7.009H38.047V4a1,1,0,0,0-1-1h-6.44a4.984,4.984,0,0,0-9.148,0h-6.44a1,1,0,0,0-1,1v3H1a1,1,0,0,0-1,1V66.082a1,1,0,0,0,1,1H37.046a1,1,0,1,0,0-2H2V9.011H14.017v3H6.007a1,1,0,0,0-1,1v48.06a1,1,0,0,0,1,1H35.044a1,1,0,1,0,0-2H7.009V14.017H45.056V39.049a1,1,0,1,0,2,0V13.016a1,1,0,0,0-1-1h-8.01v-3H50.062V39.049a1,1,0,1,0,2,0V8.01a1,1,0,0,0-1-1m-35.044-2h6.149a1,1,0,0,0,.97-.751,2.985,2.985,0,0,1,5.787,0,1,1,0,0,0,.97.751h6.149v7.009H16.02Z"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4034"
                              data-name="Path 4034"
                              d="M79.627,59.224H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                              transform="translate(-40.578 -37.197)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4035"
                              data-name="Path 4035"
                              d="M79.627,96.912H65.609a1,1,0,0,0,0,2H79.627a1,1,0,1,0,0-2"
                              transform="translate(-40.578 -60.867)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_4036"
                              data-name="Path 4036"
                              d="M65.609,134.6a1,1,0,0,0,0,2h7.009a1,1,0,0,0,0-2Z"
                              transform="translate(-40.578 -84.538)"
                              fill="#07020f"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_6466"
                          data-name="Group 6466"
                          transform="translate(1093.375 586.75)"
                        >
                          <path
                            id="Path_3927"
                            data-name="Path 3927"
                            d="M6.965-1.484A1.039,1.039,0,0,0,8.006-2.525,1.039,1.039,0,0,0,6.965-3.567H3.9l3.973-4.9a.9.9,0,0,0,.192-.577.918.918,0,0,0-.961-.929H1.645A1.039,1.039,0,0,0,.6-8.934,1.039,1.039,0,0,0,1.645-7.893H4.69L.732-2.99a.9.9,0,0,0-.192.577.918.918,0,0,0,.961.929Zm2.788-.9a1.073,1.073,0,0,0,1.073,1.073A1.073,1.073,0,0,0,11.9-2.381V-5.842l1.089-.513a.788.788,0,0,0,.368-1.025.788.788,0,0,0-1.025-.368l-.433.192v-4.87A1.073,1.073,0,0,0,10.826-13.5a1.073,1.073,0,0,0-1.073,1.073v5.88l-1.089.5a.775.775,0,0,0-.368,1.009.78.78,0,0,0,1.025.385l.433-.192Z"
                            transform="translate(6.752 20.062)"
                            fill="#07020f"
                          />
                          <path
                            id="Path_4037"
                            data-name="Path 4037"
                            d="M13.125,2.188A10.938,10.938,0,1,0,24.063,13.125,10.95,10.95,0,0,0,13.125,2.188m0-2.187A13.125,13.125,0,1,1,0,13.125,13.125,13.125,0,0,1,13.125,0Z"
                            fill="#07020f"
                          />
                        </g>
                      </g>
                    </svg>
                    <h5>Warunki oferty</h5>
                    {/* <p>
                    Uzupełnij dane podstawowe oferty i je zapisz, aby pojawiła
                    się możliwość określenia stawki za realizację.
                  </p> */}
                  </ConditionBannerWrapper>
                ) : (
                  <ConditionDetailsWrapper
                    style={{paddingLeft: '10px', paddingBottom: '60px'}}
                  >
                    <BlueHeaderComponentCondition>
                      Deklaracje
                    </BlueHeaderComponentCondition>
                    <ConditionSectionDescription isEditing={isConditionEditing}>
                      <p>
                        Opisz w punktach, co wykonasz w ramach swojej oferty,
                        czyli do czego się zobowiązujesz i co wykluczasz, a więc
                        czego Twoja oferta nie zawiera.
                      </p>
                    </ConditionSectionDescription>
                    <ExpandableHeaderWithDescription
                      text=""
                      isEditing={isConditionEditing}
                      header="Zobowiązania"
                      isLongDescription
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To bardzo ważne, żeby szczegółowo opisać usługę czy
                            produkt - unikniesz dzięki temu nieporozumień.
                          </p>
                          <br />
                          <p>Przykłady: </p>
                        </ConditionSectionDescriptionExample>

                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA
                            FAN PAGE’A NA FACEBOOKU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Facebook
                            poniedziałek-piątek{' '}
                          </p>
                          <span>Zobowiązania:</span>
                          <span>- Zamieszczę 3 posty w tygodniu. </span>
                          <span>- Do postów dodam grafikę. </span>
                          <span>
                            - Posty będą publikowane o godzinie 10:00.{' '}
                          </span>
                          <span>
                            - Będę moderował posty od 09:00 do 21:00 w dni
                            powszednie.{' '}
                          </span>
                          <span>
                            - Każdy wpis będzie zawierał unikalną treść.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO
                            UTWORU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> WIN-WIN
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Zobowiązuję się stworzyć rymowany tekst utworu po
                            wskazaniu tematyki przez zamawiającego.{' '}
                          </span>
                          <span>
                            - Tekst będzie składał się z trzech zwrotek
                            przeplatanych refrenem (zwrotki 16 taktów, refren 8
                            taktów).{' '}
                          </span>
                          <span>
                            - Usługę zrealizuję w ciągu pięciu dni roboczych od
                            złożenia zamówienia.{' '}
                          </span>
                          <span>
                            - Dopuszczam dwie sesje poprawkowe po zbiorowym
                            wskazaniu poprawek w tekście przez zamawiającego.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW
                            MUZYCZNYCH
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Prosty teledysk
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Zapewniam kamerę lub aparat z rozdzielczością 4K
                            oraz podstawowy zestaw oświetleniowy.{' '}
                          </span>
                          <span>
                            - Zapewniam 10 godzin montażu materiału po
                            ewentualnych sugestiach klienta.{' '}
                          </span>
                          <span>
                            - Zdjęcia będą trwały w jednym dniu przez osiem
                            godzin po wcześniejszym ustaleniu daty z klientem.{' '}
                          </span>
                          <span>
                            - Dopuszczam dwie darmowe poprawki po montażu, każda
                            kolejna będzie kosztować 300 złotych.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> SEO COPYWRITING -
                            ARTYKUŁY BLOGOWE
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO
                            do 5 tysięcy znaków ze spacjami
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Tekst będzie dotyczył wybranej przez klienta
                            tematyki.{' '}
                          </span>
                          <span>
                            - Tekst będzie zawierał unikalną treść, która nie
                            jest skopiowana z innych źródeł.{' '}
                          </span>
                          <span>
                            - Artykuł będzie liczył do 5000 znaków ze spacjami.{' '}
                          </span>
                          <span>
                            - Tekst będzie zawierał frazę główną w nagłówku H1
                            oraz pomocnicze słowa kluczowe w nagłówkach H2 i H3.
                          </span>
                          <span>
                            - Zezwalam na to, by klient podpisał się pod moim
                            tekstem własnym imieniem i nazwiskiem.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    {!isConditionEditing && (
                      <SectionHeaderNoEditing>
                        <p>Zobowiązania</p>
                      </SectionHeaderNoEditing>
                    )}
                    {sellerObligations.map((sellerObligation) => (
                      <SellerObligationWrapper
                        key={sellerObligation}
                        isEditing={isConditionEditing}
                      >
                        {isConditionEditing ? (
                          <>
                            <p>{sellerObligation}</p>
                            <DeleteTagButton
                              style={{marginTop: '1px', marginLeft: '1px'}}
                              onClick={() =>
                                deleteSellerObligations(sellerObligation)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.9"
                                height="12"
                                viewBox="0 0 10.9 12"
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.5 0.5)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9h9.9"
                                    transform="translate(-4.5 -6.8)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                    transform="translate(-6.4 -3)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                            </DeleteTagButton>
                          </>
                        ) : (
                          <>
                            <svg
                              style={{left: '13.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                            >
                              <path
                                id="Icon_awesome-check-circle"
                                data-name="Icon awesome-check-circle"
                                d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                                transform="translate(-0.563 -0.563)"
                                fill="#00d10f"
                              />
                            </svg>
                            <p>{sellerObligation}</p>
                          </>
                        )}
                      </SellerObligationWrapper>
                    ))}
                    {isConditionEditing && (
                      <AddSellerObligationWrapper>
                        <AddSellerObligationInputWrapper>
                          <input
                            placeholder="Napisz treść zobowiązania..."
                            value={sellerObligation}
                            onChange={handleSellerObligationChange}
                          />
                        </AddSellerObligationInputWrapper>
                        <AddTagButton onClick={() => addSellerObligation()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddTagButton>
                      </AddSellerObligationWrapper>
                    )}
                    <ExpandableHeaderWithDescription
                      text=""
                      header="Wykluczenia, ograniczenia"
                      isEditing={isConditionEditing}
                      isLongDescription
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Podaj ograniczenia. <br />
                            To zapobiegnie ewentualnym sporom.
                          </p>
                          <br />
                          <p>Przykłady: </p>
                        </ConditionSectionDescriptionExample>

                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA
                            FAN PAGE’A NA FACEBOOKU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Facebook
                            poniedziałek-piątek{' '}
                          </p>
                          <span>Ograniczenia:</span>
                          <span>
                            - Nie będę dokonywał moderacji postów w weekendy.{' '}
                          </span>
                          <span>
                            - Nie będę prowadził korespondencji z klientami
                            firmy za pośrednictwem wiadomości prywatnych w
                            serwisie Facebook.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO
                            UTWORU
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> WIN-WIN
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Tekst nie będzie zawierał sformułowań
                            rasistowskich, seksistowskich i nawołujących do
                            nienawiści na tle religijnym.
                          </span>
                          <span>
                            - Tekst nie może być wykorzystany w reklamie
                            komercyjnej.
                          </span>
                          <span>
                            - Nie zgadzam się na umieszczenie utworu z moim
                            tekstem na innych wydawnictwach, niż dyskografia
                            zamawiającego (a więc między innymi na składankach i
                            mixtape’ach).
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW
                            MUZYCZNYCH
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Prosty teledysk
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Teledysk lub jego fragmenty nie mogą być
                            wykorzystywane w reklamach.
                          </span>
                          <span>
                            - Dobór aktorów i aktorek do teledysku pozostaje po
                            stronie klienta.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> SEO COPYWRITING -
                            ARTYKUŁY BLOGOWE
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO
                            do 5 tysięcy znaków ze spacjami
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Tekst nie może ukazać się w innym miejscu, niż
                            blog klienta.
                          </span>
                          <span>
                            - Klient nie może dokonać zmian w artykule - dotyczy
                            to zarówno treści, jak i tytułu oraz nagłówków.
                          </span>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    {!isConditionEditing && sellerRestrictions.length > 0 && (
                      <SectionHeaderNoEditing>
                        <p>Wykluczenia i ograniczenia</p>
                      </SectionHeaderNoEditing>
                    )}
                    {sellerRestrictions.map((sellerRestriction) => (
                      <SellerObligationWrapper
                        key={sellerRestriction}
                        isEditing={isConditionEditing}
                      >
                        {isConditionEditing ? (
                          <>
                            <p>{sellerRestriction}</p>
                            <DeleteTagButton
                              style={{marginTop: '1px', marginLeft: '1px'}}
                              onClick={() =>
                                deleteSellerRestrictions(sellerRestriction)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.9"
                                height="12"
                                viewBox="0 0 10.9 12"
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.5 0.5)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9h9.9"
                                    transform="translate(-4.5 -6.8)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                    transform="translate(-6.4 -3)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                            </DeleteTagButton>
                          </>
                        ) : (
                          <>
                            <svg
                              style={{top: '3px', left: '11.5px'}}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.729"
                              height="12.728"
                              viewBox="0 0 12.729 12.728"
                            >
                              <path
                                id="Icon_awesome-plus-circle"
                                data-name="Icon awesome-plus-circle"
                                d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                                transform="translate(6.364 -0.796) rotate(45)"
                                fill="#ff0300"
                              />
                            </svg>

                            <p>{sellerRestriction}</p>
                          </>
                        )}
                      </SellerObligationWrapper>
                    ))}
                    {isConditionEditing && (
                      <AddSellerObligationWrapper>
                        <AddSellerObligationInputWrapper>
                          <input
                            placeholder="Napisz treść ograniczeń..."
                            value={sellerRestriction}
                            onChange={handleSellerRestrictionChange}
                          />
                        </AddSellerObligationInputWrapper>
                        <AddTagButton onClick={() => addSellerRestriction()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddTagButton>
                      </AddSellerObligationWrapper>
                    )}
                    {productType !== 4 && (
                      <>
                        <BlueHeaderComponentCondition>
                          Kanały monetyzacji
                        </BlueHeaderComponentCondition>
                        <ConditionSectionDescription
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To obszary, gdzie Twoi klienci będą mogli zamieścić
                            wyprodukowane treści. W ramach poniższych kanałów,
                            możesz dodatkowo ograniczyć ich prawa do
                            monetyzacji.
                          </p>
                        </ConditionSectionDescription>
                        <ExpandableHeaderWithDescription
                          header="Serwisy cyfrowe"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                To popularne platformy streamingowe, np.
                                Spotify, Tidal. Możesz ograniczyć maksymalną
                                liczbę odtworzeń.
                                <br />
                                Zdecyduj czy klient może bez ograniczeń
                                monetyzować treści, czy ograniczasz mu liczbę
                                dopuszczalnych odtworzeń w serwisach. <br />
                                Zwróć uwagę, że w przypadku ofert WIN-WIN nie
                                warto ograniczać możliwości monetyzacji,
                                ponieważ będziesz czerpać z tego zyski.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                        <SelectViewContainer isEditing={isConditionEditing}>
                          <SelectView isEditing={isConditionEditing}>
                            {!isConditionEditing && <p>Serwisy cyfrowe</p>}
                            <select
                              value={exploitationDigital}
                              onChange={handleExploitationDigitalChange}
                              disabled={!isConditionEditing}
                            >
                              {ExploitationDigitalEnum.map(
                                (exploitationDigital) => {
                                  const {FieldName, FlagValue} =
                                    exploitationDigital;

                                  return (
                                    <option value={FlagValue} key={FlagValue}>
                                      {FieldName}
                                    </option>
                                  );
                                },
                              )}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </SelectViewContainer>
                        <ExpandableHeaderWithDescription
                          header="YouTube"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                Zdecyduj czy dopuszczasz możliwość monetyzacji
                                (w tym ContentID) w serwisie YouTube.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                        <SelectViewContainer isEditing={isConditionEditing}>
                          <SelectView isEditing={isConditionEditing}>
                            {!isConditionEditing && <p>YouTube</p>}
                            <select
                              value={exploitationYoutube}
                              disabled={!isConditionEditing}
                              onChange={handleExploitationYoutubeChange}
                            >
                              {ExploitationYoutubeEnum.map(
                                (exploitationYoutube) => {
                                  const {FieldName, FlagValue} =
                                    exploitationYoutube;

                                  return (
                                    <option value={FlagValue} key={FlagValue}>
                                      {FieldName}
                                    </option>
                                  );
                                },
                              )}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </SelectViewContainer>
                        <ExpandableHeaderWithDescription
                          header="Dystrybucja fizyczna"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                To możliwość wydania Twojej treści w formie np.
                                CD, MC czy winyl.
                                <br />
                                Zdecyduj czy ograniczasz maksymalny nakład w
                                ramach tego szablonu warunków.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                        <SelectViewContainer isEditing={isConditionEditing}>
                          <SelectView isEditing={isConditionEditing}>
                            {!isConditionEditing && <p>Dystrybucja Fizyczna</p>}
                            <select
                              value={exploitationPhysical}
                              onChange={handleExploitationPhysicalChange}
                              disabled={!isConditionEditing}
                            >
                              {ExploitationPhysicalEnum.map(
                                (exploitationPhysical) => {
                                  const {FieldName, FlagValue} =
                                    exploitationPhysical;

                                  return (
                                    <option value={FlagValue} key={FlagValue}>
                                      {FieldName}
                                    </option>
                                  );
                                },
                              )}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </SelectViewContainer>
                      </>
                    )}
                    <BlueHeaderComponentCondition>
                      Warunki współpracy
                    </BlueHeaderComponentCondition>
                    <ConditionSectionDescription isEditing={isConditionEditing}>
                      <p>
                        Wykonując usługę, dostarczając track lub produkt
                        cyfrowy, zawierasz z klientem umowę. W tej sekcji
                        określisz jej szczegółowe warunki.
                      </p>
                    </ConditionSectionDescription>
                    <ExpandableHeaderWithDescription
                      header="Rodzaj umowy"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To określenie czy umowa ma charakter otwarty czy na
                            wyłączność.
                            <br />
                            <br />
                            Umowa otwarta - oznacza to, że ten sam track,
                            produkt cyfrowy lub usługę możesz sprzedać wielu
                            klientom. <br />
                            <br />
                            Umowa na wyłączność - oznacza to, że produkt
                            cyfrowy, track lub usługę może nabyć tylko jeden
                            klient.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    <SelectViewContainer isEditing={isConditionEditing}>
                      <SelectView isEditing={isConditionEditing}>
                        <p>Rodzaj umowy</p>
                        <select
                          disabled={!isConditionEditing}
                          value={isExclusive}
                          onChange={handleIsExclusiveChange}
                        >
                          <option value={1}>Na wyłączność</option>
                          <option value={2}>Otwarta</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <ExpandableHeaderWithDescription
                      header="Czas trwania licencji"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Określ na jaki czas klient nabędzie prawa do
                            wykorzystania tego tracku, produktu cyfrowego lub
                            wykonanej usługi.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    <SelectViewContainer isEditing={isConditionEditing}>
                      <SelectView isEditing={isConditionEditing}>
                        <p>Licencja</p>
                        <select
                          disabled={!isConditionEditing}
                          value={rightEnd}
                          onChange={handleRightEndChange}
                        >
                          {RightsEndEnum.map((rightEnd) => {
                            const {FieldName, FlagValue} = rightEnd;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <ExpandableHeaderWithDescription
                      header="Czas realizacji"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To określenie ile czasu potrzebujesz na wykonanie
                            usługi bądź dostarczenie produktu cyfrowego lub
                            tracku.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    <SelectViewContainer isEditing={isConditionEditing}>
                      <SelectView isEditing={isConditionEditing}>
                        <p>Czas na wykonanie</p>
                        <select
                          disabled={!isConditionEditing}
                          value={timeToFinish}
                          onChange={handleTimeToFinishChange}
                        >
                          {TimeToFinishEnum.map((timeToFinish) => {
                            const {FieldName, FlagValue} = timeToFinish;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </SelectViewContainer>
                    <BlueHeaderComponentCondition>
                      Oznaczenia
                    </BlueHeaderComponentCondition>
                    {!isConditionEditing ? (
                      <>
                        {indicationOfContentAuthor ? (
                          <ConditionSectionDescription
                            isEditing={true}
                            style={{marginBottom: '5px'}}
                          >
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wymaga oznaczenia treści
                              <br />
                              imieniem, nazwiskiem bądź pseudonimem
                            </p>
                          </ConditionSectionDescription>
                        ) : (
                          <ConditionSectionDescription isEditing={true}>
                            <p
                              style={{
                                textAlign: 'left',
                                width: '259px',
                                margin: '0 auto',
                              }}
                            >
                              Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                              udostępnianie
                            </p>
                          </ConditionSectionDescription>
                        )}
                        {indicationOfContentAuthor && (
                          <DisplayGrossValueNoEditing>
                            <p>Oznaczenie</p>
                            <div>
                              <p>{indicationOfContentAuthor}</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        )}
                      </>
                    ) : (
                      <>
                        <ConditionSectionDescription
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Oczekujesz aby kupujący podczas dystrybucji
                            powstałej treści umieszczał Twoje dane w tzw.
                            kredytach?
                          </p>
                        </ConditionSectionDescription>
                        <SmallSectionTitleWithSlider>
                          <h5>Wymagam oznaczenia treści</h5>
                          <ConditionSlider
                            isDisabled={false}
                            value={isIndicationOfContentAuthor}
                            onClick={() =>
                              handleIsIndicationOfContentAuthorChange(
                                !isIndicationOfContentAuthor,
                              )
                            }
                          >
                            <div />
                          </ConditionSlider>
                        </SmallSectionTitleWithSlider>
                        {isIndicationOfContentAuthor && (
                          <>
                            <ConditionSectionDescription
                              isEditing={true}
                              style={{marginBottom: '5px'}}
                            >
                              <p
                                style={{
                                  textAlign: 'left',
                                  width: '259px',
                                  margin: '0 auto',
                                  paddingLeft: '10px',
                                }}
                              >
                                Imię i nazwisko lub pseudonim
                              </p>
                            </ConditionSectionDescription>
                            <StyledInputView
                              style={{
                                marginTop: '0px',
                                width: '259px',
                                margin: '0 auto',
                              }}
                              isEditing={isConditionEditing}
                            >
                              <input
                                type="text"
                                readOnly={false}
                                value={indicationOfContentAuthor}
                                onChange={handleIndicationOfContentAuthorChange}
                              />
                            </StyledInputView>
                          </>
                        )}
                      </>
                    )}
                    <BlueHeaderComponentCondition>
                      Rozliczenia-Warunki finansowe
                    </BlueHeaderComponentCondition>
                    <ConditionSectionDescription isEditing={isConditionEditing}>
                      <p>
                        W tej sekcji określ swoje <br />
                        oczekiwania finansowe.
                      </p>
                    </ConditionSectionDescription>
                    {productType !== 4 && (
                      <>
                        {isConditionEditing && (
                          <SmallSectionTitleWithSlider>
                            <h5>Oferta WIN-WIN</h5>
                            <ConditionSlider
                              isDisabled={!userMetaData.HasMugoLabel}
                              value={
                                !userMetaData.HasMugoLabel ? false : userWinWin
                              }
                              onClick={() =>
                                handleUserWinWinChange(
                                  !userMetaData.HasMugoLabel
                                    ? false
                                    : !userWinWin,
                                )
                              }
                            >
                              <div />
                            </ConditionSlider>
                          </SmallSectionTitleWithSlider>
                        )}
                        {!userMetaData.HasMugoLabel ? (
                          <ConditionSectionDescription
                            isEditing={isConditionEditing}
                          >
                            <p>
                              WIN-WIN to nowy perspektywiczny model współpracy z
                              kupującym, w którym nabywasz udział w prawach do
                              wyprodukowanego w przyszłości dzieła.
                            </p>
                            <p style={{color: '#0091FF'}}>
                              WIN-WIN wymaga posiadania aktywnej umowy
                              wydawniczej w MUGO
                            </p>
                          </ConditionSectionDescription>
                        ) : userMetaData.HasMugoLabel && userWinWin ? (
                          <>
                            <ExpandableArrowWithDescription
                              isEditing={isConditionEditing}
                            >
                              <ConditionSectionDescription
                                style={{flexDirection: 'column'}}
                                isEditing={isConditionEditing}
                              >
                                <ConditionSectionDescriptionExample
                                  isEditing={isConditionEditing}
                                >
                                  <p>
                                    Pamiętaj, że należne Tobie przychody będą
                                    automatycznie rozliczane i pojawiać się będą
                                    w MUGO. Oznacza to, że nie musisz domagać
                                    się należnych Tobie zysków - pieniądze będą
                                    automatycznie rozliczane i wypłacane. Od
                                    tego momentu nie musisz się o to martwić i
                                    narażać się na często nieprzyjemne
                                    upominanie się o swoje należności od
                                    partnera.
                                    <br />
                                    Określ swój udział w jego ewentualnych
                                    przychodach za wykorzystanie Twojego
                                    przedmiotu usługi.
                                  </p>
                                </ConditionSectionDescriptionExample>
                              </ConditionSectionDescription>
                            </ExpandableArrowWithDescription>
                            <ConditionTitleWithInputWrapper
                              isEditing={isConditionEditing}
                            >
                              <p>
                                {!isConditionEditing
                                  ? 'Przychody z serwisów cyfrowch'
                                  : 'Serwisy cyfrowe'}
                              </p>
                              <input
                                style={
                                  !userWinWin
                                    ? {
                                        filter: 'brightness(0.5)',
                                        userSelect: 'none',
                                      }
                                    : {}
                                }
                                disabled={!isConditionEditing}
                                placeholder="%"
                                type="text"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={3}
                                onChange={handleWinWinDigitalChange}
                                value={winWinDigital + '%'}
                              />
                            </ConditionTitleWithInputWrapper>
                            <ConditionTitleWithInputWrapper
                              isEditing={isConditionEditing}
                            >
                              <p>
                                {!isConditionEditing
                                  ? 'Przychody z YouTube'
                                  : 'YouTube'}
                              </p>
                              <input
                                style={
                                  !userWinWin
                                    ? {
                                        filter: 'brightness(0.5)',
                                        userSelect: 'none',
                                      }
                                    : {}
                                }
                                disabled={!isConditionEditing}
                                placeholder="%"
                                type="text"
                                maxLength={3}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={handleWinWinYoutubeChange}
                                value={winWinYoutube + '%'}
                              />
                            </ConditionTitleWithInputWrapper>
                            <ConditionTitleWithInputWrapper
                              isEditing={isConditionEditing}
                            >
                              <p>
                                {!isConditionEditing
                                  ? 'Przychody z dystrybucji fizycznej'
                                  : 'Dystrybucja fizyczna'}
                              </p>
                              <input
                                style={
                                  !userWinWin
                                    ? {
                                        filter: 'brightness(0.5)',
                                        userSelect: 'none',
                                      }
                                    : {}
                                }
                                disabled={!isConditionEditing}
                                placeholder="%"
                                type="text"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={3}
                                onChange={handleWinWinPhysicalChange}
                                value={winWinPhysical + '%'}
                              />
                            </ConditionTitleWithInputWrapper>
                            {isConditionEditing && (
                              <ExpandableHeaderWithDescription
                                header="Data wydania w MUGO"
                                text=""
                                isEditing={isConditionEditing}
                              >
                                <ConditionSectionDescription
                                  style={{flexDirection: 'column'}}
                                  isEditing={isConditionEditing}
                                >
                                  <ConditionSectionDescriptionExample
                                    isEditing={isConditionEditing}
                                  >
                                    <p>
                                      Wprowadź datę, do której kupujący musi
                                      opublikować utwór. Jeśli do tego czasu
                                      kupujący nie dostarczy wydania do MUGO,
                                      Wasza umowa WIN-WIN przestaje być ważna.
                                    </p>
                                  </ConditionSectionDescriptionExample>
                                </ConditionSectionDescription>
                              </ExpandableHeaderWithDescription>
                            )}
                            {isConditionEditing ? (
                              <SelectViewContainer
                                isEditing={isConditionEditing}
                              >
                                <SelectView isEditing={isConditionEditing}>
                                  <p>Data wydania w Mugo</p>
                                  <select
                                    disabled={!isConditionEditing}
                                    value={timeToUploadForCustomer}
                                    onChange={
                                      handleTimeToUploadForCustomerChange
                                    }
                                  >
                                    <option value="">Wybierz</option>
                                    {TimeToUploadForCustomer.map(
                                      (exploitationPhysical) => {
                                        const {FieldName, FlagValue} =
                                          exploitationPhysical;

                                        return (
                                          <option
                                            value={FlagValue}
                                            key={FlagValue}
                                          >
                                            {FieldName}
                                          </option>
                                        );
                                      },
                                    )}
                                  </select>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6.439"
                                    height="11"
                                    viewBox="0 0 6.439 11"
                                  >
                                    <g
                                      id="Arrow_Top_Bar_Accent"
                                      data-name="Arrow – Top Bar / Accent"
                                      transform="translate(6.439 11) rotate(180)"
                                    >
                                      <path
                                        id="Arrow"
                                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                        transform="translate(0 0)"
                                        fill="#3986ef"
                                      />
                                    </g>
                                  </svg>
                                </SelectView>
                              </SelectViewContainer>
                            ) : (
                              <ConditionTitleWithInputWrapper
                                isEditing={isConditionEditing}
                              >
                                <p style={{width: 'calc(100% - 80px)'}}>
                                  Data wydania w MUGO
                                </p>
                                <input
                                  style={{width: '116px'}}
                                  disabled={!isConditionEditing}
                                  type="date"
                                  value={dateToUploadForCustomer}
                                  onChange={handleDateToUploadForCustomerChange}
                                />
                              </ConditionTitleWithInputWrapper>
                            )}
                            <ConditionSectionDescription
                              isEditing={isConditionEditing}
                            >
                              <p>
                                W systemie WIN-WIN oprócz procentowego udziału w
                                przychodach, możesz opcjonalnie wybrać
                                gwarantowaną kwotę wynagrodzenia, którą kupujący
                                od razu musi wpłacić na Twoje konto w MUGO
                                Studio. W tym przypadku musisz dopisać
                                oczekiwaną kwotę w polu &quot;Kwota
                                brutto&quot;.
                              </p>
                            </ConditionSectionDescription>
                          </>
                        ) : (
                          <ConditionSectionDescription
                            isEditing={isConditionEditing}
                          >
                            <p>
                              WIN-WIN to nowy, perspektywiczny model współpracy
                              z kupującym, w którym możesz ustalić swój udział w
                              prawach do wyprodukowanego w przyszłości dzieła,
                              na którym będziesz po prostu zarabiać!
                            </p>
                          </ConditionSectionDescription>
                        )}
                      </>
                    )}
                    <ExpandableHeaderWithDescription
                      header="Cena usługi, produktu"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Każda usługa, produkt ma swoją wartość. Podaj kwotę,
                            jakiej oczekujesz od klienta. <br />
                            <br />
                            Kwota brutto? <br />W przypadku firm, to kwota z
                            podatkiem VAT. Natomiast w przypadku osób fizycznych
                            to kwota, jaką otrzymasz od klienta i od której
                            powinieneś odprowadzić należny podatek dochodowy
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                    {userMetaData.HasIBAN ? (
                      <>
                        {isConditionEditing ? (
                          <ConditionTitleWithInputAndSelectWrapper
                            isEditing={isConditionEditing}
                            style={{marginBottom: '10px'}}
                          >
                            <p>Kwota brutto</p>
                            <InputView
                              isEditing={isConditionEditing}
                              style={{marginTop: 'unset'}}
                            >
                              <input
                                disabled={!isConditionEditing}
                                placeholder="0"
                                min={0}
                                // onBlur={() => getTransactionFee(grossValue)}
                                style={{paddingLeft: 'unset'}}
                                type="number"
                                value={grossValue}
                                onChange={(e) => {
                                  handleGrossValueChange(e);
                                }}
                              />
                            </InputView>

                            <SelectView
                              isEditing={isConditionEditing}
                              style={{marginTop: 'unset'}}
                            >
                              <select
                                value={currency}
                                onChange={handleCurrencyChange}
                                disabled={!isConditionEditing}
                              >
                                {CurrencyEnum.map((currency) => {
                                  const {FieldName, FlagValue} = currency;

                                  if (FlagValue !== 0) return;

                                  return (
                                    <option value={FlagValue} key={FlagValue}>
                                      {FieldName}
                                    </option>
                                  );
                                })}
                              </select>
                            </SelectView>
                          </ConditionTitleWithInputAndSelectWrapper>
                        ) : !isConditionEditing && grossValue ? (
                          <DisplayGrossValueNoEditing>
                            <p>
                              {!isConditionEditing && !userWinWin
                                ? 'Cena (brutto)'
                                : !isConditionEditing &&
                                  userWinWin &&
                                  'Kwota gwarantowana'}
                            </p>
                            <div>
                              <h5>{grossValue}</h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                        ) : (
                          !isConditionEditing && !grossValue && null
                        )}

                        {userMetaData.IsLegal && (
                          <ConditionTitleWithSliderAndSelectWrapper>
                            {isConditionEditing ? (
                              <>
                                <p>Faktura VAT</p>
                                <ConditionSlider
                                  value={invoice}
                                  onClick={() => handleInvoiceChange(!invoice)}
                                >
                                  <div />
                                </ConditionSlider>
                              </>
                            ) : (
                              <CheckboxInfoWrapper
                                style={{
                                  marginBottom: 'unset',
                                  width: '196px',
                                  height: '24px',
                                }}
                              >
                                <p>Faktura VAT</p>
                                <span>{invoice ? 'Tak' : 'Nie'}</span>
                              </CheckboxInfoWrapper>
                            )}

                            <SelectView
                              isEditing={isConditionEditing}
                              style={{width: '100px', marginTop: 'unset'}}
                            >
                              <select
                                value={tax}
                                onChange={handleTaxChange}
                                disabled={!isConditionEditing}
                              >
                                {TaxesEnum.map((tax) => {
                                  const {FieldName, FlagValue} = tax;

                                  return (
                                    <option value={FlagValue} key={FlagValue}>
                                      {FieldName}
                                    </option>
                                  );
                                })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3986ef"
                                  />
                                </g>
                              </svg>
                            </SelectView>
                          </ConditionTitleWithSliderAndSelectWrapper>
                        )}
                        {isConditionEditing && (
                          <>
                            <ExpandableHeaderWithDescription
                              header="Opłata transakcyjna"
                              text=""
                              isEditing={isConditionEditing}
                            >
                              <ConditionSectionDescription
                                style={{flexDirection: 'column'}}
                                isEditing={isConditionEditing}
                              >
                                <ConditionSectionDescriptionExample
                                  isEditing={isConditionEditing}
                                >
                                  <p>
                                    Opłata transakcyjna to kwota, która zostanie
                                    doliczona kupującemu, pokrywająca koszty
                                    obsługi systemu MUGO. <br />
                                    Kwota nie stanowi przychodu sprzedającego.
                                  </p>
                                </ConditionSectionDescriptionExample>
                              </ConditionSectionDescription>
                            </ExpandableHeaderWithDescription>
                          </>
                        )}
                        {grossValue > 0 && (
                          <>
                            <DisplayGrossValueNoEditing
                              style={
                                !isConditionEditing ? {marginTop: '10px'} : {}
                              }
                            >
                              <p>Opłata transakcyjna</p>
                              <div>
                                <h5>
                                  {Number(
                                    (transactionFee?.Brutto / 100).toFixed(2),
                                  )}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueNoEditing>
                            <DisplayGrossValueWithTaxNoEditing>
                              <p>Razem do zapłaty</p>
                              <div>
                                <h5>
                                  {grossValue
                                    ? `${Number(
                                        (
                                          grossValue +
                                          transactionFee?.Brutto / 100
                                        ).toFixed(2),
                                      )}`
                                    : '0'}
                                </h5>
                                <p>PLN</p>
                              </div>
                            </DisplayGrossValueWithTaxNoEditing>
                          </>
                        )}
                        {/* <SelectView
                        isEditing={isConditionEditing}
                        style={{marginBottom: '9px'}}
                      >
                        <p>Parasol ochronny</p>
                        <select
                          disabled={!isConditionEditing}
                          value={protectiveUmbrella}
                          onChange={handleProtectiveUmbrellaChange}
                        >
                          {ProtectiveUmbrellaEnum.map((protectiveUmbrella) => {
                            const {FieldName, FlagValue} = protectiveUmbrella;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView> */}
                      </>
                    ) : (
                      <TextWithLink style={{marginBottom: '40px'}}>
                        <p>Załóż konto Mangopay</p>

                        <span>
                          <Link to={'/producer'} target="_blank">
                            tutaj.{' '}
                          </Link>
                        </span>
                      </TextWithLink>
                    )}
                  </ConditionDetailsWrapper>
                )}
              </SingleColumnContentWrapper>
            </SecondColumnWrapper>

            {params.id ? (
              <SingleColumnWrapper>
                <SingleColumnHeader>
                  <p>Podpięte produkty</p>
                </SingleColumnHeader>
                <AssignedProductsColumnContentWrapper>
                  {assignedProducts.length > 0 && (
                    <AssignedProductsWrapper>
                      {assignedProducts.map((assignedProduct) => {
                        const {Id, Name, ProductType, CoverFiles} =
                          assignedProduct;

                        return (
                          <Link
                            to={`/myproducts/product/${Id}`}
                            key={Id}
                            style={{textDecoration: 'none', width: '100%'}}
                          >
                            <SingleAssignedProductWrapper>
                              {CoverFiles.length > 0 ? (
                                <img src={CoverFiles[0].FilePath} />
                              ) : (
                                <TrackPlaceholderSvgWrapper>
                                  {ProductType === 4 ? (
                                    <ServicePlaceholderSvg />
                                  ) : ProductType === 8 ? (
                                    <TrackPlaceholderSvg />
                                  ) : (
                                    <DigitalProductPlaceholderSvg />
                                  )}
                                </TrackPlaceholderSvgWrapper>
                              )}

                              <SingleAssignedProductContentWrapper>
                                <h5>{Name}</h5>
                                <p>
                                  {
                                    ProductTypeEnum.filter(
                                      (productType) =>
                                        productType.FlagValue === ProductType,
                                    )[0].FieldName
                                  }
                                </p>
                              </SingleAssignedProductContentWrapper>
                            </SingleAssignedProductWrapper>
                          </Link>
                        );
                      })}
                    </AssignedProductsWrapper>
                  )}
                </AssignedProductsColumnContentWrapper>
              </SingleColumnWrapper>
            ) : (
              <SingleColumnWrapper>
                <SingleColumnHeader>
                  <p>Czym są warunki?</p>
                </SingleColumnHeader>
                <ThirdColumnContentWrapper>
                  <h5>
                    Dodanie warunków to krok niezbędny, aby Twoja oferta mogła
                    pojawić się w MUGO Studio.
                  </h5>
                  <p style={{marginTop: '11px'}}>
                    Każda oferta musi zawierać przynajmniej jeden{' '}
                    <strong>szablon warunków współpracy.</strong> Do każdej
                    oferty możesz dodać trzy różne szablony. Dzięki temu Twoja
                    oferta będzie bardziej atrakcyjna.
                  </p>

                  <h4 style={{marginTop: '23px'}}>
                    Przygotuj z wyprzedzeniem swoje szablony, abyś nie tracił
                    czasu, tylko mógł błyskawicznie wystawiać oferty.
                  </h4>
                </ThirdColumnContentWrapper>
              </SingleColumnWrapper>
            )}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MobileMain>
          <Grid>
            {/* <SingleColumnWrapper
              isEditing={
                createConditionStep !== 0
                  ? createConditionStep === 1
                  : isConditionEditing
              }
            >
              <SingleColumnHeader
                isEditing={
                  createConditionStep !== 0
                    ? createConditionStep === 1
                    : isConditionEditing
                }
              >
                <p>Opis szablonu</p>
                {createConditionStep !== 0 && (
                  <EditBtnWrapper
                    style={{top: '2px', right: '4px'}}
                    isEditing={false}
                  >
                    <EditSvgWrapper
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                )}
              </SingleColumnHeader> */}
            <FirstColumnContentWrapper>
              {createConditionStep !== 1 && (
                <MobileSectionHeader>
                  <h2>Opis szablonu</h2>
                  {createConditionStep !== 0 ? (
                    <EditBtnWrapper
                      style={{top: '2px', right: '4px'}}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <EditSvgWrapper
                        onClick={() => {
                          if (createConditionStep === 0) {
                            handleIsConditionEditingChange(!isConditionEditing);
                          }
                        }}
                        isEditing={
                          createConditionStep !== 0
                            ? createConditionStep === 2
                            : isConditionEditing
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  ) : createConditionStep === 0 && !isConditionEditing ? (
                    <EditBtnWrapper
                      style={{top: '2px'}}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <EditSvgWrapper
                        onClick={() => {
                          if (createConditionStep === 0) {
                            handleIsConditionEditingChange(!isConditionEditing);
                          }
                        }}
                        isEditing={
                          createConditionStep !== 0
                            ? createConditionStep === 2
                            : isConditionEditing
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  ) : (
                    <PopupButton
                      buttons={['Kontynuuj', 'Zamknij']}
                      title={'Uwaga'}
                      text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                      onClick={() => {
                        getSingleCondition(singleConditionId);
                        setIsConditionEditing(false);
                      }}
                    >
                      <EditBtnWrapper
                        style={{position: 'unset'}}
                        isEditing={isConditionEditing}
                      >
                        <EditSvgWrapper isEditing={isConditionEditing}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.27"
                            height="16.658"
                            viewBox="0 0 17.27 16.658"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(-3.75 -3.41)"
                            >
                              <path
                                id="Path_3712"
                                data-name="Path 3712"
                                d="M18,30h7.885"
                                transform="translate(-5.615 -10.682)"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3713"
                                data-name="Path 3713"
                                d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </EditSvgWrapper>
                      </EditBtnWrapper>
                    </PopupButton>
                  )}
                </MobileSectionHeader>
              )}

              {createConditionStep === 1 && (
                <>
                  <MobileFirstConditionStepHeader>
                    <p>
                      Dodanie warunków to krok niezbędny, aby Twoja oferta mogła
                      pojawić się w MUGO STUDIO.
                    </p>
                    <p>
                      Każda oferta musi zawierać przynajmniej jeden szablon
                      warunków współpracy. Do każdej oferty możesz dodać trzy
                      różne szablony. Dzięki temu Twoja oferta będzie bardziej
                      atrakcyjna i zwiększysz zainteresowanie.
                    </p>
                    <p>
                      Przygotuj z wyprzedzeniem swoje szablony abyś nie tracił
                      czasu, tylko mógł błyskawicznie wystawiać oferty.
                    </p>
                  </MobileFirstConditionStepHeader>
                  <MobileSectionHeader>
                    <h2>Opis szablonu</h2>
                  </MobileSectionHeader>
                  <StyledSelectView
                    isError={errorVerification.categoryError !== null}
                    isEditing={true}
                    style={{marginTop: '9px'}}
                  >
                    <p>Kategoria oferty</p>

                    <select
                      value={productType}
                      onChange={handleProductTypeChange}
                      disabled={false}
                    >
                      <option hidden value={''}>
                        Wybierz
                      </option>
                      {ProductTypeEnum.map((productType) => {
                        const {FieldName, FlagValue} = productType;

                        if (FlagValue === 2) return;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {getProductTypePolishName(FlagValue)}
                          </option>
                        );
                      })}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#3986ef"
                        />
                      </g>
                    </svg>
                    {errorVerification.categoryError !== null && (
                      <ErrorStar
                        text={errorVerification.categoryError}
                        fixed={true}
                      />
                    )}
                  </StyledSelectView>
                  <StyledInputView
                    style={{marginTop: '18px', width: '100%'}}
                    isError={errorVerification.nameError !== null}
                    isEditing={true}
                  >
                    <p>Nazwa</p>
                    <input
                      type="text"
                      readOnly={false}
                      placeholder="Uzupełnij"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {errorVerification.nameError !== null && (
                      <ErrorStar
                        text={errorVerification.nameError}
                        fixed={true}
                      />
                    )}
                  </StyledInputView>
                  <TextareaWrapper isEditing={true} style={{marginTop: '10px'}}>
                    <h5>Opis</h5>
                    <StyledTextarea
                      maxLength={250}
                      readOnly={false}
                      placeholder="Uzupełnij"
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                    <TextareaLettersCount>
                      {description.length}/250
                    </TextareaLettersCount>
                  </TextareaWrapper>
                  <MobileBlueButton
                    style={{marginTop: '32px'}}
                    onClick={() => goToStep2()}
                  >
                    Dalej
                  </MobileBlueButton>
                </>
              )}
              {createConditionStep === 0 && isConditionEditing ? (
                <>
                  {/* <StyledSelectView
                      isError={errorVerification.categoryError !== null}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing
                      }
                      style={{marginTop: '9px'}}
                    >
                      <p>Kategoria oferty</p>

                      <select
                        value={productType}
                        onChange={handleProductTypeChange}
                        disabled={
                          !(createConditionStep !== 0
                            ? createConditionStep === 1
                            : isConditionEditing)
                        }
                      >
                        <option hidden value={''}>
                          Wybierz
                        </option>
                        {ProductTypeEnum.map((productType) => {
                          const {FieldName, FlagValue} = productType;

                          if (FlagValue === 2) return;

                          return (
                            <option value={FlagValue} key={FlagValue}>
                              {getProductTypePolishName(FlagValue)}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                      {errorVerification.categoryError !== null && (
                        <ErrorStar
                          text={errorVerification.categoryError}
                          fixed={true}
                        />
                      )}
                    </StyledSelectView> */}
                  <DisplayInputWrapper style={{marginBottom: '0px'}}>
                    <h5>Kategoria oferty</h5>
                    <p>
                      {ProductTypeEnum.length > 0 &&
                      ProductTypeEnum.filter(
                        (type) => type.FlagValue === productType,
                      ).length > 0
                        ? getProductTypePolishName(
                            ProductTypeEnum.filter(
                              (type) => type.FlagValue === productType,
                            )[0].FlagValue,
                          )
                        : ''}
                    </p>
                  </DisplayInputWrapper>
                  <StyledInputView
                    style={{marginTop: '18px', width: '100%'}}
                    isError={errorVerification.nameError !== null}
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 1
                        : isConditionEditing
                    }
                  >
                    <p>Nazwa</p>
                    <input
                      type="text"
                      readOnly={
                        !(createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing)
                      }
                      placeholder="Uzupełnij"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {errorVerification.nameError !== null && (
                      <ErrorStar
                        text={errorVerification.nameError}
                        fixed={true}
                      />
                    )}
                  </StyledInputView>
                  <TextareaWrapper
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 1
                        : isConditionEditing
                    }
                    style={{marginTop: '10px'}}
                  >
                    <h5>Opis</h5>
                    <StyledTextarea
                      maxLength={250}
                      readOnly={
                        !(createConditionStep !== 0
                          ? createConditionStep === 1
                          : isConditionEditing)
                      }
                      placeholder="Uzupełnij"
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                    <TextareaLettersCount>
                      {description.length}/250
                    </TextareaLettersCount>
                  </TextareaWrapper>
                  {/* {createConditionStep === 1 && (
                      <BlueButton
                        style={{marginTop: '32px'}}
                        onClick={() => goToStep2()}
                      >
                        Dalej
                      </BlueButton>
                    )} */}
                </>
              ) : createConditionStep !== 1 ? (
                <>
                  <DisplayInputWrapper>
                    <h5>Kategoria oferty</h5>
                    <p>
                      {ProductTypeEnum.length > 0 &&
                      ProductTypeEnum.filter(
                        (type) => type.FlagValue === productType,
                      ).length > 0
                        ? getProductTypePolishName(
                            ProductTypeEnum.filter(
                              (type) => type.FlagValue === productType,
                            )[0].FlagValue,
                          )
                        : ''}
                    </p>
                  </DisplayInputWrapper>
                  <DisplayInputWrapper>
                    <h5>Nazwa</h5>
                    <p>{name}</p>
                  </DisplayInputWrapper>
                  <DisplayTextareaWrapper>
                    <h5>Opis</h5>
                    <p>{description}</p>
                  </DisplayTextareaWrapper>
                </>
              ) : null}
            </FirstColumnContentWrapper>
            {/* </SingleColumnWrapper> */}

            {/* <SecondColumnWrapper
              isEditing={
                createConditionStep !== 0
                  ? createConditionStep === 2
                  : isConditionEditing
              }
            >
              {createConditionStep === 2 && (
                <SaveConditionButton
                  onClick={() => {
                    createCondition();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.5"
                    height="15.5"
                    viewBox="0 0 15.5 15.5"
                  >
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M18.278,25.722V19.5H10.5v6.222"
                        transform="translate(-2.889 -7.222)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V8.389h6.222"
                        transform="translate(-2.889)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </SaveConditionButton>
              )}
              {createConditionStep === 0 && isConditionEditing && (
                <SaveConditionButton
                  onClick={() => {
                    if (isDefault) {
                      createConditionFromDefault();
                    } else {
                      updateCondition();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.5"
                    height="15.5"
                    viewBox="0 0 15.5 15.5"
                  >
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M18.278,25.722V19.5H10.5v6.222"
                        transform="translate(-2.889 -7.222)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V8.389h6.222"
                        transform="translate(-2.889)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </SaveConditionButton>
              )}
              <SingleColumnHeader
                isEditing={
                  createConditionStep !== 0
                    ? createConditionStep === 2
                    : isConditionEditing
                }
              >
                {createConditionStep === 2 && (
                  <ArrowButtonLeft onClick={() => backToStep1()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117.224.157,5.127a.523.523,0,0,0,0,.747l4.961,4.9a.781.781,0,0,0,1.095,0,.76.76,0,0,0,0-1.082L1.969,5.5,6.212,1.306a.76.76,0,0,0,0-1.082.781.781,0,0,0-1.095,0"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </ArrowButtonLeft>
                )}
                <p>Warunki oferty</p>
                {createConditionStep !== 0 ? (
                  <EditBtnWrapper
                    style={{top: '2px', right: '4px'}}
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 2
                        : isConditionEditing
                    }
                  >
                    <EditSvgWrapper
                      onClick={() => {
                        if (createConditionStep === 0) {
                          handleIsConditionEditingChange(!isConditionEditing);
                        }
                      }}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                ) : createConditionStep === 0 && !isConditionEditing ? (
                  <EditBtnWrapper
                    style={{top: '2px'}}
                    isEditing={
                      createConditionStep !== 0
                        ? createConditionStep === 2
                        : isConditionEditing
                    }
                  >
                    <EditSvgWrapper
                      onClick={() => {
                        if (createConditionStep === 0) {
                          handleIsConditionEditingChange(!isConditionEditing);
                        }
                      }}
                      isEditing={
                        createConditionStep !== 0
                          ? createConditionStep === 2
                          : isConditionEditing
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                ) : (
                  <PopupButton
                    buttons={['Kontynuuj', 'Zamknij']}
                    title={'Uwaga'}
                    text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                    onClick={() => {
                      getSingleCondition(singleConditionId);
                      setIsConditionEditing(false);
                    }}
                  >
                    <EditBtnWrapper
                      style={{top: '0px'}}
                      isEditing={isConditionEditing}
                    >
                      <EditSvgWrapper isEditing={isConditionEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  </PopupButton>
                )}
              </SingleColumnHeader> */}
            <SingleColumnContentWrapper>
              {createConditionStep !== 1 && (
                <ConditionDetailsWrapper>
                  <MobileConditionDetailHeader>
                    Twoje warunki oferty
                  </MobileConditionDetailHeader>
                  <BlueHeaderComponentCondition>
                    Deklaracje
                  </BlueHeaderComponentCondition>
                  <ConditionSectionDescription isEditing={isConditionEditing}>
                    <p>
                      Opisz w punktach, co wykonasz w ramach swojej oferty,
                      czyli do czego się zobowiązujesz i co wykluczasz, a więc
                      czego Twoja oferta nie zawiera.
                    </p>
                  </ConditionSectionDescription>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      text=""
                      isEditing={isConditionEditing}
                      header="Zobowiązania"
                      isLongDescription
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To bardzo ważne, żeby szczegółowo opisać usługę czy
                            produkt - unikniesz dzięki temu nieporozumień.
                          </p>
                          <br />
                          <p>Przykłady: </p>
                        </ConditionSectionDescriptionExample>

                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA
                            FAN PAGE’A NA FACEBOOKU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Facebook
                            poniedziałek-piątek{' '}
                          </p>
                          <span>Zobowiązania:</span>
                          <span>- Zamieszczę 3 posty w tygodniu. </span>
                          <span>- Do postów dodam grafikę. </span>
                          <span>
                            - Posty będą publikowane o godzinie 10:00.{' '}
                          </span>
                          <span>
                            - Będę moderował posty od 09:00 do 21:00 w dni
                            powszednie.{' '}
                          </span>
                          <span>
                            - Każdy wpis będzie zawierał unikalną treść.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO
                            UTWORU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> WIN-WIN
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Zobowiązuję się stworzyć rymowany tekst utworu po
                            wskazaniu tematyki przez zamawiającego.{' '}
                          </span>
                          <span>
                            - Tekst będzie składał się z trzech zwrotek
                            przeplatanych refrenem (zwrotki 16 taktów, refren 8
                            taktów).{' '}
                          </span>
                          <span>
                            - Usługę zrealizuję w ciągu pięciu dni roboczych od
                            złożenia zamówienia.{' '}
                          </span>
                          <span>
                            - Dopuszczam dwie sesje poprawkowe po zbiorowym
                            wskazaniu poprawek w tekście przez zamawiającego.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW
                            MUZYCZNYCH
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Prosty teledysk
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Zapewniam kamerę lub aparat z rozdzielczością 4K
                            oraz podstawowy zestaw oświetleniowy.{' '}
                          </span>
                          <span>
                            - Zapewniam 10 godzin montażu materiału po
                            ewentualnych sugestiach klienta.{' '}
                          </span>
                          <span>
                            - Zdjęcia będą trwały w jednym dniu przez osiem
                            godzin po wcześniejszym ustaleniu daty z klientem.{' '}
                          </span>
                          <span>
                            - Dopuszczam dwie darmowe poprawki po montażu, każda
                            kolejna będzie kosztować 300 złotych.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> SEO COPYWRITING -
                            ARTYKUŁY BLOGOWE
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO
                            do 5 tysięcy znaków ze spacjami
                          </p>
                          <span>Zobowiązania:</span>
                          <span>
                            - Tekst będzie dotyczył wybranej przez klienta
                            tematyki.{' '}
                          </span>
                          <span>
                            - Tekst będzie zawierał unikalną treść, która nie
                            jest skopiowana z innych źródeł.{' '}
                          </span>
                          <span>
                            - Artykuł będzie liczył do 5000 znaków ze spacjami.{' '}
                          </span>
                          <span>
                            - Tekst będzie zawierał frazę główną w nagłówku H1
                            oraz pomocnicze słowa kluczowe w nagłówkach H2 i H3.
                          </span>
                          <span>
                            - Zezwalam na to, by klient podpisał się pod moim
                            tekstem własnym imieniem i nazwiskiem.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  {!isConditionEditing && (
                    <SectionHeaderNoEditing
                      style={{width: 'calc(100% - 28px)'}}
                    >
                      <p>Zobowiązania</p>
                    </SectionHeaderNoEditing>
                  )}
                  {sellerObligations.map((sellerObligation) => (
                    <SellerObligationWrapper
                      style={
                        isConditionEditing
                          ? {gap: '5px', paddingLeft: '12px', width: '326px'}
                          : {
                              paddingLeft: '0px',
                              width: '100%',
                              gap: '5px',
                            }
                      }
                      key={sellerObligation}
                      isEditing={isConditionEditing}
                    >
                      {isConditionEditing ? (
                        <>
                          <p>{sellerObligation}</p>
                          <DeleteTagButton
                            style={{marginTop: '1px', marginLeft: '1px'}}
                            onClick={() =>
                              deleteSellerObligations(sellerObligation)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.9"
                              height="12"
                              viewBox="0 0 10.9 12"
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </DeleteTagButton>
                        </>
                      ) : (
                        <>
                          <svg
                            style={{position: 'unset'}}
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              id="Icon_awesome-check-circle"
                              data-name="Icon awesome-check-circle"
                              d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                              transform="translate(-0.563 -0.563)"
                              fill="#00d10f"
                            />
                          </svg>
                          <p>{sellerObligation}</p>
                        </>
                      )}
                    </SellerObligationWrapper>
                  ))}
                  {isConditionEditing && (
                    <AddSellerObligationWrapper style={{width: '326px'}}>
                      <AddSellerObligationInputWrapper>
                        <input
                          placeholder="Napisz treść zobowiązania..."
                          value={sellerObligation}
                          onChange={handleSellerObligationChange}
                        />
                      </AddSellerObligationInputWrapper>
                      <AddTagButton onClick={() => addSellerObligation()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.138"
                          height="15"
                          viewBox="0 0 15.138 15"
                        >
                          <path
                            id="Path_3683"
                            data-name="Path 3683"
                            d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                            transform="translate(-171.869 -100.292)"
                            fill="#fff"
                          />
                        </svg>
                      </AddTagButton>
                    </AddSellerObligationWrapper>
                  )}
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      text=""
                      header="Wykluczenia, ograniczenia"
                      isEditing={isConditionEditing}
                      isLongDescription
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Podaj ograniczenia. <br />
                            To zapobiegnie ewentualnym sporom.
                          </p>
                          <br />
                          <p>Przykłady: </p>
                        </ConditionSectionDescriptionExample>

                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> USŁUGA PROWADZENIA
                            FAN PAGE’A NA FACEBOOKU{' '}
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Facebook
                            poniedziałek-piątek{' '}
                          </p>
                          <span>Ograniczenia:</span>
                          <span>
                            - Nie będę dokonywał moderacji postów w weekendy.{' '}
                          </span>
                          <span>
                            - Nie będę prowadził korespondencji z klientami
                            firmy za pośrednictwem wiadomości prywatnych w
                            serwisie Facebook.{' '}
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> NAPISZĘ TEKST DO
                            UTWORU
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> WIN-WIN
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Tekst nie będzie zawierał sformułowań
                            rasistowskich, seksistowskich i nawołujących do
                            nienawiści na tle religijnym.
                          </span>
                          <span>
                            - Tekst nie może być wykorzystany w reklamie
                            komercyjnej.
                          </span>
                          <span>
                            - Nie zgadzam się na umieszczenie utworu z moim
                            tekstem na innych wydawnictwach, niż dyskografia
                            zamawiającego (a więc między innymi na składankach i
                            mixtape’ach).
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> PRODUKCJA TELEDYSKÓW
                            MUZYCZNYCH
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Prosty teledysk
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Teledysk lub jego fragmenty nie mogą być
                            wykorzystywane w reklamach.
                          </span>
                          <span>
                            - Dobór aktorów i aktorek do teledysku pozostaje po
                            stronie klienta.
                          </span>
                        </ConditionSectionDescriptionExample>
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p style={{marginBottom: '0px'}}>
                            <strong>Nazwa oferty:</strong> SEO COPYWRITING -
                            ARTYKUŁY BLOGOWE
                          </p>
                          <p>
                            <strong>Nazwa szablonu:</strong> Artykuł blogowy SEO
                            do 5 tysięcy znaków ze spacjami
                          </p>
                          <span>Ograniczenia: </span>
                          <span>
                            - Tekst nie może ukazać się w innym miejscu, niż
                            blog klienta.
                          </span>
                          <span>
                            - Klient nie może dokonać zmian w artykule - dotyczy
                            to zarówno treści, jak i tytułu oraz nagłówków.
                          </span>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  {!isConditionEditing && sellerRestrictions.length > 0 && (
                    <SectionHeaderNoEditing
                      style={{width: 'calc(100% - 28px)'}}
                    >
                      <p>Wykluczenia i ograniczenia</p>
                    </SectionHeaderNoEditing>
                  )}
                  {sellerRestrictions.map((sellerRestriction) => (
                    <SellerObligationWrapper
                      style={
                        isConditionEditing
                          ? {gap: '5px', paddingLeft: '12px', width: '326px'}
                          : {
                              paddingLeft: '0px',
                              width: '100%',
                              gap: '5px',
                            }
                      }
                      key={sellerRestriction}
                      isEditing={isConditionEditing}
                    >
                      {isConditionEditing ? (
                        <>
                          <p>{sellerRestriction}</p>
                          <DeleteTagButton
                            style={{marginTop: '1px', marginLeft: '1px'}}
                            onClick={() =>
                              deleteSellerRestrictions(sellerRestriction)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.9"
                              height="12"
                              viewBox="0 0 10.9 12"
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </DeleteTagButton>
                        </>
                      ) : (
                        <>
                          <svg
                            style={{position: 'unset'}}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.729"
                            height="12.728"
                            viewBox="0 0 12.729 12.728"
                          >
                            <path
                              id="Icon_awesome-plus-circle"
                              data-name="Icon awesome-plus-circle"
                              d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                              transform="translate(6.364 -0.796) rotate(45)"
                              fill="#ff0300"
                            />
                          </svg>

                          <p>{sellerRestriction}</p>
                        </>
                      )}
                    </SellerObligationWrapper>
                  ))}
                  {isConditionEditing && (
                    <AddSellerObligationWrapper style={{width: '326px'}}>
                      <AddSellerObligationInputWrapper>
                        <input
                          placeholder="Napisz treść ograniczeń..."
                          value={sellerRestriction}
                          onChange={handleSellerRestrictionChange}
                        />
                      </AddSellerObligationInputWrapper>
                      <AddTagButton onClick={() => addSellerRestriction()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.138"
                          height="15"
                          viewBox="0 0 15.138 15"
                        >
                          <path
                            id="Path_3683"
                            data-name="Path 3683"
                            d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                            transform="translate(-171.869 -100.292)"
                            fill="#fff"
                          />
                        </svg>
                      </AddTagButton>
                    </AddSellerObligationWrapper>
                  )}
                  {productType !== 4 && (
                    <>
                      <BlueHeaderComponentCondition>
                        Kanały monetyzacji
                      </BlueHeaderComponentCondition>
                      <ConditionSectionDescription
                        isEditing={isConditionEditing}
                      >
                        <p>
                          To obszary, gdzie Twoi klienci będą mogli zamieścić
                          wyprodukowane treści. W ramach poniższych kanałów,
                          możesz dodatkowo ograniczyć ich prawa do monetyzacji.
                        </p>
                      </ConditionSectionDescription>
                      <ExpandableHeaderWithDescriptionWrapper>
                        <ExpandableHeaderWithDescription
                          header="Serwisy cyfrowe"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                To popularne platformy streamingowe, np.
                                Spotify, Tidal. Możesz ograniczyć maksymalną
                                liczbę odtworzeń.
                                <br />
                                Zdecyduj czy klient może bez ograniczeń
                                monetyzować treści, czy ograniczasz mu liczbę
                                dopuszczalnych odtworzeń w serwisach. <br />
                                Zwróć uwagę, że w przypadku ofert WIN-WIN nie
                                warto ograniczać możliwości monetyzacji,
                                ponieważ będziesz czerpać z tego zyski.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                      </ExpandableHeaderWithDescriptionWrapper>
                      <SelectViewContainer
                        isEditing={isConditionEditing}
                        style={{width: '326px'}}
                      >
                        <SelectView isEditing={isConditionEditing}>
                          {!isConditionEditing && <p>Serwisy cyfrowe</p>}
                          <select
                            value={exploitationDigital}
                            onChange={handleExploitationDigitalChange}
                            disabled={!isConditionEditing}
                          >
                            {ExploitationDigitalEnum.map(
                              (exploitationDigital) => {
                                const {FieldName, FlagValue} =
                                  exploitationDigital;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <ExpandableHeaderWithDescriptionWrapper>
                        <ExpandableHeaderWithDescription
                          header="YouTube"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                Zdecyduj czy dopuszczasz możliwość monetyzacji
                                (w tym ContentID) w serwisie YouTube.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                      </ExpandableHeaderWithDescriptionWrapper>
                      <SelectViewContainer
                        isEditing={isConditionEditing}
                        style={{width: '326px'}}
                      >
                        <SelectView isEditing={isConditionEditing}>
                          {!isConditionEditing && <p>YouTube</p>}
                          <select
                            value={exploitationYoutube}
                            disabled={!isConditionEditing}
                            onChange={handleExploitationYoutubeChange}
                          >
                            {ExploitationYoutubeEnum.map(
                              (exploitationYoutube) => {
                                const {FieldName, FlagValue} =
                                  exploitationYoutube;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                      <ExpandableHeaderWithDescriptionWrapper>
                        <ExpandableHeaderWithDescription
                          header="Dystrybucja fizyczna"
                          text=""
                          isEditing={isConditionEditing}
                        >
                          <ConditionSectionDescription
                            style={{flexDirection: 'column'}}
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescriptionExample
                              isEditing={isConditionEditing}
                            >
                              <p>
                                To możliwość wydania Twojej treści w formie np.
                                CD, MC czy winyl.
                                <br />
                                Zdecyduj czy ograniczasz maksymalny nakład w
                                ramach tego szablonu warunków.
                              </p>
                            </ConditionSectionDescriptionExample>
                          </ConditionSectionDescription>
                        </ExpandableHeaderWithDescription>
                      </ExpandableHeaderWithDescriptionWrapper>
                      <SelectViewContainer
                        isEditing={isConditionEditing}
                        style={{width: '326px'}}
                      >
                        <SelectView isEditing={isConditionEditing}>
                          {!isConditionEditing && <p>Dystrybucja Fizyczna</p>}
                          <select
                            value={exploitationPhysical}
                            onChange={handleExploitationPhysicalChange}
                            disabled={!isConditionEditing}
                          >
                            {ExploitationPhysicalEnum.map(
                              (exploitationPhysical) => {
                                const {FieldName, FlagValue} =
                                  exploitationPhysical;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              },
                            )}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                        </SelectView>
                      </SelectViewContainer>
                    </>
                  )}
                  <BlueHeaderComponentCondition>
                    Warunki współpracy
                  </BlueHeaderComponentCondition>
                  <ConditionSectionDescription isEditing={isConditionEditing}>
                    <p>
                      Wykonując usługę, dostarczając track lub produkt cyfrowy,
                      zawierasz z klientem umowę. W tej sekcji określisz jej
                      szczegółowe warunki.
                    </p>
                  </ConditionSectionDescription>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header="Rodzaj umowy"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To określenie czy umowa ma charakter otwarty czy na
                            wyłączność.
                            <br />
                            <br />
                            Umowa otwarta - oznacza to, że ten sam track,
                            produkt cyfrowy lub usługę możesz sprzedać wielu
                            klientom. <br />
                            <br />
                            Umowa na wyłączność - oznacza to, że produkt
                            cyfrowy, track lub usługę może nabyć tylko jeden
                            klient.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <SelectViewContainer
                    isEditing={isConditionEditing}
                    style={{width: '326px'}}
                  >
                    <SelectView isEditing={isConditionEditing}>
                      <p>Rodzaj umowy</p>
                      <select
                        disabled={!isConditionEditing}
                        value={isExclusive}
                        onChange={handleIsExclusiveChange}
                      >
                        <option value={1}>Na wyłączność</option>
                        <option value={2}>Otwarta</option>
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </SelectViewContainer>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header="Czas trwania licencji"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Określ na jaki czas klient nabędzie prawa do
                            wykorzystania tego tracku, produktu cyfrowego lub
                            wykonanej usługi.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <SelectViewContainer
                    isEditing={isConditionEditing}
                    style={{width: '326px'}}
                  >
                    <SelectView isEditing={isConditionEditing}>
                      <p>Licencja</p>
                      <select
                        disabled={!isConditionEditing}
                        value={rightEnd}
                        onChange={handleRightEndChange}
                      >
                        {RightsEndEnum.map((rightEnd) => {
                          const {FieldName, FlagValue} = rightEnd;

                          return (
                            <option value={FlagValue} key={FlagValue}>
                              {FieldName}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </SelectViewContainer>
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header="Czas realizacji"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            To określenie ile czasu potrzebujesz na wykonanie
                            usługi bądź dostarczenie produktu cyfrowego lub
                            tracku.
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  <SelectViewContainer
                    isEditing={isConditionEditing}
                    style={{width: '326px'}}
                  >
                    <SelectView isEditing={isConditionEditing}>
                      <p>Czas na wykonanie</p>
                      <select
                        disabled={!isConditionEditing}
                        value={timeToFinish}
                        onChange={handleTimeToFinishChange}
                      >
                        {TimeToFinishEnum.map((timeToFinish) => {
                          const {FieldName, FlagValue} = timeToFinish;

                          return (
                            <option value={FlagValue} key={FlagValue}>
                              {FieldName}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </SelectViewContainer>
                  <BlueHeaderComponentCondition>
                    Oznaczenia
                  </BlueHeaderComponentCondition>
                  {!isConditionEditing ? (
                    <>
                      {indicationOfContentAuthor ? (
                        <ConditionSectionDescription
                          isEditing={true}
                          style={{marginBottom: '5px'}}
                        >
                          <p
                            style={{
                              textAlign: 'left',
                              width: '326px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wymaga oznaczenia treści
                            <br />
                            imieniem, nazwiskiem bądź pseudonimem
                          </p>
                        </ConditionSectionDescription>
                      ) : (
                        <ConditionSectionDescription isEditing={true}>
                          <p
                            style={{
                              textAlign: 'left',
                              width: '326px',
                              margin: '0 auto',
                            }}
                          >
                            Sprzedawca wyraża zgodę na anonimowe <br /> dalsze
                            udostępnianie
                          </p>
                        </ConditionSectionDescription>
                      )}
                      {indicationOfContentAuthor && (
                        <DisplayGrossValueNoEditing style={{width: '326px'}}>
                          <p>Oznaczenie</p>
                          <div>
                            <p>{indicationOfContentAuthor}</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      )}
                    </>
                  ) : (
                    <>
                      <ConditionSectionDescription
                        isEditing={isConditionEditing}
                      >
                        <p>
                          Oczekujesz aby kupujący podczas dystrybucji powstałej
                          treści umieszczał Twoje dane w tzw. kredytach?
                        </p>
                      </ConditionSectionDescription>
                      <SmallSectionTitleWithSlider style={{width: '326px'}}>
                        <h5>Wymagam oznaczenia treści</h5>
                        <ConditionSlider
                          isDisabled={false}
                          value={isIndicationOfContentAuthor}
                          onClick={() =>
                            handleIsIndicationOfContentAuthorChange(
                              !isIndicationOfContentAuthor,
                            )
                          }
                        >
                          <div />
                        </ConditionSlider>
                      </SmallSectionTitleWithSlider>
                      {isIndicationOfContentAuthor && (
                        <>
                          <ConditionSectionDescription
                            isEditing={true}
                            style={{marginBottom: '5px'}}
                          >
                            <p
                              style={{
                                textAlign: 'left',
                                width: '326px',
                                margin: '0 auto',
                                paddingLeft: '10px',
                              }}
                            >
                              Imię i nazwisko lub pseudonim
                            </p>
                          </ConditionSectionDescription>
                          <StyledInputView
                            style={{
                              marginTop: '0px',
                              width: '326px',
                              margin: '0 auto',
                            }}
                            isEditing={isConditionEditing}
                          >
                            <input
                              type="text"
                              readOnly={false}
                              value={indicationOfContentAuthor}
                              onChange={handleIndicationOfContentAuthorChange}
                            />
                          </StyledInputView>
                        </>
                      )}
                    </>
                  )}
                  <BlueHeaderComponentCondition>
                    Rozliczenia-Warunki finansowe
                  </BlueHeaderComponentCondition>
                  <ConditionSectionDescription isEditing={isConditionEditing}>
                    <p>
                      W tej sekcji określ swoje <br />
                      oczekiwania finansowe.
                    </p>
                  </ConditionSectionDescription>
                  {productType !== 4 && (
                    <>
                      {isConditionEditing && (
                        <SmallSectionTitleWithSlider style={{width: '326px'}}>
                          <h5>Oferta WIN-WIN</h5>
                          <ConditionSlider
                            isDisabled={!userMetaData.HasMugoLabel}
                            value={
                              !userMetaData.HasMugoLabel ? false : userWinWin
                            }
                            onClick={() =>
                              handleUserWinWinChange(
                                !userMetaData.HasMugoLabel
                                  ? false
                                  : !userWinWin,
                              )
                            }
                          >
                            <div />
                          </ConditionSlider>
                        </SmallSectionTitleWithSlider>
                      )}
                      {!userMetaData.HasMugoLabel ? (
                        <ConditionSectionDescription
                          isEditing={isConditionEditing}
                        >
                          <p>
                            WIN-WIN to nowy perspektywiczny model współpracy z
                            kupującym, w którym nabywasz udział w prawach do
                            wyprodukowanego w przyszłości dzieła.
                          </p>
                          <p style={{color: '#0091FF'}}>
                            WIN-WIN wymaga posiadania aktywnej umowy wydawniczej
                            w MUGO
                          </p>
                        </ConditionSectionDescription>
                      ) : userMetaData.HasMugoLabel && userWinWin ? (
                        <>
                          <ExpandableArrowWithDescription
                            isEditing={isConditionEditing}
                          >
                            <ConditionSectionDescription
                              style={{flexDirection: 'column'}}
                              isEditing={isConditionEditing}
                            >
                              <ConditionSectionDescriptionExample
                                isEditing={isConditionEditing}
                              >
                                <p>
                                  Pamiętaj, że należne Tobie przychody będą
                                  automatycznie rozliczane i pojawiać się będą w
                                  MUGO. Oznacza to, że nie musisz domagać się
                                  należnych Tobie zysków - pieniądze będą
                                  automatycznie rozliczane i wypłacane. Od tego
                                  momentu nie musisz się o to martwić i narażać
                                  się na często nieprzyjemne upominanie się o
                                  swoje należności od partnera.
                                  <br />
                                  Określ swój udział w jego ewentualnych
                                  przychodach za wykorzystanie Twojego
                                  przedmiotu usługi.
                                </p>
                              </ConditionSectionDescriptionExample>
                            </ConditionSectionDescription>
                          </ExpandableArrowWithDescription>
                          <ConditionTitleWithInputWrapper
                            isEditing={isConditionEditing}
                            style={{width: '326px'}}
                          >
                            <p>
                              {!isConditionEditing
                                ? 'Przychody z serwisów cyfrowch'
                                : 'Serwisy cyfrowe'}
                            </p>
                            <input
                              style={
                                !userWinWin
                                  ? {
                                      filter: 'brightness(0.5)',
                                      userSelect: 'none',
                                    }
                                  : {}
                              }
                              disabled={!isConditionEditing}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              onChange={handleWinWinDigitalChange}
                              value={winWinDigital + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper
                            style={{width: '326px'}}
                            isEditing={isConditionEditing}
                          >
                            <p>
                              {!isConditionEditing
                                ? 'Przychody z YouTube'
                                : 'YouTube'}
                            </p>
                            <input
                              style={
                                !userWinWin
                                  ? {
                                      filter: 'brightness(0.5)',
                                      userSelect: 'none',
                                    }
                                  : {}
                              }
                              disabled={!isConditionEditing}
                              placeholder="%"
                              type="text"
                              maxLength={3}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={handleWinWinYoutubeChange}
                              value={winWinYoutube + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          <ConditionTitleWithInputWrapper
                            style={{width: '326px'}}
                            isEditing={isConditionEditing}
                          >
                            <p>
                              {!isConditionEditing
                                ? 'Przychody z dystrybucji fizycznej'
                                : 'Dystrybucja fizyczna'}
                            </p>
                            <input
                              style={
                                !userWinWin
                                  ? {
                                      filter: 'brightness(0.5)',
                                      userSelect: 'none',
                                    }
                                  : {}
                              }
                              disabled={!isConditionEditing}
                              placeholder="%"
                              type="text"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={3}
                              onChange={handleWinWinPhysicalChange}
                              value={winWinPhysical + '%'}
                            />
                          </ConditionTitleWithInputWrapper>
                          {isConditionEditing && (
                            <ExpandableHeaderWithDescriptionWrapper>
                              <ExpandableHeaderWithDescription
                                header="Data wydania w MUGO"
                                text=""
                                isEditing={isConditionEditing}
                              >
                                <ConditionSectionDescription
                                  style={{flexDirection: 'column'}}
                                  isEditing={isConditionEditing}
                                >
                                  <ConditionSectionDescriptionExample
                                    isEditing={isConditionEditing}
                                  >
                                    <p>
                                      Wprowadź datę, do której kupujący musi
                                      opublikować utwór. Jeśli do tego czasu
                                      kupujący nie dostarczy wydania do MUGO,
                                      Wasza umowa WIN-WIN przestaje być ważna.
                                    </p>
                                  </ConditionSectionDescriptionExample>
                                </ConditionSectionDescription>
                              </ExpandableHeaderWithDescription>
                            </ExpandableHeaderWithDescriptionWrapper>
                          )}
                          {isConditionEditing ? (
                            <SelectViewContainer
                              isEditing={isConditionEditing}
                              style={{width: '326px'}}
                            >
                              <SelectView isEditing={isConditionEditing}>
                                <p>Data wydania w Mugo</p>
                                <select
                                  disabled={!isConditionEditing}
                                  value={timeToUploadForCustomer}
                                  onChange={handleTimeToUploadForCustomerChange}
                                >
                                  <option value="">Wybierz</option>
                                  {TimeToUploadForCustomer.map(
                                    (exploitationPhysical) => {
                                      const {FieldName, FlagValue} =
                                        exploitationPhysical;

                                      return (
                                        <option
                                          value={FlagValue}
                                          key={FlagValue}
                                        >
                                          {FieldName}
                                        </option>
                                      );
                                    },
                                  )}
                                </select>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6.439"
                                  height="11"
                                  viewBox="0 0 6.439 11"
                                >
                                  <g
                                    id="Arrow_Top_Bar_Accent"
                                    data-name="Arrow – Top Bar / Accent"
                                    transform="translate(6.439 11) rotate(180)"
                                  >
                                    <path
                                      id="Arrow"
                                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                      transform="translate(0 0)"
                                      fill="#3986ef"
                                    />
                                  </g>
                                </svg>
                              </SelectView>
                            </SelectViewContainer>
                          ) : (
                            <ConditionTitleWithInputWrapper
                              isEditing={isConditionEditing}
                              style={{width: '326px'}}
                            >
                              <p style={{width: 'calc(100% - 80px)'}}>
                                Data wydania w MUGO
                              </p>
                              <input
                                style={{width: '116px'}}
                                disabled={!isConditionEditing}
                                type="date"
                                value={dateToUploadForCustomer}
                                onChange={handleDateToUploadForCustomerChange}
                              />
                            </ConditionTitleWithInputWrapper>
                          )}
                          <ConditionSectionDescription
                            isEditing={isConditionEditing}
                          >
                            <p>
                              W systemie WIN-WIN oprócz procentowego udziału w
                              przychodach, możesz opcjonalnie wybrać
                              gwarantowaną kwotę wynagrodzenia, którą kupujący
                              od razu musi wpłacić na Twoje konto w MUGO Studio.
                              W tym przypadku musisz dopisać oczekiwaną kwotę w
                              polu &quot;Kwota brutto&quot;.
                            </p>
                          </ConditionSectionDescription>
                        </>
                      ) : (
                        <ConditionSectionDescription
                          isEditing={isConditionEditing}
                        >
                          <p>
                            WIN-WIN to nowy, perspektywiczny model współpracy z
                            kupującym, w którym możesz ustalić swój udział w
                            prawach do wyprodukowanego w przyszłości dzieła, na
                            którym będziesz po prostu zarabiać!
                          </p>
                        </ConditionSectionDescription>
                      )}
                    </>
                  )}
                  <ExpandableHeaderWithDescriptionWrapper>
                    <ExpandableHeaderWithDescription
                      header="Cena usługi, produktu"
                      text=""
                      isEditing={isConditionEditing}
                    >
                      <ConditionSectionDescription
                        style={{flexDirection: 'column'}}
                        isEditing={isConditionEditing}
                      >
                        <ConditionSectionDescriptionExample
                          isEditing={isConditionEditing}
                        >
                          <p>
                            Każda usługa, produkt ma swoją wartość. Podaj kwotę,
                            jakiej oczekujesz od klienta. <br />
                            <br />
                            Kwota brutto? <br />W przypadku firm, to kwota z
                            podatkiem VAT. Natomiast w przypadku osób fizycznych
                            to kwota, jaką otrzymasz od klienta i od której
                            powinieneś odprowadzić należny podatek dochodowy
                          </p>
                        </ConditionSectionDescriptionExample>
                      </ConditionSectionDescription>
                    </ExpandableHeaderWithDescription>
                  </ExpandableHeaderWithDescriptionWrapper>
                  {userMetaData.HasIBAN ? (
                    <>
                      {isConditionEditing ? (
                        <ConditionTitleWithInputAndSelectWrapper
                          isEditing={isConditionEditing}
                          style={{marginBottom: '10px', width: '326px'}}
                        >
                          <p>Kwota brutto</p>
                          <InputView
                            isEditing={isConditionEditing}
                            style={{
                              marginTop: 'unset',
                              border: 'none',
                              background: 'transparent',
                            }}
                          >
                            <input
                              disabled={!isConditionEditing}
                              placeholder="0"
                              min={0}
                              // onBlur={() => getTransactionFee(grossValue)}
                              style={{paddingLeft: 'unset'}}
                              type="number"
                              value={grossValue}
                              onChange={(e) => {
                                handleGrossValueChange(e);
                              }}
                            />
                          </InputView>

                          <SelectView
                            isEditing={isConditionEditing}
                            style={{marginTop: 'unset'}}
                          >
                            <select
                              value={currency}
                              onChange={handleCurrencyChange}
                              disabled={!isConditionEditing}
                            >
                              {CurrencyEnum.map((currency) => {
                                const {FieldName, FlagValue} = currency;

                                if (FlagValue !== 0) return;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              })}
                            </select>
                          </SelectView>
                        </ConditionTitleWithInputAndSelectWrapper>
                      ) : !isConditionEditing && grossValue ? (
                        <DisplayGrossValueNoEditing style={{width: '326px'}}>
                          <p>
                            {!isConditionEditing && !userWinWin
                              ? 'Cena (brutto)'
                              : !isConditionEditing &&
                                userWinWin &&
                                'Kwota gwarantowana'}
                          </p>
                          <div>
                            <h5>{grossValue}</h5>
                            <p>PLN</p>
                          </div>
                        </DisplayGrossValueNoEditing>
                      ) : (
                        !isConditionEditing && !grossValue && null
                      )}

                      {userMetaData.IsLegal && (
                        <ConditionTitleWithSliderAndSelectWrapper>
                          {isConditionEditing ? (
                            <>
                              <p>Faktura VAT</p>
                              <ConditionSlider
                                value={invoice}
                                onClick={() => handleInvoiceChange(!invoice)}
                              >
                                <div />
                              </ConditionSlider>
                            </>
                          ) : (
                            <CheckboxInfoWrapper
                              style={{
                                marginBottom: 'unset',
                                width: '196px',
                                height: '24px',
                              }}
                            >
                              <p>Faktura VAT</p>
                              <span>{invoice ? 'Tak' : 'Nie'}</span>
                            </CheckboxInfoWrapper>
                          )}

                          <SelectView
                            isEditing={isConditionEditing}
                            style={{width: '100px', marginTop: 'unset'}}
                          >
                            <select
                              value={tax}
                              onChange={handleTaxChange}
                              disabled={!isConditionEditing}
                            >
                              {TaxesEnum.map((tax) => {
                                const {FieldName, FlagValue} = tax;

                                return (
                                  <option value={FlagValue} key={FlagValue}>
                                    {FieldName}
                                  </option>
                                );
                              })}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                          </SelectView>
                        </ConditionTitleWithSliderAndSelectWrapper>
                      )}
                      {isConditionEditing && (
                        <>
                          <ExpandableHeaderWithDescriptionWrapper>
                            <ExpandableHeaderWithDescription
                              header="Opłata transakcyjna"
                              text=""
                              isEditing={isConditionEditing}
                            >
                              <ConditionSectionDescription
                                style={{flexDirection: 'column'}}
                                isEditing={isConditionEditing}
                              >
                                <ConditionSectionDescriptionExample
                                  isEditing={isConditionEditing}
                                >
                                  <p>
                                    Opłata transakcyjna to kwota, która zostanie
                                    doliczona kupującemu, pokrywająca koszty
                                    obsługi systemu MUGO. <br />
                                    Kwota nie stanowi przychodu sprzedającego.
                                  </p>
                                </ConditionSectionDescriptionExample>
                              </ConditionSectionDescription>
                            </ExpandableHeaderWithDescription>
                          </ExpandableHeaderWithDescriptionWrapper>
                        </>
                      )}
                      {grossValue > 0 && (
                        <>
                          <DisplayGrossValueNoEditing
                            style={
                              !isConditionEditing
                                ? {marginTop: '10px', width: '326px'}
                                : {width: '326px'}
                            }
                          >
                            <p>Opłata transakcyjna</p>
                            <div>
                              <h5>
                                {Number(
                                  (transactionFee?.Brutto / 100).toFixed(2),
                                )}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueNoEditing>
                          <DisplayGrossValueWithTaxNoEditing
                            style={{width: '326px'}}
                          >
                            <p>Razem do zapłaty</p>
                            <div>
                              <h5>
                                {grossValue
                                  ? `${Number(
                                      (
                                        grossValue +
                                        transactionFee?.Brutto / 100
                                      ).toFixed(2),
                                    )}`
                                  : '0'}
                              </h5>
                              <p>PLN</p>
                            </div>
                          </DisplayGrossValueWithTaxNoEditing>
                        </>
                      )}
                      {/* <SelectView
                        isEditing={isConditionEditing}
                        style={{marginBottom: '9px'}}
                      >
                        <p>Parasol ochronny</p>
                        <select
                          disabled={!isConditionEditing}
                          value={protectiveUmbrella}
                          onChange={handleProtectiveUmbrellaChange}
                        >
                          {ProtectiveUmbrellaEnum.map((protectiveUmbrella) => {
                            const {FieldName, FlagValue} = protectiveUmbrella;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView> */}
                    </>
                  ) : (
                    <TextWithLink style={{marginBottom: '40px'}}>
                      <p>Załóż konto Mangopay</p>

                      <span>
                        <Link to={'/producer'} target="_blank">
                          tutaj.{' '}
                        </Link>
                      </span>
                    </TextWithLink>
                  )}
                  {createConditionStep === 2 && (
                    <MobileSaveConditionButton
                      onClick={() => {
                        createCondition();
                      }}
                    >
                      Zapisz
                    </MobileSaveConditionButton>
                  )}
                  {createConditionStep === 0 && isConditionEditing && (
                    <MobileSaveConditionButton
                      onClick={() => {
                        if (isDefault) {
                          createConditionFromDefault();
                        } else {
                          updateCondition();
                        }
                      }}
                    >
                      Zapisz
                    </MobileSaveConditionButton>
                  )}
                  {params.id && !isDefault && assignedProductsCount === 0 && (
                    <MobileBlueButton
                      style={{
                        margin: '28px auto 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PopupButton
                        buttons={['Anuluj', 'Usuń']}
                        title={'Uwaga'}
                        text={` Czy na pewno chcesz usunąć warunek ${name}?
                     `}
                        onClick={() => deleteCondition(params.id)}
                      >
                        <p>Usuń szablon</p>
                      </PopupButton>
                    </MobileBlueButton>
                  )}
                </ConditionDetailsWrapper>
              )}
            </SingleColumnContentWrapper>
            {/* </SecondColumnWrapper> */}

            {params.id && (
              // <SingleColumnWrapper>
              //   <SingleColumnHeader>
              //     <p>Podpięte produkty</p>
              //   </SingleColumnHeader>
              <AssignedProductsColumnContentWrapper>
                {assignedProducts.length > 0 && (
                  <>
                    <MobileSectionHeader>
                      <h2>Podpięte produkty</h2>
                    </MobileSectionHeader>
                    <AssignedProductsWrapper>
                      {assignedProducts.map((assignedProduct) => {
                        const {
                          Id,
                          Name,
                          ProductType,
                          CoverFiles,
                          ProductDisplayStatus,
                        } = assignedProduct;

                        return (
                          <Link
                            to={`/myproducts/product/${Id}`}
                            key={Id}
                            style={{textDecoration: 'none', width: '100%'}}
                          >
                            {/* <SingleAssignedProductWrapper>
                            {CoverFiles.length > 0 ? (
                              <img src={CoverFiles[0].FilePath} />
                            ) : (
                              <TrackPlaceholderSvgWrapper>
                                <TrackPlaceholderSvg />
                              </TrackPlaceholderSvgWrapper>
                            )}

                            <SingleAssignedProductContentWrapper>
                              <h5>{Name}</h5>
                              <p>
                                {
                                  ProductTypeEnum.filter(
                                    (productType) =>
                                      productType.FlagValue === ProductType,
                                  )[0].FieldName
                                }
                              </p>
                            </SingleAssignedProductContentWrapper>
                          </SingleAssignedProductWrapper> */}
                            <MobileAssignedProduct
                              key={Id}
                              onClick={() => {
                                navigate(`/myproducts/product/${Id}`);
                              }}
                            >
                              {CoverFiles.length > 0 ? (
                                <img src={CoverFiles[0].FilePath} />
                              ) : (
                                <MobileTrackPlaceholderSvgWrapper>
                                  {ProductType === 4 ? (
                                    <ServicePlaceholderSvg />
                                  ) : ProductType === 8 ? (
                                    <TrackPlaceholderSvg />
                                  ) : (
                                    <DigitalProductPlaceholderSvg />
                                  )}
                                </MobileTrackPlaceholderSvgWrapper>
                              )}
                              <div>
                                <p>{getProductTypePolishName(ProductType)}</p>
                                <h5>{Name}</h5>
                                <StyledStatusWrapper
                                  ProductDisplayStatus={ProductDisplayStatus}
                                >
                                  <h1>
                                    {getProductDisplayStatusDescription(
                                      ProductDisplayStatus,
                                    )}
                                  </h1>
                                  <StyledStatusLine
                                    ProductDisplayStatus={ProductDisplayStatus}
                                  >
                                    <div />
                                  </StyledStatusLine>
                                </StyledStatusWrapper>
                              </div>
                            </MobileAssignedProduct>
                          </Link>
                        );
                      })}
                    </AssignedProductsWrapper>
                  </>
                )}
              </AssignedProductsColumnContentWrapper>
              // </SingleColumnWrapper>
            )}
            {/* // : (
            //   <SingleColumnWrapper>
            //     <SingleColumnHeader>
            //       <p>Czym są warunki?</p>
            //     </SingleColumnHeader>
            //     <ThirdColumnContentWrapper>
            //       <h5>
            //         Dodanie warunków to krok niezbędny, aby Twoja oferta mogła
            //         pojawić się w MUGO Studio.
            //       </h5>
            //       <p style={{marginTop: '11px'}}>
            //         Każda oferta musi zawierać przynajmniej jeden{' '}
            //         <strong>szablon warunków współpracy.</strong> Do każdej
            //         oferty możesz dodać trzy różne szablony. Dzięki temu Twoja
            //         oferta będzie bardziej atrakcyjna.
            //       </p>

            //       <h4 style={{marginTop: '23px'}}>
            //         Przygotuj z wyprzedzeniem swoje szablony, abyś nie tracił
            //         czasu, tylko mógł błyskawicznie wystawiać oferty.
            //       </h4>
            //     </ThirdColumnContentWrapper>
            //   </SingleColumnWrapper>
            // )} */}
          </Grid>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default EditCondition;

export const BlueHeaderWrapper = styled.div`
  width: 259px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 4px auto;

  & > h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: #0091ff;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 308px;
  }
`;

export const LeftDot = styled.div`
  position: absolute;
  left: -12px;
  top: 7px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0091ff;
`;

export const BlueHeaderComponent: React.FC = ({children}) => {
  return (
    <BlueHeaderWrapper>
      <LeftDot />
      <h1>{children}</h1>
    </BlueHeaderWrapper>
  );
};

export const BlueHeaderWrapperCondition = styled.div`
  width: 259px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 4px auto;

  & > h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: #0091ff;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const LeftDotCondition = styled.div`
  position: absolute;
  left: -12px;
  top: 7px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0091ff;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
    margin-right: 4px;
  }
`;

export const BlueHeaderComponentCondition: React.FC = ({children}) => {
  return (
    <BlueHeaderWrapperCondition>
      <LeftDotCondition />
      <h1>{children}</h1>
    </BlueHeaderWrapperCondition>
  );
};
