import React, {createContext, useState, useEffect} from 'react';
import jwt_decode from 'jwt-decode';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';

interface DecodedToken {
  ProducerId: string;
  OwnedLabelId: string;
}

interface Context {
  decodedToken: DecodedToken;
}

export const ProducerDataContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

const ProducerDataProvider: React.FC<Props> = ({children}) => {
  const [tokenMugo, setTokenMugo] = useState<string>('');
  const [cookies, setCookie] = useCookies(['token']);

  const decodedToken: {ProducerId: string; OwnedLabelId: string} =
    cookies.token && jwt_decode(cookies.token);

  console.log('dekodedtoken: ', decodedToken);

  const getTokenMugo = () => {
    const locationSearch = window.location.search;
    if (locationSearch.split('').length > 1) {
      const array = locationSearch.split('?');
      const confirm = locationSearch.split('=');
      if (confirm[0] === '?userId') return;
      if (confirm[0] === '?transactionId') return;
      if (confirm[0] === '?orderId') return;
      const token = array[1];
      setTokenMugo(token);
    }
  };

  const addHours = (date: Date, hours: number) => {
    date.setHours(date.getHours() + hours);

    return date;
  };

  const getAuthToken = async (token: string) => {
    try {
      const body = {
        Jwt: token,
      };
      const response = await api.post(`Auth/LoginMugoToken`, body);

      const today = new Date();
      setCookie('token', response.data.Response.Token, {
        expires: addHours(today, 10),
      });
      window.location.href = '/';
    } catch (error) {
      window.location.href = '/identity/login';
    }
  };

  useEffect(() => {
    if (tokenMugo) {
      getAuthToken(tokenMugo);
    }
  }, [tokenMugo]);

  useEffect(() => {
    getTokenMugo();
  }, []);

  return (
    <ProducerDataContext.Provider
      value={{
        decodedToken,
      }}
    >
      {children}
    </ProducerDataContext.Provider>
  );
};

export default ProducerDataProvider;
