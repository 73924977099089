import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 392px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    /* display: none; */
    width: 100%;
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 9px;
  margin-top: 31px;
  margin-bottom: 21px;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > a {
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.05px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0091ff;
    cursor: pointer;

    & > svg {
      margin-top: 2px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    align-items: flex-start;

    & > a {
      margin-top: 16px;
      color: transparent;

      & > svg {
        width: 9.37px;
        height: 16px;
      }
    }

    & > h1 {
      width: 263px;
    }
  }
`;

export const ProductsWrapper = styled.div`
  width: 100%;
  display: flex !important;
  border-radius: 24px;
  height: 320px;
  justify-content: center;
  gap: 7px;
`;

export const MobileProductsWrapper = styled.div`
  width: 100%;
  gap: 9px;
  display: flex !important;
  border-radius: 24px;
  height: 320px;
  justify-content: center;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: 330px;

  .slick-dots {
    bottom: -12px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none !important;
  }
`;

export const MobileSliderWrapper = styled.div`
  display: none !important;
  width: 100%;
  height: 319px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  /* margin: 0 9px; */

  /* .slick-slide {
    width: 182px !important;
    display: flex !important;
    justify-content: center;
  } */

  .slick-dots {
    bottom: -1px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset !important;
  }
`;

export const SingleProduct = styled.div`
  width: 172px;
  height: 287px;
  background: ${({theme}) => theme.colors.popularCategoryBackground};
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 2px 2px 0px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: background 0.15s;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 168px;
    width: 168px;
    border-radius: 22px;
    margin-bottom: 6px;
  }

  & > h1 {
    height: 33px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }

  & > h2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 9px;
  }

  & > p {
    height: 15px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 2px;
  }

  & > h4 {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
  }

  & > svg {
    width: 59.5px;
    height: 15px;
    margin-bottom: 2px;
  }
`;

export const MobileSingleProduct = styled.div`
  width: 172px;
  height: 276px;
  background: ${({theme}) => theme.colors.popularCategoryBackground};
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 6px 2px 6px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: background 0.15s;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 158px;
    width: 158px;
    border-radius: 18px;
    margin-bottom: 2px;
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 102px;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;

      &:first-of-type {
        justify-content: center;
      }

      &:last-of-type {
        justify-content: flex-end;
      }
    }
  }

  & h1 {
    /* height: 33px; */
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }

  & h2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
  }

  & p {
    height: 15px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
  }

  & h4 {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
  }

  & svg {
    width: 59.5px;
    height: 15px;

    & path {
      fill: black;
    }
  }
`;
