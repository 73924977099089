import React, {useContext} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {useMenuContext} from 'contexts/MenuContext';
import {useLocation} from 'react-router-dom';
import {useIsOrderPageOpenContext} from 'contexts/IsOrderPageOpenContext';

export const Main = styled.div<{isDeactivated?: boolean}>`
  width: 100%;
  height: 57px;
  background: #f8f8f8;
  display: none;
  align-items: center;
  position: fixed;
  bottom: 0px;
  justify-content: center;
  border-top: 1px solid #dce0e9;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: ${({isDeactivated}) => (isDeactivated ? 'none' : 'flex')};
    z-index: 6;
    background: ${({theme}) => theme.colors.bgWhite};
    border: none;
    filter: drop-shadow(0px -3px 3px rgba(0, 0, 0, 0.07));
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 300px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: 0px 38px;
  }
`;

export const SingleLink = styled.div<{isActive?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 10px; */
  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.svgNavbarFillColor};
  }
  & > svg {
    & > g {
      & > path {
        fill: ${({isActive, theme}) =>
          isActive ? '#3A87F0' : theme.colors.svgNavbarFillColor};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    scale: 1.2;
  }
`;

const BottomMobileNavbar: React.FC = () => {
  const navigate = useNavigate();
  const {decodedToken} = useContext(ProducerDataContext);
  const {isMenuOpen, setIsMenuOpen} = useMenuContext();
  const {isOrderPageOpen} = useIsOrderPageOpenContext();

  const location = useLocation();

  const splitLocation = location.pathname.split('/');

  return (
    <Main
      isDeactivated={
        isOrderPageOpen ||
        (splitLocation[1] === 'transactions' && splitLocation.length === 3)
      }
    >
      <Wrapper>
        {/* <SingleLink
          isActive={splitLocation[1] === ''}
          onClick={() => {
            navigate('/');
            setIsMenuOpen(false);
          }}
        >
          <svg
            id="Group_3627"
            data-name="Group 3627"
            xmlns="http://www.w3.org/2000/svg"
            width="22.531"
            height="18.415"
            viewBox="0 0 22.531 18.415"
          >
            <path
              id="Path_3603"
              data-name="Path 3603"
              d="M22.153,7.88,13.387.75A3.381,3.381,0,0,0,9.14.75L.378,7.88A1.024,1.024,0,1,0,1.671,9.468l1.163-.945L2.2,14.574a.876.876,0,0,0,0,.093A3.753,3.753,0,0,0,5.94,18.415H16.591a3.753,3.753,0,0,0,3.748-3.748c0-.031,0-.061,0-.093L19.7,8.523l1.163.945A1.024,1.024,0,1,0,22.153,7.88m-3.575,6.829a1.989,1.989,0,0,1-1.987,1.945H5.94a1.99,1.99,0,0,1-1.987-1.945l.815-7.733c0-.008,0-.016,0-.025l5.668-4.612a1.321,1.321,0,0,1,1.66,0l5.672,4.613a.233.233,0,0,0,0,.024Z"
              transform="translate(0 0)"
              fill="#07020f"
            />
          </svg>
        </SingleLink> */}
        <SingleLink
          isActive={splitLocation[1] === 'producers'}
          onClick={() => {
            navigate('/producers/main');
            setIsMenuOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.491"
            height="16.578"
            viewBox="0 0 24.491 16.578"
          >
            <g id="Group_3626" data-name="Group 3626" transform="translate(0)">
              <path
                id="Path_3604"
                data-name="Path 3604"
                d="M44.278,7.914a3.892,3.892,0,1,0,5.5-5.5,3.892,3.892,0,0,0-5.5,5.5Zm1.449-4.055a1.843,1.843,0,1,1,2.607,2.607,1.888,1.888,0,0,1-2.607,0,1.843,1.843,0,0,1,0-2.607"
                transform="translate(-34.795 -1.271)"
                fill="#07020f"
              />
              <path
                id="Path_3605"
                data-name="Path 3605"
                d="M39.237,12.519a.88.88,0,0,0-.881-.881,5.408,5.408,0,0,0-5.4,5.4.881.881,0,0,0,1.762,0,3.645,3.645,0,0,1,3.64-3.641.88.88,0,0,0,.881-.881"
                transform="translate(-32.955 -3.145)"
                fill="#07020f"
              />
              <path
                id="Path_3606"
                data-name="Path 3606"
                d="M38.846,9.468a3.083,3.083,0,0,0,2.373.114.881.881,0,1,0-.6-1.657A1.342,1.342,0,0,1,39.717,5.4a.88.88,0,1,0-.595-1.657,3.1,3.1,0,0,0-.276,5.727"
                transform="translate(-33.697 -1.708)"
                fill="#07020f"
              />
              <path
                id="Path_3607"
                data-name="Path 3607"
                d="M56.059,11.638a.881.881,0,1,0,0,1.761A3.645,3.645,0,0,1,59.7,17.04a.881.881,0,0,0,1.762,0,5.408,5.408,0,0,0-5.4-5.4"
                transform="translate(-36.97 -3.145)"
                fill="#07020f"
              />
              <path
                id="Path_3608"
                data-name="Path 3608"
                d="M53.538,9.582a3.1,3.1,0,0,0,2.1-5.841.881.881,0,0,0-.6,1.657,1.342,1.342,0,1,1-.905,2.526.881.881,0,1,0-.6,1.657"
                transform="translate(-36.568 -1.708)"
                fill="#07020f"
              />
              <path
                id="Path_3609"
                data-name="Path 3609"
                d="M46.144,11.718a8.021,8.021,0,0,0-7.9,6.849,1.024,1.024,0,0,0,2.028.291,5.931,5.931,0,0,1,11.743,0,1.024,1.024,0,0,0,1.012.879.974.974,0,0,0,.147-.011,1.025,1.025,0,0,0,.869-1.159,8.02,8.02,0,0,0-7.9-6.849"
                transform="translate(-33.908 -3.159)"
                fill="#07020f"
              />
            </g>
          </svg>
        </SingleLink>
        <SingleLink
          isActive={
            splitLocation[1] === 'products' || splitLocation[1] === 'order'
          }
          onClick={() => {
            navigate('/products/main');
            setIsMenuOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.833"
            height="16.688"
            viewBox="0 0 15.833 16.688"
          >
            <g
              id="Group_3625"
              data-name="Group 3625"
              transform="translate(0.001 0)"
            >
              <path
                id="Path_3614"
                data-name="Path 3614"
                d="M74.475,4h4.916a.881.881,0,1,0,0-1.761H74.475a.881.881,0,1,0,0,1.761"
                transform="translate(-73.595 -2.091)"
                fill="#07020f"
              />
              <path
                id="Path_3615"
                data-name="Path 3615"
                d="M88.4,20.278H74.475a.881.881,0,1,0,0,1.761H88.4a.881.881,0,1,0,0-1.761"
                transform="translate(-73.595 -5.352)"
                fill="#07020f"
              />
              <path
                id="Path_3616"
                data-name="Path 3616"
                d="M90.483,14.263h-4.5a.881.881,0,1,0,0,1.761h4.5a.881.881,0,1,0,0-1.761"
                transform="translate(-75.675 -4.265)"
                fill="#07020f"
              />
              <path
                id="Path_3617"
                data-name="Path 3617"
                d="M90.269,2.059H84.943a1.024,1.024,0,0,0-1.024,1.024V8.408a1.024,1.024,0,0,0,1.024,1.024h5.326a1.024,1.024,0,0,0,1.024-1.024V3.083a1.024,1.024,0,0,0-1.024-1.024M89.245,7.384H85.967V4.107h3.277Z"
                transform="translate(-75.46 -2.059)"
                fill="#07020f"
              />
              <path
                id="Path_3618"
                data-name="Path 3618"
                d="M74.581,7.953a.883.883,0,0,0-.452.77v6.554a.88.88,0,0,0,1.345.748l5.271-3.277a.88.88,0,0,0,0-1.5L75.474,7.975a.882.882,0,0,0-.893-.022m1.309,2.355L78.612,12l-2.722,1.693Z"
                transform="translate(-73.691 -3.104)"
                fill="#07020f"
              />
            </g>
          </svg>
        </SingleLink>
        <SingleLink
          isActive={splitLocation[1] === 'tracks'}
          onClick={() => {
            navigate('/tracks/main');
            setIsMenuOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.833"
            height="16.544"
            viewBox="0 0 15.833 16.544"
          >
            <g
              id="Group_3624"
              data-name="Group 3624"
              transform="translate(0 0)"
            >
              <path
                id="Path_3610"
                data-name="Path 3610"
                d="M127.863,14.263h-4.5a.881.881,0,1,0,0,1.761h4.5a.881.881,0,1,0,0-1.761"
                transform="translate(-112.911 -4.408)"
                fill="#07020f"
              />
              <path
                id="Path_3611"
                data-name="Path 3611"
                d="M127.849,8.248h-4.558a.881.881,0,1,0,0,1.761h4.558a.881.881,0,1,0,0-1.761"
                transform="translate(-112.897 -3.321)"
                fill="#07020f"
              />
              <path
                id="Path_3612"
                data-name="Path 3612"
                d="M125.783,20.278H111.855a.881.881,0,1,0,0,1.761h13.928a.881.881,0,1,0,0-1.761"
                transform="translate(-110.83 -5.495)"
                fill="#07020f"
              />
              <path
                id="Path_3613"
                data-name="Path 3613"
                d="M114.691,14.8a3.892,3.892,0,0,0,3.892-3.892c0-.021-.006-.041-.007-.062s.007-.02.007-.03V4h7.169a.881.881,0,1,0,0-1.761h-8.193a.866.866,0,0,0-.4.1,1.024,1.024,0,0,0-.628.945V7.5a3.884,3.884,0,1,0-1.843,7.3m-1.3-5.2a1.843,1.843,0,1,1,2.607,2.607,1.886,1.886,0,0,1-2.607,0,1.843,1.843,0,0,1,0-2.607"
                transform="translate(-110.799 -2.234)"
                fill="#07020f"
              />
            </g>
          </svg>
        </SingleLink>
        <SingleLink
          isActive={splitLocation[1] === 'czytelnia'}
          onClick={() => {
            navigate('/czytelnia');
            setIsMenuOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.274"
            height="18"
            viewBox="0 0 21.274 18"
          >
            <path
              id="Icon_ionic-md-book"
              data-name="Icon ionic-md-book"
              d="M22.716,5.646l-8.7,1.636-8.7-1.636A1.8,1.8,0,0,0,3.375,7.569v12.5c0,1.059.818,1.667,1.933,1.923l8.7,1.631,8.7-1.636c1.115-.256,1.933-.864,1.933-1.923V7.569A1.8,1.8,0,0,0,22.716,5.646Zm0,14.421L14.984,21.7V9.2l7.732-1.636ZM13.04,21.7,5.308,20.067V7.569L13.04,9.205Z"
              transform="translate(-3.375 -5.627)"
              fill="#dce0e9"
            />
          </svg>
        </SingleLink>
        {
          <SingleLink
            isActive={
              splitLocation[1] !== 'account' &&
              splitLocation[1] !== 'czytelnia' &&
              splitLocation[1] !== '' &&
              splitLocation[1] !== 'identity' &&
              splitLocation[1] !== 'tracks' &&
              splitLocation[1] !== 'producers' &&
              splitLocation[1] !== 'products' &&
              splitLocation[1] !== 'order'
            }
            onClick={() => {
              navigate('/card');
              setIsMenuOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.547"
              height="17.858"
              viewBox="0 0 19.547 17.858"
            >
              <g
                id="Group_3623"
                data-name="Group 3623"
                transform="translate(0 0)"
              >
                <path
                  id="Path_3601"
                  data-name="Path 3601"
                  d="M160.914,11.714a4.011,4.011,0,1,0,0-5.672,4,4,0,0,0,0,5.672Zm1.493-4.179a1.9,1.9,0,1,1,0,2.687,1.9,1.9,0,0,1,0-2.687"
                  transform="translate(-152.841 -1.746)"
                  fill="#07020f"
                />
                <path
                  id="Path_3602"
                  data-name="Path 3602"
                  d="M171.111,15.079l-.84-8.825a5.136,5.136,0,0,0-5.129-5.084h-7.6a5.136,5.136,0,0,0-5.13,5.084l-.84,8.825c0,.029,0,.057,0,.086a3.868,3.868,0,0,0,3.864,3.863h11.821a3.867,3.867,0,0,0,3.863-3.863c0-.029,0-.057,0-.086m-17.727.126.84-8.819c0-.029,0-.057,0-.086a3.317,3.317,0,0,1,3.314-3.314h7.6A3.317,3.317,0,0,1,168.455,6.3c0,.029,0,.057,0,.086l.84,8.819a2.039,2.039,0,0,1-.111.6,9.063,9.063,0,0,0-14.049,1.221.955.955,0,0,0-.063.153,2.047,2.047,0,0,1-1.691-1.972m4.335,2.008a6.93,6.93,0,0,1,9.863-.033,2.035,2.035,0,0,1-.33.033Z"
                  transform="translate(-151.568 -1.17)"
                  fill="#07020f"
                />
              </g>
            </svg>
          </SingleLink>
        }
      </Wrapper>
    </Main>
  );
};

export default BottomMobileNavbar;
