import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import moment from 'moment';
import styled, {css, keyframes} from 'styled-components';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import {useCookies} from 'react-cookie';
import 'utils/checkbox.css';
import {countTruthyVariables} from 'utils/countTruthyVariables';
import {getOrderTypePolishName} from 'utils/getOrderTypePolishName';
import {Condition} from 'pages/Products/ProductDetail';
import api from 'services/axiosConfig';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import searchIcon from 'images/svg/search.svg';
import twoArrowsIcon from 'images/svg/two-arrows.svg';
import listIcon from 'images/svg/list.svg';
import {InputView} from 'pages/MyAccount/Settings/styles';
import arrowDown from 'images/svg/purple-select-arrow.svg';
import filterIcon from 'images/svg/filter-icon.svg';
import useAlert from 'hooks/useAlert';
import mangopayLogo from 'images/svg/mangopay-transactions-logo.svg';
import SingleTransaction from './SingleTransaction';
import {PrimaryButton} from './SingleTransaction/styles';
import {UserDataContext} from '../../../contexts/UserDataContext';
import {Navigate} from 'react-router-dom';
import {OrderStatus, OrderType, Tax} from 'constants/transactionEnums';
import {LoadingCircle} from 'utils/loadingCircle';
import {useMenuContext} from 'contexts/MenuContext';
import {useFilterMenuContext} from 'contexts/FilterMenuContext';
import {useSearchBarContext} from 'contexts/SearchBarContext';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import useInfinityScroller from 'hooks/useInfinityScroller';

import {
  CheckboxWithTitleWrapper,
  CustomBlueCheckbox,
  CountFilterDisplay,
  FilterButtonSvg,
  SortButtonSvg,
  FilterWrapper,
  FilterInputWrapper,
  SingleFilterWrapper,
  FilterMenuWrapper,
  FilterInput,
  SelectWrapper,
  PageHeaderContent,
  PageHeaderWrapper,
} from 'pages/Products/index';

export const formatWalletIBAN = (inputString: string) => {
  const firstPart = inputString.substring(0, 4);
  const secondPart = inputString.substring(4);
  const modifiedFirstPart = firstPart.substring(2);
  const formattedSecondPart = secondPart.match(/.{1,4}/g).join(' ');
  const formattedNumber = modifiedFirstPart + ' ' + formattedSecondPart;
  return formattedNumber;
};

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const NavButtonsWrapper = styled.div`
  width: 358px;
  height: 0px;
  position: fixed;
  top: 15px;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
  }
`;

export const MobileSearchBar = styled.input<{isActive?: boolean}>`
  display: ${({isActive}) => (isActive ? 'unset' : 'none')};
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: #07020f;
  background: #dce0e9;
  width: 255px;
  border-radius: 7px;
  border: none;
  position: absolute;
  right: 79px;
  padding: 0 13px;
  top: -3px;
  outline: none;

  &::placeholder {
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 120px);
    right: 88px;
  }
`;

export const FilterSvg = styled.svg<{isActive?: boolean}>`
  position: absolute;
  right: 56px;

  & path {
    fill: ${({isActive}) => (isActive ? '#3A87F0' : 'unset')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;

export const SearchSvg = styled.svg<{isActive?: boolean}>`
  position: absolute;
  right: 92px;
  margin-top: 1px;

  & path {
    fill: ${({isActive}) => (isActive ? '#3A87F0' : 'unset')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 101px;
  }
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: relative;
  top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: #352f40;
  margin-bottom: 20px;
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: black;
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
`;

export const MobileMain = styled.div`
  width: 358px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 5px 0px;
  padding-top: 65px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    gap: 5px;
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: white;
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  padding: 0 32px;
  gap: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MobileInputFilterWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: #352f40;
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const MobileSelectFilterWrapper = styled.div<{value?: any}>`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > select {
    width: 100%;
    font-size: 17px;
    background: transparent;
    border: none;
    outline: none;
    color: ${({value}) => (value || value === 0 ? '#3a87f0' : '#352f40')};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
    -webkit-appearance: none;

    & > option {
      color: #352f40;
    }
  }
`;

export const MobileCheckboxFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 26px;
  padding-right: 8px;

  & > p {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.15px;
    line-height: 16px;
    color: #07020f;
  }
`;

export const MobileCheckbox = styled.div<{
  isActive: boolean;
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({isActive}) =>
    isActive ? '3px solid #0066FF' : '3px solid rgba(112,112,112,0.3)'};
  cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};

  & > svg {
    display: ${({isActive}) => (isActive ? 'unset' : 'none')};
    margin-left: 1px;
  }
`;

export const MobileWithdrawButton = styled.button`
  border: none;
  border-radius: 14px;
  width: 104px;
  height: 38px;
  top: 9px;
  right: 18px;
  position: absolute;
  background: #0091ff;
  font-size: 15px;
  letter-spacing: 0.07px;
  color: #ffffff;
  font-weight: 400;
`;

export const MobileInfoBlockWrapper = styled.div`
  width: 100%;
  min-height: 65px;
  position: fixed;
  z-index: 2;
  top: 48px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    top: 107px;
  }
`;

export const MobileSingleInfoBlockRow = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid
    ${({theme}) => theme.colors.mobileTransactionTopBorder};
  padding: 12px 22px 16px;
  background: ${({theme}) => theme.colors.mobileTransactionTopBackground};
`;

export const TopTitle = styled.div`
  width: 100%;
  display: flex;

  & > p {
    font-size: 13px;
    letter-spacing: -0.05px;
    font-weight: 400;
    line-height: 16px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const BottomRow = styled.div`
  width: 100%;
  display: flex;

  & > p {
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 21px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > h1 {
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 21px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const NewWithdrawButton = styled.button`
  background: #0091ff;
  border: none;
  color: #ffffff;
  letter-spacing: 0.1px;
  font-size: 13px;
  cursor: pointer;
  width: 149px;
  height: 32px;
  transition: all 0.15s;
  border-radius: 9px;
`;

export const WithdrawButton = styled.button`
  background: transparent;
  border: 1px solid rgba(112, 112, 112, 0.3);
  color: #352f40;
  letter-spacing: 0.625px;
  font-size: 11px;
  cursor: pointer;
  width: 119px;
  height: 38px;
  transition: all 0.15s;
  border-radius: 9px;

  &:hover {
    background: rgba(250, 250, 250, 1);
  }

  &:active {
    background: rgba(240, 240, 240, 1);
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const ClearFiltersButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  position: absolute;
  right: 346px;
  top: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 5px;
    top: unset;
  }
`;

export const Grid = styled.div<{IbanIsNull?: boolean}>`
  width: 1012px;
  display: flex;
  border-radius: 21px;
  padding: 2px;
  padding-top: 120px;
  padding-top: ${({IbanIsNull}) => (IbanIsNull ? '45px' : '140px')};
  gap: 5px;
  padding-left: 14px;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
`;

export const FilterButton = styled.div<{isFilterMenuOpen: boolean}>`
  width: 28px;
  height: 22px;
  border-radius: 7px;
  margin-right: 1px;
  background: ${({isFilterMenuOpen, theme}) =>
    isFilterMenuOpen ? '#DCE0E9' : theme.colors.blueTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    height: 15px;
    width: 15px;
  }
`;

export const InfoBlockWrapper = styled.div`
  width: 1020px;
  display: flex;
  /* height: 152px; */
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  /* background: white; */
  border-radius: 24px 24px 21px 21px;
  position: absolute;
  top: 88px;
  z-index: 2;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const InfoBlockHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  background: ${({theme}) => theme.colors.pageHeader};
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoBlockHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const CloseWithdrawMenuButton = styled.div`
  position: absolute;
  right: 14px;
  top: 3px;
  cursor: pointer;
  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.bgWhite};
    }
  }
`;

export const InfoBlockContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 21px 21px;
`;

export const InfoBlockContent = styled.div<{wallet: string | null}>`
  width: 100%;
  /* height: calc(100% - 55px); */
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 21px 21px;
  padding: ${({wallet}) => (!wallet ? 'unset' : '19px 42px 29.5px 39px')};
  display: grid;
  grid-template-columns: 172px 405px 149px;
  justify-content: space-between;
  align-items: center;
`;

export const PhoneSvgWithText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.transactionPhoneSvg};
    }
  }

  & > h1 {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.transactionHeaderBlackFont};
  }
`;

export const MobileFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 138px;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }

    & circle {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const MobileSearchInputWrapper = styled.div`
  background: ${({theme}) => theme.colors.headerContentBackground};
  width: 258px;
  height: 38.8px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 16.5px;

  & > input {
    width: 196px;
    outline: none;
    border: none;
    background: transparent;
    color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;

    &::placeholder {
      color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    }
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const TextsInColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextsInRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  width: 100%;
`;

export const HeaderTextWithSvg = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 12px;

  & > p {
    line-height: 18px;
    letter-spacing: -0.05px;
    font-size: 11px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.transactionHeaderBlackFontOp30};
  }

  & > span {
    line-height: 18px;
    letter-spacing: -0.05px;
    font-size: 11px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.transactionHeaderBlackFont};
  }

  & > svg {
    position: relative;
    left: 8px;
    top: -4px;
  }
`;

export const InfoBlockGrid = styled.div<{IbanIsNull?: boolean}>`
  width: 100%;
  height: ${({IbanIsNull}) => (IbanIsNull ? 'auto' : '97px')};
  padding: ${({IbanIsNull}) => (IbanIsNull ? '0' : '18px 16px')};
  //padding: 18px 16px;
  //display: grid;
  display: ${({IbanIsNull}) => (IbanIsNull ? 'block' : 'grid')};
  align-items: center;
  /* justify-content: space-between; */
  grid-template-columns: 280px 170px 151px 258px 146px;

  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(112, 112, 112, 0.3);

    &:last-child {
      border-right: none;
    }
  }
`;

export const InfoBlockContentTextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  & > p {
    color: ${({theme}) => theme.colors.grayFontOp100};
    letter-spacing: 0.5px;
    font-size: 9px;
  }

  & > h5 {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.15px;
    font-size: 11px;
  }
`;

export const InfoBlockStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;

  & > p {
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: 0.5px;
    font-size: 9px;
  }

  & > h5 {
    color: #7270f2;
    letter-spacing: 0.5px;
    font-size: 11px;
  }
`;

export const InfoBlockBalanceWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 7px;
  justify-content: flex-end;
  align-items: center;

  & > p {
    font-size: 28px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    letter-spacing: 0.25px;
  }

  & > h1 {
    font-size: 28px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.25px;
  }
`;

export const InfoBlockImageWrapper = styled.div`
  width: 154px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: white;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
`;

export const SingleFilterHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const SingleFilterContentWrapper = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowWrapper = styled.div<{
  isActive?: boolean;
  isIBANActive?: boolean;
  length?: number;
}>`
  width: 84px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive, length}) =>
    isActive || length > 0 ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  &:hover {
    background: ${({isIBANActive, isActive}) =>
      isIBANActive || isActive ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  & > p {
    position: absolute;
    background: #89bfff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 6px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const WithdrawMenuWrapper = styled.div`
  width: 400px;
  min-height: 148px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  top: 369px;
  border-radius: 16px;
  background-color: ${({theme}) => theme.colors.bgWhite};
  gap: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  & > button {
    background: #0091ff;
    color: white;
    font-weight: 500;
    width: 124px;
    height: 32px;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    text-align: center;
    padding: 5px 0px;
    font-size: 11px;
    margin-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 80%;
    position: fixed;
    top: 25%;
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 80%;
    border: none;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 20px;
    position: fixed;
    top: 25%;

    & .buttonWrapper {
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid rgba(7, 2, 15, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;

      & > button {
        background: #0091ff;
        color: white;
        font-weight: 500;
        width: 124px;
        height: 32px;
        cursor: pointer;
        border: none;
        border-radius: 7px;
        text-align: center;
        padding: 5px 0px;
        font-size: 11px;
      }
    }

    & > svg {
      position: absolute;
      top: 18px;
      right: 20px;
    }

    & > button {
      width: calc(100% - 30px);
      min-height: 44px;
      border-radius: 16px;
      background: #3a87f0;
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0.07px;
    }
  } */
`;

export const WithdrawMenuHeaderWrapper = styled.div`
  width: 100%;
  min-height: 28px;
  background: #89bfff;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.blueTheme};
`;

export const WithdrawMenuHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    display: none;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 16px;
  }
`;

interface WalletProps {
  Id: string;
  Currency: string;
  Balance: number;
}

interface Wallet {
  IBAN: string | null;
  LockedWallet: WalletProps;
  RegularWallet: WalletProps;
}

// interface Transaction {
//   AuthorId: string;
//   CreditedFunds: number;
//   CreditedUserId: string;
//   CreditedWalletId: string;
//   DebitedFunds: number;
//   DebitedWalletId: string;
//   ExecutionDate: string;
//   Fees: number;
//   Nature: string;
//   ResultCode: string;
//   ResultMessage: string;
//   Status: string;
//   Type: string;
//   Id: string;
// }

interface Item {
  Id: string;
  Name: string;
  Symbol: string;
  Quantity: number;
  OrderId: string;
  ProductId?: string;
  ConditionId?: string;
  Condition: Condition;
  Tax: Tax;
  Netto: number;
  ProductType: number;
  Brutto: number;
  AmountTotal: number;
  TaxTotal: number;
  ShortDescription: string;
}

interface OrderComment {
  Accepted: boolean;
  CustommerComment: null;
  SellerResponse: null;
}

interface SellerInfo {
  BuildingNumber: string;
  CompanyName: string;
  Country: string;
  Email: string;
  FirstName: string;
  FlatNumber: string;
  LastName: string;
  NIP: string;
  PhoneNumber: string;
  PostalCode: string;
  Region: string;
  Street: string;
  Roles: string[];
}

interface BillingInfo {
  BuildingNumber: string;
  CompanyName: string;
  Country: string;
  Email: string;
  FirstName: string;
  FlatNumber: string;
  LastName: string;
  NIP: string;
  PhoneNumber: string;
  PostalCode: string;
  Region: string;
  Street: string;
  Roles: string[];
}

interface File {
  CreatedDate: string;
  FileName: string;
  FilePath: string;
  Id: string;
  FileType: number;
  LastDownloadDate: string;
  NumberOfDownloads: number;
  OrderId: string;
}

export interface Transaction {
  CreationDate: string;
  Type: number;
  PayOut?: null | PayOut;
  Order?: null | Order;
}

export interface PayOut {
  AuthorId: string;
  CreditedFunds: number;
  CreditedUserId: null | string;
  CreditedWalletId: null | string;
  DebitedFunds: number;
  DebitedWalletId: string;
  ExecutionDate: string;
  Fees: number;
  Id: string;
  Nature: string;
  ResultCode: string;
  ResultMessage: string;
  Status: string;
  Type: string;
}

export interface UserStatus {
  DisplayValue: null;
  FieldName: string;
  FlagValue: number;
}

export interface OrderInformation {
  OrderId: string;
  StatusDateTime: string;
}

export interface Message {
  Content: string;
  Id: string;
  IsCurrentUserSender: boolean;
  OrderInformation: OrderInformation;
  ProductId: null;
  RecieverId: string;
  RecieverName: string;
  RecieverPhotoFilePath: string;
  RecieverStatus: UserStatus;
  SenderId: string;
  SenderName: string;
  SenderPhotoFilePath: string;
  SenderStatus: UserStatus;
  SentDate: string;
  Thread: string;
  ThreadCount: number;
  Topic: string;
}

export interface Order {
  Id: string;
  AmountToPay: number;
  AmountTotal: number;
  BillingInfo: BillingInfo;
  CreatedAt: string;
  Coupons: [];
  Currency: number;
  CustomerId: string | null;
  CustomerNote: string;
  DateCompleted: null | string;
  DatePaid: null | string;
  Description: string;
  DiscountTax: number;
  DiscountTotal: number;
  Files: File[];
  Items: Item[];
  Number: string;
  ExpectedRealisationDate: string;
  ExtendedRealisationDate: string;
  OrderComment: OrderComment;
  PaymentMethod: number;
  ExtendedRealisationAccepted: null | boolean;
  Refunds: [];
  WinWinRealizationDate: string | null;
  Seller: null;
  SellerId: null | string;
  SellerInfo: SellerInfo;
  IsWinWin: boolean;
  Shipping: null;
  ShippingAddress: null | string;
  ShippingTax: number;
  ShippingTotal: number;
  Status: number;
  TaxToPay: number;
  TaxTotal: number;
  TransactionId: string;
  DtoType: number;
  Messages: Message[];
}

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

enum SortBy {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
}

const TransactionsComponent = () => {
  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [dateFrom, setDateFrom] = useState<string>(
    sessionStorage.getItem('transactionFilterDateFrom')
      ? sessionStorage.getItem('transactionFilterDateFrom')
      : '',
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreProducers, setHasMoreProducers] = useState(true);
  const [dateTo, setDateTo] = useState<string>(
    sessionStorage.getItem('transactionFilterDateTo')
      ? sessionStorage.getItem('transactionFilterDateTo')
      : '',
  );
  const [transactionType, setTransactionType] = useState<number | string>(
    sessionStorage.getItem('transactionFilterTransactionType')
      ? +sessionStorage.getItem('transactionFilterTransactionType')
      : '',
  );
  const [status, setStatus] = useState<number | string>(
    sessionStorage.getItem('transactionFilterStatus')
      ? +sessionStorage.getItem('transactionFilterStatus')
      : '',
  );
  const [documentNumber, setDocumentNumber] = useState<string>(
    sessionStorage.getItem('transactionFilterDocumentNumber')
      ? sessionStorage.getItem('transactionFilterDocumentNumber')
      : '',
  );
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const [isWinwin, setIsWinwin] = useState<boolean>(
    sessionStorage.getItem('transactionFilterIsWinwin') === 'yes'
      ? true
      : false,
  );
  const [amount, setAmount] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortBy | string>(
    sessionStorage.getItem('transactionFilterSortBy')
      ? sessionStorage.getItem('transactionFilterSortBy')
      : SortBy.DATE_DESCENDING,
  );
  const [wallet, setWallet] = useState<Wallet>({
    IBAN: null,
    LockedWallet: {Id: '', Currency: '', Balance: 0},
    RegularWallet: {Id: '', Currency: '', Balance: 0},
  });
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isWithdrawMenuOpen, setIsWithdrawMenuOpen] = useState<boolean>(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null | undefined
  >(undefined);
  const [currentIndexSelected, setCurrentIndexSelected] = useState<
    number | null
  >(null);

  const lastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileLastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  useEffect(() => {
    console.log('location', location);
    const searchParams = new URLSearchParams(location.search);
    const idFromUrl = searchParams.get('orderId');
    if (idFromUrl) {
      setSelectedTransactionId(idFromUrl);
      handleOpen(idFromUrl);
      console.log('transactionIDxdxdxdxdx: ', idFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    console.log('selected', selectedTransactionId);
  }, [selectedTransactionId]);

  const mobileSearchBarRef = useRef<HTMLInputElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const {setIsMenuOpen} = useMenuContext();
  const {isMobileFilterMenuOpen, setIsMobileFilterMenuOpen} =
    useFilterMenuContext();
  const {isMobileSearchBarOpen, setIsMobileSearchBarOpen} =
    useSearchBarContext();

  const handleIsMobileFilterMenuOpenChange = (value: boolean) =>
    setIsMobileFilterMenuOpen(value);

  const handleIsMobileSearchBarOpenChange = (value: boolean) =>
    setIsMobileSearchBarOpen(value);

  const [userData, setUserData] = useContext(UserDataContext);

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  const navigate = useNavigate();
  const {Alert} = useAlert();

  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  const translatedStatus = {
    'Zamówienie złożone': 0,
    'Oczekuje na płatność': 1,
    'W realizacji': [2, 3, 4, 6],
    Wstrzymane: 5,
    'W trakcie akceptacji': 7,
    'Brak akceptacji': [8, 9],
    'W sporze': 10,
    Zrealizowane: [11, 12],
    'Oczekuje na premierę': 13,
    Zamknięte: [14, 15],
    Anulowane: 16,
    'Oczekuje na zwrot': 17,
    Zwrócone: 18,
    'Status techniczny': '',
  };

  const handleOpen = (transactionId: string) => {
    setSelectedTransactionId(transactionId);
  };

  const handleClose = () => {
    setSelectedTransactionId(undefined);
    setCurrentIndexSelected(null);
  };

  const handleDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    sessionStorage.setItem('transactionFilterDateFrom', e.target.value);
    setDateFrom(e.target.value);
  };

  const handleDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    sessionStorage.setItem('transactionFilterDateTo', e.target.value);
    setDateTo(e.target.value);
  };

  const handleTransactionTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (e.target.value === '') {
      sessionStorage.setItem(
        'transactionFilterTransactionType',
        e.target.value,
      );
      return setTransactionType(e.target.value);
    } else {
      sessionStorage.setItem(
        'transactionFilterTransactionType',
        e.target.value,
      );
      return setTransactionType(+e.target.value);
    }
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    sessionStorage.setItem('transactionFilterSortBy', e.target.value);
    setSortBy(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      sessionStorage.setItem('transactionFilterStatus', e.target.value);
      return setStatus(e.target.value);
    } else {
      sessionStorage.setItem('transactionFilterStatus', e.target.value);
      return setStatus(+e.target.value);
    }
  };

  const getOrderAmountTotal = (obj: Transaction) => {
    if (obj.Order) {
      return obj.Order.AmountTotal;
    } else if (obj.PayOut) {
      return obj.PayOut.DebitedFunds;
    } else {
      return 0;
    }
  };

  const compareDates = (date1: string, date2: string) => {
    return new Date(date1).getTime() - new Date(date2).getTime();
  };

  const sortByDate = (
    objA: Transaction,
    objB: Transaction,
    descending: boolean,
  ) => {
    const dateA = getDate(objA);
    const dateB = getDate(objB);

    if (dateA && dateB) {
      return descending
        ? compareDates(dateB, dateA)
        : compareDates(dateA, dateB);
    } else if (dateA) {
      return descending ? 1 : -1;
    } else if (dateB) {
      return descending ? -1 : 1;
    } else {
      return 0;
    }
  };

  const sortByName = (
    objA: Transaction,
    objB: Transaction,
    descending: boolean,
  ) => {
    const nameA = getName(objA);
    const nameB = getName(objB);

    if (nameA && nameB) {
      return descending
        ? nameB.localeCompare(nameA)
        : nameA.localeCompare(nameB);
    } else if (nameA) {
      return descending ? 1 : -1;
    } else if (nameB) {
      return descending ? -1 : 1;
    } else {
      return 0;
    }
  };

  const getDate = (obj: Transaction): string | undefined => {
    if (obj.Order && obj.Order.CreatedAt) {
      return obj.Order.CreatedAt;
    } else if (obj.PayOut && obj.CreationDate) {
      return obj.CreationDate;
    } else {
      return undefined;
    }
  };

  const getName = (obj: Transaction): string | undefined => {
    if (obj.Order) {
      if (obj.Order.DtoType === 3) {
        return (
          obj.Order.SellerInfo?.CompanyName ||
          `${obj.Order.SellerInfo?.FirstName} ${obj.Order.SellerInfo?.LastName}`
        );
      } else {
        return (
          obj.Order.BillingInfo?.CompanyName ||
          `${obj.Order.BillingInfo?.FirstName} ${obj.Order.BillingInfo?.LastName}`
        );
      }
    } else if (obj.PayOut) {
      return 'MUGOPortfel';
    } else {
      return undefined;
    }
  };

  const handleDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    sessionStorage.setItem('transactionFilterDocumentNumber', e.target.value);
    setDocumentNumber(e.target.value);
  };

  const handleIsWinwinChange = (value: boolean) => {
    setIsWinwin(value);
    if (value === true) {
      sessionStorage.setItem('transactionFilterIsWinwin', 'yes');
    } else {
      sessionStorage.setItem('transactionFilterIsWinwin', 'no');
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAmount(+e.target.value);

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchString(e.target.value);

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleIsSortMenuOpenChange = (value: boolean) =>
    setIsSortMenuOpen(value);

  const handleIsWithdrawMenuOpenChange = (value: boolean) =>
    setIsWithdrawMenuOpen(value);

  const getMangopayWallet = async () => {
    try {
      const response = await api.get(
        `mangopayaccount/getfunds
`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      console.log(response.data.Response, 'wallet response');
      setWallet({
        IBAN: response.data.Response.IBAN,
        LockedWallet: {
          Id: response.data.Response.LockedWallet.Id,
          Currency: response.data.Response.LockedWallet.Currency,
          Balance: response.data.Response.LockedWallet.Balance,
        },
        RegularWallet: {
          Id: response.data.Response.RegularWallet.Id,
          Currency: response.data.Response.RegularWallet.Currency,
          Balance: response.data.Response.RegularWallet.Balance,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getMangopayTransaction = async () => {
    try {
      const response = await api.get(
        `MangopayTransaction/GetTransactions
`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      console.log(response.data.Response, 'transaction response');
    } catch (error) {
      console.error(error);
    }
  };

  const transferMoneyToYourAccount = async (amount: number) => {
    if (!amount) return;
    try {
      const body = {
        Amount: amount * 100,
      };
      const response = await api.post(
        `MangopayTransaction/PayOut
`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setIsWithdrawMenuOpen(false);
      Alert(`Wypłaciłeś ${amount} zł`, 'Komunikat', 'Zamknij', true);
    } catch (error) {
      setIsWithdrawMenuOpen(false);
      console.error('API error:', error);
      if (
        error?.response?.data?.Error?.Message ===
        'Insufficient funds in the wallet.'
      ) {
        Alert('Brak wystarczających środków w twoim portfelu.', 'Błąd.');
      } else if (error?.response?.data?.Error?.Message === '_') {
        Alert(
          'Coś poszło nie tak, spóbuj ponownie za chwilę, lub skontaktuj się ze wsparciem',
          'Błąd.',
        );
      } else if (
        error?.response?.data?.Error?.Message ===
        'Amount has to be greater then zero.'
      ) {
        Alert('Kwota musi być większa od zera.', 'Błąd.');
      } else {
        const errorMessage =
          error?.response?.data?.Error?.Message ||
          error.response?.data?.message ||
          error.message ||
          'Coś poszło nie tak, spóbuj ponownie za chwilę, lub skontaktuj się ze wsparciem';
        Alert(`${errorMessage}`, 'Błąd');
      }

      return null;
    }
  };

  const clearFilters = () => {
    setSearchString('');
    setDateFrom('');
    sessionStorage.removeItem('transactionFilterDateFrom');
    setDateTo('');
    sessionStorage.removeItem('transactionFilterDateTo');
    setTransactionType('');
    sessionStorage.removeItem('transactionFilterTransactionType');
    setStatus('');
    sessionStorage.removeItem('transactionFilterStatus');
    setDocumentNumber('');
    sessionStorage.removeItem('transactionFilterDocumentNumber');
    setIsWinwin(false);
    sessionStorage.removeItem('transactionFilterIsWinwin');
  };

  const getTransactions = async () => {
    try {
      const response = await api.get(
        `Orders
`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      console.log(response.data.Response, 'orders response');
      setTransactions(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getTransactionsAndPayOuts = async (
    searchString: string,
    status: any,
    dateFrom: string,
    dateTo: string,
    orderType: any,
    isWinWin: boolean,
    sortBy: SortBy | string,
    pageNumber: number,
  ) => {
    try {
      setIsLoading(true);
      const params = {
        pageSize: 20,
        pageNumber: pageNumber,
        searchString,
        status,
        dateFrom,
        dateTo,
        orderType,
        isWinWin,
        sortType: sortBy === SortBy.DATE_ASCENDING ? 0 : 1,
      };
      const response = await api.get(
        `Orders/GetPayOutsAndOrders
`,
        {
          params,
          paramsSerializer: (params) => qs.stringify(params),
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setHasMoreProducers(response.data.Response.length > 0);
      setTransactions((prev) => [...prev, ...response.data.Response]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTransactionsAndPayOutsDebounce = useCallback(
    _.debounce(
      (
        searchString,
        status,
        dateFrom,
        dateTo,
        transactionType,
        isWinwin,
        sortBy,
        pageNumber,
      ) =>
        getTransactionsAndPayOuts(
          searchString,
          status,
          dateFrom,
          dateTo,
          transactionType,
          isWinwin,
          sortBy,
          pageNumber,
        ),
      500,
    ),
    [],
  );

  const checkTransferType = (type: string, nature: string) => {
    if (type === 'TRANSFER' && nature === 'REGULAR') {
      return 2;
    }
    if (type === 'PAYIN' && nature === 'REGULAR') {
      return 2;
    }
    if (type === 'PAYOUT' && nature === 'REGULAR') {
      return 1;
    }
  };

  useEffect(() => {
    getMangopayWallet();
  }, []);

  useEffect(() => {
    getTransactionsAndPayOutsDebounce(
      searchString,
      status,
      dateFrom,
      dateTo,
      transactionType,
      isWinwin,
      sortBy,
      pageNumber,
    );
  }, [
    searchString,
    status,
    dateFrom,
    dateTo,
    transactionType,
    isWinwin,
    sortBy,
    pageNumber,
  ]);

  useEffect(() => {
    setPageNumber(1);
    setTransactions([]);
  }, [
    searchString,
    status,
    dateFrom,
    dateTo,
    transactionType,
    isWinwin,
    sortBy,
  ]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileFilterMenuWrapperRef.current) {
        if (isMobileFilterMenuOpen) {
          mobileFilterMenuWrapperRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileFilterMenuWrapperRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileFilterMenuOpen]);

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          {isWithdrawMenuOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseWithdrawMenuButton
                  onClick={() => handleIsWithdrawMenuOpenChange(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseWithdrawMenuButton>
                <WithdrawMenuHeader>
                  <p>Komunikat</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>

              <InputView
                isError={false}
                isEditing={true}
                style={{width: '180px', marginTop: '20px'}}
              >
                <p>Kwota</p>
                <input
                  type="text"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  value={amount}
                  onChange={handleAmountChange}
                />
              </InputView>
              <button onClick={() => transferMoneyToYourAccount(amount)}>
                Wypłać
              </button>
            </WithdrawMenuWrapper>
          )}
          <InfoBlockWrapper
            onMouseLeave={() => {
              handleIsFilterMenuOpenChange(false);
              handleIsSortMenuOpenChange(false);
            }}
          >
            <InfoBlockHeaderWrapper>
              <InfoBlockHeader>
                <p>
                  {wallet.IBAN === null
                    ? 'Transakcje'
                    : `STAN RACHUNKU: ${getFormattedDate()}`}
                </p>
              </InfoBlockHeader>
            </InfoBlockHeaderWrapper>
            <InfoBlockContentWrapper>
              <InfoBlockContent wallet={wallet.IBAN}>
                {wallet.IBAN !== null && (
                  <>
                    <PhoneSvgWithText>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30.724"
                        height="46"
                        viewBox="0 0 30.724 46"
                      >
                        <g
                          id="Group_6900"
                          data-name="Group 6900"
                          transform="translate(-203.063 -163)"
                        >
                          <g
                            id="Group_6262"
                            data-name="Group 6262"
                            transform="translate(194.213 163)"
                          >
                            <path
                              id="Path_3911"
                              data-name="Path 3911"
                              d="M112.674,151.653a4.023,4.023,0,0,0-2.757-1.125h-4.495a.789.789,0,0,0-.176,0H94.654a.8.8,0,0,0-.177,0H89.967a3.84,3.84,0,0,0-3.881,3.881v9.181c-.049.047-.1.094-.145.142a9.721,9.721,0,0,0,0,13.784c.048.048.1.095.145.142v14.985A3.835,3.835,0,0,0,89.8,196.52c.054,0,.109,0,.163,0h19.95c.054,0,.109,0,.163,0a3.836,3.836,0,0,0,3.718-3.877V154.41a4.023,4.023,0,0,0-1.125-2.757m-12.732,1.814H96.46l-.687-1.415h8.339l-.687,1.415ZM84.6,170.624a8.167,8.167,0,0,1,2.43-5.8,8.051,8.051,0,0,1,5.767-2.394,8.236,8.236,0,0,1,8.234,8.2,8.232,8.232,0,0,1-2.43,5.8,8.137,8.137,0,0,1-5.8,2.394,8.052,8.052,0,0,1-5.767-2.394,8.232,8.232,0,0,1-2.43-5.8m27.677,22.018a2.488,2.488,0,0,1-.689,1.669,2.393,2.393,0,0,1-1.669.689H89.967a2.392,2.392,0,0,1-1.669-.689,2.488,2.488,0,0,1-.689-1.669v-2.1h21.946a.775.775,0,0,0,.762-.8.766.766,0,0,0-.762-.762H87.609v-10.13a9.733,9.733,0,0,0,12.079-15.117,9.794,9.794,0,0,0-6.892-2.866,9.618,9.618,0,0,0-5.187,1.525V154.41a2.488,2.488,0,0,1,.689-1.669,2.392,2.392,0,0,1,1.669-.689h4.1l1.236,2.5a.746.746,0,0,0,.689.435H103.9a.711.711,0,0,0,.689-.435l1.236-2.5h4.1a2.353,2.353,0,0,1,2.358,2.358v38.232Z"
                              transform="translate(-74.225 -150.524)"
                              fill="#07020f"
                            />
                            <path
                              id="Path_3912"
                              data-name="Path 3912"
                              d="M215.738,540.752h-5.586a.791.791,0,0,0-.762.762.766.766,0,0,0,.762.762h5.586a.766.766,0,0,0,.762-.762.791.791,0,0,0-.762-.762"
                              transform="translate(-187.082 -499.178)"
                              fill="#07020f"
                            />
                          </g>
                          <path
                            id="Path_3927"
                            data-name="Path 3927"
                            d="M4.945-5.26a.713.713,0,0,0,.714-.714.713.713,0,0,0-.714-.714h-2.1L5.572-10.05a.613.613,0,0,0,.132-.4.63.63,0,0,0-.659-.637H1.3a.713.713,0,0,0-.714.714.713.713,0,0,0,.714.714H3.385L.672-6.293a.613.613,0,0,0-.132.4A.63.63,0,0,0,1.2-5.26Zm1.912-.615a.736.736,0,0,0,.736.736.736.736,0,0,0,.736-.736V-8.249L9.076-8.6a.54.54,0,0,0,.253-.7.54.54,0,0,0-.7-.253l-.3.132v-3.34a.736.736,0,0,0-.736-.736.736.736,0,0,0-.736.736v4.032l-.747.341a.531.531,0,0,0-.253.692.535.535,0,0,0,.7.264l.3-.132Z"
                            transform="translate(207.96 191.613)"
                            fill="#07020f"
                          />
                        </g>
                      </svg>
                      <h1>
                        {wallet.IBAN === null
                          ? ''
                          : `${new Intl.NumberFormat('de-DE', {
                              minimumFractionDigits: 2,
                            }).format(
                              (wallet.RegularWallet.Balance +
                                wallet.LockedWallet.Balance) /
                                100,
                            )} ${wallet.RegularWallet.Currency}`}
                      </h1>
                    </PhoneSvgWithText>
                    <TextsInColumn>
                      <HeaderTextWithSvg style={{justifyContent: 'center'}}>
                        <p>Numer konta bankowego</p>
                        <span>
                          {wallet.IBAN === null
                            ? ''
                            : `${formatWalletIBAN(wallet.IBAN)}`}
                        </span>
                      </HeaderTextWithSvg>
                      <TextsInRow>
                        <HeaderTextWithSvg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              id="Icon_awesome-check-circle"
                              data-name="Icon awesome-check-circle"
                              d="M9.563,5.062a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.062ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445a.29.29,0,0,0,.411,0Z"
                              transform="translate(-0.563 -0.563)"
                              fill="#00d10f"
                            />
                          </svg>
                          <p>Dostępne środki</p>
                          <span>
                            {' '}
                            {wallet.IBAN === null
                              ? ''
                              : `${new Intl.NumberFormat('de-DE', {
                                  minimumFractionDigits: 2,
                                }).format(
                                  wallet.RegularWallet.Balance / 100,
                                )} ${wallet.RegularWallet.Currency}`}
                          </span>
                        </HeaderTextWithSvg>
                        <HeaderTextWithSvg style={{justifyContent: 'flex-end'}}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              id="Icon_material-do-not-disturb-on"
                              data-name="Icon material-do-not-disturb-on"
                              d="M7.5,3A4.5,4.5,0,1,0,12,7.5,4.5,4.5,0,0,0,7.5,3ZM9.75,7.95H5.25v-.9h4.5Z"
                              transform="translate(-3 -3)"
                              fill="#ff0300"
                            />
                          </svg>

                          <p>Zablokowane środki</p>
                          <span>
                            {wallet.IBAN === null
                              ? ''
                              : `${new Intl.NumberFormat('de-DE', {
                                  minimumFractionDigits: 2,
                                }).format(wallet.LockedWallet.Balance / 100)} ${
                                  wallet.LockedWallet.Currency
                                }`}
                          </span>
                        </HeaderTextWithSvg>
                      </TextsInRow>
                    </TextsInColumn>
                    <NewWithdrawButton
                      onClick={() =>
                        handleIsWithdrawMenuOpenChange(!isWithdrawMenuOpen)
                      }
                    >
                      Wypłać
                    </NewWithdrawButton>
                  </>
                )}
              </InfoBlockContent>

              <FilterWrapper style={{paddingBottom: '10px'}}>
                <SortButtonSvg
                  value={isSortMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(!isSortMenuOpen);
                    handleIsFilterMenuOpenChange(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.824"
                  height="13.204"
                  viewBox="0 0 18.824 13.204"
                >
                  <g
                    id="Group_1311"
                    data-name="Group 1311"
                    transform="translate(-2536.541 -534.989)"
                  >
                    <path
                      id="Path_1508"
                      data-name="Path 1508"
                      d="M2543.457,537.33l-2.582-2.1a1.076,1.076,0,0,0-1.357,0l-2.581,2.1a1.075,1.075,0,0,0,1.357,1.668l.9-.732v6.852a1.075,1.075,0,1,0,2.15,0v-6.735l.756.615a1.075,1.075,0,1,0,1.357-1.668Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1509"
                      data-name="Path 1509"
                      d="M2548.448,545.852l2.582,2.1a1.074,1.074,0,0,0,1.357,0l2.581-2.1a1.075,1.075,0,0,0-1.357-1.668l-.9.732v-6.852a1.075,1.075,0,0,0-2.151,0V544.8l-.756-.615a1.075,1.075,0,0,0-1.357,1.668Z"
                      fill="#797c93"
                    />
                  </g>
                </SortButtonSvg>
                <FilterInputWrapper>
                  {/* <img src={searchIcon} alt="search" /> */}
                  <svg
                    id="Group_4104"
                    data-name="Group 4104"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#0091ff"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    value={searchString}
                    onChange={handleSearchStringChange}
                  />
                </FilterInputWrapper>
                {countTruthyVariables(
                  searchString,
                  dateFrom,
                  dateTo,
                  documentNumber,
                  transactionType,
                  status,
                  isWinwin,
                ) > 0 && (
                  <CountFilterDisplay style={{top: '146px'}}>
                    {countTruthyVariables(
                      searchString,
                      dateFrom,
                      dateTo,
                      documentNumber,
                      transactionType,
                      status,
                      isWinwin,
                    )}
                  </CountFilterDisplay>
                )}
                <FilterButtonSvg
                  value={isFilterMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(false);
                    handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.87"
                  height="15.047"
                  viewBox="0 0 20.87 15.047"
                >
                  <g
                    id="Group_1312"
                    data-name="Group 1312"
                    transform="translate(-2473.175 -536.818)"
                  >
                    <path
                      id="Path_1510"
                      data-name="Path 1510"
                      d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1511"
                      data-name="Path 1511"
                      d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1512"
                      data-name="Path 1512"
                      d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 548.864)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 542.853)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 536.818)"
                      fill="#797c93"
                    />
                  </g>
                </FilterButtonSvg>
                {countTruthyVariables(
                  searchString,
                  dateFrom,
                  dateTo,
                  documentNumber,
                  transactionType,
                  status,
                  isWinwin,
                ) > 0 && (
                  <ClearFiltersButton onClick={() => clearFilters()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </ClearFiltersButton>
                )}
              </FilterWrapper>
              {isSortMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kolejność</p>
                        <select value={sortBy} onChange={handleSortByChange}>
                          <option value={SortBy.DATE_DESCENDING}>
                            Najnowsze
                          </option>
                          <option value={SortBy.DATE_ASCENDING}>
                            Najstarsze
                          </option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
              {isFilterMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Typ transakcji</p>
                        <select
                          value={transactionType}
                          onChange={handleTransactionTypeChange}
                        >
                          <option value={''}>Wszystkie</option>
                          <option value={OrderType.Purchase}>Zakup</option>
                          <option value={OrderType.SellerPlan}>Plan</option>
                          <option value={OrderType.Sale}>Sprzedaż</option>
                          <option value={OrderType.PayoutFee}>Wypłata</option>
                          {/* <option value={99}>Wypłata</option> */}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Status transakcji</p>
                        <select value={status} onChange={handleStatusChange}>
                          <option value={''}>Wszystkie</option>
                          <option value={OrderStatus.Pending}>
                            Zamówienie złożone
                          </option>
                          <option value={OrderStatus.AwaitingPayment}>
                            Oczekuje na płatność
                          </option>
                          <option
                            value={OrderStatus.AwaitingDocumentAdvanceInvoice}
                          >
                            W realizacji
                          </option>
                          {/* <option
                          value={OrderStatus.AwaitingDocumentContractStart}
                        >
                          AwaitingDocumentContractStart
                        </option> */}
                          {/* <option value={OrderStatus.InRealisation}>
                          InRealisation
                        </option> */}
                          <option value={OrderStatus.OnHold}>Wstrzymane</option>
                          {/* <option
                          value={OrderStatus.RealizationDateExtensionRequest}
                        >
                          RealizationDateExtensionRequest
                        </option> */}
                          <option value={OrderStatus.Submitted}>
                            W trakcie akceptacji
                          </option>
                          <option value={OrderStatus.CommentsFromCustomer}>
                            Brak akceptacji
                          </option>
                          {/* <option value={OrderStatus.CommentsFRejectedBySeller}>
                          CommentsRejectedBySeller
                        </option> */}
                          <option value={OrderStatus.InConflict}>
                            W sporze
                          </option>
                          <option value={OrderStatus.Completed}>
                            Zrealizowane
                          </option>
                          {/* <option value={OrderStatus.CompletedWithDocument}>
                          CompletedWithDocument
                        </option> */}
                          <option value={OrderStatus.AwaitingWinWinAction}>
                            Oczekuje na premierę
                          </option>
                          <option value={OrderStatus.Closed}>Zamknięte</option>
                          {/* <option
                          value={OrderStatus.AwaitingDocumentContractEnding}
                        >
                          AwaitingDocumentContractEnding
                        </option> */}
                          <option value={OrderStatus.Cancelled}>
                            Anulowane
                          </option>
                          <option value={OrderStatus.AwaitingRefund}>
                            Oczekuje na zwrot
                          </option>
                          <option value={OrderStatus.Refunded}>Zwrócone</option>
                          <option value={OrderStatus.Failed}>
                            Status techniczny
                          </option>
                          {/* <option value={OrderStatus.Trash}>Trash</option> */}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <FilterInput>
                        <p>Data transakcji od</p>
                        <input
                          type="date"
                          placeholder="Od"
                          value={dateFrom}
                          onChange={handleDateFromChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                      <FilterInput>
                        <p>Data transakcji do</p>
                        <input
                          type="date"
                          placeholder="Do"
                          value={dateTo}
                          onChange={handleDateToChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko Win-Win</p>
                        <CustomBlueCheckbox
                          isActive={isWinwin}
                          onClick={() => handleIsWinwinChange(!isWinwin)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
            </InfoBlockContentWrapper>
          </InfoBlockWrapper>
          <Grid IbanIsNull={wallet.IBAN === null} ref={scrollRef}>
            {transactions
              // .filter((transaction) => {
              //   let shouldInclude = true;

              //   if (status || status === 0) {
              //     const transactionStatus = transaction.Order?.Status;

              //     if (
              //       (status === 2 ||
              //         status === 3 ||
              //         status === 4 ||
              //         status === 6) &&
              //       (transactionStatus === 2 ||
              //         transactionStatus === 3 ||
              //         transactionStatus === 4 ||
              //         transactionStatus === 6)
              //     ) {
              //       shouldInclude = true;
              //     } else if (
              //       (status === 8 || status === 9) &&
              //       (transactionStatus === 8 || transactionStatus === 9)
              //     ) {
              //       shouldInclude = true;
              //     } else if (
              //       (status === 11 || status === 12) &&
              //       (transactionStatus === 11 || transactionStatus === 12)
              //     ) {
              //       shouldInclude = true;
              //     } else if (
              //       (status === 14 || status === 15) &&
              //       (transactionStatus === 14 || transactionStatus === 15)
              //     ) {
              //       shouldInclude = true;
              //     } else {
              //       shouldInclude = status === transactionStatus;
              //     }
              //   }

              //   if (documentNumber) {
              //     if (transaction.Order && transaction.Order.Number) {
              //       shouldInclude =
              //         shouldInclude &&
              //         transaction.Order.Number.toLowerCase().indexOf(
              //           documentNumber.toLowerCase(),
              //         ) !== -1;
              //     } else if (transaction.PayOut && transaction.PayOut.Id) {
              //       shouldInclude =
              //         shouldInclude &&
              //         transaction.PayOut.Id.toLowerCase().indexOf(
              //           documentNumber.toLowerCase(),
              //         ) !== -1;
              //     }
              //   }

              //   if (isWinwin && !transaction.Order?.IsWinWin) {
              //     shouldInclude = shouldInclude && false;
              //   }

              //   const createdAt = transaction.Order
              //     ? moment(transaction.Order.CreatedAt)
              //     : moment(transaction.CreationDate);

              //   if (
              //     dateFrom &&
              //     createdAt.isBefore(moment(dateFrom).startOf('day'))
              //   ) {
              //     shouldInclude = shouldInclude && false;
              //   }

              //   if (dateTo && createdAt.isAfter(moment(dateTo).endOf('day'))) {
              //     shouldInclude = shouldInclude && false;
              //   }

              //   if (transactionType) {
              //     if (transactionType === 99) {
              //       shouldInclude = shouldInclude && !transaction.Order;
              //     } else if (
              //       transaction.Order &&
              //       transaction.Dto === transactionType
              //     ) {
              //       shouldInclude = shouldInclude && true;
              //     } else {
              //       shouldInclude = shouldInclude && false;
              //     }
              //   }

              //   return shouldInclude;
              // })
              // .filter((transaction) => {
              //   // if (
              //   //   (status || status === 0) &&
              //   //   transaction.Order?.Status !== status
              //   // )
              //   //   return false;
              //   if (status || status === 0) {
              //     const transactionStatus = transaction.Order?.Status;

              //     if (
              //       (status === 2 ||
              //         status === 3 ||
              //         status === 4 ||
              //         status === 6) &&
              //       (transactionStatus === 2 ||
              //         transactionStatus === 3 ||
              //         transactionStatus === 4 ||
              //         transactionStatus === 6)
              //     ) {
              //       return true;
              //     } else if (
              //       (status === 8 || status === 9) &&
              //       (transactionStatus === 8 || transactionStatus === 9)
              //     ) {
              //       return true;
              //     } else if (
              //       (status === 11 || status === 12) &&
              //       (transactionStatus === 11 || transactionStatus === 12)
              //     ) {
              //       return true;
              //     } else if (
              //       (status === 14 || status === 15) &&
              //       (transactionStatus === 14 || transactionStatus === 15)
              //     ) {
              //       return true;
              //     }

              //     return transactionStatus === status;
              //   }
              //   if (documentNumber) {
              //     if (transaction.Order && transaction.Order.Number) {
              //       if (
              //         transaction.Order.Number.toLowerCase().indexOf(
              //           documentNumber.toLowerCase(),
              //         ) === -1
              //       ) {
              //         return false;
              //       }
              //     } else if (transaction.PayOut && transaction.PayOut.Id) {
              //       if (
              //         transaction.PayOut.Id.toLowerCase().indexOf(
              //           documentNumber.toLowerCase(),
              //         ) === -1
              //       ) {
              //         return false;
              //       }
              //     }
              //   }
              //   if (isWinwin && !transaction.Order?.IsWinWin) return false;

              //   const createdAt = transaction.Order
              //     ? moment(transaction.Order.CreatedAt)
              //     : moment(transaction.CreationDate);
              //   if (
              //     dateFrom &&
              //     createdAt.isBefore(moment(dateFrom).startOf('day'))
              //   ) {
              //     return false;
              //   }

              //   if (dateTo && createdAt.isAfter(moment(dateTo).endOf('day'))) {
              //     return false;
              //   }

              //   if (transactionType) {
              //     if (transactionType === 99) {
              //       return !transaction.Order;
              //     } else if (
              //       transaction.Order &&
              //       transaction.Dto === transactionType
              //     ) {
              //       return true;
              //     } else {
              //       return false;
              //     }
              //   }
              //   return true;
              // })
              // .sort((a, b) => {
              //   if (sortBy === 3) {
              //     return sortByDate(a, b, false);
              //   } else if (sortBy === 4) {
              //     return sortByDate(a, b, true);
              //   } else if (sortBy === 5) {
              //     return getOrderAmountTotal(a) - getOrderAmountTotal(b);
              //   } else if (sortBy === 6) {
              //     return getOrderAmountTotal(b) - getOrderAmountTotal(a);
              //   } else if (sortBy === 1) {
              //     return sortByName(a, b, false);
              //   } else if (sortBy === 2) {
              //     return sortByName(a, b, true);
              //   } else {
              //     return 0;
              //   }
              // })
              .map((transaction, index) => {
                if (transaction.Type === 1 || transaction.Type === 3) {
                  return (
                    <SingleTransaction
                      desktopRef={lastElementRef}
                      setTransactions={setTransactions}
                      transaction={transaction}
                      key={transaction.Order.Id}
                      index={index}
                      setCurrentIndexSelected={setCurrentIndexSelected}
                      currentIndexSelected={currentIndexSelected}
                      isDetailsOpen={
                        selectedTransactionId === transaction.Order.Id
                      }
                      onOpen={() => handleOpen(transaction.Order.Id)}
                      onClose={handleClose}
                    />
                  );
                } else {
                  return (
                    <SingleTransaction
                      setTransactions={setTransactions}
                      desktopRef={lastElementRef}
                      transaction={transaction}
                      index={index}
                      setCurrentIndexSelected={setCurrentIndexSelected}
                      currentIndexSelected={currentIndexSelected}
                      key={transaction.PayOut.Id}
                      isDetailsOpen={
                        selectedTransactionId === transaction.PayOut.Id
                      }
                      onOpen={() => handleOpen(transaction.PayOut.Id)}
                      onClose={handleClose}
                    />
                  );
                }
              })}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        {isWithdrawMenuOpen && (
          <WithdrawMenuWrapper>
            <WithdrawMenuHeaderWrapper>
              <CloseWithdrawMenuButton
                onClick={() => handleIsWithdrawMenuOpenChange(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                >
                  <path
                    id="Path_4069"
                    data-name="Path 4069"
                    d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                    transform="translate(-23.455 0)"
                    fill="#fff"
                  />
                </svg>
              </CloseWithdrawMenuButton>
              <WithdrawMenuHeader>
                <p>Komunikat</p>
              </WithdrawMenuHeader>
            </WithdrawMenuHeaderWrapper>

            <InputView
              isError={false}
              isEditing={true}
              style={{width: '180px', marginTop: '20px'}}
            >
              <p>Kwota</p>
              <input
                type="text"
                placeholder="Uzupełnij"
                readOnly={false}
                value={amount}
                onChange={handleAmountChange}
              />
            </InputView>
            <button onClick={() => transferMoneyToYourAccount(amount)}>
              Wypłać
            </button>
          </WithdrawMenuWrapper>
        )}
        <MenuMain
          isMenuOpen={isMobileFilterMenuOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Filtrowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileFilterMenuOpenChange(false);
                setIsBodyOverflowBlocked(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(false);
                mobileFilterMenuWrapperRef.current.style.transition =
                  'all 0.35s ease-out';
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MenuWrapper>
            {isSortMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={sortBy}>
                  <select value={sortBy} onChange={handleSortByChange}>
                    <option value={SortBy.DATE_DESCENDING}>Najnowsze</option>
                    <option value={SortBy.DATE_ASCENDING}>Najstarsze</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
              </>
            )}
            {isFilterMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={transactionType}>
                  <select
                    value={transactionType}
                    onChange={handleTransactionTypeChange}
                  >
                    <option value={''} hidden>
                      Typ
                    </option>
                    <option value={''}>Wszystkie</option>
                    <option value={OrderType.Purchase}>Zakup</option>
                    <option value={OrderType.SellerPlan}>Plan</option>
                    <option value={OrderType.Sale}>Sprzedaż</option>
                    <option value={OrderType.PayoutFee}>Wypłata</option>
                    {/* <option value={99}>Wypłata</option> */}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>

                <MobileSelectFilterWrapper value={status}>
                  <select value={status} onChange={handleStatusChange}>
                    <option value={''} hidden>
                      Status
                    </option>
                    <option value={''}>Wszystkie</option>
                    <option value={OrderStatus.Pending}>
                      Zamówienie złożone
                    </option>
                    <option value={OrderStatus.AwaitingPayment}>
                      Oczekuje na płatność
                    </option>
                    <option value={OrderStatus.AwaitingDocumentAdvanceInvoice}>
                      W realizacji
                    </option>
                    {/* <option
                          value={OrderStatus.AwaitingDocumentContractStart}
                        >
                          AwaitingDocumentContractStart
                        </option> */}
                    {/* <option value={OrderStatus.InRealisation}>
                          InRealisation
                        </option> */}
                    <option value={OrderStatus.OnHold}>Wstrzymane</option>
                    {/* <option
                          value={OrderStatus.RealizationDateExtensionRequest}
                        >
                          RealizationDateExtensionRequest
                        </option> */}
                    <option value={OrderStatus.Submitted}>
                      W trakcie akceptacji
                    </option>
                    <option value={OrderStatus.CommentsFromCustomer}>
                      Brak akceptacji
                    </option>
                    {/* <option value={OrderStatus.CommentsFRejectedBySeller}>
                          CommentsRejectedBySeller
                        </option> */}
                    <option value={OrderStatus.InConflict}>W sporze</option>
                    <option value={OrderStatus.Completed}>Zrealizowane</option>
                    {/* <option value={OrderStatus.CompletedWithDocument}>
                          CompletedWithDocument
                        </option> */}
                    <option value={OrderStatus.AwaitingWinWinAction}>
                      Oczekuje na premierę
                    </option>
                    <option value={OrderStatus.Closed}>Zamknięte</option>
                    {/* <option
                          value={OrderStatus.AwaitingDocumentContractEnding}
                        >
                          AwaitingDocumentContractEnding
                        </option> */}
                    <option value={OrderStatus.Cancelled}>Anulowane</option>
                    <option value={OrderStatus.AwaitingRefund}>
                      Oczekuje na zwrot
                    </option>
                    <option value={OrderStatus.Refunded}>Zwrócone</option>
                    <option value={OrderStatus.Failed}>
                      Status techniczny
                    </option>
                    {/* <option value={OrderStatus.Trash}>Trash</option> */}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>

                <MobileInputFilterWrapper>
                  <p>Data&nbsp;od</p>
                  <input
                    type="date"
                    placeholder="Od"
                    value={dateFrom}
                    onChange={handleDateFromChange}
                  />
                </MobileInputFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Data&nbsp;do</p>
                  <input
                    type="date"
                    placeholder="Do"
                    value={dateTo}
                    onChange={handleDateToChange}
                  />
                </MobileInputFilterWrapper>

                <MobileCheckboxFilterWrapper>
                  <p>Win-Win</p>
                  <MobileCheckbox
                    isActive={isWinwin}
                    onClick={() => handleIsWinwinChange(!isWinwin)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
              </>
            )}
          </MenuWrapper>
        </MenuMain>
        <MobileMain>
          <MobileInfoBlockWrapper>
            <MobileSingleInfoBlockRow>
              <TopTitle>
                <p>{`Stan rachunku: ${getFormattedDate()}`}</p>
              </TopTitle>
              <BottomRow>
                <h1>
                  {wallet.IBAN === null
                    ? ''
                    : `${new Intl.NumberFormat('de-DE', {
                        minimumFractionDigits: 2,
                      }).format(
                        (wallet.RegularWallet.Balance +
                          wallet.LockedWallet.Balance) /
                          100,
                      )} ${wallet.RegularWallet.Currency}`}
                </h1>
              </BottomRow>
              <MobileWithdrawButton
                onClick={() =>
                  handleIsWithdrawMenuOpenChange(!isWithdrawMenuOpen)
                }
              >
                Wypłać
              </MobileWithdrawButton>
            </MobileSingleInfoBlockRow>
          </MobileInfoBlockWrapper>
          <div style={{width: '100%', position: 'absolute'}}>
            <MobileSingleInfoBlockRow>
              <TopTitle style={{justifyContent: 'space-between'}}>
                <p>Dostępne środki</p>
                <p>Wartość</p>
              </TopTitle>
              <BottomRow style={{justifyContent: 'space-between'}}>
                <p>
                  {' '}
                  {wallet.IBAN === null
                    ? ''
                    : `${new Intl.NumberFormat('de-DE', {
                        minimumFractionDigits: 2,
                      }).format(wallet.RegularWallet.Balance / 100)} ${
                        wallet.RegularWallet.Currency
                      }`}
                </p>
                <p>
                  {' '}
                  {wallet.IBAN === null
                    ? ''
                    : `${new Intl.NumberFormat('de-DE', {
                        minimumFractionDigits: 2,
                      }).format(wallet.LockedWallet.Balance / 100)} ${
                        wallet.LockedWallet.Currency
                      }`}
                </p>
              </BottomRow>
            </MobileSingleInfoBlockRow>
            <MobileSingleInfoBlockRow>
              <TopTitle style={{justifyContent: 'center'}}>
                <p>Numer konta bankowego</p>
              </TopTitle>
              <BottomRow style={{justifyContent: 'center'}}>
                <p>
                  {' '}
                  {wallet.IBAN === null
                    ? ''
                    : `${formatWalletIBAN(wallet.IBAN)}`}
                </p>
              </BottomRow>
            </MobileSingleInfoBlockRow>
          </div>
          {isLoading && <LoadingCircle />}
          <MobileFilterWrapper>
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(true);
                handleIsFilterMenuOpenChange(false);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="23.197"
              height="17.036"
              viewBox="0 0 23.197 17.036"
            >
              <g
                id="Group_1924"
                data-name="Group 1924"
                transform="translate(-255.98 -356.674)"
                opacity="0.86"
              >
                <g
                  id="Group_1923"
                  data-name="Group 1923"
                  transform="translate(255.98 356.674)"
                >
                  <path
                    id="Path_1649"
                    data-name="Path 1649"
                    d="M258.494,352.337a1.071,1.071,0,0,0-1.437,0l-3.981,3.585a1.076,1.076,0,1,0,1.439,1.6l2.185-1.968v11.931a1.075,1.075,0,0,0,2.151,0V355.553l2.185,1.968a1.076,1.076,0,1,0,1.439-1.6Z"
                    transform="translate(-252.719 -352.06)"
                  />
                  <path
                    id="Path_1650"
                    data-name="Path 1650"
                    d="M269.854,363.478a1.073,1.073,0,0,0-1.518-.079l-2.187,1.97V353.436a1.075,1.075,0,1,0-2.149,0v11.931l-2.185-1.968a1.076,1.076,0,1,0-1.439,1.6l3.981,3.585a1.076,1.076,0,0,0,1.439,0L269.775,365A1.075,1.075,0,0,0,269.854,363.478Z"
                    transform="translate(-246.934 -351.822)"
                  />
                </g>
              </g>
            </svg>
            <MobileSearchInputWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.157"
                height="15"
                viewBox="0 0 15.157 15"
              >
                <g id="Group_1310" data-name="Group 1310" opacity="0.86">
                  <path
                    id="Path_1507"
                    data-name="Path 1507"
                    d="M2602.818,548.518l-3.771-3.436a6.235,6.235,0,0,0-.48-8.26h0a6.231,6.231,0,1,0-.752,9.442l3.856,3.514a.853.853,0,1,0,1.149-1.261Zm-12.1-3.843a4.879,4.879,0,1,1,3.449,1.429A4.884,4.884,0,0,1,2590.714,544.675Z"
                    transform="translate(-2587.94 -535.001)"
                  />
                </g>
              </svg>
              <input
                type="text"
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </MobileSearchInputWrapper>
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(true);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <g
                id="Group_1925"
                data-name="Group 1925"
                transform="translate(-155.861 -459.378)"
                opacity="0.86"
              >
                <path
                  id="Path_1651"
                  data-name="Path 1651"
                  d="M173.319,462.311H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.126)"
                />
                <path
                  id="Path_1652"
                  data-name="Path 1652"
                  d="M173.319,468.486H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.886)"
                />
                <path
                  id="Path_1653"
                  data-name="Path 1653"
                  d="M173.319,474.366H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 1.609)"
                />
                <circle
                  id="Ellipse_11"
                  data-name="Ellipse 11"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 472.907)"
                />
                <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 466.156)"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 459.378)"
                />
              </g>
            </svg>
            {countTruthyVariables(
              searchString,
              dateFrom,
              dateTo,
              documentNumber,
              transactionType,
              status,
              isWinwin,
            ) > 0 && (
              <ClearFiltersButton onClick={() => clearFilters()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.9"
                  height="12"
                  viewBox="0 0 10.9 12"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9h9.9"
                      transform="translate(-4.5 -6.8)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                      transform="translate(-6.4 -3)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </ClearFiltersButton>
            )}
          </MobileFilterWrapper>
          {transactions
            // .filter((transaction) => {
            //   let shouldInclude = true;

            //   if (status || status === 0) {
            //     const transactionStatus = transaction.Order?.Status;

            //     if (
            //       (status === 2 ||
            //         status === 3 ||
            //         status === 4 ||
            //         status === 6) &&
            //       (transactionStatus === 2 ||
            //         transactionStatus === 3 ||
            //         transactionStatus === 4 ||
            //         transactionStatus === 6)
            //     ) {
            //       shouldInclude = true;
            //     } else if (
            //       (status === 8 || status === 9) &&
            //       (transactionStatus === 8 || transactionStatus === 9)
            //     ) {
            //       shouldInclude = true;
            //     } else if (
            //       (status === 11 || status === 12) &&
            //       (transactionStatus === 11 || transactionStatus === 12)
            //     ) {
            //       shouldInclude = true;
            //     } else if (
            //       (status === 14 || status === 15) &&
            //       (transactionStatus === 14 || transactionStatus === 15)
            //     ) {
            //       shouldInclude = true;
            //     } else {
            //       shouldInclude = status === transactionStatus;
            //     }
            //   }

            //   if (documentNumber) {
            //     if (transaction.Order && transaction.Order.Number) {
            //       shouldInclude =
            //         shouldInclude &&
            //         transaction.Order.Number.toLowerCase().indexOf(
            //           documentNumber.toLowerCase(),
            //         ) !== -1;
            //     } else if (transaction.PayOut && transaction.PayOut.Id) {
            //       shouldInclude =
            //         shouldInclude &&
            //         transaction.PayOut.Id.toLowerCase().indexOf(
            //           documentNumber.toLowerCase(),
            //         ) !== -1;
            //     }
            //   }

            //   if (isWinwin && !transaction.Order?.IsWinWin) {
            //     shouldInclude = shouldInclude && false;
            //   }

            //   const createdAt = transaction.Order
            //     ? moment(transaction.Order.CreatedAt)
            //     : moment(transaction.CreationDate);

            //   if (
            //     dateFrom &&
            //     createdAt.isBefore(moment(dateFrom).startOf('day'))
            //   ) {
            //     shouldInclude = shouldInclude && false;
            //   }

            //   if (dateTo && createdAt.isAfter(moment(dateTo).endOf('day'))) {
            //     shouldInclude = shouldInclude && false;
            //   }

            //   if (transactionType) {
            //     if (transactionType === 99) {
            //       shouldInclude = shouldInclude && !transaction.Order;
            //     } else if (
            //       transaction.Order &&
            //       transaction.Dto === transactionType
            //     ) {
            //       shouldInclude = shouldInclude && true;
            //     } else {
            //       shouldInclude = shouldInclude && false;
            //     }
            //   }

            //   return shouldInclude;
            // })
            // .sort((a, b) => {
            //   if (sortBy === 3) {
            //     return sortByDate(a, b, false);
            //   } else if (sortBy === 4) {
            //     return sortByDate(a, b, true);
            //   } else if (sortBy === 5) {
            //     return getOrderAmountTotal(a) - getOrderAmountTotal(b);
            //   } else if (sortBy === 6) {
            //     return getOrderAmountTotal(b) - getOrderAmountTotal(a);
            //   } else if (sortBy === 1) {
            //     return sortByName(a, b, false);
            //   } else if (sortBy === 2) {
            //     return sortByName(a, b, true);
            //   } else {
            //     return 0;
            //   }
            // })
            .map((transaction, index) => {
              if (transaction.Type === 1 || transaction.Type === 3) {
                return (
                  <SingleTransaction
                    setTransactions={setTransactions}
                    mobileRef={mobileLastElementRef}
                    transaction={transaction}
                    key={transaction.Order.Id}
                    index={index}
                    setCurrentIndexSelected={setCurrentIndexSelected}
                    currentIndexSelected={currentIndexSelected}
                    isDetailsOpen={
                      selectedTransactionId === transaction.Order.Id
                    }
                    onOpen={() => handleOpen(transaction.Order.Id)}
                    onClose={handleClose}
                  />
                );
              } else {
                return (
                  <SingleTransaction
                    setTransactions={setTransactions}
                    mobileRef={mobileLastElementRef}
                    transaction={transaction}
                    index={index}
                    setCurrentIndexSelected={setCurrentIndexSelected}
                    currentIndexSelected={currentIndexSelected}
                    key={transaction.PayOut.Id}
                    isDetailsOpen={
                      selectedTransactionId === transaction.PayOut.Id
                    }
                    onOpen={() => handleOpen(transaction.PayOut.Id)}
                    onClose={handleClose}
                  />
                );
              }
            })}
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default TransactionsComponent;
