import React, {useState, useContext} from 'react';

import {
  CommentBtn,
  CommentOneHeader,
  CommentOneHeaderSvgWrapper,
  CommentOneWrapper,
  CommentText,
  InfoTextWrapper,
} from './styles';

import {v4} from 'uuid';

import {UserDataContext} from 'contexts/UserDataContext';
import {VisitedProducerContext} from 'contexts/VisitedProducerContext';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

import {useCookies} from 'react-cookie';
import api from 'services/axiosConfig';

import {useNavigate} from 'react-router-dom';
// import commentPlaceholder from '../../../../../../images/CardOverview/placeholder_comment.png';
import commentPlaceholder from '../../../../../../images/CardOverview/komentarz.png';

interface Props {
  Comment: {
    Content: string;
    CreationDate: string;
    CreatorName: string;
    Rate: number;
    Id: string;
    SendingUserId?: string;
    SendingProducerImage?: string;
  };
  removeComment: (id: string) => void;
  isMobile: boolean;
  commentsAfterAdd: (newComment) => void;
}

export const CommentOne: React.FC<Props> = ({
  Comment,
  commentsAfterAdd,
  isMobile,
  removeComment,
}) => {
  const isWritingElement = Comment.Id === 'ADDNEWCOMMENT';

  const [rating, setRating] = useState<number>(3);
  const [content, setContent] = useState<string>('');
  const [isWritingMod, setIsWritingMod] = useState(isWritingElement);
  const [hover, setHover] = useState<number>(0);

  const {Content, CreationDate, CreatorName, Rate, Id, SendingUserId} = Comment;

  const [userData, setUserData] = useContext(UserDataContext);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );

  const {decodedToken} = useContext(ProducerDataContext);

  const {FirstName, LastName, UserName, UserId} = userData;

  const [cookies, setCookie] = useCookies(['token']);

  const navigate = useNavigate();

  const handleContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // if (e.target.value.length > 250) return;
    setContent((prevState) => e.target.value);
  };

  const createComment = async () => {
    // if (rating === 0) return alert('Wybierz ocenę');
    if (visitedProducer.Id === '') return alert('nie mamy id producer');
    try {
      const body = {
        ReceivingProducerId: visitedProducer.Id,
        Content: content,
        Rate: rating,
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
      };

      const response = await api.post(`Producer/Comments`, body, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const newComment = {
        Id: v4(),
        Content: content,
        CreationDate: new Date().toISOString().slice(0, 10),
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
        Rate: rating,
        SendingUserId: userData.UserId,
        SendingProducerImage: '',
      };
      // {
      //   isEditing &&
      //     updateComments({
      //       comments: [
      //         newComment,
      //         ...comments.filter((item) => item.Id !== 'add'),
      //       ],
      //     });
      // }

      // setComments((prev) => [newComment, ...prev]);

      commentsAfterAdd(newComment);
      setContent('');
    } catch (error) {
      console.error(error);
    }
  };

  const saveUpdateComment = async (id: string) => {
    try {
      const body = {
        ProducerId: visitedProducer.Id,
        Content: content,
        Rate: rating,
        Id: id,
      };

      const response = await api.put(`Producer/Comments`, body, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const newComment = {
        Id: v4(),
        Content: content,
        CreationDate: new Date().toISOString().slice(0, 10),
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
        Rate: rating,
        SendingUserId: userData.UserId,
      };
      // {
      //   isEditing &&
      //     updateComments({
      //       comments: [
      //         newComment,
      //         ...comments.filter((item) => item.Id !== 'add'),
      //       ],
      //     });
      // }
      // setComments((prev) => [newComment, ...prev]);

      commentsAfterAdd(newComment);
      setContent('');
    } finally {
      // editingPersonalCommentOff();
    }
  };

  if (
    visitedProducer.Id === decodedToken?.ProducerId &&
    Id === 'ADDNEWCOMMENT'
  ) {
    return null;
  }

  // if (
  //   visitedProducer.Id !== decodedToken?.ProducerId &&
  //   Id === 'ADDNEWCOMMENT'
  // ) {
  //   return <h4>TEST komentu</h4>;
  // }

  if (Id === 'VISITCARD')
    return (
      <InfoTextWrapper>
        <p>{Content}</p>
      </InfoTextWrapper>
    );

  if (Id === 'FIRSTCOMMENT') {
    return (
      <CommentOneWrapper
        isMobile={isMobile}
        isWritingMode={false}
        isLogged={UserId !== ''}
      >
        <img
          style={{width: '100%', height: '100%'}}
          src={commentPlaceholder}
          alt="placeholder"
        />
      </CommentOneWrapper>
    );
  }

  if (UserId === '') {
    return (
      <CommentOneWrapper
        isMobile={isMobile}
        isWritingMode={false}
        isLogged={UserId !== ''}
      >
        <CommentOneHeader isNewComment={false}>
          <p>{CreatorName}</p>
          <CommentOneHeaderSvgWrapper>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              if (ratingValue <= Rate) {
                return (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-full"
                      data-name="Icon metro-star-full"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794Z"
                      transform="translate(-2.571 -2.683)"
                      fill="black"
                    />
                  </svg>
                );
              } else {
                return (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-empty"
                      data-name="Icon metro-star-empty"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794ZM6.251,7.919l-1.606.844.307-1.789-1.3-1.267,1.8-.261.8-1.627.8,1.627,1.8.261L7.55,6.975l.307,1.789L6.251,7.919Z"
                      transform="translate(-2.571 -2.683)"
                      fill="black"
                    />
                  </svg>
                );
              }
            })}
          </CommentOneHeaderSvgWrapper>
        </CommentOneHeader>
        <CommentText isMobile={isMobile} isNewComment={false}>
          <p>{Content}</p>
        </CommentText>
        {Id === 'ADDNEWCOMMENT' && (
          <CommentBtn onClick={() => navigate(`/identity/login`)}>
            Zaloguj się
          </CommentBtn>
        )}
      </CommentOneWrapper>
    );
  }

  return (
    <CommentOneWrapper
      isMobile={isMobile}
      isWritingMode={isWritingMod}
      isLogged={UserId !== ''}
    >
      <CommentOneHeader isNewComment={isWritingMod}>
        {isWritingMod ? (
          <p>{`${userData.FirstName} ${userData.LastName}`}</p>
        ) : (
          <p>{CreatorName}</p>
        )}
        {isWritingMod ? (
          <CommentOneHeaderSvgWrapper>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              if (ratingValue <= (hover || rating)) {
                return (
                  <svg
                    key={index}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                    onClick={() => setRating(ratingValue)}
                    style={{cursor: 'pointer'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-full"
                      data-name="Icon metro-star-full"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794Z"
                      transform="translate(-2.571 -2.683)"
                      fill="#5271ff"
                    />
                  </svg>
                );
              } else {
                return (
                  <svg
                    key={index}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                    onClick={() => setRating(ratingValue)}
                    style={{cursor: 'pointer'}}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-empty"
                      data-name="Icon metro-star-empty"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794ZM6.251,7.919l-1.606.844.307-1.789-1.3-1.267,1.8-.261.8-1.627.8,1.627,1.8.261L7.55,6.975l.307,1.789L6.251,7.919Z"
                      transform="translate(-2.571 -2.683)"
                      fill="#5271ff"
                    />
                  </svg>
                );
              }
            })}
          </CommentOneHeaderSvgWrapper>
        ) : (
          <CommentOneHeaderSvgWrapper>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              if (ratingValue <= Rate) {
                return (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-full"
                      data-name="Icon metro-star-full"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794Z"
                      transform="translate(-2.571 -2.683)"
                      fill="black"
                    />
                  </svg>
                );
              } else {
                return (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.36"
                    height="7"
                    viewBox="0 0 7.36 7"
                  >
                    <path
                      id="Icon_metro-star-empty"
                      data-name="Icon metro-star-empty"
                      d="M9.931,5.357l-2.543-.37-1.137-2.3-1.137,2.3-2.543.37L4.411,7.15,3.976,9.683l2.274-1.2,2.274,1.2L8.091,7.15l1.84-1.794ZM6.251,7.919l-1.606.844.307-1.789-1.3-1.267,1.8-.261.8-1.627.8,1.627,1.8.261L7.55,6.975l.307,1.789L6.251,7.919Z"
                      transform="translate(-2.571 -2.683)"
                      fill="black"
                    />
                  </svg>
                );
              }
            })}
          </CommentOneHeaderSvgWrapper>
        )}
      </CommentOneHeader>
      <CommentText isMobile={isMobile} isNewComment={isWritingMod}>
        {isWritingMod ? (
          <textarea
            value={content}
            onChange={(e) => handleContent(e)}
            placeholder={Content}
          />
        ) : (
          <p>{Content}</p>
        )}
      </CommentText>
      {isWritingMod && (
        <CommentBtn onClick={createComment}>Opublikuj</CommentBtn>
      )}
      {!isWritingMod && UserId === SendingUserId ? (
        <CommentBtn onClick={() => removeComment(Id)}>Usuń</CommentBtn>
      ) : !isWritingMod &&
        decodedToken?.ProducerId === '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb' ? (
        <CommentBtn onClick={() => removeComment(Id)}>Usuń</CommentBtn>
      ) : null}
    </CommentOneWrapper>
  );
};
