import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import _ from 'lodash';

import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {UserThemeContext} from 'contexts/UserThemeContext';
import {BusinessCardColorsContext} from '../../../../contexts/BusinessCardColorsContext';
import {ResolutionContext} from 'contexts/ResolutionContext';

import motywNeonImg from 'images/motyw_neon2.png';
import motywClassicImg from 'images/motyw_classic2.png';
import motywArtisticImg from 'images/motyw_artistic2.png';

import useAlert from '../../../../hooks/useAlert';

import {
  EmptyDiv,
  hexToRgb,
  pageStyleTypeObj,
  pageStyleTypeOptionsArr,
  pageThemeTypeObj,
  pageThemeTypeOptionsArr,
  PatternButtonWrapper,
  Role,
  StyledSelectView,
} from 'pages/MyStudio/BusinessCard';

import {
  AltPanelContent,
  AltPanelTextWrapper,
  CardHeaderStatusWrapperRight,
  CardHeaderSvgWrapperLeft,
  CardSvg,
  CloseMobileMenu,
  DraftBlueBtn,
  DraftRedBtn,
  FooterCircleAvatar,
  FooterSellerAvatarAndInfoWrapper,
  FooterSellerInfoWrapper,
  FooterWrapper,
  HederNav,
  Main,
  MainHeaderHiddenWrapper,
  MainHeaderLeftPanelButton,
  MainHeaderLeftPanelCheckBoxWrapper,
  MainHeaderLeftPanelEditWrapper,
  MainHeaderLeftPanelSvgWrapper,
  MainHeaderLeftPanelText,
  MainHeaderLeftPanelWrapper,
  MainPanelContentWrapper,
  MainPanelWrapper,
  MainWrapper,
  MobileOptionsInColumn,
  NewMainHeader,
  NewStyleCheckBox,
  NoPlanBtnWrapper,
  NoPlanSvgWrapper,
  NoPlanText,
  NoPlanWrapper,
  OptionsContent,
  OptionsInColumn,
  OptionsWrapper,
  PageStyleOption,
  PageThemeOption,
  PanelContainer,
  PanelSvgEditIconWrapper,
  PanelSvgWrapper,
  StatusWrapper,
  GridStructur,
  GridOneItem,
} from './styles';

import RGL, {WidthProvider} from 'react-grid-layout';
import EditBlockComponent from 'pages/MyStudio/BusinessCard/EditBlockComponent';
import {BussinesCardSelect} from 'components/Select/BussinesCardSelect';
import {LoadingCircle} from 'utils/loadingCircle';
import {AddBlock} from 'pages/MyStudio/BusinessCard/Blocks/AddBlock';
import {v4} from 'uuid';
import {
  Block,
  BlockColor,
  BlockType,
  BusinessCardCategory,
  Producer,
} from 'pages/MyStudio/BusinessCard/interface';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import defaultCoverImg from 'images/svg/defaultOfferImg.svg';
import dragonBlueArrow from 'images/svg/dragonDropBlueArrow.svg';
import ShortDescriptionBlockComponent, {
  ShortDescriptionBlock,
} from 'pages/MyStudio/BusinessCard/Blocks/ShortDescriptionBlock';
import LongDescriptionBlockComponent, {
  LongDescriptionBlock,
} from 'pages/MyStudio/BusinessCard/Blocks/LongDescriptionBlock';
import DemoReelBlockComponent, {
  DemoReelBlock,
} from 'pages/MyStudio/BusinessCard/Blocks/DemoReelBlock';
import {VideoBlockComponent} from 'pages/MyStudio/BusinessCard/Blocks/VideoBlock';
import GalleryBlockComponent, {
  GalleryBlock,
} from 'pages/MyStudio/BusinessCard/Blocks/GalleryBlock';
import CommentsBlockComponent, {
  CommentsBlock,
} from 'pages/MyStudio/BusinessCard/Blocks/CommentsBlock';
import {BannerBlockComponent} from 'pages/MyStudio/BusinessCard/Blocks/BannerBlock';
import RecomendedProductionsBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/RecomendedProductionBlock';
import {EmptyBlockComponent} from 'pages/MyStudio/BusinessCard/Blocks/EmptyBlock';
import TrackBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/TrackBlock';
import RecomendedTracksBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/RecomendedTracksBlock';
import SocialMediaBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/SocialMediaBlock';
import {TitleBlock} from 'pages/MyStudio/BusinessCard/Blocks/TitleBlock';
import {HeaderBlock} from 'pages/MyStudio/BusinessCard/Blocks/HeaderBlock';
import OfferBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/OfferBlock';
import DefaultBlockComponent from 'pages/MyStudio/BusinessCard/Blocks/DefaultBlock';
import MobileOverviewComponent from 'pages/MyStudio/BusinessCard/MobileOverviewComponent';
import {ImgAndText} from 'pages/MyStudio/BusinessCard/Blocks/ImgAndText';
import {GreatHeaderBlock} from 'pages/MyStudio/BusinessCard/Blocks/GreatHeaderBlock';
import {YouTubeBlock} from 'pages/MyStudio/BusinessCard/Blocks/YouTubeBlock';
import {
  ChoosePatternCardsWrapper,
  ChoosePatternModeWrapper,
  ChoosePatternOneCard,
  ThemeSelectWrapper,
} from 'pages/MyStudio/BusinessCard/styles';
import SpotifyBlock from 'pages/MyStudio/BusinessCard/Blocks/SpotifyBlock';

const getMaxHeight = (block: Block[]) => {
  if (block.length < 1) return 7;
  const arr = block.sort(
    (a, b) =>
      b.WebArea.StartY +
      b.WebArea.Height -
      (a.WebArea.StartY + a.WebArea.Height),
  );
  if (arr[0].WebArea.StartY + arr[0].WebArea.Height < 7) return 8;
  return arr[0].WebArea.StartY + arr[0].WebArea.Height + 2;
};

function createArr(x: number, y: number) {
  const arr = [];
  for (let i = 0; i < x * y; i++) {
    arr.push(i);
  }
  return arr;
}

export const renderBlockContent = (
  roles: Role[],
  block: Block,
  theme: string,
  mobileOverview: boolean,
  isEditing = false,
  updateBlockDate = null,
) => {
  const {Type} = block;
  switch (Type) {
    case 1:
      return (
        <ShortDescriptionBlockComponent
          theme={theme}
          block={block as ShortDescriptionBlock}
          isEditing={isEditing}
          changeText={updateBlockDate}
          mobileOverview={mobileOverview}
        />
      );
    case 2:
      return (
        <LongDescriptionBlockComponent
          isEditing={isEditing}
          theme={theme}
          mobileOverview={mobileOverview}
          block={block as LongDescriptionBlock}
          changeText={updateBlockDate}
        />
      );
    case 4:
      return (
        <DemoReelBlockComponent
          isEditing={isEditing}
          block={block as DemoReelBlock}
          mobileOverview={mobileOverview}
          theme={theme}
          updateDemoGallery={updateBlockDate}
        />
      );
    case 8:
      return (
        <VideoBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateVideo={updateBlockDate}
        />
      );
    case 16:
      return (
        <GalleryBlockComponent
          theme={theme}
          mobileOverview={mobileOverview}
          block={block as GalleryBlock}
          updateImgGallery={updateBlockDate}
          isEditing={isEditing}
        />
      );
    case 32:
      return (
        <CommentsBlockComponent
          block={block as CommentsBlock}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateComments={updateBlockDate}
        />
      );
    case 128:
      return (
        <BannerBlockComponent
          block={block}
          theme={theme}
          isEditing={isEditing}
          updateBanner={updateBlockDate}
          mobileOverview={mobileOverview}
        />
      );
    case 256:
      return (
        <RecomendedProductionsBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateProductionBlock={updateBlockDate}
        />
      );
    case 512:
      return <EmptyBlockComponent isEditing={isEditing} block={block} />;
    case 1024:
      return (
        <TrackBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateTrack={updateBlockDate}
        />
      );
    case 2048:
      return (
        <RecomendedTracksBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateTracksBlock={updateBlockDate}
        />
      );
    case 4096:
      return (
        <SocialMediaBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          changeUrl={updateBlockDate}
        />
      );
    case 8192:
      return (
        <TitleBlock
          theme={theme}
          isEditing={isEditing}
          block={block}
          changeText={updateBlockDate}
          mobileOverview={mobileOverview}
        />
      );
    case 16384:
      return (
        <HeaderBlock
          theme={theme}
          isEditing={isEditing}
          block={block}
          changeText={updateBlockDate}
          mobileOverview={mobileOverview}
        />
      );
    case 32768:
      return (
        <OfferBlockComponent
          theme={theme}
          mobileOverview={mobileOverview}
          block={block}
          isEditing={isEditing}
          updateOffer={updateBlockDate}
        />
      );
    case 65536:
      return (
        <YouTubeBlock
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          changeText={updateBlockDate}
        />
      );
    case 131072:
      return (
        <GreatHeaderBlock
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          changeText={updateBlockDate}
        />
      );
    case 262144:
      return (
        <ImgAndText
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          changeText={updateBlockDate}
        />
      );

    case 524288:
      return (
        <SpotifyBlock
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
        />
      );
    case 64:
      return (
        <DefaultBlockComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          roles={roles}
        />
      );
    default:
      return <h1>default</h1>;
  }
};

const EditCard = () => {
  const [cookies, setCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);

  const [producer, setProducer] = useState<Producer>({
    Id: '',
    ChosenSkinId: '',
    ChosenSkin: {
      Id: '',
      Name: '',
      PrimaryColorHex: '',
      SecondaryColorHex: '',
    },
    CardCategory: {
      Description: '',
      Id: '',
      Name: '',
    },
    BackgroundColorHex: '',
    Blocks: [],
    ProducerLastName: '',
    ProducerFirstName: '',
    ProducerPostalCode: '',
    ProducerStreet: '',
    ProducerProducerPhoto: '',
    PublishedCardOutOfDate: '',
  });

  const [isEditing, toggleEditing] = useState<boolean>(false);
  const [isEditingMode, setIsEditingMod] = useState<boolean>(true);
  const [blockHoverId, setBlockHoverId] = useState<string>('');
  const [blockForEditing, setBlockForEditing] = useState<Block>({
    BackgroundColorHex: BlockColor.DARK_BLUE,
    BlockOpacityIn0To100Ratio: 100,
    FontColorHex: BlockColor.WHITE,
    TitleColorHex: BlockColor.WHITE,
    Id: v4(),
    WebArea: {
      Height: 1,
      StartX: 1,
      StartY: 1,
      Width: 3,
    },
    MobileArea: {
      Height: 1,
      StartX: 1,
      StartY: 1,
      Width: 3,
    },
    Type: 1,
  });

  const [selectedBlock, setSelectedBlock] = useState<number>(0);
  const [roles, setRoles] = useState<Role[]>([]);
  const [bgColors, setBgColors] = useState<string[]>([
    '17181B',
    '5271FF',
    'FF64F4',
    'F8F8F8',
    '5C5C66',
    'B3D7D8',
    'ffffff',
    'e9e9e9',
    '049e26',
    'Dodaj',
  ]);
  const [showListing, setShowListing] = useState<boolean>(false);
  const [isHeaderLeftPanelOpen, setIsHeaderLeftPanelOpen] =
    useState<boolean>(false);
  const [blockTypes, setBlockTypes] = useState<BlockType[]>([]);
  const [newPageStyle, setNewPageStyle] = useState('1');
  const [newCategory, setNewCategory] = useState('1');
  const [isHighlightActive, setIsHighlightActive] = useState(false);
  const [theme, setTheme] = useContext(UserThemeContext);
  const [sellerPlan, setSellerPlan] = useState({ActiveFrom: '', Name: ''});
  const [activePlan, setActivePlan] = useState(
    '0b9da0bf-cd5d-11ed-9742-000d3a44fa94',
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingColors, setIsLoadingColors] = useState(true);
  const [isProducerDataLoading, setIsProducerDataLoading] = useState(false);
  const [businessCardCategory, setBusinessCardCategory] = useState<
    BusinessCardCategory[]
  >([]);
  const [draftMatchPublish, setDraftMatchPublish] = useState<boolean>(false);
  const [draftStatus, setDraftStatus] = useState<number>(0);
  const [overview, setOverview] = useState<boolean>(false);
  const [mobileOverview, setMobileOverview] = useState<boolean>(false);
  const {Alert} = useAlert();
  const [newBlockIsAdded, setNewBlockIsAdded] = useState<boolean>(false);
  const [mobileGoToProducer, setMobileGoToProducer] = useState(false);

  const [businessCardColors, setBusinessCardColors] = useContext(
    BusinessCardColorsContext,
  );
  const {windowWidth} = useContext(ResolutionContext);

  const [cordsForAddBlock, setCordsForAddBlock] = useState<{
    y: number;
    x: number;
  }>({y: 99, x: 99});

  const blocksRef = useRef([] as any);

  const params = useParams();

  const ReactGridLayout = WidthProvider(RGL);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  const saveNewColorForBlock = (colors: string[], newColor: string) => {
    setBgColors([...colors, newColor]);
  };

  const newLayout = producer.Blocks.map((block) => ({
    w: block.WebArea.Width,
    h: block.WebArea.Height,
    i: block.Id,
    x: block.WebArea.StartX,
    y: block.WebArea.StartY,
    isBounded: undefined,
    isDraggable: undefined,
    isResizable: undefined,
    maxH: 99,
    maxW: 9,
    minH: 1,
    minW: 1,
    moved: false,
    resizeHandles: undefined,
    static: false,
  }));

  const chooseCardTemplate = async (
    producerId: string,
    cardTemplate: number,
  ) => {
    setIsLoading(true);
    try {
      const response = await api.put(
        `Producer/${producerId}/card/0/template/${cardTemplate}`,
        {},

        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );

      await getProducerData(decodedToken.ProducerId);
      setIsEditingMod(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setDraftMatchPublish(false);
    }
  };

  const newMobileLayout = producer.Blocks.map((block) => ({
    w: block.MobileArea.Width,
    h: block.MobileArea.Height,
    i: block.Id,
    x: block.MobileArea.StartX,
    y: block.MobileArea.StartY,
    isBounded: undefined,
    isDraggable: undefined,
    isResizable: undefined,
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    resizeHandles: undefined,
    static: false,
  }));

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducerData = async (producerId: string) => {
    try {
      const response = await api.get(`Producer/edit-card/${producerId}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const {ChosenSkinId, BackgroundColorHex} = response.data.Response;

      setProducer(response.data.Response);
      setIsLoading(false);
      setIsLoadingColors(false);
      if (response.data.Response.Blocks.length < 1) {
        setMobileGoToProducer(true);
      }
    } catch (error) {
      console.error('getProducer editCard', error);
      setIsLoading(false);
      setIsLoadingColors(false);
    } finally {
      console.log('koniec');
    }
  };

  const getProducerAllowedBlocks = async (producerId: string) => {
    try {
      const response = await api.get(`Producer/${producerId}/AllowedBlocks`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setBlockTypes(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getSellerInfo = async () => {
    try {
      const response = await api.get(`producer/${params.id}/seller`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setSellerPlan({
        ActiveFrom: response.data.Response.CurrentPlan.ActiveFrom,
        Name: response.data.Response.CurrentPlan.Plan.Name,
      });
      setActivePlan(response.data.Response.CurrentPlan.Plan.Id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRoles();
    getSellerInfo();
    getProducerData(params.id);
    getProducerAllowedBlocks(params.id);
  }, []);

  const editBlock = (block: Block | any, index: number) => {
    if (isLoading || isLoadingColors) return;
    if (isEditing) return;

    setSelectedBlock(index);
    setBlockForEditing(block);
    toggleEditing(true);
    document.body.style.overflowY = 'hidden';
  };

  const deleteBlock = (e: React.MouseEvent<SVGSVGElement>, id: string) => {
    e.stopPropagation();
    setProducer((prev) => ({
      ...prev,
      Blocks: [...prev.Blocks.filter((block) => block.Id !== id)],
    }));
    setSelectedBlock(0);
    blocksRef.current = [];
  };

  const publishCardMobile = async (producerId: string) => {
    setIsLoading(true);
    try {
      const response = await api.get(`Producer/mobile/${producerId}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setProducer(response.data.Response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const publishCard = async (producerId: string) => {
    setIsLoading(true);
    try {
      // await updateProducer(decodedToken.ProducerId, producer, 0, theme);
      // await publishCardMobile(params.id);
      const response = await api.patch(
        `Producer/edit-PublishCard`,
        {CardCategoryId: newCategory, ProducerId: params.id},
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      setDraftMatchPublish(true);
      setDraftStatus(1);
      Alert(
        'Wizytówka zostałą zaktualizowana i widoczna jest dla innych użytkowników',
        'Zaktualizowano wizytówkę',
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setDraftMatchPublish(true);
    }
  };

  const removePublishCard = async (producerId: string) => {
    setIsLoading(true);
    try {
      // await updateProducer(decodedToken.ProducerId, producer, 0, theme);

      const response = await api.put(
        `Producer/HideCard/${producerId}`,
        {},

        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      setDraftStatus(2);
      Alert(
        'Wizytówka zostałą usunięta z publikacji i jest nie widoczna  dla innych użytkowników',
        'Zaktualizowano wizytówkę',
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setDraftMatchPublish(false);
    }
  };

  const removeCard = async (producerId: string) => {
    setIsLoading(true);
    try {
      // await updateProducer(decodedToken.ProducerId, producer, 0, theme);

      const response = await api.put(
        `Producer/${producerId}/Card/0/clear`,
        {},

        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      setProducer((prev) => ({
        ...prev,
        Blocks: [],
      }));
      setIsEditingMod(false);
      setDraftStatus(2);
      Alert('Wizytówka zostałą usunięta ', 'Wizytówka zostałą usunięta');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setDraftMatchPublish(false);
    }
  };

  const addNewBlock = async (Type: number) => {
    // await getProducerData(decodedToken.ProducerId, 0);

    if (Type === 1) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            BlockOpacityIn0To100Ratio: 1,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 1,
            Text: 'tekst',
            Title: 'Tytuł',
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 26,
          },
        ],
      }));
    }
    if (Type === 2) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            Type: 2,
            WebArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            TextOne: 'test',
            TextTwo: '',
            TextThree: '',
            Title: '',
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 21,
          },
        ],
      }));
    }
    if (Type === 4) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            Type: 4,
            WebArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            BlockFileIds: [],
            BlockFiles: [],
          },
        ],
      }));
    }
    if (Type === 8) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            Type: 8,
            WebArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            BlockFileIds: [],
            BlockFiles: [],
          },
        ],
      }));
    }
    if (Type === 16) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            Type: 16,
            WebArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            BlockFileIds: [],
            BlockFiles: [],
            Base64Files: [],
          },
        ],
      }));
    }
    if (Type === 32) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 5,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 5,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 32,
            Comments: [],
          },
        ],
      }));
    }
    if (Type === 128) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 128,
            Base64BannerString: null,
            CurrentFileFtpFilePath: '',
            RemoveCurrentBanner: false,
          },
        ],
      }));
    }
    if (Type === 256) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 256,
            Offers: [],
            OfferIds: [],
          },
        ],
      }));
    }
    if (Type === 512) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 3,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 3,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 512,
          },
        ],
      }));
    }
    if (Type === 1024) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 1024,
            Offer: {
              Id: '00000000-0000-0000-0000-000000000000',
              Name: `Ilustracja wygenerowana przez sztuczną
      inteligencję`,
              IsWinWin: true,
              Description: '',
              CoverFile: defaultCoverImg,
              ProductDemoFile: '',
              GrossValue: 4000,
              Currency: 'zł',
              Category: {Name: 'Grafika', CategoryId: 'test1'},
              SecondCategoryId: {CategoryId: 'Ilustracja', Name: 'Ilustracja'},
              ThirdCategoryId: {CategoryId: null, Name: ''},
              ProductType: 8,
            },
          },
        ],
      }));
    }
    if (Type === 2048) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 3,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 2048,
            Offers: [],
            OfferIds: [],
          },
        ],
      }));
    }
    if (Type === 4096) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 4096,
            // Url: '',
            // UrlType: null,
            Urls: [],
          },
        ],
      }));
    }
    if (Type === 8192) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 2,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 8192,
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 32,
            Title: '',
            Subtitle: '',
          },
        ],
      }));
    }
    if (Type === 16384) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 1,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 16384,
            Title: '',
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 22,
          },
        ],
      }));
    }
    if (Type === 32768) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 32768,
            Offer: {
              Id: '00000000-0000-0000-0000-000000000000',
              Name: `Ilustracja wygenerowana przez sztuczną
      inteligencję`,
              IsWinWin: true,
              Description: '',
              CoverFile: defaultCoverImg,
              GrossValue: 4000,
              Currency: 'zł',
              Category: {Name: 'Grafika', CategoryId: 'test1'},
              SecondCategoryId: {CategoryId: 'Ilustracja', Name: 'Ilustracja'},
              ThirdCategoryId: {CategoryId: null, Name: ''},
              ProductType: 0,
            },
          },
        ],
      }));
    }
    if (Type === 65536) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 65536,
            Url: null,
          },
        ],
      }));
    }
    if (Type === 131072) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 131072,
            Title: '',
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 100,
          },
        ],
      }));
    }
    if (Type === 262144) {
      setProducer((prev) => ({
        ...prev,
        Blocks: [
          ...prev.Blocks,
          {
            BlockOpacityIn0To100Ratio: 1,
            BackgroundColorHex: businessCardColors.BackgroundColor,
            FontColorHex: businessCardColors.FontColor,
            TitleColorHex: businessCardColors.TitleColor,
            Id: '00000000-0000-0000-0000-000000000000',
            WebArea: {
              Height: 6,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 18,
            },
            MobileArea: {
              Height: 4,
              StartX: cordsForAddBlock.x,
              StartY: cordsForAddBlock.y,
              Width: 6,
            },
            Type: 262144,
            TextOne: '',
            TextTwo: '',
            HorizontalFormat: 1,
            VerticalFormat: 1,
            Interline: 21,
            BlockFileIds: [],
            BlockFiles: [],
            Base64Files: [],
          },
        ],
      }));
    }
    setNewBlockIsAdded(true);
  };

  // const handleNewPageStyle = (value) => {
  //   setNewPageStyle(value);
  // };

  // const handleNewPageTheme = (value) => {
  //   setTheme(value);
  // };

  // const handleNewCategory = (value) => {
  //   setNewCategory(value);
  // };

  const addBlockFileIdsToBlocks = (blocks: Block[]) => {
    return blocks;
    if (blocks.filter((item) => item.BlockFiles).length < 1) return blocks;

    const blocksToChange = blocks
      .filter((item) => item.BlockFiles)
      .map((item) => ({
        ...item,
        BlockFileIds: [...item.BlockFiles.map((item) => item.Id)],
      }));
    const blocksUnchanged = blocks.filter((item) => !item.BlockFiles);
    return [...blocksToChange, ...blocksUnchanged];
  };

  const addOfferIdsToBlocks = (blocks: Block[]) => {
    if (blocks.filter((item) => item.Offers).length < 1) return blocks;
    const blocksToChange = blocks
      .filter((item) => item.Offers)
      .map((item) => ({
        ...item,
        OfferIds: [...item.Offers.map((item) => item.Id)],
      }));
    const blocksUnchanged = blocks.filter((item) => !item.Offers);
    return [...blocksToChange, ...blocksUnchanged];
  };

  const createNewBlocksToUpdate = (blocks: Block[]) => {
    const newBlocks = addBlockFileIdsToBlocks(blocks);
    return addOfferIdsToBlocks(newBlocks);
  };

  const updateDateAfterChangeType = async (blocks: Block[]) => {
    const oneBlock = blocks.filter(
      (item) => item.Id === '00000000-0000-0000-0000-000000000000',
    );
    if (oneBlock.length > 0) {
      // await getProducerData(decodedToken.ProducerId, 0);
    }
  };

  const updateProducer = async (
    ProducerId: string,
    producer: Producer,
    type: number,
    theme: string,
  ) => {
    if (!theme) return;
    if (isProducerDataLoading) return;
    setIsProducerDataLoading(true);
    try {
      const {BackgroundColorHex, Blocks, ChosenSkinId, Id} = producer;
      if (!Id) return;
      // console.log(Blocks, 'bloki');
      const newBlocks = createNewBlocksToUpdate(Blocks);
      // console.log(newBlocks, 'bloki jakie wysyłam');
      const body = {
        ProducerId: params.id,
        CardId: Id,
        CardCategoryId: '054d142a-96fa-49e1-957a-7ef656ed7954',
        ChosenSkinId: ChosenSkinId || 'd4ca0027-a0c8-490e-9f4f-e51f970ee646',
        BackgroundColorHex: theme,
        Blocks: newBlocks,
        UpdateType: type,
      };
      // console.log(
      //   'sprawdzam  zmianie jakie wysyłam',
      //   body.Blocks.filter((item) => item.Type === 4 || item.Type === 16),
      // );
      // console.log(JSON.stringify(body));
      console.log('sprawdzam funkcje Updataproducer w card', body);
      await api.put(`Producer/edit-card`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      await updateDateAfterChangeType(Blocks);
      setDraftMatchPublish(false);
      setDraftStatus(0);
    } catch (error) {
      console.error(error);
    } finally {
      setIsProducerDataLoading(false);
    }
  };

  const updateProducerDebounce = React.useCallback(
    _.debounce(
      (id, producer, type, theme) => updateProducer(id, producer, type, theme),
      1500,
    ),
    [],
  );

  useEffect(() => {
    if (!cookies.token) return;
    if (!decodedToken.ProducerId) return;
    if (!isLoading) {
      updateProducerDebounce(decodedToken.ProducerId, producer, 0, theme);
    }
  }, [producer, theme]);

  const handleSkillSelectChange = () => {
    console.log();
  };
  const handleShowListing = (value: boolean) => {
    setShowListing(value);
    console.log('otwieram zamykam');
  };

  if (windowWidth <= 1024 || mobileOverview)
    return (
      <MobileOverviewComponent
        mobileGoToProducer={mobileGoToProducer}
        roles={roles}
        Blocks={producer.Blocks}
        isEditing={isEditing}
        overview={mobileOverview}
        setOverview={setMobileOverview}
        setProducer={setProducer}
        newLayout={newMobileLayout}
        mobileOverview={mobileOverview}
        activePlan={activePlan}
        draftMatchPublish={draftMatchPublish}
        draftStatus={draftStatus}
        publishedCardOutOfDate={producer.PublishedCardOutOfDate}
      />
    );

  return (
    <MainWrapper>
      <Main>
        {isEditing && (
          <EditBlockComponent
            roles={roles}
            blockForEditing={blockForEditing}
            isEditing={isEditing}
            selectedObject={producer.Blocks[selectedBlock]}
            setBlockForEditing={setBlockForEditing}
            setProducer={setProducer}
            toggleEditing={toggleEditing}
            bgColors={bgColors}
            saveNewColorForBlock={saveNewColorForBlock}
            blockTypes={blockTypes}
            mobileOverview={false}
            ProducerIdForOffert={params.id}
          />
        )}

        <MainPanelWrapper>
          <NewMainHeader>
            <MainHeaderLeftPanelWrapper
              style={
                isHeaderLeftPanelOpen
                  ? {visibility: 'visible'}
                  : {visibility: 'hidden'}
              }
            >
              <MainHeaderHiddenWrapper
                style={isHeaderLeftPanelOpen ? {top: '0px'} : null}
              >
                {activePlan ===
                '0b9da0bf-cd5d-11ed-9742-000d3a44fa94' ? null : (
                  <MainHeaderLeftPanelButton
                    style={{marginTop: '5px'}}
                    isActive={draftMatchPublish}
                    onClick={() => {
                      if (!draftMatchPublish) {
                        publishCard(decodedToken.ProducerId);
                      }
                    }}
                  >
                    {draftMatchPublish ? 'Opublikowano' : 'Opublikuj'}
                  </MainHeaderLeftPanelButton>
                )}
              </MainHeaderHiddenWrapper>
            </MainHeaderLeftPanelWrapper>

            <CardHeaderSvgWrapperLeft>
              <CardSvg
                isActive={!mobileOverview}
                onClick={() => setMobileOverview(false)}
                className="WEBICON"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="32"
                viewBox="0 0 34 32"
              >
                <g
                  id="Group_7970"
                  data-name="Group 7970"
                  transform="translate(-860 -99)"
                >
                  <g
                    id="Rectangle_4245"
                    data-name="Rectangle 4245"
                    transform="translate(860 99)"
                    fill="none"
                    stroke={mobileOverview ? 'rgba(23,22,29,0.21)' : '#0091ff'}
                    strokeWidth="1"
                  >
                    <rect width="34" height="32" rx="9" stroke="none" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="31"
                      rx="8.5"
                      fill="none"
                    />
                  </g>
                  <g
                    id="Group_7967"
                    data-name="Group 7967"
                    transform="translate(869 108.5)"
                  >
                    <path
                      id="Path_4204"
                      data-name="Path 4204"
                      d="M7.264,8H5.632v9.791H18.686V8Zm9.791,1.632v6.527H7.264V9.632Zm3.264,9.791H4v1.632H20.318Z"
                      transform="translate(-4 -8)"
                    />
                  </g>
                </g>
              </CardSvg>
              <CardSvg
                isActive={mobileOverview}
                onClick={
                  producer.Blocks.length > 1
                    ? () => {
                        setMobileOverview(true);
                        setIsEditingMod(false);
                        publishCardMobile(params.id);
                      }
                    : null
                }
                className="MOBILEICON"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="32"
                viewBox="0 0 34 32"
              >
                <g
                  id="Group_7969"
                  data-name="Group 7969"
                  transform="translate(-904 -99)"
                >
                  <g
                    id="Rectangle_1948"
                    data-name="Rectangle 1948"
                    transform="translate(904 99)"
                    fill="none"
                    stroke="rgba(23,22,29,0.21)"
                    strokeWidth="1"
                  >
                    <rect width="34" height="32" rx="9" stroke="none" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="33"
                      height="31"
                      rx="8.5"
                      fill="none"
                    />
                  </g>
                  <g
                    id="Group_7968"
                    data-name="Group 7968"
                    transform="translate(916 109)"
                    opacity="0.5"
                  >
                    <path
                      id="Path_4206"
                      data-name="Path 4206"
                      d="M9.326,4H8V17H18.606V4Zm7.955,1.3V15.7H9.326V5.3Zm-3.315,7.8H12.64v1.3h1.326Z"
                      transform="translate(-8 -4)"
                      fill="#17161d"
                    />
                  </g>
                </g>
              </CardSvg>
            </CardHeaderSvgWrapperLeft>
            <p>Strona twórcy(ADMIN)</p>
            <CardHeaderStatusWrapperRight>
              <StatusWrapper
                status={
                  draftStatus === 1
                    ? 3
                    : draftStatus === 2
                    ? 4
                    : activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94'
                    ? 1
                    : new Date(producer.PublishedCardOutOfDate).getTime() >
                        new Date().getTime() && draftMatchPublish
                    ? 3
                    : new Date(producer.PublishedCardOutOfDate).getTime() >
                        new Date().getTime() && !draftMatchPublish
                    ? 2
                    : 4
                }
              >
                <p>
                  {draftStatus === 1
                    ? 'Opublikowany'
                    : draftStatus === 2
                    ? 'Nieopublikowany'
                    : activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94'
                    ? 'Niedostępne'
                    : new Date(producer.PublishedCardOutOfDate).getTime() >
                        new Date().getTime() && draftMatchPublish
                    ? 'Opublikowany'
                    : new Date(producer.PublishedCardOutOfDate).getTime() >
                        new Date().getTime() && !draftMatchPublish
                    ? 'Opublikowane nieaktualne'
                    : 'Nieopublikowany'}
                </p>

                <span></span>
              </StatusWrapper>

              {isEditingMode ? (
                <svg
                  onClick={() => {
                    setIsEditingMod(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                >
                  <g
                    id="Group_7128"
                    data-name="Group 7128"
                    transform="translate(-464 -154.5)"
                  >
                    <g
                      id="Rectangle_3841"
                      data-name="Rectangle 3841"
                      transform="translate(464 154.5)"
                      fill="#0091ff"
                      stroke="rgba(7,2,15,0.3)"
                      strokeWidth="1"
                    >
                      <rect width="26" height="28" rx="8" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="25"
                        height="27"
                        rx="7.5"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Rectangle_3842"
                      data-name="Rectangle 3842"
                      transform="translate(465 160.5)"
                      fill="#fff"
                      stroke="rgba(112,112,112,0.15)"
                      strokeWidth="1"
                    >
                      <rect width="24" height="21" rx="7" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="20"
                        rx="6.5"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Icon_feather-edit-3"
                      data-name="Icon feather-edit-3"
                      transform="translate(473 166.5)"
                    >
                      <path
                        id="Path_3712"
                        data-name="Path 3712"
                        d="M18,30h4.205"
                        transform="translate(-13.795 -22)"
                        fill="none"
                        stroke="#0091ff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3713"
                        data-name="Path 3713"
                        d="M10.808,4.608a.991.991,0,0,1,1.4,1.4L6.369,11.851,4.5,12.318l.467-1.869Z"
                        transform="translate(-4.5 -4.318)"
                        fill="none"
                        stroke="#0091ff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  onClick={() => {
                    if (activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94')
                      return;
                    setIsEditingMod(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                >
                  <g
                    id="Group_7129"
                    data-name="Group 7129"
                    transform="translate(-433 -154.5)"
                  >
                    <g
                      id="Rectangle_3843"
                      data-name="Rectangle 3843"
                      transform="translate(433 154.5)"
                      fill="#dce0e9"
                      stroke="rgba(7,2,15,0.3)"
                      strokeWidth="1"
                    >
                      <rect width="26" height="28" rx="8" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="25"
                        height="27"
                        rx="7.5"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Rectangle_3844"
                      data-name="Rectangle 3844"
                      transform="translate(434 155.5)"
                      fill="#fff"
                      stroke="rgba(112,112,112,0.15)"
                      strokeWidth="1"
                    >
                      <rect width="24" height="21" rx="7" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="20"
                        rx="6.5"
                        fill="none"
                      />
                    </g>
                    <g
                      id="Icon_feather-edit-3"
                      data-name="Icon feather-edit-3"
                      transform="translate(442 161.5)"
                    >
                      <path
                        id="Path_3712"
                        data-name="Path 3712"
                        d="M18,30h4.205"
                        transform="translate(-13.795 -22)"
                        fill="none"
                        stroke="#17161d"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3713"
                        data-name="Path 3713"
                        d="M10.808,4.608a.991.991,0,0,1,1.4,1.4L6.369,11.851,4.5,12.318l.467-1.869Z"
                        transform="translate(-4.5 -4.318)"
                        fill="none"
                        stroke="#17161d"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </CardHeaderStatusWrapperRight>
          </NewMainHeader>
          {isEditingMode && (
            <HederNav>
              {/* <StyledSelectView isEditing={true} isError={false}>
                <p>Szablon</p>
                <select value={'skill'} onChange={handleSkillSelectChange}>
                  <option hidden value={''}>
                    Wybierz
                  </option>
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
              </StyledSelectView>
              <StyledSelectView isEditing={true} isError={false}>
                <p>Motyw</p>
                <select value={'skill'} onChange={handleSkillSelectChange}>
                  <option hidden value={''}>
                    Wybierz
                  </option>
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
              </StyledSelectView> */}
              <DraftBlueBtn
                onClick={() => {
                  if (!draftMatchPublish) {
                    publishCard(params.id);
                  }
                }}
              >
                Opublikuj
              </DraftBlueBtn>
              <DraftRedBtn
                onClick={() => {
                  removeCard(params.id);
                }}
              >
                Usuń
              </DraftRedBtn>
            </HederNav>
          )}
          <MainPanelContentWrapper
            isChooseMode={producer.Blocks.length < 1}
            overview={!isEditingMode}
            isBasicPlan={
              activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94' ||
              activePlan === '0a9da0bf-cd5d-11ed-9742-000d3a44fa94'
            }
          >
            {(isLoading || isProducerDataLoading || isLoadingColors) && (
              <LoadingCircle style={{position: 'fixed', zIndex: 99}} />
            )}

            {isLoading || isProducerDataLoading || isLoadingColors ? (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 99,
                  // width: '100vw',
                  // height: '100vh',
                  background: 'transparent',
                  cursor: 'wait',
                }}
              />
            ) : null}

            {activePlan === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94' ||
            activePlan === '0a9da0bf-cd5d-11ed-9742-000d3a44fa94' ? (
              <NoPlanWrapper>
                <NoPlanSvgWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.913"
                    height="31.928"
                    viewBox="0 0 28.913 31.928"
                  >
                    <path
                      id="Icon_simple-webpack"
                      data-name="Icon simple-webpack"
                      d="M31.524,27.18l-13,7.32V28.8l8.1-4.435,4.9,2.816Zm.889-.8V11.071L27.656,13.8v9.839Zm-28.024.8,13,7.32V28.8l-8.1-4.435-4.9,2.816Zm-.889-.8V11.071L8.256,13.8v9.839L3.5,26.379Zm.556-16.3,13.33-7.508V8.084L8.846,12.76l-.065.037L4.056,10.08Zm27.8,0L18.527,2.572V8.084l8.54,4.677.065.037,4.725-2.717ZM17.386,27.5,9.4,23.129V14.463l7.989,4.593V27.5Zm1.141,0,7.989-4.373V14.463l-7.989,4.593V27.5ZM9.938,13.46l8.02-4.392,8.02,4.392-8.02,4.612Z"
                      transform="translate(-3.5 -2.572)"
                      fill="#0091ff"
                    />
                  </svg>
                </NoPlanSvgWrapper>
                <NoPlanText>
                  Zmień plan działania aby móc korzystać ze Strony Twórcy.
                </NoPlanText>
                <NoPlanText>Więcej info w zakładce PRO.</NoPlanText>
                <NoPlanBtnWrapper
                  onClick={() => (window.location.href = 'pro')}
                >
                  <p>Zobacz plany działania</p>
                </NoPlanBtnWrapper>
              </NoPlanWrapper>
            ) : producer.Blocks.length > 0 ? (
              <>
                {' '}
                <GridStructur isEditing={isEditingMode}>
                  {createArr(getMaxHeight(producer.Blocks), 18).map(
                    (item, index) => (
                      <GridOneItem
                        style={
                          isLoading || isLoadingColors
                            ? {cursor: 'default'}
                            : null
                        }
                        onClick={(e) => {
                          if (isLoading || isLoadingColors) return;
                          getMaxHeight(producer.Blocks);
                          handleShowListing(true);
                        }}
                        key={index}
                      ></GridOneItem>
                    ),
                  )}
                  {/*<GridOneItem />*/}

                  <AddBlock
                    allowdBlock={blockTypes}
                    addNewBlock={addNewBlock}
                    isOpenListing={showListing}
                    toggleListing={handleShowListing}
                    randomCords={cordsForAddBlock}
                    maxHeight={getMaxHeight(producer.Blocks)}
                  />
                </GridStructur>
                <ReactGridLayout
                  cols={18}
                  isDraggable={isEditingMode}
                  isResizable={false}
                  rowHeight={56}
                  maxRows={900}
                  margin={[0, 0]}
                  containerPadding={[0, 0]}
                  compactType={'vertical'}
                  layout={newLayout}
                  onLayoutChange={(layout) => {
                    if (!_.isEqual(newLayout, layout)) {
                      setProducer((prev) => ({
                        ...prev,
                        Blocks: [
                          ...prev.Blocks.map((block, index) => {
                            if (block.Id === layout[index].i)
                              return {
                                ...block,
                                WebArea: {
                                  StartX: layout[index].x,
                                  StartY: layout[index].y,
                                  Width: layout[index].w,
                                  Height: layout[index].h,
                                },
                              };
                            return block;
                          }),
                        ],
                      }));
                    }
                  }}
                  preventCollision={false}
                >
                  {producer.Blocks &&
                    producer.Blocks.map((block, index) => {
                      const {
                        Id,
                        BackgroundColorHex,
                        BlockOpacityIn0To100Ratio,
                        FontColorHex,
                        Type,
                        WebArea,
                      } = block;
                      const {Width, Height} = WebArea;
                      const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
                      const FontColorRgb = hexToRgb(FontColorHex);

                      return (
                        <PanelContainer
                          bgColor={BackgroundColorRgb}
                          key={Id}
                          isEditing={isEditing}
                          blockWidth={Width}
                          blockHeight={Height}
                        >
                          <PanelSvgWrapper
                            onClick={() => {
                              if (isLoading || isLoadingColors) return;
                              setBlockHoverId(Id);
                            }}
                            isHover={blockHoverId === Id}
                            blockWidth={Width}
                            blockHeight={Height}
                            isEditing={isEditingMode}
                          >
                            {blockHoverId !== Id ? null : (
                              <>
                                <img src={dragonBlueArrow} />
                                <PanelSvgEditIconWrapper
                                  blockHeight={Height}
                                  blockWidth={Width}
                                >
                                  {Type === 64 ? (
                                    <div
                                      style={{
                                        height: '46px',
                                        width: '66px',
                                        opacity: 0,
                                      }}
                                    ></div>
                                  ) : (
                                    <svg
                                      onClick={(e) => deleteBlock(e, Id)}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="66"
                                      height="46"
                                      viewBox={
                                        Height === 1 && Width === 1
                                          ? '-8 0 66 46'
                                          : '0 0 66 46'
                                      }
                                    >
                                      <defs>
                                        <filter
                                          id="Background"
                                          x="0"
                                          y="0"
                                          width="66"
                                          height="46"
                                          filterUnits="userSpaceOnUse"
                                        >
                                          <feOffset dy="3" />
                                          <feGaussianBlur
                                            stdDeviation="3"
                                            result="blur"
                                          />
                                          <feFlood floodOpacity="0.161" />
                                          <feComposite
                                            operator="in"
                                            in2="blur"
                                          />
                                          <feComposite in="SourceGraphic" />
                                        </filter>
                                      </defs>
                                      <g
                                        id="Group_4608"
                                        data-name="Group 4608"
                                        transform="translate(-771 -568)"
                                      >
                                        <g
                                          transform="matrix(1, 0, 0, 1, 771, 568)"
                                          filter="url(#Background)"
                                        >
                                          <rect
                                            id="Background-2"
                                            data-name="Background"
                                            width="48"
                                            height="28"
                                            rx="14"
                                            transform="translate(9 6)"
                                            fill="#f1f1f2"
                                          />
                                        </g>
                                        <path
                                          id="Icon_material-delete"
                                          data-name="Icon material-delete"
                                          d="M8.5,20.5a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V8.5H8.5Zm13-15H18l-1-1H12l-1,1H7.5v2h14Z"
                                          transform="translate(789.5 574.5)"
                                          fill="#3a87f0"
                                        />
                                      </g>
                                    </svg>
                                  )}
                                  {/*edycja*/}
                                  <svg
                                    onClick={() => editBlock(block, index)}
                                    onTouchStart={() => editBlock(block, index)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="66"
                                    height="46"
                                    viewBox={
                                      Height === 1 && Width === 1
                                        ? '8 0 66 46'
                                        : '0 0 66 46'
                                    }
                                  >
                                    <defs>
                                      <filter
                                        id="Background"
                                        x="0"
                                        y="0"
                                        width="66"
                                        height="46"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feOffset dy="3" />
                                        <feGaussianBlur
                                          stdDeviation="3"
                                          result="blur"
                                        />
                                        <feFlood floodOpacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                      </filter>
                                    </defs>
                                    <g
                                      id="Group_4621"
                                      data-name="Group 4621"
                                      transform="translate(-656 -540)"
                                    >
                                      <g
                                        transform="matrix(1, 0, 0, 1, 656, 540)"
                                        filter="url(#Background)"
                                      >
                                        <rect
                                          id="Background-2"
                                          data-name="Background"
                                          width="48"
                                          height="28"
                                          rx="14"
                                          transform="translate(9 6)"
                                          fill="#f1f1f2"
                                        />
                                      </g>
                                      <path
                                        id="Icon_material-settings"
                                        data-name="Icon material-settings"
                                        d="M21.423,14.529a7.24,7.24,0,0,0,0-2.058l2.215-1.733a.53.53,0,0,0,.126-.672l-2.1-3.633a.528.528,0,0,0-.641-.231L18.41,7.253a7.671,7.671,0,0,0-1.774-1.029l-.4-2.782A.512.512,0,0,0,15.722,3h-4.2a.512.512,0,0,0-.514.441l-.4,2.783A8.067,8.067,0,0,0,8.834,7.252L6.219,6.2a.512.512,0,0,0-.641.231l-2.1,3.633a.518.518,0,0,0,.126.672L5.82,12.471A8.327,8.327,0,0,0,5.747,13.5a8.327,8.327,0,0,0,.073,1.029L3.6,16.261a.53.53,0,0,0-.126.672l2.1,3.633a.528.528,0,0,0,.641.231l2.614-1.05a7.671,7.671,0,0,0,1.775,1.029l.4,2.782a.512.512,0,0,0,.514.441h4.2a.512.512,0,0,0,.514-.441l.4-2.782a8.067,8.067,0,0,0,1.774-1.029l2.615,1.05a.512.512,0,0,0,.641-.231l2.1-3.633a.53.53,0,0,0-.126-.672l-2.215-1.733Zm-7.8,2.646A3.675,3.675,0,1,1,17.3,13.5,3.679,3.679,0,0,1,13.622,17.175Z"
                                        transform="translate(675.594 546.5)"
                                        fill="#3a87f0"
                                      />
                                    </g>
                                  </svg>
                                </PanelSvgEditIconWrapper>
                              </>
                            )}
                          </PanelSvgWrapper>

                          <AltPanelContent
                            overview={!isEditingMode}
                            blockWidth={Width}
                            backgroundAlpha={BlockOpacityIn0To100Ratio}
                            backgroundColor={BackgroundColorRgb}
                            ref={(el) => (blocksRef.current[index] = el)}
                          >
                            <AltPanelTextWrapper
                              fontColor={FontColorRgb}
                              blockWidth={Width}
                            >
                              {renderBlockContent(
                                roles,
                                block,
                                theme,
                                mobileOverview,
                                isEditing,
                              )}
                            </AltPanelTextWrapper>
                          </AltPanelContent>
                        </PanelContainer>
                      );
                    })}
                </ReactGridLayout>
                {/* </BlocksWrapper> */}
              </>
            ) : isLoading || isProducerDataLoading || isLoadingColors ? null : (
              <ChoosePatternModeWrapper>
                <ThemeSelectWrapper> </ThemeSelectWrapper>
                <h4>Wybierz motyw</h4>
                <ChoosePatternCardsWrapper>
                  <ChoosePatternOneCard
                    onClick={() =>
                      chooseCardTemplate(decodedToken.ProducerId, 3)
                    }
                  >
                    {' '}
                    <img src={motywNeonImg} />
                    <p>Neon Vibe</p>
                  </ChoosePatternOneCard>
                  <ChoosePatternOneCard
                    onClick={() =>
                      chooseCardTemplate(decodedToken.ProducerId, 1)
                    }
                  >
                    {' '}
                    <img src={motywClassicImg} />
                    <p>Classic Blue</p>
                  </ChoosePatternOneCard>
                  <ChoosePatternOneCard
                    onClick={() =>
                      chooseCardTemplate(decodedToken.ProducerId, 2)
                    }
                  >
                    {' '}
                    <img src={motywArtisticImg} />
                    <p>Artistic Splash</p>
                  </ChoosePatternOneCard>
                  {/* <ChoosePatternOneCard
                    onClick={() =>
                      chooseCardTemplate(decodedToken.ProducerId, 0)
                    }
                  >
                    {' '}
                    <img src={motywWlasnyImg} />
                    <p>Własny</p>
                  </ChoosePatternOneCard> */}
                </ChoosePatternCardsWrapper>
                <PatternButtonWrapper>
                  <button
                    onClick={() =>
                      chooseCardTemplate(decodedToken.ProducerId, 0)
                    }
                  >
                    Stwórz własny
                  </button>
                </PatternButtonWrapper>
              </ChoosePatternModeWrapper>
            )}
          </MainPanelContentWrapper>
          <FooterWrapper>
            <FooterSellerAvatarAndInfoWrapper>
              <FooterCircleAvatar>
                <img src={producer.ProducerProducerPhoto} alt="avatar" />
              </FooterCircleAvatar>
              <FooterSellerInfoWrapper>
                {producer.ProducerName ? (
                  <p
                    style={{fontWeight: 'bold'}}
                  >{` ${producer.ProducerName}`}</p>
                ) : (
                  <p
                    style={{fontWeight: 'bold'}}
                  >{`${producer.ProducerFirstName} ${producer.ProducerLastName}`}</p>
                )}
                {/* <p>{`${producer.ProducerStreet},${cities} ${producer.ProducerPostalCode} `}</p>{' '} */}
                <p>{`Konto prywatne od ${sellerPlan.ActiveFrom.slice(
                  0,
                  10,
                )}`}</p>
              </FooterSellerInfoWrapper>
            </FooterSellerAvatarAndInfoWrapper>
            {/* <FooterBigBlueBtn>Wyślij wiadomość</FooterBigBlueBtn> */}
          </FooterWrapper>
        </MainPanelWrapper>
      </Main>
    </MainWrapper>
  );
};

export default EditCard;
