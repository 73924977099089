import styled from 'styled-components';
import arrowDown from 'images/svg/purple-select-arrow.svg';
export const FaqMainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FaqMain = styled.div`
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 88px;
  transition: 0.3s;

  & > p {
    font-size: 14px;
    /* color: rgba(255, 255, 255, 0.92); */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    top: 0px;
    position: relative;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const FaqHeaderWrapper = styled.div`
  width: 100%;
  /* background: #89bfff; */
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: #0091ff;
  border-radius: 24px 24px 0 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s;

  & > h4 {
    letter-spacing: -0.015px;
    font-size: 18px;
    /* color: #07020f; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 21px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const FaqFilterWrapper = styled.div<{isMenuOpen: boolean}>`
  width: 100%;
  position: absolute;
  top: 53px;
  height: ${({isMenuOpen}) => (isMenuOpen ? '220px' : '24px')};
  display: flex;
  justify-content: ${({isMenuOpen}) =>
    isMenuOpen ? 'unset' : ' space-between'};
  border-radius: 0 0 5px 5px;
  padding: ${({isMenuOpen}) => (isMenuOpen ? '0px' : '0 10px')};
  align-items: ${({isMenuOpen}) => (isMenuOpen ? 'unset' : ' center')};
  background: ${({theme}) => theme.colors.bgWhite};
  /* background: ${({isMenuOpen}) => (isMenuOpen ? 'red' : 'blue')}; */
  transition: 0.3s;
  z-index: 1;
  & > p {
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const FaqFilterContentWrapper = styled.div`
  width: 100%;
`;

export const FaqFilterHeader = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const FaqFilterContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FaqFilterSvgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 9px;
    line-height: 24px;
  }
  & > svg {
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.fontBlackOp100};
      }
      & > g {
        & > path {
          fill: ${({theme}) => theme.colors.fontBlackOp100};
        }
      }
    }
  }
`;
export const FaqScroll = styled.div`
  width: 800px;
  /* height: auto; */
  margin-top: 50px;
  height: 600px;
  overflow-y: scroll;
  transition: 0.3s;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a87f0;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
    margin-top: 0px;

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 5px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }
`;
export const FaqContentWrapper = styled.div`
  /* margin-top: 50px; */
  width: 780px;
  height: auto;
  /* height: 600px;
  overflow-y: scroll; */
  transition: 0.3s;

  @media (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const FaqTitle = styled.h2`
  font-size: 25px;
  letter-spacing: 0.625px;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 35px;
  color: ${({theme}) => theme.colors.fontBlackOp100};

  @media (max-width: 1024px) {
    font-size: 18px;
    margin-top: 36px;
  }
`;

export const FaqItem = styled.div`
  width: 100%;
  height: auto;
  transition: 0.3s;
`;
export const FaqItemHeader = styled.div`
  width: 100%;
  border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp100}`};
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 35px; */
  position: relative;
  transition: 0.3s;
  padding: 0 5px;
  cursor: pointer;
  & > h4 {
    font-weight: bold;
    font-size: 15px;
    width: 650px;
    line-height: 28px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  & > p {
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    align-self: end;
  }
  @media (max-width: 1024px) {
    & > h4 {
      font-size: 11px;
      width: 220px;
    }
    & > p {
      font-size: 9px;
    }
  }
`;
export const FaqSvgWrapper = styled.div`
  position: absolute;
  right: 5px;
  /* top: 30px; */
  bottom: -22px;
  & > svg {
    & > path {
      stroke: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;
export const FaqItemTextWrapper = styled.div`
  margin-left: 20px;
  height: 36px;
  width: 690px;
  margin-bottom: 0;
  transition: 0.3s;
  transform: scaleY(0);

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.25px;
    transition: 0.3s;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const RegulaminsWrapper = styled.div`
  width: 614px;
  height: auto;
  /* margin-bottom: 50px; */
  margin: 50px auto;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const RegulaminItem = styled.div`
  width: 100%;
  display: flex;
  height: 28px;
  justify-content: space-between;
  & > h4 {
    font-size: 15px;
    line-height: 28px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    & > h4 {
      font-size: 11px;
    }
  }
`;
export const RegulaminBtnWrapper = styled.div`
  display: flex;
  align-items: center;

  & > p {
    font-size: 11px;
    line-height: 28px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding-right: 10px;
    cursor: pointer;
  }
  & > svg {
    cursor: pointer;
    & > path {
      stroke: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const ArrowWrapper = styled.div<{isActive?: boolean; length?: number}>`
  width: 84px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive, length}) =>
    isActive || length > 0 ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  &:hover {
    background: rgba(137, 190, 255, 0.3);
  }

  & > p {
    position: absolute;
    background: #89bfff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 6px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: #07020f;
  }
`;

export const SelectWrapper = styled.div`
  width: 146px;
  height: 22px;
  border-radius: 7px;
  /* border: 1px solid rgba(255, 255, 255, 0.11); */
  position: relative;
  border: 1px solid rgba(112, 112, 112, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: rgba(7, 2, 15, 0.5);

    cursor: pointer;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 119px 5px;

    & > option {
      background: white;
      height: 90px;
    }
  }

  @media (max-width: 1045px) {
    & > select {
      background-position: 284px 4px;
    }
  }
`;
