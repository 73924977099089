import styled from 'styled-components';
import {MessagesType} from '../interfaces';

export const MainWrapper = styled.div`
  color: ${({theme}) => theme.colors.bgWhite};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    background: ${({theme}) => theme.colors.pageBodyLeft};
  }
`;

export const MobileMain = styled.div`
  width: 400px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  flex-direction: column;
  align-items: center;
  background: transparent;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    /* margin-bottom: 0px; */
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: unset;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: 665px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  position: absolute;
  top: 88px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 21px 21px;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  overflow: hidden;
  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  z-index: 2;
  width: 100%;
  height: 55px;
  font-size: 15px;
  line-height: 24px;
  /* border-radius: 24px 24px 0 0; */
  letter-spacing: 0.625px;
  font-weight: 400;
  background: ${({theme}) => theme.colors.pageHeader};
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  color: ${({theme}) => theme.colors.fontBlackOp100};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Flex = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  border-radius: 9px;
  display: flex;
`;

export const LeftSection = styled.div<{isWriteMode: boolean}>`
  position: relative;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9.5px 0px 0px 7.5px;
  opacity: ${({isWriteMode}) => (isWriteMode ? '0.3' : 'unset')};
  pointer-events: ${({isWriteMode}) => (isWriteMode ? 'none' : 'unset')};
  user-select: ${({isWriteMode}) => (isWriteMode ? 'none' : 'unset')};
  display: ${({isWriteMode}) => (isWriteMode ? 'none' : 'flex')};
`;

export const MobileLeftSection = styled.div<{
  isWriteMode: boolean;
  isMenuOpen?: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 0px 0px;
  opacity: ${({isWriteMode}) => (isWriteMode ? '1' : '1')};
  pointer-events: ${({isWriteMode}) => (isWriteMode ? 'unset' : 'unset')};
  user-select: ${({isWriteMode}) => (isWriteMode ? 'unset' : 'unset')};
  display: ${({isWriteMode}) => (isWriteMode ? 'flex' : 'flex')};
  @media (max-width: 1024px) {
    /* height: ${({isMenuOpen}) => (isMenuOpen ? '0px' : 'auto')};
    overflow: ${({isMenuOpen}) => (isMenuOpen ? 'hidden' : 'auto')}; */
  }
`;

export const LeftSectionButtonWrapper = styled.div`
  width: 306px;
  height: 28px;
  border-radius: 7px;
  padding: 3px;
  gap: 2px;
  border: ${({theme}) => `1px solid ${theme.colors.columnBorder}`};
  background: transparent;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 14px);
    height: 24px;
    padding: 0px;

    background: ${({theme}) => theme.colors.inputMobileBg};
    border-radius: 5px;

    border: ${({theme}) => `1px solid ${theme.colors.inputMobileBorderColor}`};
  }
`;

export const LeftSectionButton = styled.button<{
  value: MessagesType;
  messagesType: MessagesType;
}>`
  cursor: ${({value, messagesType}) =>
    value === messagesType ? 'default' : 'pointer'};
  background: ${({value, messagesType, theme}) =>
    value === messagesType ? theme.colors.blueTheme : theme.colors.bgBlackOp15};
  border: none;
  color: ${({value, messagesType, theme}) =>
    value === messagesType
      ? theme.colors.fontBlackOp100
      : theme.colors.fontBlackOp50};
  font-size: 11px;
  letter-spacing: 0.1px;
  font-weight: 400;
  border-radius: 5px;
  padding: 3px 5px;
  flex-basis: 49.9%;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: 1024px) {
    margin: 2px;
    width: 152px;
    /* height: 52px; */
    /* font-size: 13px; */
    background: ${({value, messagesType, theme}) =>
      value === messagesType ? theme.colors.blueTheme : 'transparent'};
    line-height: 12px;
    border-radius: 4px;
    border: none;
    color: ${({value, messagesType, theme}) =>
      value === messagesType ? 'white' : theme.colors.fontBlackOp50};

    border: ${({value, messagesType, theme}) =>
      value === messagesType ? `1px solid ${theme.colors.fontBlue}` : `none`};
  }
`;

export const LeftSectionSearchWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  margin-top: 7px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;
  }
`;

export const RefreshButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 28px;
  border-radius: 7px;
  transition: all 0.15s;
  position: absolute;
  right: 10px;

  &:hover {
    background: rgba(47, 47, 104, 0.05);
  }

  & > svg {
    cursor: pointer;
    transition: all 0.15s;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
    padding-left: 6px;

    &:hover {
      background: transparent;
    }
  }
`;

export const MobileIconsWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  // position: sticky;
  // top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
  overflow: hidden;

  @media (max-width: 1024px) {
    position: fixed;
  }
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  display: flex;
  height: 100%;
  padding: 0px 20px;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  width: 267px;
  height: 28px;
  background: transparent;
  border-radius: 7px;
  border: ${({theme}) => `1px solid ${theme.colors.columnBorder}`};
  display: flex;
  padding: 3px 5px;
  align-items: center;
  margin-left: 12px;
  justify-self: flex-start;
  gap: 10.5px;

  & > svg {
    margin-left: 10.5px;
    width: 11.11px;
    height: 11px;
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.bgBlack};
      }
    }
  }

  & > input {
    width: calc(100% - 30px);
    /* padding-left: 10px; */
    background: transparent;
    font-size: 11px;
    padding: 0 2px;
    border: none;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};

    &::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > input:focus {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 53px);
    margin-left: unset;
  }
`;

export const LeftSectionMessagesWrapper = styled.div`
  margin-top: 2px;
  width: 100%;
  height: 88%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  border-radius: 16px;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  padding-left: 2px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: unset;
    overflow-y: auto;
    margin-top: 15px;
    align-items: center;
  }
`;

export const LeftSectionMessageWrapper = styled.div<{isActive?: boolean}>`
  /* height: 120px; */
  width: 100%;
  border-radius: 16px;
  background: ${({isActive, theme}) =>
    isActive ? theme.colors.bgWhite : theme.colors.bgWhite};
  cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};
  border: 1px solid
    ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp15};
  transition: all 0.15s;

  &:hover {
    border: ${({isActive, theme}) =>
      isActive
        ? `1px solid ${theme.colors.fontBlackOp100}`
        : '1px solid #0066FF'};

    & .LeftSectionMessageContentWrapper {
      transition: all 0.15s;
      background: ${({isActive, theme}) =>
        isActive ? theme.colors.bgGrey : theme.colors.bgGrey};
    }
  }
  @media (max-width: 1024px) {
    width: calc(100% - 14px);
    @media (max-width: 1024px) {
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 2px;
      /* background: ${({theme}) => theme.colors.bodyBg}; */
      &:hover {
        border: ${({isActive, theme}) =>
          isActive
            ? `1px solid ${theme.colors.fontBlackOp100}`
            : '1px solid #0066FF'};
      }
    }
  }
`;

export const LeftSectionMessageHeader = styled.div`
  position: relative;
  width: 100%;
  padding-left: 11px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 52px;
  display: flex;
  align-items: center;

  & > img {
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  & > svg {
    border-radius: 100%;
    width: 32px;
    height: 32px;

    & > g {
      & > g {
        & > path {
          fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
        }
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 2px;
    justify-content: flex-end;

    & > span {
      border-radius: 100%;
      width: 12px;
      height: 12px;
      background: #19d627;
      position: absolute;
      top: 5px;
      left: 32px;
    }

    & > p {
      font-size: 12px;
      letter-spacing: 0.3px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      max-width: 180px;
      height: 17px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      & > span {
        font-size: 12px;
        letter-spacing: -0.25px;
        font-weight: 700;
        color: ${({theme}) => theme.colors.fontBlackOp100};
      }
    }
  }

  & > span {
    position: absolute;
    font-size: 9px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    right: 19px;
    top: 9px;
  }
  @media (max-width: 1024px) {
    & > img {
      border-radius: 7px;
      width: 34px;
      height: 34px;
    }
    & > svg {
      border-radius: 7px;
      width: 34px;
      height: 34px;
    }
    & > span {
      font-size: 11px;
      letter-spacing: -0.15px;
      font-weight: bold;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      opacity: 0.3;
      right: 19px;
      top: 9px;
    }
    & > div {
      margin-bottom: 4px;
      & > p {
        font-size: 13px;

        & > span {
          font-size: 11px;
          line-height: 13px;
          & > svg {
            margin-bottom: 4px;
            margin-left: 9px;
          }
        }
      }
    }
  }
`;

export const LeftSectionMessageContentWrapper = styled.div<{
  isActive?: boolean;
}>`
  height: 54px;
  padding: 8px 16px 8px;
  display: flex;
  border: none;
  border-radius: 0px 0px 16px 16px;
  flex-direction: column;
  background: ${({theme}) => theme.colors.bgGrey};

  & > h4 {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 21px;
    font-weight: 500;
  }

  & > p {
    font-size: 12px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 19px;
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 1024px) {
    height: 60px;
    border-radius: 14px;
    /* background: rgba(255, 255, 255, 1); */
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    border: 1px solid rgba(112, 112, 112, 0.3);
    & > p {
      font-size: 13px;
    }
  }
`;

export const BlackScreen = styled.div`
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0px;
  border-radius: 15px;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const RightSection = styled.div<{isWriteMode: boolean}>`
  z-index: 2;
  position: relative;
  background: ${({isWriteMode, theme}) =>
    isWriteMode ? 'transparent' : `${theme.colors.pageBodyCenterAndRight}`};
  padding: 14px 8px 8px 8px;
  border-bottom-right-radius: 18px;
  flex-basis: ${({isWriteMode}) => (isWriteMode ? '100%' : '67%')};
  display: flex;
  flex-direction: column;
`;

export const RightSectionHeaderWrapper = styled.div<{isWriteMode?: boolean}>`
  padding: 0px 0px 16px 0px;
  width: 100%;
`;

export const RightSectionIconsWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  & > div {
    display: flex;
    gap: 5px;
  }
`;

export const RightSectionSingleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 28px;
  border-radius: 7px;
  transition: all 0.15s;

  &:hover {
    background: rgba(47, 47, 104, 0.05);
  }

  & > svg {
    cursor: pointer;
    transition: all 0.15s;
  }
`;

export const CancelButton = styled.button`
  background: transparent;
  width: 85px;
  height: 22px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 11px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    background: rgba(47, 47, 104, 0.05);
  }
`;

export const RightSectionAddressAndTitleWrapper = styled.div<{
  isWriteMode?: boolean;
}>`
  display: flex;
  width: 100%;
  padding-bottom: 16px;

  & > p {
    width: 60px;
    font-size: 12px;
    color: ${({theme}) => theme.colors.fontBlue};
    padding-right: 12px;
    letter-spacing: 0.1px;
    font-weight: 400;
    line-height: 24px;
  }

  & > span {
    width: 95%;
    font-size: 11px;
    padding-left: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 500;
    border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  }
`;

export const RightSectionAddressAndTitleWriteMode = styled.div`
  display: flex;
  width: 100%;
  background: transparent;
  padding: 5px 11.5px;
  border-radius: 5px;
  border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};

  & > span {
    width: 95%;
    font-size: 11px;
    padding-left: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 500;
    border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 500;
    width: 80px;
  }

  & > input {
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border: none;
    padding: unset;

    & > input {
      border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
      line-height: 24px;
      font-size: 13px;
    }

    & > p {
      color: ${({theme}) => theme.colors.fontBlue};
      line-height: 24px;
      font-size: 13px;
    }
  }
`;

export const RightSectionMessageContent = styled.div<{
  isWriteMode?: boolean;
}>`
  position: relative;
  padding: ${({isWriteMode}) =>
    isWriteMode ? 'unset' : '24px 11.5px 5px 10.5px'};
  width: ${({isWriteMode}) => (isWriteMode ? '100%' : '95%')};
  margin-left: ${({isWriteMode}) => (isWriteMode ? '0px' : '10px')};
  height: 88%;
  background: transparent;
  border: ${({isWriteMode, theme}) =>
    isWriteMode ? `1px solid ${theme.colors.fontBlackOp50}` : 'none'};
  border-radius: 5px;
  overflow-y: auto;
  white-space: pre-line;
  color: ${({theme}) => theme.colors.fontBlackOp100};

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  & > p {
    text-align: justify;
    font-size: 11px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  & > textarea {
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-size: 11px;
    line-height: 21px;
    padding: 10px;
    padding-top: 20px;
    outline: none;
    background: ${({isWriteMode}) =>
      isWriteMode ? 'transparent' : 'rgba(47,47,104,0.05)'};
    border-radius: 5px;
    border: none;
    resize: none;
    width: 100%;
    height: 99%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: unset;
    padding-left: unset;
    width: 100%;
    border: none;
    margin-top: ${({isWriteMode}) => (isWriteMode ? '10px' : 'unset')};
    overflow-y: unset;

    & > textarea {
      padding-top: unset;
      font-size: 13px;
    }

    & > p {
      padding-left: 9px;
      font-size: 13px;
    }
  }
`;

export const ContentTitle = styled.h4`
  font-size: 11px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  letter-spacing: 0.625px;
  font-weight: 500;
  position: absolute;
  top: 0px;
  left: 10.5px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
  }
`;

export const SearchResultWrapper = styled.div<{
  isFocused: boolean;
  autoCompleted: boolean;
}>`
  width: 100%;
  position: absolute;
  background: ${({theme}) => theme.colors.bgGrey};
  border-radius: 5px;
  border: ${({theme}) => `1px solid ${theme.colors.pageBodyLeft}`};
  backdrop-filter: blur(3px);
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 26px;
  /* padding: 5px 0; */
  z-index: 5;
  visibility: ${({autoCompleted, isFocused}) =>
    autoCompleted && isFocused ? 'visible' : 'hidden'};

  @media (max-width: 1045px) {
    width: 90%;
  }
`;

export const SingleSearchResultWrapper = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  z-index: 5;
  cursor: pointer;

  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }

  &:hover {
    /* background: #16265c; */
    background: #89bfff;
  }
`;

export const InputWithSearchResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.bgWhite};
    width: 80px;
  }

  & > input {
    color: ${({theme}) => theme.colors.bgWhite};
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SvgEmptyMsgWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  align-items: center;
  display: flex;
  flex-direction: column;
  & > h4 {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.1px;
    opacity: 0.5;
    padding-top: 5px;
  }
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const RightSectionThreadMessagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.blueTheme};
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
  @media (max-width: 1024px) {
    overflow-y: auto;
    /* height: auto; */
  }
`;
export const ThreadMessageWrapper = styled.div<{
  isReciever: boolean;
  isFirst: boolean;
}>`
  width: 98%;
  /* min-height: 85px; */
  border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  padding: 10px;
  border-radius: 16px;
  background: ${({isReciever, theme}) =>
    isReciever ? theme.colors.bgWhite : theme.colors.bgGrey};

  /* align-self: ${({isReciever}) =>
    isReciever ? 'flex-start' : 'flex-end'}; */
  align-self: center;
`;

export const ThreadMessageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const ThreadMessageHeaderNameAndImg = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  & > img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
  & > svg {
    width: 20px;
    height: 20px;
    border-radius: 100%;

    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
      }
    }
  }
  & > p {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    width: 400px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    & > p {
      width: auto;
    }
  }
`;
export const ThreadMessageHeaderIcons = styled.div`
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  & > p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const ThreadMessageContent = styled.p`
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  height: auto;
  letter-spacing: 0.125px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  white-space: pre-line;
`;

export const ThreadResponsIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding-left: 10px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.625px;
  }
  & > svg {
    cursor: pointer;
    margin-right: 8px;
  }
  @media (max-width: 1024px) {
    padding-top: 10px;
    & > p {
      font-size: 13px;
    }
  }
  @media (max-width: 400px) {
    & > p {
      font-size: 11px;
    }
  }
`;

export const ThreadResponseWrappers = styled.div`
  width: 98%;
  min-height: 200px;
  margin: 0 auto;
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 16px;

  box-shadow: ${({theme}) => theme.colors.inputBoxShadowColor};
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp100}`};
  }
`;

export const ThreadResponseHeaderNameAndImg = styled.div`
  padding: 10px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  & > img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
  }
  & > svg {
    width: 36px;
    height: 36px;
    border-radius: 100%;

    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
      }
    }
  }
  & > p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const ThreadResponseContent = styled.div`
  width: 100%;
  /* min-height: 50px; */
  height: 50%;
  & > textarea {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 14px;
    line-height: 21px;
    padding: 10px;
    padding-top: 10px;
    outline: none;
    background: transparent;
    border-radius: 5px;
    border: none;
    resize: none;
    width: 100%;
    /* height: 99%; */
    &::-webkit-scrollbar {
      width: 24px;
      height: 10px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      background-repeat: no-repeat;
      width: 9px;
      height: 15px;
      background-position: 4px 3px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-button:single-button:vertical:increment {
      background-repeat: no-repeat;
      width: 9px;
      height: 15px;
      background-position: 4px 3px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-corner {
      cursor: pointer;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({theme}) => theme.colors.blueTheme};
      border-radius: 25px;
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      padding: 3px;
      cursor: pointer;
    }
  }
`;

export const ThreadResponseButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  & > button {
    width: 88px;
    height: 32px;
    background: ${({theme}) => theme.colors.blueTheme};
    color: ${({theme}) => theme.colors.bgWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none;
    /* padding: 5px 10px; */
    font-size: 11px;
    border-radius: 16px;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const ThreadMobileScrollWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 20px;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
`;

export const MobileTopicWrapper = styled.div`
  & > p {
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    line-height: 11px;
    font-size: 11px;
    max-width: 243px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: transform 0.3s ease;
    text-transform: uppercase;
  }
`;

export const SvgReplyAndRefreshWrapper = styled.div`
  display: flex;
  gap: 5px;
  & > svg {
    cursor: pointer;
  }
`;
