import React, {useState, useRef, useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {Link, Navigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {useNavigate, useLocation} from 'react-router-dom';

import useAlert from 'hooks/useAlert';
import api from 'services/axiosConfig';

import appleLogo from 'images/svg/apple-logo.svg';
import facebookLogo from 'images/svg/facebook-logo.svg';
import googleLogo from 'images/svg/google-logo.svg';
import mugoImage from 'images/post_mugo.png';

import openEye from 'images/svg/openEye.svg';
import closeEye from 'images/svg/closeEye.svg';

import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {ErrorStar} from 'components/ErrorStar';
import {LoadingCircle} from 'utils/loadingCircle';
import {MobileInput} from 'components/MobileInput';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileMainWrapper = styled.div<{ProductId: string}>`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  background: ${({theme}) => theme.colors.pageBodyLeft};
  border-radius: ${({ProductId}) => (ProductId ? '16px' : 'unset')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
`;

export const MobileInputsWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;
  gap: 13px;
  display: flex;
  flex-direction: column;
`;

export const MobileMain = styled.div`
  width: 312px;
  min-height: calc(100vh - 105px);
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 40px 0px 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;

  & > a {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: none;
      }
    }
  }

  @media (max-height: 560px) {
    margin-bottom: 57px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: 0px 20px;
  }
`;

export const MobileInputWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: ${({theme}) => theme.colors.bgGrey};
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &:-webkit-autofill {
      -webkit-text-fill-color: #3a87f0 !important;
    }

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: relative;
  top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 7px;

    &:first-of-type {
      margin-bottom: 21px;
    }
  }
`;

export const PrimaryAppleButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({theme}) => theme.colors.bgBlack};
  border-radius: 15px;
  font-size: 15px;
  color: ${({theme}) => theme.colors.bgWhite};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;
  & > div {
    & > svg {
      fill: ${({theme}) => theme.colors.bgWhite};
      & > path {
        fill: ${({theme}) => theme.colors.bhWhite};
      }
    }
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 7px;

    &:first-of-type {
      margin-bottom: 21px;
    }
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobilePageHeader = styled.h4`
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-bottom: 82px;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24.5px 85px 11px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;
  }
`;

const IconShowPasswordWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  & > svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  & > img {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 12px;

    & > img {
      width: 19px;
      height: 19px;
    }
  }
`;

export const GoogleFacebookAppleLogoWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 50px;
  margin-left: 28px;

  & > svg {
    width: 36px;
    height: 36px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: right;
    width: unset;
    margin-right: 15.5px;
  }

  @media (max-width: 340px) {
    margin-left: 20px;
  }
`;

export const LinkToLogin = styled.a`
  font-weight: 400;
  font-size: 11px;
  margin-top: 11px;
  letter-spacing: 0.625px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.fontBlackOp50};
  text-decoration: underline;
`;

interface LoginData {
  login: string;
  password: string;
}

interface ErrorVerification {
  loginError: string | null;
  passwordError: string | null;
}

interface DataFromOffer {
  productType: number;
  productId: string;
}

interface LocationState {
  state: null | DataFromOffer;
}

interface Props {
  ProductId?: string;
  ProductType?: number;
  handleIsLoginPageOpenChange?: (value: boolean) => void;
}

const LoginComponent: React.FC<Props> = ({
  ProductId,
  ProductType,
  handleIsLoginPageOpenChange,
}) => {
  const [loginData, setLoginData] = useState<LoginData>({
    login: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorVerification, setErrorVerification] = useState<ErrorVerification>(
    {
      loginError: null,
      passwordError: null,
    },
  );

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();

  const [isTwoStepAuthentication, setIsTwoStepAuthentication] =
    useState<boolean>(false);
  const [twoStepAuthenticationCode, setTwoStepAuthenticationCode] =
    useState<string>('');
  const [twoStepAuthentiactionToken, setTwoStepAuthenticationToken] =
    useState<string>('');
  const [isTwoAuthError, setIsTwoAuthError] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies(['token']);

  const navigate = useNavigate();

  const location = useLocation();
  const {state} = location as LocationState;

  const {Alert} = useAlert();

  const loginButtonRef = useRef<HTMLButtonElement>(
    document.createElement('button'),
  );

  const handleAuthTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTwoStepAuthenticationToken(e.target.value);
  };

  const handleLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({...prev, [e.target.id]: e.target.value}));
    setErrorVerification({
      loginError: null,
      passwordError: null,
    });
  };

  const addHours = (date: Date, hours: number) => {
    date.setHours(date.getHours() + hours);

    return date;
  };

  const sendTwoStepAuthentication = async () => {
    try {
      const response = await api.get(`TwoStepAuthentication`, {
        params: {
          token: twoStepAuthentiactionToken,
          code: twoStepAuthenticationCode,
        },
      });

      const today = new Date();
      setCookie('token', response.data.Response.Token, {
        expires: addHours(today, 10),
        path: '/',
      });

      const productType = localStorage.getItem('productType');
      const productId = localStorage.getItem('productId');

      localStorage.removeItem('productId');
      localStorage.removeItem('productType');

      if (ProductId && ProductType) {
        if (ProductType === 8) {
          navigate(`/tracks/main/${ProductId}`);
          return navigate(0);
        } else {
          navigate(`/products/main/${ProductId}`);
          return navigate(0);
        }
      }

      if (productType && productId) {
        if (productType === '8') {
          navigate(`/tracks/main/${productId}`);
          navigate(0);
        } else {
          navigate(`/products/main/${productId}`);
          navigate(0);
        }
      } else {
        // navigate('/producer');

        // window.location.href = '/producer';
        navigate(0);
        window.location.href = '/producer';
      }
    } catch (error) {
      console.error(error);
      setIsTwoAuthError(true);
    }
  };

  const logIn = async () => {
    const {login, password} = loginData;

    setErrorVerification({
      loginError: null,
      passwordError: null,
    });
    let areThereErrors = false;

    if (!login) {
      setErrorVerification((prev) => ({
        ...prev,
        loginError: 'Login nie może być pusty',
      }));
      areThereErrors = true;
    }

    if (!password) {
      setErrorVerification((prev) => ({
        ...prev,
        passwordError: 'Hasło nie może być pusty',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;
    if (isLoading) return;
    setIsLoading(true);

    try {
      const body = {
        Username: loginData.login,
        Password: loginData.password,
      };

      const response = await api.post(`Auth/login`, body);

      if (!response.data.Response.RequiresTwoFactor) {
        const today = new Date();
        setCookie('token', response.data.Response.Token, {
          expires: addHours(today, 10),
          path: '/',
        });
        // if (state) {
        //   const {productId, productType} = state;
        //   if (productType === 8) {
        //     navigate(`/tracks/main/${productId}`);
        //     navigate(0);
        //   } else {
        //     navigate(`/products/main/${productId}`);
        //     navigate(0);
        //   }
        // } else {
        //   navigate('/producer');
        //   navigate(0);
        // }
        const productType = localStorage.getItem('productType');
        const productId = localStorage.getItem('productId');

        localStorage.removeItem('productId');
        localStorage.removeItem('productType');

        if (ProductId && ProductType) {
          if (ProductType === 8) {
            navigate(`/tracks/main/${ProductId}`);
            return navigate(0);
          } else {
            navigate(`/products/main/${ProductId}`);
            return navigate(0);
          }
        }

        if (productType && productId) {
          if (productType === '8') {
            navigate(`/tracks/main/${productId}`);
            navigate(0);
          } else {
            navigate(`/products/main/${productId}`);
            navigate(0);
          }
        } else {
          console.log('loguje do producer');
          // navigate('/producer');

          navigate(0);
          window.location.href = '/producer';
        }
      }

      if (response.data.Response.RequiresTwoFactor) {
        setIsTwoStepAuthentication(true);
        setTwoStepAuthenticationCode(response.data.Response.TwoFactorCode);
      }
      // window.location.href = '/';
    } catch (error) {
      console.error(error);
      if (error.response.data.Error.Message === 'incorrect password') {
        setErrorVerification((prevState) => ({
          ...prevState,
          passwordError: 'Błędne hasło',
        }));
      } else {
        setErrorVerification((prevState) => ({
          ...prevState,
          loginError:
            'Nie można znaleźć użytkownika o podanej nazwie użytkownika / adresie e-mail',
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithFacebook = async () => {
    try {
      const respons = await api.get(`Auth/FacebookAuthLink`);
      window.location.href = respons.data.Response.Code;
    } catch (error) {
      console.error(error);
    }
  };

  const loginWithGoogle = async () => {
    try {
      const respons = await api.get(`Auth/GoogleAuthLink`);
      window.location.href = respons.data.Response.Code;
    } catch (error) {
      console.error(error);
    }
  };

  const loginWithApple = async () => {
    try {
      const respons = await api.get(`Auth/AppleAuthLink`);
      window.location.href = respons.data.Response.Code;
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') return loginButtonRef.current.click();
  };

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper>
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Witamy w MUGO STUDIO!</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <ImageWrapper>
                <img src={mugoImage} />
              </ImageWrapper>
              {isTwoStepAuthentication ? (
                <RightSectionContent
                  style={{padding: '0 85px 0 88px', justifyContent: 'center'}}
                >
                  <h1 style={{marginBottom: '18px'}}>Weryfikacja Dwuetapowa</h1>
                  <InputView isError={isTwoAuthError} isEditing={true}>
                    <p>Token</p>
                    <input
                      placeholder="Wprowadź token z emaila"
                      type="text"
                      onChange={handleAuthTokenChange}
                      value={twoStepAuthentiactionToken}
                      id="code"
                    />
                    {isTwoAuthError && (
                      <ErrorStar text={'Podałeś nieprawidłowy kod'} />
                    )}
                  </InputView>
                  <LinkToLogin
                    onClick={() => setIsTwoStepAuthentication(false)}
                  >
                    Powrót do ekranu logowania
                  </LinkToLogin>
                  <PrimaryButton
                    style={{justifyContent: 'center', marginTop: '20px'}}
                    onClick={() => sendTwoStepAuthentication()}
                  >
                    Zaloguj
                  </PrimaryButton>
                </RightSectionContent>
              ) : (
                <RightSectionContent onKeyUp={handleKeyUp}>
                  <h1 style={{marginBottom: '14px'}}>Logowanie</h1>
                  <InputView
                    isError={errorVerification.loginError !== null}
                    isEditing={true}
                  >
                    <p>E-mail / Login</p>
                    <input
                      type="text"
                      // style={{paddingRight: '27.5px'}}
                      value={loginData.login}
                      onChange={handleLoginDataChange}
                      id="login"
                    />
                    {errorVerification.loginError !== null && (
                      <ErrorStar text={errorVerification.loginError} />
                    )}
                  </InputView>
                  <InputView
                    isError={errorVerification.passwordError !== null}
                    isEditing={true}
                    style={{marginBottom: '100px'}}
                  >
                    <p>Hasło</p>
                    <input
                      style={{paddingRight: '17.5px'}}
                      type={showPassword ? 'text' : 'password'}
                      value={loginData.password}
                      onChange={handleLoginDataChange}
                      id="password"
                    />
                    {errorVerification.passwordError !== null && (
                      <ErrorStar text={errorVerification.passwordError} />
                    )}
                    <IconShowPasswordWrapper
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                      {showPassword ? (
                        <img src={closeEye} alt="hidePAssword" />
                      ) : (
                        <img src={openEye} alt="showPAssword" />
                      )}
                      {/* {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15.414"
                          viewBox="0 0 20 15.414"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect width="20" height="15.414" fill="none" />
                            </clipPath>
                          </defs>
                          <g
                            id="Input_Action_Light_Hide_Password"
                            data-name="Input Action – Light / Hide Password"
                            clipPath="url(#clip-path)"
                          >
                            <path
                              id="Combined_Shape"
                              data-name="Combined Shape"
                              d="M3,14l2.085-2.085A15.885,15.885,0,0,1,0,8,16.153,16.153,0,0,1,4.739,4.25a11.7,11.7,0,0,1,8.735-.723L17,0l1.414,1.414-14,14ZM7,8a2.984,2.984,0,0,0,.437,1.563l4.126-4.126A3,3,0,0,0,7,8Zm1.507,4.906,1.939-1.939a3.018,3.018,0,0,0,2.52-2.519L16.475,4.94A17.3,17.3,0,0,1,20,8a16.168,16.168,0,0,1-4.738,3.75A11.672,11.672,0,0,1,10,13,11.777,11.777,0,0,1,8.508,12.906Z"
                              fill="#0065f2"
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 10"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect width="20" height="10" fill="none" />
                            </clipPath>
                          </defs>
                          <g
                            id="Input_Action_Light_Show_Password"
                            data-name="Input Action – Light / Show Password"
                            clipPath="url(#clip-path)"
                          >
                            <path
                              id="Combined_Shape"
                              data-name="Combined Shape"
                              d="M10,10A12.913,12.913,0,0,1,2.929,7.5,18.309,18.309,0,0,1,0,5l.006-.007A18.5,18.5,0,0,1,2.929,2.5,12.911,12.911,0,0,1,10,0a12.911,12.911,0,0,1,7.071,2.5A18.32,18.32,0,0,1,20,5l-.006.007A18.5,18.5,0,0,1,17.071,7.5,12.913,12.913,0,0,1,10,10Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,10,2Z"
                              transform="translate(0)"
                              fill="#0065f2"
                            />
                          </g>
                        </svg>
                      )} */}
                    </IconShowPasswordWrapper>
                  </InputView>
                  <Link to={'/identity/recover'}>Nie pamiętasz hasła?</Link>
                  <Link to={'/identity/register'}>Zarejestruj się</Link>
                  <Link
                    style={{marginBottom: '14px'}}
                    to={'/identity/recover/email'}
                  >
                    Wyślij ponownie e-mail potwierdzający
                  </Link>
                  <PrimaryButton
                    style={{marginBottom: '7px', justifyContent: 'center'}}
                    ref={loginButtonRef}
                    onClick={() => logIn()}
                  >
                    {isLoading ? 'Logowanie...' : 'Zaloguj się'}
                  </PrimaryButton>
                  {/* <PrimaryButton
                    style={{marginBottom: '7px'}}
                    background="#FFFFFF"
                    color="#07020F"
                    borderColor="rgba(112,112,112,0.3)"
                    onClick={loginWithFacebook}
                  >
                    <GoogleFacebookAppleLogoWrapper>
                
                      <svg
                        id="Facebook_f_logo__2021_"
                        data-name="Facebook_f_logo_(2021)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="-6.934"
                            y1="10.216"
                            x2="-6.934"
                            y2="10.241"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#0062e0" />
                            <stop offset="1" stopColor="#19afff" />
                          </linearGradient>
                        </defs>
                        <path
                          id="Path_3758"
                          data-name="Path 3758"
                          d="M11.69,27.86A14.028,14.028,0,0,1,14,0a14.028,14.028,0,0,1,2.31,27.86l-.77-.63H12.46Z"
                          fill="url(#linear-gradient)"
                        />
                        <path
                          id="Path_3759"
                          data-name="Path 3759"
                          d="M23,20.26l.63-3.92H19.92V13.61c0-1.12.42-1.96,2.1-1.96h1.82V8.08a22.893,22.893,0,0,0-3.08-.28,5.072,5.072,0,0,0-5.46,5.46v3.08H11.8v3.92h3.5v9.87a12.81,12.81,0,0,0,4.62,0V20.26Z"
                          transform="translate(-3.54 -2.34)"
                          fill="#fff"
                        />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Facebook
                  </PrimaryButton> */}
                  {/* <PrimaryButton
                    style={{marginBottom: '7px'}}
                    background="#FFFFFF"
                    color="#07020F"
                    borderColor="rgba(112,112,112,0.3)"
                    onClick={loginWithGoogle}
                  >
                    <GoogleFacebookAppleLogoWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="36px"
                        height="36px"
                      >
                        <path
                          fill="#fbc02d"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                        <path
                          fill="#e53935"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        />
                        <path
                          fill="#4caf50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        />
                        <path
                          fill="#1565c0"
                          d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Google
                  </PrimaryButton> */}
                  {/* <PrimaryAppleButton onClick={loginWithApple}>
                    <GoogleFacebookAppleLogoWrapper>
                      <svg
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="36px"
                        height="36px"
                      >
                        {' '}
                        <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z" />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Apple
                  </PrimaryAppleButton> */}
                </RightSectionContent>
              )}
            </PageHeaderContent>
          </PageHeaderWrapper>
        </Main>
      </MainWrapper>
      <MobileMainWrapper ProductId={ProductId}>
        {handleIsLoginPageOpenChange && (
          <CloseIsLoginPageOpenWrapper>
            Logowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsLoginPageOpenChange(false);
                setIsBodyOverflowBlocked(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
        )}
        <MobileMain>
          {isLoading && <LoadingCircle />}

          <MobileInputsWrapper>
            <MobileInputWrapper>
              <p>Login</p>
              <input
                type="text"
                placeholder="Wprowadź"
                value={loginData.login}
                onChange={handleLoginDataChange}
                id="login"
              />
              {errorVerification.loginError !== null && (
                <ErrorStar fixed text={errorVerification.loginError} />
              )}
            </MobileInputWrapper>
            <MobileInputWrapper>
              <p>Hasło</p>
              <input
                placeholder="Wprowadź"
                type={showPassword ? 'text' : 'password'}
                value={loginData.password}
                onChange={handleLoginDataChange}
                id="password"
              />
              {errorVerification.passwordError !== null && (
                <ErrorStar fixed text={errorVerification.passwordError} />
              )}
            </MobileInputWrapper>
          </MobileInputsWrapper>
          <PrimaryButton
            style={{justifyContent: 'center'}}
            ref={loginButtonRef}
            onClick={() => logIn()}
          >
            {isLoading ? 'Logowanie...' : 'Zaloguj'}
          </PrimaryButton>
          {/* <PrimaryButton
            background="#FFFFFF"
            color="#07020F"
            borderColor="rgba(112,112,112,0.3)"
            onClick={loginWithFacebook}
          >
            <GoogleFacebookAppleLogoWrapper>
              <svg
                style={{borderRadius: '36px', background: '#0062e0'}}
                id="Facebook_f_logo__2021_"
                data-name="Facebook_f_logo_(2021)"
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <defs>
                  <linearGradient
                    id="linear-gradient"
                    x1="-6.934"
                    y1="10.216"
                    x2="-6.934"
                    y2="10.241"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stopColor="#0062e0" />
                    <stop offset="1" stopColor="#19afff" />
                  </linearGradient>
                </defs>
                <path
                  id="Path_3758"
                  data-name="Path 3758"
                  d="M11.69,27.86A14.028,14.028,0,0,1,14,0a14.028,14.028,0,0,1,2.31,27.86l-.77-.63H12.46Z"
                  fill="url(#linear-gradient)"
                />
                <path
                  id="Path_3759"
                  data-name="Path 3759"
                  d="M23,20.26l.63-3.92H19.92V13.61c0-1.12.42-1.96,2.1-1.96h1.82V8.08a22.893,22.893,0,0,0-3.08-.28,5.072,5.072,0,0,0-5.46,5.46v3.08H11.8v3.92h3.5v9.87a12.81,12.81,0,0,0,4.62,0V20.26Z"
                  transform="translate(-3.54 -2.34)"
                  fill="#fff"
                />
              </svg>
            </GoogleFacebookAppleLogoWrapper>
            Zaloguj się przez Facebook
          </PrimaryButton> */}
          {/* <PrimaryButton
            background="#FFFFFF"
            color="#07020F"
            borderColor="rgba(112,112,112,0.3)"
            onClick={loginWithGoogle}
          >
            <GoogleFacebookAppleLogoWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.809"
                height="19.194"
                viewBox="0 0 18.809 19.194"
              >
                <g id="Google__G__Logo" transform="translate(1184.577 765.171)">
                  <path
                    id="Path_3760"
                    data-name="Path 3760"
                    d="M-1089.33-687.239v3.717h5.165a4.425,4.425,0,0,1-1.928,2.888l3.115,2.417a9.376,9.376,0,0,0,2.861-7.058,11.028,11.028,0,0,0-.174-1.963Z"
                    transform="translate(-85.65 -70.08)"
                    fill="#4285f4"
                  />
                  <path
                    id="Path_3761"
                    data-name="Path 3761"
                    d="M-1171.172-651.791l-.7.538-2.486,1.937h0a9.6,9.6,0,0,0,8.567,5.3,9.16,9.16,0,0,0,6.351-2.321l-3.115-2.417a5.717,5.717,0,0,1-3.237.925,5.729,5.729,0,0,1-5.374-3.952Z"
                    transform="translate(-9.187 -101.956)"
                    fill="#34a853"
                  />
                  <path
                    id="Path_3762"
                    data-name="Path 3762"
                    d="M-1183.548-712.61a9.48,9.48,0,0,0-1.029,4.3,9.48,9.48,0,0,0,1.029,4.3c0,.009,3.193-2.478,3.193-2.478a5.748,5.748,0,0,1-.306-1.823,5.749,5.749,0,0,1,.306-1.823Z"
                    transform="translate(0 -47.265)"
                    fill="#fbbc05"
                  />
                  <path
                    id="Path_3763"
                    data-name="Path 3763"
                    d="M-1165.794-761.35a5.236,5.236,0,0,1,3.673,1.431l2.748-2.748a9.207,9.207,0,0,0-6.421-2.5,9.58,9.58,0,0,0-8.568,5.3l3.193,2.478A5.729,5.729,0,0,1-1165.794-761.35Z"
                    transform="translate(-9.186 0)"
                    fill="#ea4335"
                  />
                </g>
              </svg>
            </GoogleFacebookAppleLogoWrapper>
            Zaloguj się przez Google
          </PrimaryButton> */}
          {/* <PrimaryAppleButton onClick={loginWithApple}>
            <GoogleFacebookAppleLogoWrapper>
              <svg
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="36px"
                height="36px"
              >
                {' '}
                <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z" />
              </svg>
            </GoogleFacebookAppleLogoWrapper>
            Zaloguj się przez Apple
          </PrimaryAppleButton> */}
          <Link to={'/identity/recover'}>Nie pamiętasz hasła?</Link>
          <Link to={'/identity/register'}>Zarejestruj się</Link>
          <Link to={'/identity/recover/email'}>
            Przyślij ponownie e-mail potwierdzający
          </Link>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default LoginComponent;
