import React from 'react';
import {Navigate} from 'react-router-dom';

const ProtectedRoute = ({accessGranted, children}) => {
  if (!accessGranted) {
    return <Navigate to="/Protectedlogin" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
