import {Select} from 'components/Select';
import React, {useCallback, useEffect, useState} from 'react';
import {
  BlueDataText,
  CheckBox,
  InfoText,
  PayCheckOptionWrapper,
  PriceWrapper,
  PrimaryButton,
  ProMessageContentWrapper,
  ProMessageHeader,
  ProMessageTitle,
  ProMessageWapper,
  BackButton,
} from './styles';
import api from '../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import useAlert from '../../../../hooks/useAlert';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {ErrorStar} from '../../../../components/ErrorStar';
import {LoadingCircle} from 'utils/loadingCircle';
import payWithCard from 'images/payWithCard.png';

import {StyledSelectView} from 'pages/MyStudio/Account';
import {InputView} from 'pages/MyAccount/Settings/styles';

const dataTypeArr = [
  {text: '1 miesiąc (20PLN)', value: 0},
  // {text: 'test', value: 1},
  // {text: 'test1', value: 2},
  // {text: 'test2', value: 3},
];
const dataTypeObj = {
  0: {text: '1 miesiąc (20PLN)', value: 0},
  // 1: {text: 'test', value: 1},
  // 2: {text: 'test1', value: 2},
  // 3: {text: 'test2', value: 3},
};

interface Order {
  Id: string;
  Price: string | number;
  Time: string | number;
  Name: string;
}
interface Props {
  OrderData: Order;
  close: () => void;
  KYCLevel: string;
}

export const ProMessage: React.FC<Props> = ({OrderData, close, KYCLevel}) => {
  const [cookies, setCookie] = useCookies(['token']);
  const [price, setPrice] = useState<string | number>(OrderData.Price);
  const [isError, setIsError] = useState(false);
  const [confirmedCoupon, setConfirmedCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPayP24Loading, setIsPayP24Loading] = useState<boolean>(false);
  const [isPayTransferLoading, setIsPayTransferLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const {Alert} = useAlert();

  const [data, setData] = useState({
    text: `1 miesiąc (${Number(OrderData.Price) / 100}PLN)`,
    value: 0,
  });
  const [paymentMethod, setPayMentMethod] = useState('przelewy24');
  const [coupon, setCoupon] = useState('');

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
    setIsError(false);
  };

  const handlePaymentMethod = (v) => setPayMentMethod(v);

  const handleDataChange = (value) => setData(value);

  const getPriceWithCoupon = async (coupon) => {
    if (coupon.lenght < 2) return;
    try {
      const body = {
        planId: OrderData.Id,
        duration: 30,
        coupon: coupon,
      };

      const respons = await api.get(`Coupon`, {
        headers: {Authorization: `bearer ${cookies.token}`},
        params: body,
      });

      if (respons.data.Response.PriceAfterDiscount !== null) {
        setPrice(respons.data.Response.PriceAfterDiscount);
        setConfirmedCoupon(true);
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
      setConfirmedCoupon(false);
      console.error(error);
    }
  };

  const getPriceWithCouponDebounce = useCallback(
    _.debounce((coupon) => getPriceWithCoupon(coupon), 2000),
    [],
  );

  const payWithPay24 = async () => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        PlanId: OrderData.Id,
        Coupon: coupon,
      };
      const respons = await api.post(
        'MangopayTransaction/BuyPlanCardPayIn',
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      close();
      window.location.href = respons.data.Response.RedirectUrl;
    } catch (error) {
      console.error(error);
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const payWithMugoWallet = async () => {
    if (isPayTransferLoading) return;
    setIsPayTransferLoading(true);
    try {
      const body = {
        PlanId: OrderData.Id,
        Coupon: coupon,
      };
      const respons = await api.post(
        'MangopayTransaction/BuyPlanTransfer',
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );

      Alert('Dziękujemy za zakup', 'Komunikat', 'Zamknij');
      navigate('/transactions');
      // close();
      // window.location.href = respons.data.Response.RedirectUrl;
    } catch (error) {
      console.error(error);

      if (
        error.response.data.Error.Message ===
        'Insufficient funds in the wallet.'
      ) {
        Alert('Za mało środków w portfelu.', 'Komunikat');
        close();
      } else {
        Alert(
          'Wystąpił problem z twoim konte mangopay. Sprawdź zakładke konto czy status konta jest "Zaakceptowany"',
          'Komunikat',
        );
        close();
      }
    } finally {
      setIsPayTransferLoading(false);
    }
  };

  const pay = () => {
    if (paymentMethod === 'przelewy24') {
      return payWithPay24();
    } else if (paymentMethod === 'mugo') {
      return payWithMugoWallet();
    }
  };

  const today = new Date();
  const todayPlus30Days = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);

  const dd = String(todayPlus30Days.getDate()).padStart(2, '0');
  const mm = String(todayPlus30Days.getMonth() + 1).padStart(2, '0');
  const yyyy = todayPlus30Days.getFullYear();

  const formattedDate = `${dd}-${mm}-${yyyy}`;

  useEffect(() => {
    if (coupon.length > 1) {
      getPriceWithCouponDebounce(coupon);
    }
  }, [coupon]);

  return (
    <ProMessageWapper>
      {(isPayP24Loading || isPayTransferLoading) && <LoadingCircle />}
      <ProMessageHeader>
        <BackButton onClick={close}>
          <svg
            id="Arrow_Top_Bar_Accent"
            data-name="Arrow – Top Bar / Accent"
            xmlns="http://www.w3.org/2000/svg"
            width="8.195"
            height="14"
            viewBox="0 0 8.195 14"
          >
            <path
              id="Arrow"
              d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
              transform="translate(0 0)"
              fill="#fff"
            />
          </svg>
        </BackButton>
        <h2>Zamówienie</h2>
      </ProMessageHeader>
      <ProMessageContentWrapper>
        <ProMessageTitle>
          <p>Produkt/usługa:</p>
          <p style={{fontWeight: '600'}}>Dostęp do konta {OrderData.Name}</p>
        </ProMessageTitle>
        {/* <Select
          customStyles={{
            background: 'transparent',
            width: '312px',
            margin: '0 auto',
          }}
          selectWidth={'100%'}
          title="Okres"
          isEditing={true}
          value={data}
          values={[
            {text: `1 miesiąc (${Number(OrderData.Price) / 100}PLN)`, value: 0},
          ]}
          onChange={(v) => handleDataChange(v)}
        /> */}
        <StyledSelectView style={{width: '360px'}} isEditing={true}>
          <p>Okres</p>
          <select value={data.value} onChange={(v) => handleDataChange(v)}>
            {' '}
            <option hidden value={''}>
              Wybierz
            </option>
            <option value={0}>
              {`1 miesiąc (${Number(OrderData.Price) / 100}PLN)`}
            </option>
          </select>
        </StyledSelectView>
        <BlueDataText>Do dnia {formattedDate}</BlueDataText>
        <InfoText>
          Sprawdź inne dostępne okresy, skorzystaj z subskrypcji dzięki temu nie
          tracisz możliwości jakie daje konto.
        </InfoText>
        <PriceWrapper>
          <p>RAZEM</p>
          <h4>{(Number(price) / 100).toFixed(2).replace('.', ',')}zł</h4>
        </PriceWrapper>
        <InputView style={{width: '360px'}} isEditing={true} isError={isError}>
          <p>Kod promocyjny </p>
          <input
            style={confirmedCoupon ? {background: '#19D627'} : null}
            value={coupon}
            disabled={confirmedCoupon}
            onChange={(e) => handleCouponChange(e)}
            placeholder="Uzupełnij"
          />
          {isError ? (
            <ErrorStar text={'Podany kupon jest nieprawidłowy'} />
          ) : null}
        </InputView>
        <PriceWrapper>
          <p>Kwota do zapłaty</p>
          <h4>{(Number(price) / 100).toFixed(2).replace('.', ',')}zł</h4>
        </PriceWrapper>
        <PayCheckOptionWrapper
          isActive={paymentMethod === 'mugo'}
          onClick={
            KYCLevel === 'REGULAR' ? () => handlePaymentMethod('mugo') : null
          }
          style={
            KYCLevel === 'REGULAR'
              ? null
              : {cursor: 'default', background: '#dce0e9'}
          }
        >
          <CheckBox
            isActive={paymentMethod === 'mugo'}
            style={KYCLevel === 'REGULAR' ? null : {cursor: 'default'}}
          >
            {' '}
            {paymentMethod === 'mugo' ? <span></span> : null}
          </CheckBox>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="93"
            height="37"
            viewBox="0 0 93 37"
          >
            <g
              id="Group_4379"
              data-name="Group 4379"
              transform="translate(-220 -1082)"
            >
              <text
                id="_Value"
                data-name="↳ Value"
                transform="translate(220 1098)"
                fill="#647b9c"
                fontSize="15"
                fontFamily="SegoeUI, Segoe UI"
                letterSpacing="-0.005em"
              >
                <tspan x="0" y="0">
                  Portfel
                </tspan>
                <tspan x="0" y="15">
                  MUGO
                </tspan>
              </text>
              <path
                id="Icon_awesome-coins"
                data-name="Icon awesome-coins"
                d="M0,28.5v3C0,33.982,6.047,36,13.5,36S27,33.982,27,31.5v-3c-2.9,2.046-8.212,3-13.5,3S2.9,30.544,0,28.5ZM22.5,9C29.953,9,36,6.982,36,4.5S29.953,0,22.5,0,9,2.018,9,4.5,15.047,9,22.5,9ZM0,21.122V24.75c0,2.482,6.047,4.5,13.5,4.5S27,27.232,27,24.75V21.122c-2.9,2.391-8.22,3.628-13.5,3.628S2.9,23.513,0,21.122Zm29.25.773C33.279,21.115,36,19.666,36,18V15a17.267,17.267,0,0,1-6.75,2.426ZM13.5,11.25C6.047,11.25,0,13.767,0,16.875S6.047,22.5,13.5,22.5,27,19.983,27,16.875,20.953,11.25,13.5,11.25Zm15.42,3.959c4.219-.759,7.08-2.25,7.08-3.959v-3c-2.5,1.765-6.785,2.714-11.3,2.939A7.874,7.874,0,0,1,28.92,15.209Z"
                transform="translate(277 1083)"
                fill="#b3b2b1"
              />
            </g>
          </svg>
        </PayCheckOptionWrapper>
        <PayCheckOptionWrapper
          isActive={paymentMethod === 'przelewy24'}
          onClick={() => handlePaymentMethod('przelewy24')}
        >
          <CheckBox isActive={paymentMethod === 'przelewy24'}>
            {' '}
            {paymentMethod === 'przelewy24' ? <span></span> : null}
          </CheckBox>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112.666"
            height="38"
            viewBox="0 0 112.666 38"
          >
            <g id="przelewy24_logo_2022" transform="translate(-12.7 -16.586)">
              <path
                id="Path_3716"
                data-name="Path 3716"
                d="M373.483,384.712l9.3-.012-.339,2.117-7.778,7.427,6.529-.012-.381,2.283-9.615.006.4-2.361,7.457-7.142h-5.952Z"
                transform="translate(-337.182 -346.225)"
                fill="#d13239"
                fillRule="evenodd"
              />
              <path
                id="Path_3717"
                data-name="Path 3717"
                d="M26.127,309.046a2.327,2.327,0,0,0-.815-.511,5.92,5.92,0,0,0-1.082-.291,9.158,9.158,0,0,0-1.237-.131c-.3-.012-.488-.012-.488-.012H15.412L12.7,324.476h2.533l1-6.065,4.912.024a7.518,7.518,0,0,0,3.271-.642,3.85,3.85,0,0,0,1.719-2.355s.149-.607.274-1.344c.137-.815.274-1.79.345-2.307.03-.226.048-.369.048-.369a2.25,2.25,0,0,0,.036-.428,2.926,2.926,0,0,0-.333-1.433A2.353,2.353,0,0,0,26.127,309.046Zm-1.95,2.89c0,.03-.226,1.344-.523,2.9a1.308,1.308,0,0,1-1.094,1.029,8.434,8.434,0,0,1-1.8.149l-4.1-.012.9-5.441,3.716.012s.25-.006.6,0a12.131,12.131,0,0,1,1.362.083A1.374,1.374,0,0,1,24,310.9a1.009,1.009,0,0,1,.19.535A2.232,2.232,0,0,1,24.177,311.935Z"
                transform="translate(0 -274.18)"
                fill="#d13239"
                fillRule="evenodd"
              />
              <path
                id="Path_3718"
                data-name="Path 3718"
                d="M734.835,308.1h2.438l-2.729,16.376-2.444-.006Z"
                transform="translate(-676.622 -274.18)"
                fill="#d13239"
                fillRule="evenodd"
              />
              <path
                id="Path_3719"
                data-name="Path 3719"
                d="M996.9,384.618l2.337-.006.827,8.313,3.6-8.325,2.884.006.85,8.349,3.591-8.343h2.426l-5.15,11.8h-2.878l-.833-8.265-3.633,8.265-2.831.012Z"
                transform="translate(-925.676 -346.131)"
                fill="#d13239"
                fillRule="evenodd"
              />
              <g
                id="Group_4376"
                data-name="Group 4376"
                transform="translate(44.642 38.325)"
              >
                <path
                  id="Path_3720"
                  data-name="Path 3720"
                  d="M558.934,382.429a10.664,10.664,0,0,0-2.872-.256,8.9,8.9,0,0,0-1.778.137,4.748,4.748,0,0,0-2.706,1.463,9.386,9.386,0,0,0-1.273,3.865,18.273,18.273,0,0,0-.41,3.913,2.612,2.612,0,0,0,1.582,2.307,5.86,5.86,0,0,0,2.046.4,13.517,13.517,0,0,0,3.461-.2,4.242,4.242,0,0,0,2.272-1.4,5.894,5.894,0,0,0,.6-.987,4.717,4.717,0,0,0,.339-.958l.155-.636-2.527.006s-.137,1.671-1.5,1.826a12.826,12.826,0,0,1-2.361.083c-.262-.012-1.725.054-1.606-1.172a.424.424,0,0,1,.006-.077,6.323,6.323,0,0,1,.22-1.754l7.938-.024.339-1.962C561.247,384.79,560.967,383.1,558.934,382.429Zm-.529,4.305-5.441-.006.214-.862a2.166,2.166,0,0,1,.565-.963,2.5,2.5,0,0,1,1.314-.381,9.945,9.945,0,0,1,2.64.071.9.9,0,0,1,.719.523A4.12,4.12,0,0,1,558.4,386.734Z"
                  transform="translate(-549.863 -382.172)"
                  fill="#d13239"
                />
                <path
                  id="Path_3721"
                  data-name="Path 3721"
                  d="M591.88,526.9c0,.018-.006.036-.006.054C591.868,527.046,591.88,526.891,591.88,526.9Z"
                  transform="translate(-589.374 -518.291)"
                  fill="#d13239"
                />
              </g>
              <g
                id="Group_4377"
                data-name="Group 4377"
                transform="translate(59.621 38.331)"
              >
                <path
                  id="Path_3722"
                  data-name="Path 3722"
                  d="M843.706,527c0,.018-.006.036-.006.054C843.7,527.146,843.706,526.991,843.706,527Z"
                  transform="translate(-841.206 -518.391)"
                  fill="#d13239"
                />
                <path
                  id="Path_3723"
                  data-name="Path 3723"
                  d="M810.834,382.529a10.663,10.663,0,0,0-2.872-.256,8.9,8.9,0,0,0-1.778.137,4.748,4.748,0,0,0-2.706,1.463,9.386,9.386,0,0,0-1.273,3.865,18.272,18.272,0,0,0-.41,3.913,2.611,2.611,0,0,0,1.582,2.307,5.861,5.861,0,0,0,2.046.4,13.519,13.519,0,0,0,3.461-.2,4.242,4.242,0,0,0,2.271-1.4,5.9,5.9,0,0,0,.6-.987,4.72,4.72,0,0,0,.339-.957l.155-.636-2.527.006s-.137,1.671-1.5,1.826a13.743,13.743,0,0,1-2.361.089c-.262-.012-1.725.048-1.606-1.171a.42.42,0,0,1,.006-.077,6.322,6.322,0,0,1,.22-1.754l7.938-.024.339-1.962C813.147,384.89,812.867,383.189,810.834,382.529Zm-.553,4.3-5.441-.006.214-.862a2.166,2.166,0,0,1,.565-.963,2.488,2.488,0,0,1,1.314-.381,9.942,9.942,0,0,1,2.64.071.9.9,0,0,1,.719.523A4.127,4.127,0,0,1,810.281,386.828Z"
                  transform="translate(-801.763 -382.272)"
                  fill="#d13239"
                />
              </g>
              <path
                id="Path_3724"
                data-name="Path 3724"
                d="M1266.779,384.806l1.564,8.634,4.418-8.64,2.486.024-6.369,12.339a13.033,13.033,0,0,1-1.867,2.8,3.243,3.243,0,0,1-1.736.88,3.979,3.979,0,0,1-1.38,0l-.595-.107.369-2.206a3.581,3.581,0,0,0,1.57-.048c.589-.232,1.058-1.231,1.058-1.231l.3-.5-2.3-11.952,2.486.006Z"
                transform="translate(-1176.235 -346.319)"
                fill="#d13239"
                fillRule="evenodd"
              />
              <path
                id="Path_3725"
                data-name="Path 3725"
                d="M1451.372,310.686l2.563.006.155-.993s.273-1.8.886-2.141a2.9,2.9,0,0,1,.874-.279,11.466,11.466,0,0,1,2.218-.107,24.846,24.846,0,0,1,2.551.172.9.9,0,0,1,.809,1.171l-.214,1.564a3.8,3.8,0,0,1-.345,1.136,2.235,2.235,0,0,1-1.178.761c-.844.273-3.734,1.011-3.734,1.011l-2.271.654a5.412,5.412,0,0,0-2.177,1.267,5.208,5.208,0,0,0-1.2,2.361c-.107.511-.708,4.05-.708,4.05l12.261.006.41-2.45-9.7.012.172-1s.113-1.029.529-1.368a2.1,2.1,0,0,1,.969-.523c.464-.166,2.045-.589,2.045-.589l3.657-1a5.808,5.808,0,0,0,2.783-1.605,8.929,8.929,0,0,0,1.088-3.175,12.91,12.91,0,0,0,.048-2.664,2.848,2.848,0,0,0-1.492-1.724,7.922,7.922,0,0,0-3.7-.488,12.49,12.49,0,0,0-4.419.547,3.536,3.536,0,0,0-2.176,2.218,15.285,15.285,0,0,0-.708,3.169Z"
                transform="translate(-1351.456 -271.028)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3726"
                data-name="Path 3726"
                d="M1689.933,318.3l1.719-10.3h-3.045l-9.478,10.18-.428,2.581h8.289l-.606,3.615,2.545.006.6-3.621,2.349.006.422-2.468Zm-2.533.006-5.358-.006,6.523-6.951Z"
                transform="translate(-1566.933 -274.086)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3727"
                data-name="Path 3727"
                d="M328.9,224.331h7.808s1.748-1.415,2.991-2.325,3.5-2.343,3.5-2.343l-4.412-2.063s-3.728,2.307-5.31,3.4c-1.534,1-4.579,3.336-4.579,3.336Z"
                transform="translate(-297.398 -189.061)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3728"
                data-name="Path 3728"
                d="M553.821,93.4,550.2,90.959a68.455,68.455,0,0,1,7.635-3.6c4.353-1.736,6.678-2.462,6.678-2.462l.737,3.455s-4.192,1.4-6.595,2.539a42,42,0,0,0-4.834,2.5Z"
                transform="translate(-505.538 -64.252)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3729"
                data-name="Path 3729"
                d="M838.33,25.712l-.63-3.538s4.472-1.189,8.563-1.95,9.526-1.124,9.526-1.124l-1.8,5.465a39.371,39.371,0,0,0-9.259-.042,51.959,51.959,0,0,0-6.4,1.189Z"
                transform="translate(-775.942 -2.365)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3730"
                data-name="Path 3730"
                d="M1159.5,22.664l3.021-6.071a83.522,83.522,0,0,1,12.327.755,84.656,84.656,0,0,1,10.816,2.3l-14.474,7.57a36.032,36.032,0,0,0-7.581-3.455c-2.379-.7-4.109-1.094-4.109-1.094Z"
                transform="translate(-1078.607 0)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3731"
                data-name="Path 3731"
                d="M1399.7,112.393l3.182,2.414h26.146a5.2,5.2,0,0,0-.749-2.046,13.284,13.284,0,0,0-2.051-2.373,22.736,22.736,0,0,0-2.408-1.826,37.936,37.936,0,0,0-5.994-3.163l-18.125,6.993Z"
                transform="translate(-1304.523 -83.533)"
                fill="#b3b2b1"
                fillRule="evenodd"
              />
              <path
                id="Path_3732"
                data-name="Path 3732"
                d="M242.843,384.7a5.726,5.726,0,0,0-2.7.821l.137-.821h-2.6L235.6,396.456h2.6l1.154-6.511a3.328,3.328,0,0,1,3.152-2.955l1.344-.006.4-2.283Z"
                transform="translate(-209.645 -346.225)"
                fill="#d13239"
              />
            </g>
          </svg> */}
          <img src={payWithCard} alt="CardLogo" />
        </PayCheckOptionWrapper>
      </ProMessageContentWrapper>
      <PrimaryButton onClick={pay}>
        <h4>Zamawiam i płacę</h4>
        <p>
          Klikając w ten przycisk potwierdzasz zamówienie, a Twórca otrzyma od
          Ciebie zamówienie
        </p>
      </PrimaryButton>
    </ProMessageWapper>
  );
};
