import React, {useEffect, useState, useCallback, useContext} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import api from 'services/axiosConfig';
import coverImage from 'images/digital-product-cover.png';
import winWinIcon from 'images/svg/win-win-icon.svg';
import {Offer2} from '../index';
import {WinWinSvg} from 'components/SvgComponents';
import {useCookies} from 'react-cookie';
import {UserDataContext} from 'contexts/UserDataContext';
import useAlert from 'hooks/useAlert';
import {
  BottomSection,
  CoverWithDescriptionContainer,
  DesktopBottomSection,
  DesktopCoverWithNameWrapper,
  DesktopNameWithCategoriesWrapper,
  DesktopPriceWithTitleWrapper,
  DesktopPriceWithWinWinWrapper,
  DesktopTopSection,
  DesktopWinWinInfo,
  MobileCoverWithDescriptionContainer,
  MobileCoverWithNameWrapper,
  MobileNameWithCategoriesWrapper,
  MobileSingleProductWithViewsCountWrapper,
  MobileSingleProductWrapper,
  MobileTextContentWrapper,
  MobileViewsCount,
  PriceWithTitleWrapper,
  PriceWithWinWinWrapper,
  ProducerDataWrapper,
  ProducerImage,
  SingleProductWrapper,
  StyledMobileLink,
  SvgRightTopWrapper,
  TopSection,
  WinWinInfo,
} from './styles';
import {MobileViewsAndPublishedWrapper} from 'pages/Tracks/SingleDigitalProduct/styles';

interface ProductOption {
  FieldName: string;
  FlagValue: number;
}

interface Props {
  offer: Offer2;
  categories: Category[];
  roles: Role[];
  mobileRef?: any;
  desktopRef?: any;
}

interface Category {
  Description: string;
  Id: string;
  Name: string;
  ParentCategoryId: string | null;
  Position: number;
  ProductType: number;
}

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export const getSpecificRole = (roles: Role[], selectedRole: number) => {
  if (!roles || (!selectedRole && selectedRole !== 0)) return 0;
  return roles.filter((role) => role.FlagValue === selectedRole).length > 0
    ? roles.filter((role) => role.FlagValue === selectedRole)[0].FieldName
    : 'Nie znaleziono';
};

const SingleProducts: React.FC<Props> = ({
  offer,
  categories,
  roles,
  desktopRef,
  mobileRef,
}) => {
  const {
    AvailabilityEndDate,
    AvailabilityStartDate,
    Category,
    SecondCategoryId,
    ThirdCategoryId,
    CoverFile,
    Description,
    Featured,
    GrossValue,
    Id,
    IsWinWin,
    Name,
    NegotiatedOffer,
    ProducerId,
    ProducerName,
    ProductDemoFile,
    ProductId,
    ProductType,
    RightsEnd,
    Tags,
    Currency,
    ViewsInfo,
    ProducerCoverFile,
    ProducerBuildingNumber,
    ProducerCountry,
    ProducerFirstName,
    ProducerFlatNumber,
    ProducerPostalCode,
    ProducerRegion,
    ProducerRole,
    ProducerSecondName,
    ProducerSecondaryRole,
    ProducerStreet,
    Favourite,
    VerifiedRole,
    VerifiedSecondaryRole,
  } = offer;
  const [firstCategory, setFirstCategory] = useState<Category>(
    categories.filter(
      (singleCategory) => singleCategory.Id === Category?.CategoryId,
    )[0] || {
      Description: '',
      Id: '',
      Name: '',
      ParentCategoryId: '',
      Position: 0,
      ProductType: 0,
    },
  );
  const [secondCategory, setSecondCategory] = useState<Category>(
    categories.filter(
      (singleCategory) => singleCategory.Id === SecondCategoryId?.CategoryId,
    )[0] || {
      Description: '',
      Id: '',
      Name: '',
      ParentCategoryId: '',
      Position: 0,
      ProductType: 0,
    },
  );
  const [thirdCategory, setThirdCategory] = useState<Category>(
    categories.filter(
      (singleCategory) => singleCategory.Id === ThirdCategoryId?.CategoryId,
    )[0] || {
      Description: '',
      Id: '',
      Name: '',
      ParentCategoryId: '',
      Position: 0,
      ProductType: 0,
    },
  );

  const [isFollow, setIsFollow] = useState<boolean | null>(Favourite);

  const [userData, setUserData] = useContext(UserDataContext);

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const {Alert} = useAlert();

  console.log('favorite:', offer.Favourite, offer.Name);

  const unFollowOffer = async (id: string) => {
    try {
      const response = await api.delete(`Offer/favourites/${id}`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const followOffer = async (id: string) => {
    try {
      const response = await api.post(
        `Offer/favourites/${id}`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const follow = () => {
    if (userData.FirstName === null || userData.FirstName === '') {
      return Alert(
        'Aby korzytać z funkcji obserwacji, należy się zalogować',
        'Komunikat',
      );
    }

    if (isFollow === false || isFollow === null) {
      setIsFollow(true);
      followOffer(Id);
    }

    if (isFollow === true) {
      setIsFollow(false);
      unFollowOffer(Id);
    }

    // setIsFollow((prevState) => !prevState);
  };

  const getFormattedDate = (date: string) => {
    if (!date) return;
    const availabilityEndDate = new Date(date);
    const year = availabilityEndDate.getFullYear();
    let month: string | number = availabilityEndDate.getMonth() + 1;
    let day: string | number = availabilityEndDate.getDate();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;

    return `${day}.${month}.${year}`;
  };

  return (
    <>
      <Link
        ref={desktopRef}
        to={`/products/main/${ProductId}`}
        style={{textDecoration: 'none'}}
      >
        {/* <SingleProductWithViewsCountWrapper> */}
        <SingleProductWrapper>
          <DesktopTopSection>
            <ProducerImage src={ProducerCoverFile} />
            <ProducerDataWrapper>
              {/* {Featured && <PromotionText>Promowany</PromotionText>} */}
              <h4>
                {ProducerName
                  ? ProducerName
                  : `${ProducerFirstName} ${ProducerSecondName}`}
              </h4>
              <p>
                <p>{`${getSpecificRole(roles, offer.ProducerRole)} 
                
                ${
                  offer.ProducerSecondaryRole
                    ? `• ${getSpecificRole(roles, offer.ProducerSecondaryRole)}`
                    : ''
                }
                
               `}</p>
              </p>
              <p>{offer.ProducerRegion}</p>
            </ProducerDataWrapper>
            <SvgRightTopWrapper>
              {/* {VerifiedSecondaryRole && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.403"
                  height="9"
                  viewBox="0 0 9.403 9"
                >
                  <path
                    id="Icon_awesome-star"
                    data-name="Icon awesome-star"
                    d="M5.639.312,4.491,2.639l-2.568.374a.563.563,0,0,0-.311.96L3.47,5.784,3.03,8.341a.562.562,0,0,0,.816.592l2.3-1.207,2.3,1.207a.563.563,0,0,0,.816-.592L8.816,5.784l1.858-1.81a.563.563,0,0,0-.311-.96L7.8,2.639,6.647.312A.563.563,0,0,0,5.639.312Z"
                    transform="translate(-1.441 0.001)"
                    opacity="0.7"
                  />
                </svg>
              )} */}
              {VerifiedRole && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.811"
                  height="10.5"
                  viewBox="0 0 12.811 10.5"
                >
                  <g
                    id="Icon_feather-user-check"
                    data-name="Icon feather-user-check"
                    transform="translate(-0.75 -3.75)"
                    opacity="0.7"
                  >
                    <path
                      style={{fill: 'none'}}
                      id="Path_4196"
                      data-name="Path 4196"
                      d="M9,25.5v-1a2,2,0,0,0-2-2H3.5a2,2,0,0,0-2,2v1"
                      transform="translate(0 -12)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      style={{fill: 'none'}}
                      id="Path_4197"
                      data-name="Path 4197"
                      d="M10.75,6.5a2,2,0,1,1-2-2A2,2,0,0,1,10.75,6.5Z"
                      transform="translate(-3.5)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      style={{fill: 'none'}}
                      id="Path_4198"
                      data-name="Path 4198"
                      d="M25.5,14.5l1,1,2-2"
                      transform="translate(-16 -6)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              )}
            </SvgRightTopWrapper>
          </DesktopTopSection>
          <DesktopBottomSection>
            <CoverWithDescriptionContainer>
              <DesktopCoverWithNameWrapper>
                <img src={CoverFile} />
                <DesktopNameWithCategoriesWrapper>
                  <h4>
                    {Name}
                    {Featured && (
                      <svg
                        style={{marginLeft: '8px'}}
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.727"
                        height="14"
                        viewBox="0 0 12.727 14"
                      >
                        <path
                          id="Path_4195"
                          data-name="Path 4195"
                          d="M13.7,9.765V5.625H12.432L8.614,8.807H3.523L2.25,9.443V13.9l1.273.636L6.7,19.625H8.614L7.42,14.534H8.614l3.818,2.545H13.7v-4.14a1.507,1.507,0,0,0,1.273-1.587A1.507,1.507,0,0,0,13.7,9.765Z"
                          transform="translate(-2.25 -5.625)"
                          fill="#0091ff"
                        />
                      </svg>
                    )}
                  </h4>
                  <p>{`${firstCategory.Name && firstCategory.Name}${
                    secondCategory.Name && ` • ${secondCategory.Name}`
                  }${thirdCategory.Name && ` • ${thirdCategory.Name}`}`}</p>
                  <h5>{Description}</h5>
                </DesktopNameWithCategoriesWrapper>
                <DesktopPriceWithWinWinWrapper>
                  <DesktopPriceWithTitleWrapper>
                    <DesktopWinWinInfo IsWinWin={IsWinWin}>
                      <WinWinSvg />
                    </DesktopWinWinInfo>
                    <h4>
                      <span>od </span>
                      {GrossValue ? (
                        <>
                          {`${new Intl.NumberFormat('de-DE').format(
                            +GrossValue / 100,
                          )} zł`}
                        </>
                      ) : (
                        <>0 zł</>
                      )}
                    </h4>
                  </DesktopPriceWithTitleWrapper>
                  <MobileViewsCount
                    isFollow={isFollow}
                    onClick={(event) => {
                      event.preventDefault();
                      follow();
                    }}
                  >
                    {`${
                      ViewsInfo.ViewsCount
                    }  wyświetleń  •  opublikowano  ${getFormattedDate(
                      AvailabilityStartDate,
                    )}`}
                    {isFollow ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="14"
                        viewBox="0 0 21 14"
                      >
                        <path
                          id="Icon_material-playlist-add-check"
                          data-name="Icon material-playlist-add-check"
                          d="M15,13H3v2H15Zm0-4H3v2H15ZM3,19h8V17H3Zm19.5-4.5L24,16l-6.99,7L12.5,18.5,14,17l3.01,3Z"
                          transform="translate(-3 -9)"
                          fill="#0091ff"
                        />
                      </svg>
                    ) : (
                      <svg
                        // onClick={(event) => follow(event)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="14"
                        viewBox="0 0 20 14"
                      >
                        <path
                          id="Icon_material-playlist-add"
                          data-name="Icon material-playlist-add"
                          d="M15,13H3v2H15Zm0-4H3v2H15Zm4,8V13H17v4H13v2h4v4h2V19h4V17ZM3,19h8V17H3Z"
                          transform="translate(-3 -9)"
                          fill="#fff"
                        />
                      </svg>
                    )}
                  </MobileViewsCount>
                </DesktopPriceWithWinWinWrapper>
              </DesktopCoverWithNameWrapper>
            </CoverWithDescriptionContainer>
          </DesktopBottomSection>
        </SingleProductWrapper>
        {/* </SingleProductWithViewsCountWrapper> */}
      </Link>

      <StyledMobileLink ref={mobileRef} to={`/products/main/${ProductId}`}>
        <MobileSingleProductWithViewsCountWrapper>
          <MobileSingleProductWrapper>
            <TopSection>
              <ProducerImage src={ProducerCoverFile} />
              <ProducerDataWrapper>
                <h4>
                  {' '}
                  {ProducerName
                    ? ProducerName
                    : `${ProducerFirstName} ${ProducerSecondName}`}
                  {VerifiedSecondaryRole && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.358"
                      height="8"
                      viewBox="0 0 8.358 8"
                    >
                      <path
                        id="Icon_awesome-star"
                        data-name="Icon awesome-star"
                        d="M5.172.277,4.152,2.346l-2.283.333a.5.5,0,0,0-.277.853L3.244,5.141,2.854,7.414a.5.5,0,0,0,.725.526L5.621,6.867,7.662,7.941a.5.5,0,0,0,.725-.526L8,5.141,9.648,3.532a.5.5,0,0,0-.277-.853L7.089,2.346,6.069.277A.5.5,0,0,0,5.172.277Z"
                        transform="translate(-1.441 0.001)"
                        opacity="0.7"
                      />
                    </svg>
                  )}
                </h4>
                <p>
                  <p>{`${getSpecificRole(
                    roles,
                    offer.ProducerRole,
                  )} • ${getSpecificRole(
                    roles,
                    offer.ProducerSecondaryRole,
                  )}  `}</p>
                </p>
                <p>{offer.ProducerRegion}</p>
              </ProducerDataWrapper>
              <SvgRightTopWrapper>
                {VerifiedRole && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.303"
                    height="15"
                    viewBox="0 0 18.303 15"
                  >
                    <g
                      id="Icon_feather-user-check"
                      data-name="Icon feather-user-check"
                      transform="translate(-0.5 -3.5)"
                      opacity="0.7"
                    >
                      <path
                        style={{fill: 'none'}}
                        id="Path_4196"
                        data-name="Path 4196"
                        d="M12.333,26.833V25.389A2.889,2.889,0,0,0,9.444,22.5H4.389A2.889,2.889,0,0,0,1.5,25.389v1.444"
                        transform="translate(0 -9.333)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        style={{fill: 'none'}}
                        id="Path_4197"
                        data-name="Path 4197"
                        d="M12.528,7.389A2.889,2.889,0,1,1,9.639,4.5,2.889,2.889,0,0,1,12.528,7.389Z"
                        transform="translate(-2.722 0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        style={{fill: 'none'}}
                        id="Path_4198"
                        data-name="Path 4198"
                        d="M25.5,14.944l1.444,1.444L29.833,13.5"
                        transform="translate(-12.444 -4.667)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                )}
              </SvgRightTopWrapper>
            </TopSection>
            <BottomSection>
              <MobileCoverWithDescriptionContainer>
                <MobileCoverWithNameWrapper>
                  <img src={CoverFile} />

                  <MobileTextContentWrapper style={{width: 'auto'}}>
                    <MobileNameWithCategoriesWrapper>
                      <h4>{Name}</h4>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.699"
                        height="13.675"
                        viewBox="0 0 15.699 13.675"
                      >
                        <g
                          id="Icon_ionic-md-megaphone"
                          data-name="Icon ionic-md-megaphone"
                          transform="matrix(0.966, -0.259, 0.259, 0.966, -3.629, -1.801)"
                          opacity="0.7"
                        >
                          <path
                            id="Path_4193"
                            data-name="Path 4193"
                            d="M25.964,10.083l-.722.719a2.542,2.542,0,0,1,0,3.6l.722.722a3.565,3.565,0,0,0,0-5.041Z"
                            transform="translate(-12.773 -2.477)"
                          />
                          <path
                            id="Path_4194"
                            data-name="Path 4194"
                            d="M29.221,6.961l-.709.709a4.527,4.527,0,0,1,0,6.394l.709.709a5.523,5.523,0,0,0,0-7.812Z"
                            transform="translate(-14.59 -0.742)"
                          />
                          <path
                            id="Path_4195"
                            data-name="Path 4195"
                            d="M11.25,8.878V5.625h-1l-3,2.5h-4l-1,.5v3.5l1,.5,2.5,4h1.5l-.937-4H7.25l3,2h1V11.372a1.184,1.184,0,0,0,1-1.247A1.184,1.184,0,0,0,11.25,8.878Z"
                          />
                        </g>
                      </svg>

                      <p>
                        {firstCategory.Name || ''}
                        {secondCategory.Name ? `-${secondCategory.Name}` : ''}
                        {thirdCategory.Name ? `-${thirdCategory.Name}` : ''}
                      </p>
                      {/* <p>{secondCategory.Name || ''}</p>
                      <p>{thirdCategory.Name || ''}</p> */}
                    </MobileNameWithCategoriesWrapper>
                    <PriceWithWinWinWrapper>
                      <PriceWithTitleWrapper>
                        <p>od </p>

                        <h4>
                          {`${new Intl.NumberFormat('de-DE').format(
                            +GrossValue / 100,
                          )} zł`}
                        </h4>

                        <WinWinInfo IsWinWin={IsWinWin}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="55.53"
                            height="14"
                            viewBox="0 0 55.53 14"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_3780"
                                  data-name="Rectangle 3780"
                                  width="55.53"
                                  height="14"
                                  fill="#fb9614"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Group_6972"
                              data-name="Group 6972"
                              clipPath="url(#clip-path)"
                            >
                              <path
                                id="Path_4111"
                                data-name="Path 4111"
                                d="M210.528,39.406l-.892,1.937c-.223.484-.454,1-.669,1.491h-.015c-.038-.477-.085-.984-.138-1.468l-.215-1.96h-1.161l-.922,1.968c-.223.477-.461,1-.661,1.46h-.015c-.031-.461-.077-.976-.123-1.453l-.192-1.975h-1.653l.7,5.565h1.637l.615-1.314c.223-.477.453-.984.669-1.476h.015c.038.484.084.961.138,1.453l.146,1.337h1.668l2.675-5.565Z"
                                transform="translate(-182.052 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4112"
                                data-name="Path 4112"
                                d="M274.3,39.406l-.984,5.565H274.9l.984-5.565Z"
                                transform="translate(-244.065 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4113"
                                data-name="Path 4113"
                                d="M297.445,39.406l-.277,1.583c-.1.569-.2,1.176-.277,1.676l-.015.008c-.269-.515-.553-1.038-.838-1.553l-.946-1.714h-1.476l-.984,5.565h1.522l.277-1.584c.1-.569.2-1.176.277-1.676l.015-.008c.269.515.554,1.038.838,1.553l.945,1.714h1.476l.984-5.565Z"
                                transform="translate(-261.314 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4114"
                                data-name="Path 4114"
                                d="M349.151,61.188h1.929l.208-1.168h-1.929Z"
                                transform="translate(-311.784 -53.596)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4115"
                                data-name="Path 4115"
                                d="M377.569,39.406l-.892,1.937c-.223.484-.454,1-.669,1.491h-.015c-.038-.477-.085-.984-.138-1.468l-.215-1.96h-1.161l-.922,1.968c-.223.477-.461,1-.661,1.46h-.016c-.031-.461-.077-.976-.123-1.453l-.192-1.975h-1.653l.7,5.565h1.637l.615-1.314c.223-.477.453-.984.669-1.476h.016c.038.484.084.961.138,1.453l.146,1.337H376.5l2.675-5.565Z"
                                transform="translate(-331.215 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4116"
                                data-name="Path 4116"
                                d="M441.34,39.406l-.984,5.565h1.584l.984-5.565Z"
                                transform="translate(-393.228 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4117"
                                data-name="Path 4117"
                                d="M464.485,39.406l-.277,1.583c-.1.569-.2,1.176-.277,1.676l-.015.008c-.269-.515-.553-1.038-.838-1.553l-.946-1.714h-1.476l-.984,5.565h1.522l.277-1.584c.1-.569.2-1.176.277-1.676l.015-.008c.269.515.554,1.038.838,1.553l.945,1.714h1.476l.984-5.565Z"
                                transform="translate(-410.477 -35.189)"
                                fill="#fb9614"
                              />
                              <path
                                id="Path_4118"
                                data-name="Path 4118"
                                d="M16.552,2.18H15.467l-.822-.822H11.921l2.786,2.786.763-.039h1.082a.75.75,0,0,1,.749.749V9.137a.75.75,0,0,1-.748.749l-2.931,0-1.973,1.972a.75.75,0,0,1-1.06,0L7.562,8.829a.751.751,0,0,1-.172-.793l5.368-.171.269-.269a2.675,2.675,0,0,0,0-3.784L10,.785a2.676,2.676,0,0,0-3.784,0L4.809,2.192l-2.133,0A2.679,2.679,0,0,0,0,4.866V9.147a2.679,2.679,0,0,0,2.676,2.676h.919l.944.944H7.263L4.352,9.856l-.809.04H2.676a.75.75,0,0,1-.749-.749V4.866a.75.75,0,0,1,.748-.749l2.931,0L7.579,2.147a.749.749,0,0,1,1.059,0l3.027,3.027a.751.751,0,0,1,.172.793l-5.368.171L6.2,6.408a2.676,2.676,0,0,0,0,3.784l3.027,3.027a2.679,2.679,0,0,0,3.784,0l1.408-1.408,2.133,0a2.679,2.679,0,0,0,2.676-2.676V4.856A2.679,2.679,0,0,0,16.552,2.18"
                                transform="translate(0 -0.001)"
                                fill="#fb9614"
                              />
                            </g>
                          </svg>
                        </WinWinInfo>
                      </PriceWithTitleWrapper>
                    </PriceWithWinWinWrapper>
                    <MobileViewsCount isFollow={isFollow}>
                      {isFollow ? (
                        <svg
                          onClick={(event) => {
                            event.preventDefault();
                            follow();
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="14"
                          viewBox="0 0 21 14"
                        >
                          <path
                            id="Icon_material-playlist-add-check"
                            data-name="Icon material-playlist-add-check"
                            d="M15,13H3v2H15Zm0-4H3v2H15ZM3,19h8V17H3Zm19.5-4.5L24,16l-6.99,7L12.5,18.5,14,17l3.01,3Z"
                            transform="translate(-3 -9)"
                            fill="#0091ff"
                          />
                        </svg>
                      ) : (
                        <svg
                          onClick={(event) => {
                            event.preventDefault();
                            follow();
                          }}
                          // onClick={(event) => follow(event)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="14"
                          viewBox="0 0 20 14"
                        >
                          <path
                            id="Icon_material-playlist-add"
                            data-name="Icon material-playlist-add"
                            d="M15,13H3v2H15Zm0-4H3v2H15Zm4,8V13H17v4H13v2h4v4h2V19h4V17ZM3,19h8V17H3Z"
                            transform="translate(-3 -9)"
                            fill="#fff"
                          />
                        </svg>
                      )}
                      {/* {`${
                        ViewsInfo.ViewsCount
                      }  wyświetleń  •  opublikowano  ${getFormattedDate(
                        AvailabilityStartDate,
                      )}`} */}
                      <MobileViewsAndPublishedWrapper>
                        <p>{`${ViewsInfo.ViewsCount}  wyświetleń `}</p>
                        <p>
                          {`  opublikowano  ${getFormattedDate(
                            AvailabilityStartDate,
                          )}`}
                        </p>
                      </MobileViewsAndPublishedWrapper>
                    </MobileViewsCount>
                  </MobileTextContentWrapper>
                </MobileCoverWithNameWrapper>
                <p>{Description}</p>
              </MobileCoverWithDescriptionContainer>
            </BottomSection>
          </MobileSingleProductWrapper>
        </MobileSingleProductWithViewsCountWrapper>
      </StyledMobileLink>
    </>
  );
};

export default SingleProducts;
